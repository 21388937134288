import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { TeamNonCompliantUsers } from './schema';

/******************************************************************************
 * Get Team Non Compliant Users
 ******************************************************************************/

export interface getTeamNonCompliantUsersReq {
  team_id: string;
}

export const getTeamNonCompliantUsers =
  (options: ApiOptions) =>
  async ({ team_id }: getTeamNonCompliantUsersReq) =>
    request<{ data: TeamNonCompliantUsers }>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/non-compliant-users`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });

/******************************************************************************
 * Update Team Free Plan Blocking
 ******************************************************************************/

export interface UpdateTeamFreePlanBlockingReq {
  team_id: string;
  is_consumer_connection_blocked: boolean;
}

export const updateFreePlanBlocking =
  (options: ApiOptions) =>
  ({ team_id, ...body }: UpdateTeamFreePlanBlockingReq) =>
    request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/capabilities`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
