import { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  BaseModal,
  BaseModalProps,
  Button,
  FieldLabel,
  Input,
  ModalSize
} from '@parsec/components';
import { useUpdateTeamName } from '@parsec/queries';

const version = 'newFont';

const formSchema = (defaultName: string) =>
  z
    .object({
      name: z
        .string()
        .min(1)
        .max(100, { message: 'Name must be less than 100 characters' })
    })
    .refine(({ name }) => name !== defaultName, {
      message: 'New team name must be different from current team name',
      path: ['name']
    });

interface EditTeamNameModalProps extends BaseModalProps {
  defaultTeamName?: string;
}

export default function EditTeamNameModal({
  children,
  open: openProp,
  onOpenChange,

  defaultTeamName
}: EditTeamNameModalProps) {
  const [open, setOpen] = useState(openProp);
  const isModalOpen = Boolean(open || openProp); // openProp is used for controlled open

  const updateTeamName = useUpdateTeamName();

  type FormValues = z.infer<ReturnType<typeof formSchema>>;

  const formMethods = useForm<FormValues>({
    resolver: zodResolver(formSchema(defaultTeamName ?? '')),
    defaultValues: {
      name: defaultTeamName
    }
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors }
  } = formMethods;

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        formMethods.reset({ name: defaultTeamName }); // reset name prop on open
        updateTeamName.reset(); // reset mutation errors
      }
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [defaultTeamName, formMethods, onOpenChange, updateTeamName]
  );

  const onSubmit: SubmitHandler<FormValues> = async values => {
    try {
      await updateTeamName.mutateAsync({ name: values.name });
      handleOpenChange(false);
    } catch (_error) {
      // no-op error captured on mutation result
    }
  };

  return (
    <BaseModal open={isModalOpen} onOpenChange={handleOpenChange}>
      {children ? (
        <BaseModal.Trigger asChild>{children}</BaseModal.Trigger>
      ) : null}
      <BaseModal.Portal>
        <BaseModal.Overlay>
          <BaseModal.Content size={ModalSize.Small}>
            <BaseModal.DialogueHeader>
              <BaseModal.Title>Edit Team Name</BaseModal.Title>
            </BaseModal.DialogueHeader>
            <BaseModal.ContentWrapper>
              <form id="team_name_form" onSubmit={handleSubmit(onSubmit)}>
                <FieldLabel
                  version={version}
                  hasError={Boolean(formErrors['name'])}
                >
                  <FieldLabel.Label label="Team Name">
                    <Input version={version} autoFocus {...register('name')} />
                  </FieldLabel.Label>
                  <FieldLabel.HelperTextContainer>
                    <FieldLabel.Message match={({ hasError }) => hasError}>
                      {formErrors['name']?.message}
                    </FieldLabel.Message>
                  </FieldLabel.HelperTextContainer>
                </FieldLabel>
              </form>
            </BaseModal.ContentWrapper>
            <BaseModal.Footer
              errorMessage={updateTeamName.error?.error}
              errorType="error"
            >
              <Button
                form="team_name_form"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
              <BaseModal.Close asChild>
                <Button level="secondary">Cancel</Button>
              </BaseModal.Close>
            </BaseModal.Footer>
          </BaseModal.Content>
        </BaseModal.Overlay>
      </BaseModal.Portal>
    </BaseModal>
  );
}
