import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, warpSubscription, WarpSubscription } from './schema';

/******************************************************************************
 * Create
 ******************************************************************************/

export interface CreateWarpSubscriptionReq {
  plan_id: 'warp-monthly' | 'warp-yearly';
  token: string;
  customer: {
    email: string;
    company?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    tax_id?: string;
    billing_address: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      zip?: string;
      country: string;
    };
  };
  coupon?: string;
}

export const createWarpSubscription =
  (options: ApiOptions) => (body: CreateWarpSubscriptionReq) => {
    return request<{ data: WarpSubscription }>({
      type: Method.POST,
      url: `${options.kessel}/warp`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });
  };

/******************************************************************************
 * Get
 ******************************************************************************/

export const getWarpSubscription = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/warp/subscription`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(warpSubscription)
  });

/******************************************************************************
 * Update
 ******************************************************************************/

export interface UpdateWarpSubscriptionReq {
  plan_id: string;
  coupon?: string;
}

export const updateWarpSubscription =
  (options: ApiOptions) => (body: UpdateWarpSubscriptionReq) =>
    request({
      type: Method.PATCH,
      url: `${options.kessel}/warp/subscription`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(warpSubscription),
      body
    });

/******************************************************************************
 * Cancel
 ******************************************************************************/

export const cancelWarpSubscription = (options: ApiOptions) => () =>
  request({
    type: Method.POST,
    url: `${options.kessel}/warp/subscription/cancel`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(warpSubscription)
  });

/******************************************************************************
 * Resume
 ******************************************************************************/

export const resumeWarpSubscription = (options: ApiOptions) => () =>
  request({
    type: Method.POST,
    url: `${options.kessel}/warp/subscription/resume`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(warpSubscription)
  });
