import { forwardRef } from 'react';

import { styled } from '@parsec/styles';

export type DividerProps = React.ComponentPropsWithRef<typeof Hr>;

export const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
  return <Hr {...props} ref={ref} />;
});

Divider.displayName = 'Divider';

export default Divider;

const Hr = styled('hr', {
  height: '0.1rem',
  backgroundColor: '$pancham',
  border: 'none'
});
