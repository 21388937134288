/**
 * The ProgressCounter component allow the user to know
 * at what stage he is in a process like a survey for instance.
 * It will be used in the sign up survey.
 * https://www.figma.com/file/AkCHyGFT5hweGtoEuzuQNl/Marketing---New-Sign-up-%2F-Onboarding?type=design&node-id=1305-1739&t=XCWJ5MKb9CJnPhL0-4
 */
import { type ReactNode } from 'react';

import { styled, keyframes } from '@parsec/styles';

// STYLES
const Container = styled('div', {
  width: '100%',
  display: 'grid',
  rowGap: '$medium'
});

const ProgressCounterLabel = styled('p', {
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn'
});

const ProgressCounterItems = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  gap: '$small'
});

const ProgressCounterItem = styled('div', {
  width: '7.6rem',
  height: '0.2rem',
  backgroundColor: 'rgba(105, 105, 105, 0.69)'
});

const PassedColorAnimation = keyframes({
  '0%': { backgroundColor: '$primary500' },
  '100%': { backgroundColor: '$consoleWhite' }
});

const ActiveScaleAnimation = keyframes({
  '0%': {
    transform: 'scaleX(0)',
    transformOrigin: '0% 0%',
    backgroundColor: '$primary500'
  },
  '100%': {
    transform: 'scaleX(1)',
    transformOrigin: '0% 0%',
    backgroundColor: '$primary500'
  }
});

const ProgressCounterInsideItem = styled('div', {
  height: '0.2rem',
  width: '100%',
  variants: {
    isActive: {
      true: {
        animation: `${ActiveScaleAnimation} 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`
      }
    },
    isPassed: {
      true: {
        backgroundColor: '$consoleWhite',
        animation: `${PassedColorAnimation} 0.5s linear`
      }
    }
  }
});

// COMPONENTS
/**
 * ProgressCounter base component
 */
export interface ProgressCounterProps {
  children: ReactNode;
}

function ProgressCounter(props: ProgressCounterProps) {
  const { children, ...rest } = props;
  return <Container {...rest}>{children}</Container>;
}

export default ProgressCounter;

/**
 * Label
 */
interface LabelProps {
  children: ReactNode;
}

const Label = (props: LabelProps) => {
  const { children, ...rest } = props;
  return <ProgressCounterLabel {...rest}>{children}</ProgressCounterLabel>;
};

/**
 * Items
 */
interface ItemsProps {
  children: ReactNode;
}

const Items = (props: ItemsProps) => {
  const { children, ...rest } = props;
  return <ProgressCounterItems {...rest}>{children}</ProgressCounterItems>;
};

/**
 * Item
 */
interface ItemProps {
  isActive?: boolean;
  isPassed?: boolean;
}

const Item = (props: ItemProps) => {
  const { isActive = false, isPassed = false, ...rest } = props;

  return (
    <ProgressCounterItem {...rest}>
      <ProgressCounterInsideItem isActive={isActive} isPassed={isPassed} />
    </ProgressCounterItem>
  );
};

// EXPORTS
ProgressCounter.Label = Label;
ProgressCounter.Items = Items;
ProgressCounter.Item = Item;
