import {
  createContext,
  useContext,
  ChangeEvent,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  useEffect
} from 'react';

import { styled } from '@parsec/styles';

import { priceFormatCents, noop } from '../../utils';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Input from '../Input';

// ========= STYLES ========= //
const Title = styled('h3', {
  fontFamily: '$newDefault',
  fontSize: '$body',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  paddingBottom: '$small'
});

const GridWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridRowGap: '$xxlarge'
});

const ContentWrapper = styled(GridWrapper, {
  gridRowGap: '$medium',
  gridTemplateColumns: '16rem auto auto',
  gridTemplateAreas:
    '"seatsCount explanation info" "actions actions actions" "error error error"',
  gridColumnGap: '$xxxlarge',
  padding: '$xlarge $xlarge $medium',
  width: '94rem',
  alignItems: 'end',
  background: '$carkol',
  borderRadius: '$large'
});

const Actions = styled('div', {
  fontFamily: '$newDefault',
  gridArea: 'actions',
  display: 'flex',
  columnGap: '$xlarge',
  justifySelf: 'start',
  marginTop: '$xlarge'
});

const InfoWrapper = styled(GridWrapper, {
  fontFamily: '$newDefault',
  gridColumnGap: '$xlarge',
  gridArea: 'info',
  gridTemplateColumns: '1.6rem auto',
  alignItems: 'center',
  border: '1px solid $pancham',
  borderRadius: '$large',
  padding: '$xlarge'
});

const InfoText = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '1rem',
  lineHeight: '$info'
});

const StyledIcon = styled(Icon, {
  width: '1.6rem',
  height: '1.6rem'
});

const ExplanationWrapper = styled(GridWrapper, {
  gridRowGap: '$xlarge',
  gridColumnGap: '$xlarge',
  gridArea: 'explanation',
  gridTemplateAreas: '"title title title" "seats equal price"',
  gridTemplateColumns: 'auto 1.7rem auto'
});

const BoldText = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution',
  fontWeight: '$bold'
});

const GrayedText = styled('span', {
  fontFamily: '$newDefault',
  color: '$rhyhorn',
  fontSize: '$newBody',
  variants: {
    inherit: {
      true: {
        fontSize: 'inherit'
      }
    }
  }
});

const CalcWrapper = styled(GridWrapper, {
  fontFamily: '$newDefault',
  gridRowGap: '$medium',
  variants: {
    kind: {
      seats: {
        gridArea: 'seats'
      },
      price: {
        gridArea: 'price'
      }
    }
  }
});

const CalcText = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$subtitle',
  lineHeight: '$attribution',
  fontWeight: '$bold',
  variants: {
    kind: {
      blue: {
        color: '$primary500'
      }
    }
  }
});

const FormWrapper = styled('form', {
  fontFamily: '$newDefault',
  background: '$ultraDark',
  height: '9.8rem',
  borderRadius: '$large',
  display: 'grid',
  gridRowGap: '$medium',
  justifyItems: 'center',
  padding: '$medium $medium $xxlarge'
});

const Controls = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateAreas: '"dec input inc"',
  alignItems: 'center',
  gridColumnGap: '$large'
});

const Paddle = styled(IconButton, {
  width: '1.8rem',
  height: '1.8rem',
  color: '$primary500',
  '&:disabled': {
    opacity: 0.5,
    cursor: 'default'
  },
  variants: {
    kind: {
      inc: {
        gridArea: 'inc'
      },
      dec: {
        gridArea: 'dec'
      }
    }
  }
});

const SeatsInput = styled(Input, {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  gridArea: 'input',
  borderRadius: '$large',
  textAlign: 'center',
  width: '4.8rem',
  backgroundColor: '$cereza'
});

const ErrorText = styled(InfoText, {
  fontFamily: '$newDefault',
  gridArea: 'error',
  color: '$error500',
  lineHeight: '$attribution'
});

// ========= CONSTANTS ========= //
const INTERVAL_YEARLY = 'year';
const INTERVAL_MONTHLY = 'month';

export const enum ErrorType {
  Invalid = 'invalid',
  BelowMin = 'belowMin',
  AboveMax = 'aboveMax'
}

// ========= CONTEXT ========= //
export type ManageTeamSeatsErrorType = {
  hasError: boolean;
  type: ErrorType | '';
};

interface ContextType {
  seatUnitPrice: number;
  interval: typeof INTERVAL_YEARLY | typeof INTERVAL_MONTHLY;
  minPaidSeats: number;
  maxPaidSeats: number;
  currentPaidSeats: number;
  seatsDelta: number;
  updatedSeats: number;
  onSetUpdatedSeats: (seats: number) => void;
  error: ManageTeamSeatsErrorType;
  onSetError: Dispatch<SetStateAction<ManageTeamSeatsErrorType>>;
  onValidateSeatCount?: (seatCount: string) => void;
  onInputBlur?: (seatCount: number | '') => void;
}

const ManageTeamSeatsContext = createContext<ContextType>({
  seatUnitPrice: 0,
  interval: INTERVAL_MONTHLY,
  minPaidSeats: 0,
  maxPaidSeats: 0,
  currentPaidSeats: 0,
  seatsDelta: 0,
  updatedSeats: 0,
  onSetUpdatedSeats: noop,
  error: {
    hasError: false,
    type: ''
  },
  onSetError: noop,
  onValidateSeatCount: noop,
  onInputBlur: noop
});

// ========= COMPONENTS ========= //
const Info = () => {
  const { interval, minPaidSeats, seatUnitPrice } = useContext(
    ManageTeamSeatsContext
  );

  const formattedUnitPrice = priceFormatCents(
    interval === INTERVAL_YEARLY ? seatUnitPrice * 12 : seatUnitPrice
  );

  return (
    <InfoWrapper>
      <StyledIcon name="infoFilled" />
      <InfoText>
        Note: Minimum of {minPaidSeats} seats per team. Based on your {interval}
        ly subscription, 1 seat costs {formattedUnitPrice} / {interval}.
      </InfoText>
    </InfoWrapper>
  );
};

const Explanation = () => {
  const {
    updatedSeats,
    seatUnitPrice,
    seatsDelta,
    currentPaidSeats,
    interval
  } = useContext(ManageTeamSeatsContext);

  const price = useMemo(
    () =>
      interval === INTERVAL_MONTHLY
        ? updatedSeats * seatUnitPrice
        : updatedSeats * seatUnitPrice * 12,
    [interval, updatedSeats, seatUnitPrice]
  );
  const formattedCalcPrice = priceFormatCents(price);

  return (
    <ExplanationWrapper>
      <BoldText style={{ gridArea: 'title' }}>
        Payment at next renewal <GrayedText>(Before taxes)</GrayedText>
      </BoldText>
      <CalcWrapper kind="seats">
        <CalcText>
          {currentPaidSeats}{' '}
          {seatsDelta > 0 ? (
            <CalcText kind="blue" as="span">
              + {seatsDelta}
            </CalcText>
          ) : seatsDelta < 0 ? (
            <CalcText kind="blue" as="span">
              - {Math.abs(seatsDelta)}
            </CalcText>
          ) : null}
        </CalcText>
        <GrayedText>Seats</GrayedText>
      </CalcWrapper>
      <CalcText style={{ gridArea: 'equal' }}>
        <GrayedText inherit>=</GrayedText>
      </CalcText>
      <CalcWrapper kind="price">
        <CalcText kind={seatsDelta != 0 ? 'blue' : undefined}>
          {formattedCalcPrice}
        </CalcText>
        <GrayedText>
          Billed {interval === 'month' ? 'month' : 'annual'}ly
        </GrayedText>
      </CalcWrapper>
    </ExplanationWrapper>
  );
};

const SeatsCounter = ({ label = 'Total Seats' }: { label?: string }) => {
  const {
    currentPaidSeats,
    minPaidSeats,
    maxPaidSeats,
    onSetUpdatedSeats,
    updatedSeats,
    onValidateSeatCount,
    onSetError,
    onInputBlur
  } = useContext(ManageTeamSeatsContext);

  const [seatInputValue, setSeatInputValue] = useState<number | ''>(
    currentPaidSeats
  );

  // Reset input value when user click "Clear Changes" btn
  useEffect(() => {
    setSeatInputValue(updatedSeats);
  }, [updatedSeats]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      onValidateSeatCount?.(inputValue);
      // if it's not numeric, we don't want it
      if (!/^[0-9]*$/.test(inputValue) || inputValue === '') {
        setSeatInputValue('');
        onSetError({
          hasError: true,
          type: ErrorType.Invalid
        });
      } else if (Number(inputValue) < minPaidSeats) {
        setSeatInputValue(Number(inputValue));
        onSetError({
          hasError: true,
          type: ErrorType.BelowMin
        });
      } else if (Number(inputValue) > maxPaidSeats) {
        setSeatInputValue(Number(inputValue));
        onSetError({
          hasError: true,
          type: ErrorType.AboveMax
        });
      } else {
        setSeatInputValue(Number(inputValue));
        onSetError({
          hasError: false,
          type: ''
        });
      }
    },
    [onValidateSeatCount, onSetError, minPaidSeats, maxPaidSeats]
  );

  const handleInputBlur = useCallback(() => {
    onInputBlur?.(seatInputValue); // to be deprecated via https://jira.unity3d.com/browse/PARSEC-4112
    onSetError({
      hasError: false,
      type: ''
    });
    if (seatInputValue === '' || isNaN(seatInputValue)) {
      setSeatInputValue(currentPaidSeats);
    } else if (seatInputValue < minPaidSeats) {
      setSeatInputValue(minPaidSeats);
      onSetUpdatedSeats(minPaidSeats);
    } else if (seatInputValue > maxPaidSeats) {
      setSeatInputValue(maxPaidSeats);
      onSetUpdatedSeats(maxPaidSeats);
    } else {
      onSetUpdatedSeats(seatInputValue);
    }
  }, [
    onInputBlur,
    seatInputValue,
    onSetError,
    minPaidSeats,
    maxPaidSeats,
    currentPaidSeats,
    onSetUpdatedSeats
  ]);

  // Have to re-evaluate when user presses enter
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevents unintended change when pressing enter
        handleInputBlur();
      }
    },
    [handleInputBlur]
  );

  const handleButtonClick = useCallback(
    (type: 'dec' | 'inc') => {
      const addValue = type === 'dec' ? -1 : 1;

      const newSeat = !isNaN(Number(seatInputValue))
        ? Number(seatInputValue) + addValue
        : '';
      setSeatInputValue(newSeat);
      onSetUpdatedSeats(Number(newSeat));
    },
    [onSetUpdatedSeats, seatInputValue]
  );

  return (
    <FormWrapper onSubmit={(e: React.FormEvent) => e.preventDefault()}>
      <BoldText as="label" htmlFor="seats">
        {label}
      </BoldText>
      <Controls>
        <Paddle
          id="decrement_seats"
          css={{ color: '$primary500' }}
          disabled={Number(seatInputValue) <= minPaidSeats}
          title="Decrement seats"
          icon="minus"
          kind="dec"
          onClick={() => handleButtonClick('dec')}
        />
        <SeatsInput
          id="seats"
          pattern="[0-9]*"
          name="seats"
          type="number"
          step="1"
          value={seatInputValue}
          min={minPaidSeats}
          max={maxPaidSeats}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event)
          }
          onBlur={handleInputBlur}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            handleKeyDown(event)
          }
          required
        />
        <Paddle
          id="increment_seats"
          css={{ color: '$primary500' }}
          title="Increment seats"
          icon="plus"
          kind="inc"
          onClick={() => handleButtonClick('inc')}
          disabled={Number(seatInputValue) >= maxPaidSeats}
        />
      </Controls>
    </FormWrapper>
  );
};

const ErrorMessage = () => {
  const { error, minPaidSeats, maxPaidSeats } = useContext(
    ManageTeamSeatsContext
  );

  switch (error.type) {
    case ErrorType.Invalid:
      return <ErrorText>Please enter a valid number of seats.</ErrorText>;
    case ErrorType.AboveMax:
      return <ErrorText>Seats have a maximum of {maxPaidSeats}.</ErrorText>;
    case ErrorType.BelowMin:
      return <ErrorText>Seats must be a minimum of {minPaidSeats}.</ErrorText>;
    default:
      return null;
  }
};

export interface ManageTeamSeatsProps
  extends Omit<ContextType, 'seatsDelta' | 'error' | 'onSetError'> {
  children: ReactNode;
}

export const ManageTeamSeats = ({
  children,
  currentPaidSeats,
  updatedSeats,
  ...rest
}: ManageTeamSeatsProps) => {
  const seatsDelta = useMemo(() => {
    return updatedSeats - currentPaidSeats;
  }, [currentPaidSeats, updatedSeats]);

  const [error, setError] = useState<ManageTeamSeatsErrorType>({
    hasError: false,
    type: ''
  });

  const contextValues = {
    currentPaidSeats,
    seatsDelta,
    updatedSeats,
    error,
    onSetError: setError,
    ...rest
  } as ContextType;

  return (
    <ManageTeamSeatsContext.Provider value={contextValues}>
      <GridWrapper>{children}</GridWrapper>
    </ManageTeamSeatsContext.Provider>
  );
};

ManageTeamSeats.Title = Title;
ManageTeamSeats.Content = ContentWrapper;
ManageTeamSeats.Info = Info;
ManageTeamSeats.Actions = Actions;
ManageTeamSeats.Explanation = Explanation;
ManageTeamSeats.SeatsCounter = SeatsCounter;
ManageTeamSeats.Error = ErrorMessage;
