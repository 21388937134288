import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, teamSubscription } from './schema';

/******************************************************************************
 * Get Team Subscription
 ******************************************************************************/

export interface GetTeamSubscriptionReq {
  team_id: string;
}

export const getTeamSubscription =
  (options: ApiOptions) =>
  ({ team_id }: GetTeamSubscriptionReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/subscription`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(teamSubscription)
    });

/******************************************************************************
 * Update Team Subscription
 ******************************************************************************/

export interface UpdateTeamSubscriptionReq {
  team_id: string;
  plan_id?: string;
  standard_seats?: number;
  po_number?: string;
}

export const updateTeamSubscription =
  (options: ApiOptions) =>
  ({ team_id, ...body }: UpdateTeamSubscriptionReq) =>
    request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/subscription`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body,
      middleware: options.middleware,
      validator: data(teamSubscription)
    });

/******************************************************************************
 * Cancel Team Subscription
 ******************************************************************************/

export interface CancelTeamSubscriptionReq {
  team_id: string;
}

export const cancelTeamSubscription =
  (options: ApiOptions) =>
  ({ team_id }: CancelTeamSubscriptionReq) =>
    request({
      type: Method.POST,
      headers: { Authorization: `Bearer ${options.token()}` },
      url: `${options.kessel}/teams/${team_id}/subscription/cancel`,
      middleware: options.middleware,
      validator: data(teamSubscription)
    });

/******************************************************************************
 * Resume Team Subscription
 ******************************************************************************/

export interface ResumeTeamSubscriptionReq {
  team_id: string;
}

export const resumeTeamSubscription =
  (options: ApiOptions) =>
  ({ team_id }: ResumeTeamSubscriptionReq) =>
    request({
      type: Method.POST,
      headers: { Authorization: `Bearer ${options.token()}` },
      url: `${options.kessel}/teams/${team_id}/subscription/resume`,
      middleware: options.middleware,
      validator: data(teamSubscription)
    });

/******************************************************************************
 * Remove Scheduled Changes
 ******************************************************************************/

export const removeScheduledTeamSubChanges =
  (options: ApiOptions) =>
  ({ team_id }: { team_id: string }) =>
    request({
      type: Method.POST,
      headers: { Authorization: `Bearer ${options.token()}` },
      url: `${options.kessel}/teams/${team_id}/subscription/remove-scheduled-changes`,
      middleware: options.middleware
    });
