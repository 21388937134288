import Script from 'next/script';

import { ONETRUST_DATA_DOMAIN_SCRIPT } from '../config';

const OneTrust = () => (
  <>
    <Script
      id="onetrust"
      strategy="afterInteractive"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script={ONETRUST_DATA_DOMAIN_SCRIPT}
      data-dLayer-ignore="true"
      data-document-language="true"
      type="text/javascript"
    />
    <Script
      id="onetrustWrap"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `function OptanonWrapper(){}`
      }}
    />
  </>
);

export default OneTrust;
