import { useEffect, useState } from 'react';

import { useGetTeamSubscription } from '@parsec/queries';
import { daysBetween } from '@parsec/time';

export function useTeamTrialDate() {
  const subscription = useGetTeamSubscription();

  const [data, setData] = useState<{
    daysLeft: number;
    endDate: Date;
  }>();
  useEffect(() => {
    if (
      subscription.data?.trial_end &&
      subscription.data.status === 'in_trial'
    ) {
      const endDate = new Date(subscription.data.trial_end);
      const daysLeft = daysBetween(new Date(), endDate);

      setData({ daysLeft: daysLeft > 0 ? daysLeft : 0, endDate: endDate });
    } else {
      //resets if looking in the moment of conversion. edge case.
      setData(undefined);
    }
  }, [subscription.data?.trial_end, subscription.data?.status]);

  return data;
}
