/**
 * Use to tell users that we have an active incident
 * See https://www.figma.com/file/8AImDcDmtp69BN5weGgKeA/Parsec-For-Teams?type=design&node-id=4920-35312&mode=design&t=KyAOL3JYy0NpfvpO-0
 */

import AlertBanner from '../AlertBanner';
import { IncidentStatus } from '../SharedType';

import { useIncidentNotification } from './IncidentNotificationContext';

// COMPONENT
export interface IncidentNotificationBannerProps {
  status: IncidentStatus;
  onClose?: () => void;
  text: string | JSX.Element;
  title?: string;
}

interface Incidents {
  status: IncidentStatus;
  level: 'ok' | 'warn' | 'error';
  title: string;
}

const incidents: Incidents[] = [
  {
    status: IncidentStatus.Positive,
    level: 'ok',
    title: 'Resolved'
  },
  {
    status: IncidentStatus.Neutral,
    level: 'warn',
    title: 'Monitoring'
  },
  {
    status: IncidentStatus.Negative,
    level: 'error',
    title: 'Issue'
  },
  {
    status: IncidentStatus.Maintenance,
    level: 'warn',
    title: 'Upcoming Maintenance'
  },
  {
    status: IncidentStatus.UnplannedMaintenance,
    level: 'warn',
    title: 'Unplanned Maintenance'
  },
  {
    status: IncidentStatus.MaintenanceDown,
    level: 'error',
    title: 'Connections Down for Scheduled Maintenance'
  },
  {
    status: IncidentStatus.MaintenanceDone,
    level: 'ok',
    title: 'Scheduled Maintenance Complete'
  }
];

function IncidentNotificationBanner(props: IncidentNotificationBannerProps) {
  const { onClose, status, text, title } = props;
  const { onIncidentClose, isDismissible } = useIncidentNotification();

  const incident = incidents.find(incident => incident.status === status);
  if (incident === undefined) return null;

  return (
    <AlertBanner
      isDismissible={isDismissible}
      level={incident.level}
      onClose={() => {
        onIncidentClose();
        onClose?.();
      }}
    >
      <AlertBanner.Title>{title ?? incident.title}</AlertBanner.Title>
      <AlertBanner.Description>{text}</AlertBanner.Description>
    </AlertBanner>
  );
}

export default IncidentNotificationBanner;
