import { record, string, number } from 'narrows';

// @parsec
import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { Auth, auth } from './schema';

export interface CreateSessionReq {
  email: string;
  password: string;
  tfa?: string;
  token?: string;
  captcha_token: string;
}

export interface CreateSessionError {
  error: string;
  tfa_required?: boolean;
}

export const createSession =
  (options: ApiOptions) => (body: CreateSessionReq) =>
    request<Auth, CreateSessionError>({
      type: Method.POST,
      url: `${options.kessel}/v2/auth`,
      body: {
        ...body,
        session_scopes: ['api.writer', 'team.admin']
      },
      middleware: options.middleware,
      validator: auth
    });

export const deleteSession = (options: ApiOptions) => () =>
  request({
    type: Method.DELETE,
    url: `${options.kessel}/auth/sessions`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware
  });

export interface SAMLLogInReq {
  team_id: string;
}

export const samlLogIn =
  (options: ApiOptions) =>
  ({ team_id }: SAMLLogInReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/v2/saml/weblogin/${team_id}`,
      body: { session_scopes: ['api.writer', 'team.admin', 'ws.client'] },
      middleware: options.middleware,
      validator: record({ sso_url: string })
    });

export interface ConfirmSAMLSSOReq {
  token: string;
  email: string;
  password: string;
  tfa?: string;
}

export const confirmSAMLSSO =
  (options: ApiOptions) => (body: ConfirmSAMLSSOReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/saml/confirm`,
      middleware: options.middleware,
      body
    });

export interface SAMLSessionReq {
  signed_token: string;
}

export const createSamlSession =
  (options: ApiOptions) => (body: SAMLSessionReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/v2/saml/session/token`,
      body,
      middleware: options.middleware,
      validator: record({ session_id: string })
    });

export const getZendeskToken = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/zendesk`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: record({ jwt: string })
  });

export interface GetTeamSamlLoginAttemptReq {
  jwt: string;
}

export const getTeamSamlLoginAttempt =
  (options: ApiOptions) =>
  ({ jwt }: GetTeamSamlLoginAttemptReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/saml/approve?approval_token=${jwt}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: record({
        requester_ip: string,
        requester_location: string,
        approver_ip: string,
        approver_location: string,
        expires_at: number
      })
    });

export interface ApproveTeamSamlLoginAttemptReq {
  approval_token: string;
  approved: boolean;
}

export const approveTeamSamlLoginAttempt =
  (options: ApiOptions) => (body: ApproveTeamSamlLoginAttemptReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/saml/approve`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
