import { useMemo } from 'react';

import {
  useGetTeamSubscription,
  useGetTeamBillingDetails,
  useEstimateTeamSubscription,
  useGetTeam
} from '@parsec/queries';

export function useTeamSubscriptionCost(
  props: {
    quantity?: number;
    plan?: string;
  } = {}
) {
  const { quantity, plan } = props;

  const team = useGetTeam();

  const { data: subscription } = useGetTeamSubscription();
  const { data: billingDetails } = useGetTeamBillingDetails();

  const estimatePlanId = plan ?? subscription?.plan_id ?? '';

  const seats = quantity ?? team.data?.capabilities.standard_seats ?? 0;

  const enabled = Boolean(
    subscription && billingDetails && seats > 0 && estimatePlanId
  );

  const estimate = useEstimateTeamSubscription(
    {
      plan_id: estimatePlanId,
      country: billingDetails?.billing_address.country,
      zip: billingDetails?.billing_address.zip,
      seats: seats
    },
    { enabled }
  );

  const unitPrice = useMemo(() => {
    const plan = estimate.data?.line_items.find(item => item.type === 'plan');
    return plan?.unit_amount_cents;
  }, [estimate]);

  return {
    subtotal: estimate.data?.subtotal_amount_cents ?? NaN,
    total: estimate.data?.total_amount_cents ?? NaN,
    discount: estimate.data?.discount_amount_cents ?? NaN,
    tax: estimate.data?.tax_amount_cents ?? NaN,
    error: estimate.error?.error,
    unitPrice: unitPrice ?? NaN
  };
}
