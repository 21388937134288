import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { creditBalance, creditEstimate, data } from './schema';

export interface AddAccessLinkCreditReq {
  team_id: string;
  price_type: 'standard' | 'package';
  quantity: number;
  nonce: string;
}

export const addAccessLinkCredit =
  (options: ApiOptions) =>
  /**
   * Adds Access Link Credits
   */
  async ({ team_id, ...body }: AddAccessLinkCreditReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/access-link-credits`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });

export interface GetAccessLinkCreditBalanceReq {
  team_id: string;
}

export const getAccessLinkCreditBalance =
  (options: ApiOptions) =>
  /**
   * Get Access Link Credit balance
   */
  async ({ team_id }: GetAccessLinkCreditBalanceReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/access-link-credits/balance`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(creditBalance)
    });

export interface GetAccessLinkCostEstimateReq {
  team_id: string;
  emails: string[];
}

export const getAccessLinkCostEstimate =
  (options: ApiOptions) =>
  /**
   * Get Access Link cost estimate
   */
  async ({ team_id, ...body }: GetAccessLinkCostEstimateReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/access-link-credits/estimate`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(creditEstimate),
      body
    });
