import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';

export interface SignUpReq {
  email: string;
  name: string;
  password: string;
  marketing_opt_in: boolean | null;
  captcha_token: string;
}

export const signUp = (options: ApiOptions) => (body: SignUpReq) => {
  return request({
    type: Method.POST,
    url: `${options.kessel}/v2/public/signup`,
    body
  });
};

export interface SendPasswordResetEmailReq {
  email: string;
  captcha_token: string;
}

export const sendPasswordResetEmail =
  (options: ApiOptions) => (body: SendPasswordResetEmailReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/public/password/send-reset-email`,
      body
    });

export interface ResetPasswordReq {
  code: string;
  email: string;
  password: string;
  tfa?: string;
  captcha_token: string;
}

export const resetPassword =
  (options: ApiOptions) => (body: ResetPasswordReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/public/password/reset`,
      body
    });
