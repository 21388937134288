import { useState, useEffect } from 'react';

/** Name of the Parsec cookie */
const NAME = 'parsec_login';

/** Default value for the Parsec cookie */
const DEFAULT_COOKIE = {
  token: '',
  redirect: ''
};

/** Information that is stored in the Parsec cookie */
export type ParsecCookie = typeof DEFAULT_COOKIE;

/** Gets the domain of the current window URL */
function getDomain() {
  if (typeof window === 'undefined') return '';
  const { hostname = '' } = window.location;
  return hostname === 'localhost' ? '' : hostname;
}

function getLegacyDomain() {
  if (typeof window === 'undefined') return '';
  const { hostname = '' } = window.location;
  return hostname === 'localhost'
    ? ''
    : hostname.substring(
        hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1
      );
}

/** Sets the value of document.cookie */
function setCookie(cookie: string) {
  if (typeof document !== 'undefined') document.cookie = cookie;
}

/** Saves a ParsecCookie */
export function save(input: Partial<ParsecCookie>) {
  const prev = cookie();
  const value = JSON.stringify({ ...prev, ...input });
  const secure = window.location.protocol === 'https:' ? 'secure;' : '';

  const full = `${NAME}=${value};domain=${getDomain()};path=/;SameSite=Strict;${secure}max-age=31536000`;
  setCookie(full);
}

/**
 * Clears legacy cookie. Not dash cookie.
 * Follow up ticket: PARSEC-1624
 */
function clearLegacyCookie() {
  const legacyDomain = getLegacyDomain();
  const dashDomain = getDomain();
  if (legacyDomain !== dashDomain) {
    // can't mock domains, so the tests still work
    const legacyCookie = `${NAME}=;domain=${getLegacyDomain()};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    setCookie(legacyCookie);
  }
}

/** Clears a ParsecCookie */
export function clear() {
  const cookie = `${NAME}=;domain=${getDomain()};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  clearLegacyCookie();
  setCookie(cookie);
}

/** Gets a ParsecCookie */
export const cookie = (() => {
  let prev = '';
  let memo: ParsecCookie | undefined;

  return () => {
    const next = typeof document !== 'undefined' ? document.cookie : '';
    // Do string comparson to avoid parsing cookie each time
    if (prev !== next) {
      // If the cookie changes, parse and memoize the latest auth state
      memo = parse<{ parsec_login: ParsecCookie }>(next)?.parsec_login;
      if (memo) ensureRedirectIsFromValidDomain(memo);
      prev = next;
    }

    return memo || DEFAULT_COOKIE;
  };
})();

/** Parses a cookie */
function parse<T extends object>(cookie: string): Partial<T> {
  const result: Partial<T> = {};
  for (const c of cookie.split(';')) {
    const [key, val] = c.trim().split(/=(.+)/); // The value may contain =, so only split on first instance

    try {
      result[key as keyof T] = JSON.parse(val);
    } catch (_err) {
      // console.error('Could not parse cookie', key, val, err);
    }
  }

  return result;
}

/** Rewrites the redirect url set in the cookie if it's not going to a TLD or supported subdomain */
function ensureRedirectIsFromValidDomain(obj: ParsecCookie) {
  const domain = getDomain();

  if (domain) {
    const redirectURL = obj.redirect ?? '';
    const hasRedirect = redirectURL !== '';

    const okayURLS = [
      `https://account.${domain}`,
      `https://teams.${domain}`,
      `https://support.${domain}`,
      `https://dash.${domain}`,
      `https://${domain}`,
      '/' // relative urls
    ];
    if (hasRedirect && !okayURLS.some(url => redirectURL.startsWith(url))) {
      obj.redirect = '';
    }
  }
}

// TODO: move into @parsec/hooks
/**
 * React Hook that polls for the ParsecCookie
 */
export function useCookie() {
  const [state, setState] = useState(cookie());
  useEffect(() => {
    const timer = setInterval(() => {
      const result = cookie();
      if (result.token !== state.token || result.redirect !== state.redirect) {
        setState(state => ({
          ...state,
          ...result
        }));
      }
    }, 100);
    return () => clearInterval(timer);
  }, [setState, state.token, state.redirect]);
  return state;
}
