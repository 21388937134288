import { ComponentPropsWithRef, ReactNode } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Separator from '@radix-ui/react-separator';

import { VariantProps, keyframes, styled } from '@parsec/styles';

import BaseBillingCardForm from '../BillingCardForm';
import Button, { ButtonProps } from '../Button';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Input, { type InputProps } from '../Input';
import Tooltip from '../Tooltip';

// custom billing card preview for the modal
import BillingCardPreview from './BillingCardPreview';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const DialogOverlay = styled(Dialog.Overlay, {
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  fontFamily: '$newDefault',
  zIndex: 1 // needed for teams app
});

const DialogContent = styled(Dialog.Content, {
  fontFamily: '$newDefault',
  display: 'grid',
  gridRowGap: '$xxlarge',
  borderRadius: '$xlarge',
  minWidth: '30rem',
  backgroundColor: '$computerBlack',
  maxWidth: '62rem'
});

const DialogTitle = styled(Dialog.Title, {
  fontFamily: '$newDefault',
  color: '$ultraDark',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  lineHeight: '2.9rem',
  letterSpacing: 0
});

const HeaderCloseWrapper = styled('div', {
  position: 'absolute',
  top: '2.1rem',
  right: '2.1rem'
});

const DialogDescription = styled(Dialog.Description, {
  fontFamily: '$newDefault',
  color: '$ultraDark'
});

// make sure to use a `h2` element inside
const DialogHeader = styled('div', {
  fontFamily: '$newDefault',
  position: 'relative',
  padding: '$xxlarge $xxlarge 1.7rem $xxlarge',
  borderRadius: '$xlarge $xlarge 0 0',
  backgroundColor: '$warning500'
});

const DialogFooter = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xxlarge',
  padding: '$xxlarge',
  backgroundColor: '$samehada',
  height: '7.6rem',
  borderRadius: '0 0 $xlarge $xlarge',
  fontFamily: '$newDefault'
});

const LegalCopy = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '1rem', // no token available
  lineHeight: '$info',
  padding: '0 $xxlarge'
});

const SummaryWrapper = styled('div', {
  fontFamily: '$newDefault',
  backgroundColor: '$samehada'
});

interface ModalHeaderProps {
  children: ReactNode;
}

const ModalHeader = ({ children }: ModalHeaderProps) => (
  <DialogHeader>
    {children}
    <HeaderCloseWrapper>
      <Dialog.Close aria-label="Close">
        <Icon name="ex" />
      </Dialog.Close>
    </HeaderCloseWrapper>
  </DialogHeader>
);

/** SUMMARY STYLED COMPONENTS */
const QuickSummaryWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  gridTemplateRows: 'auto 1fr',
  gridTemplateAreas: `"subtitle subtitle"
    "price info"`,
  rowGap: '$medium',
  padding: '0 $xxlarge',
  fontFamily: '$newDefault'
});

const SummaryTitle = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  gridArea: 'subtitle'
});

const SummarySubTotal = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '3.6rem', // no token available
  fontWeight: '$black',
  lineHeight: '4.8rem',
  letterSpacing: 0,
  gridArea: 'price'
});

const SummaryDesc = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution',
  letterSpacing: 0,
  textAlign: 'right',
  gridArea: 'info'
});

/** QUICK SUMMARY COMPONENT */
interface QuickSummaryProps {
  title: ReactNode;
  subtotal: ReactNode;
  children: ReactNode;
}

const QuickSummary = ({ title, subtotal, children }: QuickSummaryProps) => {
  return (
    <QuickSummaryWrapper>
      <SummaryTitle>{title}</SummaryTitle>
      <SummarySubTotal>{subtotal}</SummarySubTotal>
      <SummaryDesc>{children}</SummaryDesc>
    </QuickSummaryWrapper>
  );
};

/** PoINPUT COMPONENTS */
const PoWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$xlarge $xxlarge',
  fontFamily: '$newDefault'
});

const Flex = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  alignItems: 'center',
  gap: '$large'
});

const PoInput = styled(Input, {
  fontFamily: '$newDefault',
  flex: '0 1 22rem'
});

interface PoInputProps {
  className?: string;
  checked?: boolean;
  inputOptions?: InputProps;
  readOnly?: boolean;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * PO Number check box and input. Can pass input specific props via props.inputOptions.
 * @param props
 * @returns JSX.Element
 */
const PoNumber = ({
  className,
  checked = false,
  inputOptions,
  readOnly = false,
  onCheckboxChange
}: PoInputProps) => {
  return (
    <PoWrapper className={className}>
      <Flex>
        <Checkbox
          id="po_number"
          checked={checked}
          readOnly={readOnly}
          onChange={onCheckboxChange}
        />
        <label htmlFor="po_number">Assign purchase order number</label>
      </Flex>
      <PoInput
        placeholder="Enter PO number"
        disabled={!checked}
        {...inputOptions}
      />
    </PoWrapper>
  );
};

/** PLAN CHANGE MODAL */
export interface PlanChangeModalProps {
  children: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  trigger?: ReactNode;
  container?: Dialog.PortalProps['container'];
}

export function PlanChangeModal({
  children,
  container,
  defaultOpen,
  onOpenChange,
  open,
  trigger
}: PlanChangeModalProps) {
  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      open={open}
    >
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      <Dialog.Portal container={container}>
        <DialogOverlay>{children}</DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

/** TABLE STYLED COMPONENTS */
const BaseTable = styled('table', {
  fontFamily: '$newDefault',
  tableLayout: 'fixed',
  borderCollapse: 'separate',
  backgroundColor: '$samehada',
  fontSize: '$info',
  fontWeight: '$normal',
  lineHeight: '$attribution',
  width: '100%'
});

const BaseTh = styled('th', {
  '&:first-child': {
    textAlign: 'left',
    paddingLeft: '$xxlarge'
  },
  [`&[scope="col"]`]: {
    paddingTop: '$xxlarge',
    '&:last-child': {
      paddingRight: '$xxlarge',
      textAlign: 'right'
    }
  },
  [`&[scope="row"]`]: {
    paddingLeft: '$xxlarge',
    fontWeight: '$normal',
    paddingTop: '$large'
  },
  variants: {
    bold: {
      true: {
        fontSize: '$newBody',
        fontWeight: '$bold',
        [`&[scope="row"]`]: {
          paddingTop: 0,
          fontWeight: '$bold'
        }
      }
    },
    disabled: {
      true: {
        color: '$nice'
      }
    },
    muted: {
      true: {
        color: '$rhyhorn'
      }
    },
    warning: {
      true: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '0.4rem solid $colors$warning500',
        [`&[scope="col"]`]: {
          paddingTop: '$xlarge',
          '&:last-child': {
            paddingRight: '$none'
          }
        }
      }
    }
  }
});

const Tr = styled('tr', {
  '& + &': {
    td: {
      paddingTop: '$large'
    }
  },
  variants: {
    columnHighlight: {
      4: {
        '& > td:nth-child(4)': {
          backgroundColor: 'rgba(122, 122, 122, 0.05)',
          color: '$rhyhorn'
        }
      },
      3: {
        '& > td:nth-child(3)': {
          backgroundColor: 'rgba(122, 122, 122, 0.05)',
          color: '$rhyhorn'
        }
      },
      2: {
        '& > td:nth-child(2)': {
          backgroundColor: 'rgba(122, 122, 122, 0.05)',
          color: '$rhyhorn'
        }
      },
      1: {
        '& > td:nth-child(1)': {
          backgroundColor: 'rgba(122, 122, 122, 0.05)',
          color: '$rhyhorn'
        }
      }
    },
    extraPadding: {
      top: { '& > td': { paddingTop: '$xxlarge' } },
      bottom: {
        '& > td': {
          paddingBottom: '$xxlarge'
        }
      }
    },
    cellHighlight: {
      4: {
        '& > td:nth-child(4)': {
          backgroundColor: 'rgba(122, 122, 122, 0.1)'
        }
      },
      3: {
        '& > td:nth-child(3)': {
          backgroundColor: 'rgba(122, 122, 122, 0.1)'
        }
      },
      2: {
        '& > td:nth-child(2)': {
          backgroundColor: 'rgba(122, 122, 122, 0.1)'
        }
      },
      1: {
        '& > td:nth-child(1)': {
          backgroundColor: 'rgba(122, 122, 122, 0.1)'
        }
      }
    },
    background: {
      light: {
        backgroundColor: '$samehada'
      }
    }
  }
});

const BaseTd = styled('td', {
  '&:last-child': {
    paddingRight: '$xxlarge'
  },
  textAlign: 'right',
  variants: {
    bold: {
      true: {
        fontSize: '$attribution',
        fontWeight: '$bold'
      }
    },
    disabled: {
      true: {
        color: '$nice'
      }
    },
    warning: {
      true: { color: '$warning500' }
    }
  }
});

const Thead = styled('thead', {
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution'
});

const Tfoot = styled('tfoot', {
  backgroundColor: 'rgba(20, 20, 20, 0.8)',
  '& > tr:first-child > th': {},
  '& > tr:last-child > td:last-child': {
    paddingBottom: '$xlarge',
    paddingTop: '$xlarge'
  }
});

const Tbody = styled('tbody', {
  '& > tr:last-child > td:last-child': {
    paddingBottom: '$xxlarge'
  }
});

const Col = styled('col', {
  variants: {
    highlight: {
      true: {
        background: 'rgba(122, 122, 122, 0.1)'
      }
    }
  }
});

const WarningIcon = styled(Icon, {
  color: '$warning500',
  height: '1rem',
  width: '1rem',
  marginRight: '$small'
});

export interface ThProps extends ComponentPropsWithRef<typeof BaseTh> {
  warning?: boolean;
}

function Th({ children, warning = false, ...rest }: ThProps) {
  return (
    <BaseTh {...rest} warning={warning}>
      {warning ? <WarningIcon name="warning" /> : null}
      {children}
    </BaseTh>
  );
}

export interface TdProps extends ComponentPropsWithRef<typeof BaseTd> {}

function Td({ children, ...rest }: TdProps) {
  return <BaseTd {...rest}>{children}</BaseTd>;
}

export interface TableProps extends ComponentPropsWithRef<typeof BaseTable> {
  disabledCols?: number | number[];
}

const getDisabledColStyles = (col: number) => {
  if (col === 0) {
    return {
      '& th:nth-of-type(1)': {
        color: '$nice'
      }
    };
  }

  return {
    [`& th[scope="col"]:nth-of-type(${col + 1}), & td:nth-of-type(${col})`]: {
      color: '$nice'
    }
  };
};

function Table({ disabledCols, ...rest }: TableProps) {
  // Lets us style multiple column font color
  let disabledColStyles = disabledCols ? {} : undefined;
  if (disabledCols) {
    if (Array.isArray(disabledCols)) {
      disabledColStyles = disabledCols.reduce((acc, currColNum: number) => {
        const style = getDisabledColStyles(currColNum);
        acc = { ...acc, ...style };
        return acc;
      }, {});
    } else {
      disabledColStyles = getDisabledColStyles(disabledCols);
    }
  }

  return (
    <BaseTable
      {...(disabledColStyles ? { css: disabledColStyles } : {})}
      {...rest}
    />
  );
}

const PaymentSeparator = styled(Separator.Root, {
  gridArea: 'separator',
  justifySelf: 'center',
  backgroundColor: '$pancham',
  '&[data-orientation=vertical]': { height: '100%', width: 1 }
});

const PaymentWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateColumns: '1fr 4rem 1fr',
  gridTemplateRows: '$xxxlarge 1fr',
  gridTemplateAreas: `"paymentHeader separator billingAddressHeader"
    "cardInfo separator billingAddress"`,
  gridRowGap: '$xlarge',
  padding: '0 $xxlarge',
  variants: {
    editingCard: {
      true: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `"paymentHeader"
        "cardInfo"`
      }
    }
  }
});

const CardInfoWrapper = styled('div', {
  fontFamily: '$newDefault',
  gridArea: 'cardInfo'
});

const PaymentHeader = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  gridArea: 'paymentHeader'
});

interface BillingMethodInfoProps {
  className?: string;
  children: ReactNode;
  onCtaClick?(): void;
  ctaText?: string;
  title?: ReactNode;
  buttonProps?: ButtonProps;
}

function BillingMethodInfo({
  onCtaClick,
  title = 'Payment Details',
  ctaText = 'Edit',
  buttonProps,
  children
}: BillingMethodInfoProps) {
  return (
    <>
      <PaymentHeader>
        {title}{' '}
        {onCtaClick ? (
          <Button
            css={{
              fontSize: '$newBody',
              fontWeight: '$bold',
              lineHeight: '$attribution',
              padding: '0 $xsmall'
            }}
            onClick={onCtaClick}
            level="link"
            kind="primary"
            {...buttonProps}
          >
            {ctaText}
          </Button>
        ) : null}
      </PaymentHeader>
      <CardInfoWrapper>{children}</CardInfoWrapper>
    </>
  );
}

const BillingAddressWrapper = styled('div', {
  fontFamily: '$newDefault',
  gridArea: 'billingAddress',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '$attribution',
  fontSize: '$newBody',
  columnGap: '$small'
});

const BillingAddressHeader = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  gridArea: 'billingAddressHeader'
});

const BillingSpan = styled('span', {
  display: 'flex',
  alignItems: 'center',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution',
  '& + &': {
    paddingTop: '$small'
  },
  variants: {
    muted: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

interface BillingAddressProps {
  children: ReactNode;
  title?: ReactNode;
}

// TODO: add edit button to address later
function BillingAddress({
  children,
  title = 'Billed to'
}: BillingAddressProps) {
  return (
    <>
      <BillingAddressHeader>{title}</BillingAddressHeader>
      <BillingAddressWrapper>{children}</BillingAddressWrapper>
    </>
  );
}

const InvoiceRowWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  gridTemplateRows: 'auto',
  gridTemplateAreas: `"label value"`,
  '&:first-of-type': {
    paddingTop: '$small' // this padding is needed to align the invoice row with the billing contact children
  },
  '& + &': {
    paddingTop: '$medium'
  }
});

const InvoiceSpan = styled('span', {
  fontFamily: '$newDefault',
  fontSize: '1rem',
  fontWeight: '$normal',
  lineHeight: '$info',
  variants: {
    muted: {
      true: {
        color: '$rhyhorn'
      }
    },
    label: {
      true: {
        gridArea: 'label'
      }
    },
    value: {
      true: {
        gridArea: 'value',
        textAlign: 'right'
      }
    }
  }
});

interface InvoiceRowProps {
  muted?: boolean;
  label: string;
  value: string;
}

const InvoiceRow = ({ muted = false, label, value }: InvoiceRowProps) => {
  return (
    <InvoiceRowWrapper>
      <InvoiceSpan label muted={muted}>
        {label}
      </InvoiceSpan>
      <InvoiceSpan value muted={muted}>
        {value}
      </InvoiceSpan>
    </InvoiceRowWrapper>
  );
};

const TooltipBtn = ({
  tooltipText,
  title
}: {
  tooltipText: string;
  title: string;
}) => {
  return (
    <Tooltip
      tooltipText={tooltipText}
      placement="top"
      triggerStyle={{ display: 'inline-flex' }}
    >
      <IconButton
        title={title}
        icon="warning"
        css={{ color: '$warning500', height: '1rem', width: '1rem' }}
      />
    </Tooltip>
  );
};

const BillingCardForm = styled(BaseBillingCardForm, {
  display: 'grid',
  gridRowGap: '$xlarge'
});

// Used to create that 8px gap without removing all grid gutters
const AlertWrapper = styled('div', {
  padding: '0.8rem $xxlarge 0'
});

const AlertContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '4.4rem 1fr',
  gridColumnGap: '$medium',
  height: '10.4rem',
  padding: '1.6rem 2rem 1.6rem 1.6rem',
  borderRadius: '$large',
  variants: {
    severity: {
      warning: {
        color: '$ultraDark',
        backgroundColor: '$warning500'
      },
      error: {
        color: '$consoleWhite',
        backgroundColor: '$error500'
      }
    }
  },
  defaultVariants: {
    severity: 'warning'
  }
});

const AlertTitle = styled('h2', {
  fontFamily: '$newDefault',
  fontSize: '$body',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  textTransform: 'capitalize'
});

const AlertBody = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution'
});

interface AlertProps extends VariantProps<typeof AlertContainer> {
  title: string;
  children: ReactNode;
}

/**
 * A functional component that displays an alert message with a specified severity.
 *
 */
function Alert({ severity, title, children }: AlertProps) {
  // When needed add more icons based on `severity` defined in the variants
  return (
    <AlertWrapper>
      <AlertContainer severity={severity} role="alert">
        <Icon
          name="warningSign"
          css={{ height: '2.4rem', width: '2.4rem', margin: 'auto' }}
        />
        <div>
          {typeof title === 'string' ? <AlertTitle>{title}</AlertTitle> : title}
          <AlertBody>{children}</AlertBody>
        </div>
      </AlertContainer>
    </AlertWrapper>
  );
}

PlanChangeModal.Alert = Alert;
PlanChangeModal.Header = ModalHeader;
PlanChangeModal.Footer = DialogFooter;
PlanChangeModal.Title = DialogTitle;
PlanChangeModal.Content = DialogContent;
PlanChangeModal.Description = DialogDescription;
PlanChangeModal.QuickSummary = QuickSummary;
PlanChangeModal.ChangeSummary = SummaryWrapper;
PlanChangeModal.Close = Dialog.Close;
PlanChangeModal.PoNumber = PoNumber;
PlanChangeModal.Legal = LegalCopy;

PlanChangeModal.Table = Table;
PlanChangeModal.Tbody = Tbody;
PlanChangeModal.Tfoot = Tfoot;
PlanChangeModal.Td = Td;
PlanChangeModal.Th = Th;
PlanChangeModal.Tr = Tr;
PlanChangeModal.Thead = Thead;
PlanChangeModal.Col = Col;

PlanChangeModal.PaymentMethod = PaymentWrapper;
PlanChangeModal.PaymentSeparator = PaymentSeparator;
PlanChangeModal.BillingMethodInfo = BillingMethodInfo;
PlanChangeModal.BillingCardForm = BillingCardForm;

PlanChangeModal.BillingAddress = BillingAddress;
PlanChangeModal.BillingSpan = BillingSpan;
PlanChangeModal.InvoiceSpan = InvoiceSpan;
PlanChangeModal.InvoiceRow = InvoiceRow;
PlanChangeModal.TooltipBtn = TooltipBtn;

PlanChangeModal.BillingCardPreview = BillingCardPreview;
