import * as n from 'narrows';

import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { invoice } from './schema';

/******************************************************************************
 * Get Team Invoices
 ******************************************************************************/

export interface GetTeamInvoicesReq {
  team_id: string;
  cursor: string;
  limit?: number;
}

export const getTeamInvoices =
  (options: ApiOptions) =>
  ({ team_id, cursor, limit = 10 }: GetTeamInvoicesReq) => {
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/invoices?cursor=${cursor}&limit=${limit}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: n.record({
        data: n.record({
          invoices: n.array(invoice),
          cursor: n.string
        })
      })
    });
  };

/******************************************************************************
 * Create a PDF for an individual invoice
 ******************************************************************************/

export interface CreateTeamInvoicePdfReq {
  team_id: string;
  token: string;
}

export const createTeamInvoicePdf =
  (options: ApiOptions) => (req: CreateTeamInvoicePdfReq) => {
    const { team_id, token } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/invoices/download`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { token },
      validator: n.record({
        data: n.record({
          download_url: n.string
        })
      })
    });
  };

/******************************************************************************
 * Update PO Number for an individual invoice
 ******************************************************************************/

export interface UpdateInvoicePoNumberReq {
  team_id: string;
  invoice_id: string;
  po_number: string;
}

export const updateInvoicePoNumber =
  (options: ApiOptions) =>
  ({ team_id, invoice_id, po_number }: UpdateInvoicePoNumberReq) => {
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/invoices/${invoice_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { po_number }
    });
  };
