import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, domain, domainList } from './schema';

/******************************************************************************
 * Create a record of a verified domain for a team.
 ******************************************************************************/

export interface CreateTeamDomainReq {
  team_id: string;
  domain: string;
}

export const createTeamDomain =
  (options: ApiOptions) =>
  /**
   * Create a record for a domain.
   */
  (req: CreateTeamDomainReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/domains`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body,
      validator: data(domain)
    });
  };

/******************************************************************************
 * Get a list of a team's verified domains.
 ******************************************************************************/

export interface GetTeamDomainsReq {
  team_id: string;
}

export const getTeamDomains =
  (options: ApiOptions) =>
  /**
   * Get a list of team's domain records.
   */
  (req: GetTeamDomainsReq) => {
    const { team_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/domains`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(domainList)
    });
  };

/******************************************************************************
 * Remove a verified domain from a team.
 ******************************************************************************/

export interface DeleteTeamDomainReq {
  team_id: string;
  domain_id: string;
}

export const deleteTeamDomain =
  (options: ApiOptions) =>
  /**
   * Remove a domain from a team's records.
   */
  (req: DeleteTeamDomainReq) => {
    const { team_id, domain_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/domains/${domain_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Request to verify a domain.
 ******************************************************************************/

export interface VerifyTeamDomainReq {
  team_id: string;
  domain_id: string;
}

export const verifyTeamDomain =
  (options: ApiOptions) =>
  /**
   * Send a message to the backend to verify a domain record,
   * using the domain's ID generated from createTeamDomain.
   */
  (req: VerifyTeamDomainReq) => {
    const { team_id, domain_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/domains/${domain_id}/verify`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
