import { ApiOptions } from '@parsec/kessel';
import { Method, request } from '@parsec/request';

import { TeamRolePermissionSummary } from './schema';

/******************************************************************************
 * Get TeamRoleActionsSummary
 ******************************************************************************/

export interface GetTeamRolePermissionSummary {
  team_id: string;
  user_id: number;
}
export const getTeamRolePermissionSummary =
  (options: ApiOptions) =>
  /**
   * Gets a user's permissions based on all of their team roles
   */
  (req: GetTeamRolePermissionSummary) => {
    const { team_id, user_id } = req;
    return request<{ data: TeamRolePermissionSummary }>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/members/${user_id}/permissions`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
