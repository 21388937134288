import * as n from 'narrows';

import { getPlatform } from '@parsec/platform';
import { captureException } from '@parsec/sentry';

import * as errors from './errors';

export interface ErrorCode {
  type: (typeof errors)[keyof typeof errors];
  values?: string[];
}

export interface FailureRes {
  status: number;
  error: string;
  codes: ErrorCode[];
}
export type Req<T> =
  | {
      method: 'GET';
      url: string;
      headers?: { [key: string]: string };
      validator?: n.Validator<T>;
      middleware?(res: Response): void;
    }
  | {
      method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
      url: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body?: any;
      headers?: { [key: string]: string };
      validator?: n.Validator<T>;
      middleware?(res: Response): void;
    };

export default async function request<T>(req: Req<T>): Promise<T> {
  const { validator = () => true, middleware } = req;
  const platform = getPlatform();

  let res: Response | null = null;
  let body: unknown;

  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      ...req.headers
    };

    if (platform) headers['X-Parsec-OS'] = platform;

    // Make request
    res = await fetch(req.url, {
      method: req.method,
      headers,
      body:
        req.method !== 'GET'
          ? headers['Content-Type'] === 'application/json'
            ? JSON.stringify(req.body)
            : req.body
          : undefined
    });

    middleware?.(res);

    const pathname = new URL(req.url).pathname;

    let rawBody = '';
    if (res.status !== 204) {
      try {
        rawBody = await res.text();
        body = JSON.parse(rawBody);
      } catch (err) {
        console.error(err);
        captureException(err, {
          tags: { path: pathname, requestErrorType: 'invalid_json' },
          contexts: { res: { rawBody } }
        });

        throw new Error('Request failed: Invalid JSON');
      }
    }

    // Failed
    if (!res.ok || res.status >= 400) {
      const message = hasError(body) ? body.error : '';
      throw new Error(message || 'Request failed');
    }

    if (!validator(body)) {
      const invalid = n.report(validator, body) || [];
      const invalidPropertyPath = invalid.join('.') ?? '';

      const err = new Error(
        `${req.method} ${pathname}: Invalid response body at ${invalidPropertyPath}`
      );
      captureException(err, {
        tags: { pathname, requestErrorType: 'validator', invalidPropertyPath },
        contexts: { res: { body, ok: res.ok, status: res.status } }
      });

      throw err;
    }

    return body as T;
  } catch (err) {
    throw parseError({
      status: res?.status ?? -1,
      error: err instanceof Error ? err.message : 'Request failed.',
      body: body
    });
  }
}

const validateCodes = n.array(
  n.record({
    type: n.any(...Object.values(errors).map(code => n.literal(code))),
    values: n.optional(n.array(n.string))
  })
);

export function parseError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any,
  defaults: Partial<FailureRes> = {}
): FailureRes {
  const { status = -1, error = 'Request failed.', codes = [] } = defaults;

  return {
    status: typeof err?.status === 'number' ? err.status : status,
    error: typeof err?.error === 'string' ? err.error : error,
    codes: validateCodes(err?.body?.codes)
      ? err.body.codes
      : [...codes, { type: errors.ERR_CODE_INVALID }]
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasError(body: any): body is { error: string } {
  return Boolean(body) && 'error' in body && typeof body.error === 'string';
}
