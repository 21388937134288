import { useState, ChangeEvent } from 'react';

import { styled } from '@parsec/styles';

import { default as BaseButton } from '../Button';
import { default as BaseInput } from '../Input';

export interface CouponProps {
  defaultValue: string;
  apply(coupon: string): void;
  errorMsg?: string;
  successMsg?: string;
}

export default function Coupon(props: CouponProps) {
  const { defaultValue, apply, errorMsg, successMsg } = props;
  const [value, setValue] = useState(defaultValue);

  const renderMsg = (): JSX.Element | null => {
    if (errorMsg) {
      return <ErrorMessage>{errorMsg}</ErrorMessage>;
    } else if (successMsg) {
      return <SuccessMessage>{successMsg}</SuccessMessage>;
    } else {
      return null;
    }
  };

  return (
    <InputWrapper>
      <Label htmlFor="subscription_coupon">Coupon Code</Label>
      <Input
        id="subscription_coupon"
        defaultValue={defaultValue}
        placeholder="Enter Your Coupon Code"
        name="coupon"
        type="text"
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
      />
      <Button type="button" onClick={() => apply(value)}>
        Apply
      </Button>
      {renderMsg()}
    </InputWrapper>
  );
}

const InputWrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: '"header header" "coupon submit" "result result"',
  gridTemplateColumns: 'auto max-content',
  gridTemplateRows: 'auto auto $space$xxlarge',
  columnGap: '$xlarge',
  alignItems: 'center',
  boxAlign: 'center'
});

const Label = styled('label', {
  fontWeight: '$bold',
  fontSize: '$body',
  gridArea: 'header'
});

const Input = styled(BaseInput, {
  gridArea: 'coupon',
  marginTop: '$xlarge'
});

const Button = styled(BaseButton, {
  backgroundColor: '$chandelure',
  width: '16rem',
  gridArea: 'submit',
  marginTop: '$xlarge'
});

const ErrorMessage = styled('p', {
  color: '$error500',
  gridArea: 'result',
  fontSize: '$info',
  marginTop: '$small'
});

const SuccessMessage = styled('p', {
  color: '$success500',
  gridArea: 'result',
  fontSize: '$info',
  marginTop: '$small'
});
