// libraries
// @parsec
import { styled } from '@parsec/styles';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

export interface FallbackProps {
  id: string;
  error: string;
}

export default function Fallback(props: FallbackProps) {
  const { id, error } = props;

  return (
    <Wrapper>
      <p>
        Sorry, Parsec encountered an error. Please{' '}
        <a href="https://parsec.app/zendesk/?return_to=https%3A%2F%2Fsupport.parsec.app%2Fhc%2Fen-us%2Frequests%2Fnew">
          contact support
        </a>
        .
      </p>

      {error ? (
        <ErrorMessage>
          <code>{error}</code>
        </ErrorMessage>
      ) : null}
      {id ? (
        <Id>
          <strong>ID:</strong> <code>{id}</code>
          <Tooltip
            tooltipText={
              <TooltipText>
                This ID helps us figure out what went wrong.
                <br />
                If you contact support, copy and paste them the ID so we can
                help you more easily.
              </TooltipText>
            }
          >
            <Icon name="help" />
          </Tooltip>
        </Id>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  flex: 1,
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large',
  alignContent: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  minHeight: '100%'
});

const ErrorMessage = styled('p', {
  backgroundColor: '$computerBlack',
  borderRadius: '$medium',
  padding: '$medium $large'
});

const Id = styled('span', {
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gap: '$medium',
  textAlign: 'center',
  fontSize: '$info',
  lineHieght: '$info'
});

const TooltipText = styled('p', {
  fontSize: '$info',
  lineHieght: '$info'
});
