// @parsec
import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { verification } from './schema';

export const getVerification = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/me/email-confirmed`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: verification
  });
