import { useMemo } from 'react';

import { parseError, FailureRes } from '@parsec/request';

export function useWrapError<T>(
  err: unknown,
  defaults: Partial<FailureRes<T>> = {}
) {
  return useMemo(() => {
    if (err) {
      const parsed = parseError(err, defaults);
      if (parsed.codes.some(code => code.type === 'insufficient_permissions')) {
        parsed.error = `You do not have permission to perform this action. Please contact your team admin to update your permissions.`;
      }
      return parsed;
    }
    return undefined;
  }, [err, defaults]);
}
