// @parsec
import { auth, schema, teamSaml } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_saml');

export function useTeamSAMLData() {
  return useQueryData(key.all(), schema.saml);
}

// ****************************************************************************

export function useGetTeamSaml() {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = { team_id: me.data?.team_id ?? '' };
  const enabled = Boolean(vars.team_id);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamSaml.getTeamSAML(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Team SAML settings."
  });

  return { ...result, error };
}

// ****************************************************************************

type UpdateTeamSamlIdpVars = Omit<teamSaml.UpdateTeamSAMLIDPReq, 'team_id'>;

export function useUpdateTeamSamlIdp() {
  const kessel = useKessel();
  const cache = useTeamSAMLData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn(req: UpdateTeamSamlIdpVars) {
      return kessel.teamSaml.updateTeamSAMLIDP({ ...req, team_id: teamId });
    },
    {
      onSuccess: () => {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Team SAML IDP."
  });

  return { ...result, error };
}

// ****************************************************************************

type UpdateTeamSamlSettingsVars = Omit<
  teamSaml.UpdateTeamSAMLSettingsReq,
  'team_id'
>;

export function useUpdateTeamSamlSettings() {
  const kessel = useKessel();
  const teamCache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn(req: UpdateTeamSamlSettingsVars) {
      return kessel.teamSaml.updateTeamSAMLSettings({
        ...req,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        teamCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Team SAML settings."
  });

  return { ...result, error };
}

/******************************************************************************
 * Get SAML Login attempt information
 ******************************************************************************/

export function useGetSamlLoginAttempt(jwt: string) {
  const kessel = useKessel();

  const enabled = jwt !== '';

  const result = useQuery(
    key.detail({ jwt }),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.auth.getTeamSamlLoginAttempt(req);
      return res.body;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get SAML login attempt information."
  });

  return { ...result, error };
}

/******************************************************************************
 * Approve SAML Login attempt
 ******************************************************************************/

export function useApproveSamlLoginAttempt() {
  const kessel = useKessel();

  const result = useMutation(async function mutationFn(
    req: auth.ApproveTeamSamlLoginAttemptReq
  ) {
    return kessel.auth.approveTeamSamlLoginAttempt(req);
  });

  const error = useWrapError(result.error, {
    error: 'Failed to approve SAML login attempt.'
  });
  return { ...result, error };
}
