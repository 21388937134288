import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { scimApiKeyData } from './schema';

/******************************************************************************
 * Get SCIM API Key
 ******************************************************************************/

export interface GetScimApiKeyReq {
  team_id: string;
}

export const getScimApiKey =
  (options: ApiOptions) =>
  /**
   * Get the SCIM auth API Key for this team.
   */
  (req: GetScimApiKeyReq) => {
    const { team_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/scim-api-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: scimApiKeyData,
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Create SCIM API Key
 ******************************************************************************/

export interface CreateScimApiKeyReq {
  team_id: string;
}

export const createScimApiKey =
  (options: ApiOptions) =>
  /**
   * Generate a SCIM auth API Key for this team.
   */
  (req: CreateScimApiKeyReq) => {
    const { team_id } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/scim-api-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: scimApiKeyData,
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Delete SCIM API Key
 ******************************************************************************/

export interface DeleteScimApiKeyReq {
  team_id: string;
}

export const deleteScimApiKey =
  (options: ApiOptions) =>
  /**
   * Delete an SCIM API key.
   */
  (req: DeleteScimApiKeyReq) => {
    const { team_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/scim-api-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
