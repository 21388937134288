import { GroupPermissions, TeamPermissions } from './schema';

export interface ApiOptions {
  kessel: string;
  downtimeURL: string;
  token(): string | undefined;
  middleware?(res: Response): void;
}

export interface IdleRes {
  type: Status.Idle;
}

export interface PendingRes {
  type: Status.Pending;
}

export interface SuccessRes<T> {
  type: Status.Success;
  status: number;
  body: T;
}

export interface FailureRes<E> {
  type: Status.Failure;
  status: number;
  error: string;
  body?: E;
}

export type Res<T = unknown, E = { error?: string }> =
  | IdleRes
  | PendingRes
  | SuccessRes<T>
  | FailureRes<E>;

export enum Status {
  Idle,
  Pending,
  Success,
  Failure
}

// Auth

export interface AuthErr {
  error: string;
  tfa_required?: boolean;
}

// Host

export enum HostMode {
  Game = 'game',
  Desktop = 'desktop'
}

// Session

export enum SessionRole {
  Host = 'host',
  Client = 'client',
  User = 'user'
}

// Warp

export enum WarpPlanID {
  Monthly = 'warp-monthly',
  Yearly = 'warp-yearly'
}

export enum WarpInterval {
  Monthly = 'month',
  Yearly = 'year'
}

export enum WarpStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Future = 'future',
  InTrial = 'in_trial',
  NonRenewing = 'non_renewing',
  Paused = 'paused'
}

export interface WarpBillingCard {
  first_name: string;
  last_name: string;
  last4: string;
  expiry_month: number;
  expiry_year: number;
  zip: string;
  country: string;
}

export function intervalToPlan(i: WarpInterval) {
  return i === WarpInterval.Monthly ? WarpPlanID.Monthly : WarpPlanID.Yearly;
}

export interface PermissionSummary {
  team: TeamPermissions;
  groups: {
    [key: string]: GroupPermissions;
  };
}

// Sales Tax

export interface Tax {
  totalAmount: number;
  totalTaxCalculated: number;
  taxSummary: {
    taxName: string;
    rate: number;
    taxCalculated: number;
  }[];
}
