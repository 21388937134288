import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, changelogRss } from './schema';

/******************************************************************************
 * Get Changelog RSS
 ******************************************************************************/

export const getRSS = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' // for the cors
    },
    url: `https://parsec.app/changelog.xml`,
    middleware: options.middleware,
    validator: data(changelogRss),
    includePlatform: false
  });
