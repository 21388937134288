import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { TeamEvent } from './schema';

/******************************************************************************
 * Get TeamEvents
 ******************************************************************************/

export interface GetTeamEventsReq {
  teamId: string;
}

export const getTeamEvents =
  (options: ApiOptions) =>
  /**
   * Gets TeamEvents for a Team.
   */
  (req: GetTeamEventsReq) => {
    const { teamId } = req;
    return request<{ data: TeamEvent[] }>({
      type: Method.GET,
      url: `${options.kessel}/teams/${teamId}/events`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
