import { teamPurchase } from '@parsec/kessel';

import { useAccessLinkCreditData } from './accessLinkCredit';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useWrapError } from './useWrapError';

/******************************************************************************
 * Add access link credits
 ******************************************************************************/

type CreateTeamPurchaseGuestAccessVars = Omit<
  teamPurchase.CreateTeamPurchaseGuestAccessReq,
  'team_id'
>;

export function usePurchaseGuestAccess() {
  const kessel = useKessel();
  const accessLinkCreditCache = useAccessLinkCreditData();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: CreateTeamPurchaseGuestAccessVars) {
      return kessel.teamPurchase.createTeamPurchaseGuestAccess({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess: () => accessLinkCreditCache.invalidate() }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to purchase guest access.'
  });
  return { ...result, error };
}
