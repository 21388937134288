// @parsec
import { save } from '@parsec/cookie';

import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useWrapError } from './useWrapError';

// ****************************************************************************

export function useSamlLogIn() {
  const kessel = useKessel();
  const result = useMutation(kessel.auth.samlLogIn);

  const error = useWrapError(result.error, {
    error: "Couldn't log in with SAML."
  });

  return { ...result, error };
}

// ****************************************************************************

export function useConfirmSamlSso() {
  const kessel = useKessel();
  const result = useMutation(kessel.auth.confirmSAMLSSO);

  const error = useWrapError(result.error, {
    error: "Couldn't confirm SAML."
  });

  return { ...result, error };
}

// ****************************************************************************

export function useCreateSamlSession() {
  const kessel = useKessel();

  const result = useMutation(kessel.auth.createSamlSession, {
    onSuccess(res) {
      save({ token: res.body.session_id });
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't create SAML session."
  });

  return { ...result, error };
}
