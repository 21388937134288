/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef, FocusEvent } from 'react';

export interface FocusWithin {
  type: 'self' | 'child' | 'outside';
}

interface Options {
  onWrapperFocus?(e: FocusEvent, f: FocusWithin): void;
  onWrapperBlur?(e: FocusEvent, f: FocusWithin): void;
  onFocus?(e: FocusEvent, f: FocusWithin): void;
  onBlur?(e: FocusEvent, f: FocusWithin): void;
  onFocusChange?(isFocused: boolean): void;
}

export function useFocus(options: Options) {
  const noop = useCallback(() => {}, []);

  const {
    onWrapperFocus = noop,
    onWrapperBlur = noop,
    onFocus = noop,
    onBlur = noop,
    onFocusChange = noop
  } = options;

  const handleWrapperFocus = useRef(onWrapperFocus);
  handleWrapperFocus.current = onWrapperFocus;
  const handleWrapperBlur = useRef(onWrapperBlur);
  handleWrapperBlur.current = onWrapperBlur;
  const handleFocus = useRef(onFocus);
  handleFocus.current = onFocus;
  const handleBlur = useRef(onBlur);
  handleBlur.current = onBlur;
  const handleFocusChange = useRef(onFocusChange);
  handleFocusChange.current = onFocusChange;

  return useMemo(
    () => ({
      onFocus(event: FocusEvent<HTMLElement>) {
        if (event.currentTarget === event.target) {
          handleFocus.current(event, { type: 'self' });
        } else {
          handleFocus.current(event, { type: 'child' });
        }
        if (!event.currentTarget?.contains(event.relatedTarget as any)) {
          // Not triggered when swapping focus between children
          handleFocus.current(event, { type: 'outside' });
          handleWrapperFocus.current(event, { type: 'outside' });
          handleFocusChange.current(true);
        }
      },

      onBlur(event: FocusEvent<HTMLElement>) {
        if (event.currentTarget === event.target) {
          handleBlur.current(event, { type: 'self' });
        } else if (event.currentTarget.contains(event.target)) {
          handleBlur.current(event, { type: 'child' });
        }

        if (!event.currentTarget.contains(event.relatedTarget as any)) {
          // Not triggered when swapping focus between children
          handleFocus.current(event, { type: 'outside' });
          handleWrapperFocus.current(event, { type: 'outside' });
          handleFocusChange.current(false);
        }
      }
    }),
    []
  );
}
