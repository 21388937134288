import { styled } from '@parsec/components';

export const Description = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  variants: {
    info: {
      true: {
        margin: 0,
        fontSize: '$newInfo',
        lineHeight: '$info',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }
});
