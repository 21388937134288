import { useRef } from 'react';

/**
 * Use this hook when you want to have multiple accessible refs. Utilizes ref callbacks.
 * More info here: https://react.dev/reference/react-dom/components/common#ref-callback
 * Example: https://react.dev/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
 */
export function useMultiRef<K extends string, V extends HTMLElement>() {
  const refMap = useRef<Map<K, V> | null>(null);

  function getMap() {
    if (!refMap.current) {
      refMap.current = new Map<K, V>();
    }
    return refMap.current;
  }

  function setRefNode(name: K) {
    return (node: V) => {
      const map = getMap();
      if (node) {
        map.set(name, node);
      } else {
        map.delete(name);
      }
    };
  }

  return { setRefNode, getMap };
}
