// libraries
import QRCode from 'qrcode.react';

import { styled } from '@parsec/styles';

export interface QrCodeProps {
  className?: string;
  value: string;
  label?: string;
}

export default function QrCode(props: QrCodeProps) {
  const { className, value, label = value } = props;

  return (
    <Wrapper className={className} skeleton={!value}>
      {value ? (
        <QRCode
          value={value}
          size={120}
          bgColor="#fff"
          level="L"
          renderAs="svg"
        />
      ) : null}
      <Secret skeleton={!label}>{label}</Secret>
    </Wrapper>
  );
}

const SKELETON_STYLES = {
  content: '',
  display: 'block',
  backgroundColor: '$reshiram',
  borderRadius: '$small'
};

const Wrapper = styled('figure', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '$medium',
  fontSize: '$info',
  fontFamily: 'monospace',
  backgroundColor: 'white',
  color: 'black',
  padding: '$medium',
  borderRadius: '$small',
  variants: {
    skeleton: {
      true: {
        '&::before': {
          ...SKELETON_STYLES,
          width: '12rem',
          height: '12rem'
        }
      }
    }
  }
});

const Secret = styled('figcaption', {
  display: 'block',
  width: '100%',
  fontWeight: 'normal',
  variants: {
    skeleton: {
      true: {
        '&::before': {
          ...SKELETON_STYLES,
          width: 'inherit',
          height: '1.2rem',
          margin: '$small 0'
        }
      }
    }
  }
});
