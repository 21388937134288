import { useState, ReactNode, useEffect } from 'react';

import { useSelect } from 'downshift';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { styled } from '@parsec/styles';

import Radio from '../Radio';

interface FilterDropdownItem<T> {
  text: ReactNode;
  filterType: T;
  key?: string;
  disabled?: boolean;
  onSelect?(): void;
}

type FilterDropdownChildrenFunction = (options: {
  isOpen: boolean;
  props: object;
}) => ReactNode;

type FilterGroup<T> = {
  title?: string;
  items: FilterDropdownItem<T>[];
};

export interface FilterDropdownProps<T> {
  className?: string;
  itemGroups: FilterGroup<T>[];
  defaultValue?: T;
  children: FilterDropdownChildrenFunction;
}

export default function FilterDropdown<T>(props: FilterDropdownProps<T>) {
  const { itemGroups, defaultValue, children } = props;

  const allItems = itemGroups.reduce(
    (memo, group) => [...memo, ...group.items],
    [] as FilterDropdownItem<T>[]
  );

  const [toggle, setToggle] = useState<HTMLElement | null>(null);
  const [popover, setPopover] = useState<HTMLElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(toggle, popover, {
    placement: 'bottom-end',
    modifiers: [
      { name: 'flip' },
      {
        name: 'offset',
        options: { offset: [0, 8] }
      }
    ]
  });

  const select = useSelect<FilterDropdownItem<T>>({
    defaultSelectedItem: allItems.find(item =>
      !defaultValue ? false : item.filterType === defaultValue
    ),
    items: allItems,
    itemToString: item => `${item?.text}`,
    onIsOpenChange: changes => {
      if (changes.isOpen && forceUpdate) {
        Promise.resolve().then(forceUpdate);
      }
    },
    onSelectedItemChange: state => {
      state.selectedItem?.onSelect?.();
    }
  });

  const {
    isOpen,
    highlightedIndex,
    selectedItem,
    toggleMenu,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    setHighlightedIndex
  } = select;

  const toggleProps = getToggleButtonProps({
    ref: setToggle
  });

  useEffect(() => {
    if (isOpen) window.addEventListener('scroll', toggleMenu);
    return () => window.removeEventListener('scroll', toggleMenu);
  }, [isOpen, toggleMenu]);

  useEffect(() => {
    if (forceUpdate) {
      Promise.resolve().then(forceUpdate);
    }
  }, [forceUpdate, isOpen]);

  let index = 0;

  return (
    <>
      {children({
        isOpen,
        props: toggleProps
      })}

      {createPortal(
        <DropdownMenuWrapper
          style={styles.popper}
          {...attributes.popper}
          {...getMenuProps({ ref: setPopover })}
        >
          {isOpen && (
            <DropdownMenu onMouseLeave={() => setHighlightedIndex(-1)}>
              <HeaderWrapper>
                <strong>Filters</strong>
                {/* Uncomment reset button when this filter eventually expands with more options */}
                {/* <Button level="link" size="small">
                  Reset All
                </Button> */}
              </HeaderWrapper>
              {itemGroups.map((group, i) => (
                <div key={group.title ?? `group-${i}`}>
                  {group.title && <span>{group.title}</span>}
                  <DropdownUl key={i}>
                    {group.items.map(item => {
                      const itemIndex = index;
                      index += 1;
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <DropdownLi
                          {...getItemProps({
                            key: item.key || `${item.text}`,
                            item,
                            index: itemIndex
                          })}
                          highlighted={
                            highlightedIndex >= 0
                              ? highlightedIndex === itemIndex
                              : selectedItem?.text === item.text
                          }
                        >
                          <Radio
                            readOnly
                            checked={defaultValue === item.filterType}
                          />
                          <span>{item.text}</span>
                        </DropdownLi>
                      );
                    })}
                  </DropdownUl>
                </div>
              ))}
            </DropdownMenu>
          )}
        </DropdownMenuWrapper>,
        document.getElementById('popovers') ?? document.body
      )}
    </>
  );
}

const DropdownMenuWrapper = styled('div', {
  minWidth: '24rem',
  '&:focus': {
    outlineStyle: 'none'
  }
});

const DropdownMenu = styled('div', {
  backgroundColor: '$computerBlack',
  padding: '$medium',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '12rem',
  maxHeight: '22rem',
  boxShadow: 'inset 0 0 0 0.1rem $colors$pangoro',
  borderRadius: '$medium'
});

const HeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$medium $small'
});

const DropdownUl = styled('ul', {
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  height: '100%',
  padding: '$small',
  '&:not(:last-child)': {
    boxShadow: 'inset 0 -0.1rem 0 $colors$zekrom'
  }
});

const DropdownLi = styled('li', {
  display: 'flex',
  padding: '$medium $small  ',
  borderRadius: '$xsmall',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  gap: '$large',
  overflow: 'hidden',
  alignItems: 'center',
  variants: {
    highlighted: {
      true: {
        backgroundColor: 'rgba(249, 249, 249, 0.05)'
      }
    }
  }
});
