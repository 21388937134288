import { useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import AnimateHeight from 'react-animate-height';
import { useQueryClient } from 'react-query';

import {
  Avatar,
  Button,
  Flyout,
  Icon,
  IconButton,
  Time,
  Tooltip
} from '@parsec/components';
import { useTeamTrialDate, useSeatCompliance } from '@parsec/hooks';
import {
  useGetMe,
  useGetTeam,
  useGetTeamRolePermissionSummary,
  useGetTeamSaml,
  useGetTeamSubscription,
  useLogOut
} from '@parsec/queries';
import { styled } from '@parsec/styles';

import { CHANGELOG_URL, DASH_URL } from 'lib/config';
import {
  useEnabledPages,
  useHasInvalidCard,
  useHasLatePayment,
  useViewChangelog,
  useTeamMembersToRemove,
  useCountRelayIssues
} from 'lib/hooks';

import EditTeamNameModal from './EditTeamNameModal';
import NewChangelogModal from './NewChangelogModal';

const version = 'newFont';

// STYLES
const Wrapper = styled('nav', {
  fontFamily: '$newDefault',
  stack: {
    direction: 'vertical',
    layout: 'auto 1fr auto'
  },
  background: '$ultraDark',
  height: '100vh',
  borderRight: '.1rem solid $pangoro'
});

const Header = styled('header', {
  margin: '$xxlarge $xlarge 0'
});

const Nav = styled('ul', {
  padding: '0 1.4rem',
  overflow: 'auto'
});

const SectionLabel = styled('p', {
  color: '$rhyhorn',
  fontSize: '$newInfo',
  lineHeight: '$attribution',
  textTransform: 'uppercase',
  paddingLeft: '1.2rem',
  marginTop: '2rem'
});

const ViewLogBtn = styled('button', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  unset: 'all',
  position: 'relative',
  display: 'inline-flex',
  cursor: 'pointer',
  paddingRight: '1rem',
  color: '$consoleWhite',
  '&:hover, &:focus': {
    color: '$primary500'
  },
  '&::after': {
    content: '',
    backgroundColor: '$primary500',
    position: 'absolute',
    height: '0.5rem',
    width: '0.5rem',
    borderRadius: '100%',
    opacity: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto 0',
    transition: 'opacity 550ms ease'
  },
  variants: {
    new: {
      true: {
        '&::after': {
          opacity: 1
        }
      }
    }
  }
});

const LogUpdated = styled('p', {
  color: '$rhyhorn',
  fontSize: '$newBody',
  fontStyle: 'italic',
  fontWeight: '400'
});

const Section = styled('li', {
  borderRadius: '.5rem',
  transition: 'background-color 125ms ease',
  width: '100%',
  color: '$consoleWhite',
  padding: '.3rem 1.2rem ',

  variants: {
    active: {
      true: {
        backgroundColor: '$computerBlack',
        color: '$primary500 !important',
        '& span': {
          color: '$primary500'
        }
      },
      false: {}
    },
    borderTop: {
      true: {
        fontSize: '1.4rem',
        paddingTop: '1.1rem',
        '&::before': {
          content: '',
          display: 'block',
          height: '0.01rem',
          width: '25.6rem',
          margin: '1.6rem 0',
          backgroundColor: '$pangoro'
        }
      }
    }
  }
});

const StyledLink = styled(Link, {
  stack: {
    gap: '$medium',
    valign: 'center',
    hdistribute: 'start'
  },

  color: '$consoleWhite',
  fontWeight: 'normal',
  textDecoration: 'none',
  fontSize: '$newBody',
  lineHeight: '$attribution',
  transition: 'color 125ms ease',
  fill: '$rhyhorn',
  path: '$rhyhorn',

  '&:hover': {
    color: '$primary500',
    fill: '$primary500'
  },
  '&:focus': {
    color: '$primary500',
    fill: '$primary500',
    outline: 0
  },

  '&.active': {
    cursor: 'default'
  },

  variants: {
    active: {
      true: {
        color: '$primary500'
      }
    },
    issues: {
      true: {
        color: '$error500'
      }
    },
    showStatusIcon: {
      /**
       * showStatusIcon variant defines grid columns (inherited from stack
       * attribute) to allow a right-aligned status icon in the child div.
       */
      true: {
        gridTemplateColumns: `1.6rem auto 1.8rem`,
        justifyContent: 'stretch'
      }
    }
  }
});

const Footer = styled('footer', {
  display: 'grid',
  padding: '$xlarge',
  position: 'relative'
});

const Profile = styled('div', {
  height: '6rem',
  display: 'flex',
  fontSize: '$body',
  lineHeight: '2.2rem',
  color: '$rhyhorn',
  textDecoration: 'none',
  alignItems: 'center',
  padding: '$xlarge',

  variants: {
    trial: {
      true: {
        border: '.1rem solid $duskull',
        borderRadius: '0 0 $medium $medium'
      }
    }
  }
});

const TrialsInfo = styled('div', {
  border: '.1rem solid $duskull',
  borderBottom: 'none',
  padding: '$xlarge',
  display: 'grid',
  justifyItems: 'center',
  borderRadius: '$medium $medium 0 0',
  gap: '$xxlarge',
  textAlign: 'center',
  fontFamily: '$newDefault',
  fontSize: '$newBody'
});

const Expiration = styled('p', {
  fontSize: '$newInfo',
  color: '#FFAD11', //no token,
  linHeight: '$info'
});

const Name = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '$medium',
  maxWidth: '20rem',
  color: '$consoleWhite'
});

const LogOut = styled('button', {
  width: '100%',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$body',
  color: '$error500',
  margin: '0 .4rem',
  textAlign: 'left',
  borderRadius: '$small',
  cursor: 'pointer',

  '&:focus': {
    outlineStyle: 'none'
  }
});

const AppName = styled('h6', {
  color: '$rhyhorn',
  textTransform: 'uppercase',
  fontSize: '$newInfo',
  lineHeight: '$info',
  fontFamily: '$newDefault'
});

const TeamId = styled('p', {
  fontFamily: '$newDefault',
  color: '$primary500',
  lineHeight: '$newBody',
  fontSize: '$body',
  fontWeight: 'bold',
  textTransform: 'uppercase'
});

const Company = styled('h4', {
  fontSize: '$body',
  lineHeight: '$attribution',
  textTransform: 'uppercase',
  fontFamily: '$newDefault',
  overflowWrap: 'anywhere',
  variants: {
    loading: {
      true: {
        '&:before': {
          display: 'inline-block',
          content: ' ',
          height: '3.2rem',
          width: '20rem',
          borderRadius: '$small',
          background: '$duskull'
        }
      }
    }
  }
});

const ProfileBtn = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  maxWidth: '23rem'
});

const AALink = styled('a', {
  display: 'block',
  width: '100%',
  fontSize: '$newBody',
  lineHeight: '$body',
  fontWeight: 'normal',
  margin: '0 .4rem',
  textAlign: 'left',
  borderRadius: '$small',
  color: 'inherit'
});

const NavIcon = styled(Icon, {
  color: '$rhyhorn',
  '& path': {
    fill: 'inherit',
    color: 'inherit'
  },
  variants: {
    active: {
      true: {
        color: '$primary500',
        '& path': { fill: '$primary500' },
        fill: '$primary500'
      }
    }
  }
});

const WarningIcon = styled(Icon, {
  color: '$warning500'
});

const Caret = styled(Icon, {
  width: '1.2rem',
  height: '100%',
  color: '$rhyhorn',
  transform: 'rotate(0deg)',
  transition: 'transform 100ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(-180deg)',
        transition: 'transform 100ms ease-in-out'
      }
    }
  }
});

const IdCopyPaste = styled('button', {
  color: '$primary500',
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  gap: '.4rem',
  variants: {
    cursor: {
      true: {
        cursor: 'pointer'
      }
    }
  }
});

const ExtraInfo = styled('span', {
  display: 'flex',
  gap: '1rem'
});

const TeamNameWrapper = styled('span', {
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  position: 'relative'
});

const EditButton = styled(IconButton, {
  width: 'auto',
  height: '100%'
});

const CopiedPopUp = styled('span', {
  fontFamily: '$newDefault',
  display: 'grid',
  padding: '.5rem 1rem',
  textAlign: 'left',
  position: 'absolute',
  fontSize: '$newInfo',
  lineHeight: '$info',
  fontWeight: 'bold',
  transform: 'translate(0%, -110%)',
  color: 'white',
  background: '$primary500',
  borderRadius: '$small',
  boxShadow: '0 .3rem .6rem #00000029',
  border: '1px solid $computerBlack',

  variants: {
    open: {
      true: {
        pointerEvents: 'all',
        opacity: 1,
        transition: 'transform 100ms ease-out, opacity 100ms ease-in-out'
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'max-height 750ms ease-in-out, opacity 500ms ease-in-out'
      }
    }
  }
});

const SeatsComparisonWrapper = styled('span', {
  color: '$nice',
  '&:before': {
    content: '• '
  },
  fontSize: '$body',
  lineHeight: '$body',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '1rem'
});

const SeatsComparison = styled('span', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '.4rem',
  alignItems: 'center'
});

const SeatNumbers = styled('p', {
  fontSize: '$newBody',
  variants: {
    loading: {
      true: {
        '&:before': {
          display: 'inline-block',
          content: ' ',
          height: '2.2rem',
          width: '7rem',
          borderRadius: '$small',
          background: '$duskull'
        }
      }
    }
  }
});

const CaretContainer = styled('div', {
  width: '3rem',
  height: '3rem',
  display: 'grid',
  placeItems: 'center',
  borderRadius: '50%',
  marginLeft: '$small',

  '&:hover': {
    backgroundColor: '$pancham'
  }
});

// COMPONENTS
export interface SidebarProps {
  className?: string;
}

export function Sidebar(props: SidebarProps) {
  const { className } = props;
  const router = useRouter();

  const isMembersActive = router.pathname === '/members';
  const isInvitesActive = router.pathname === '/invites';
  const isGroupsActive = router.pathname.startsWith('/groups');
  const isRolesActive = router.pathname.startsWith('/roles');
  const isGuestAccessActive = router.pathname.startsWith('/guest-access');
  const isComputersActive = router.pathname.startsWith('/computers');
  const isRelaysActive = router.pathname.startsWith('/relays');
  const isBillingActive = router.pathname.startsWith('/billing');
  const isSubscriptionActive = router.pathname.startsWith('/subscription');
  const isSecurityActive = router.pathname.startsWith('/security');
  const isAppRulesActive = router.pathname.startsWith('/app-rules');
  const isKeysActive = router.pathname.startsWith('/keys');
  const isAuditLogsActive = router.pathname.startsWith('/audit-logs');
  const isHelpActive = router.pathname === '/help';

  const queryClient = useQueryClient();

  const subscription = useGetTeamSubscription();

  const trialNonRenewing =
    Boolean(subscription.data?.cancelled_at) &&
    subscription.data?.status === 'in_trial';

  const getMeQuery = useGetMe();
  const me = getMeQuery.data;

  const { mutateAsync: logOut } = useLogOut();
  const enabledPages = useEnabledPages();

  /* Team Section */
  const shouldDisplayMembersAndInvites =
    enabledPages?.members || enabledPages?.invites;

  const shouldDisplayGroupManagement =
    enabledPages?.groups.members || enabledPages?.groups.edit;

  const shouldDisplayAdminRoles =
    enabledPages?.roles.permissions ||
    enabledPages?.roles.edit ||
    enabledPages?.roles.admins;
  const shouldDisplayGuestAccess = enabledPages?.guestAccess;
  const shouldDisplayTeamComputers = enabledPages?.computers;
  const shouldDisplayRelays = enabledPages?.relays;

  const shouldDisplayTeamSection =
    shouldDisplayMembersAndInvites ||
    shouldDisplayGroupManagement ||
    shouldDisplayAdminRoles ||
    shouldDisplayGuestAccess ||
    shouldDisplayTeamComputers;

  /* Access Section */
  const shouldDisplaySecurityAndSaml = enabledPages?.security;
  const shouldDisplayAppRules = enabledPages?.appRules;
  const shouldDisplayAPIKeys = enabledPages?.apiKeys;

  const shouldDisplayAccessSection =
    shouldDisplaySecurityAndSaml ||
    shouldDisplayAppRules ||
    shouldDisplayAPIKeys;

  /* Account Section */
  const shouldDisplaySubscription = enabledPages?.subscription;
  const shouldDisplayBilling = enabledPages?.billing;
  const shouldDisplayAuditLogs = enabledPages?.auditLogs;
  const shouldDisplayHelp = enabledPages?.help;

  const shouldDisplayAccountSection =
    shouldDisplaySubscription ||
    shouldDisplayBilling ||
    shouldDisplayAuditLogs ||
    shouldDisplayHelp;

  const hasInvalidCard = useHasInvalidCard();
  const hasLatePayment = useHasLatePayment();
  const hasBillingIssues = hasInvalidCard || hasLatePayment;

  const relayIssuesCount = useCountRelayIssues();
  const hasRelayIssues = relayIssuesCount > 0;

  const [openCopied, setCopied] = useState(false);

  const { team, usedSeats, totalSeats, canUpdateTeamInfo, hasSaml } =
    useHeaderState();

  const teamId = team?.id ?? '';

  const copy = async () => {
    if (teamId) await navigator.clipboard.writeText(teamId);
  };

  //Trial UI
  const trialData = useTeamTrialDate();
  const inTrialWithAdmin = trialData && shouldDisplaySubscription;

  /* Profile Section */
  // Redirect focus when "manage subscription" button is clicked
  const linkRef = useRef<HTMLAnchorElement>(null);

  const handleSubscriptionClick = () => {
    if (linkRef.current) {
      linkRef.current.focus();
    }
  };

  const handleLogOutClick = async () => {
    try {
      await logOut();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Could not delete session', err);
    } finally {
      queryClient.clear();
    }
  };

  const { showComplianceWarning } = useSeatCompliance();

  const showSamlDomainWarning =
    !showComplianceWarning && hasSaml && !team?.is_using_new_saml_domain;

  const samlDomainWarning = showSamlDomainWarning ? (
    <Tooltip
      tooltipText="Changes have been made to the domain used for our SAML endpoints."
      placement="top"
      version={version}
    >
      <WarningIcon name="warningSign" />
    </Tooltip>
  ) : null;

  const { showPendingMembersWarning } = useTeamMembersToRemove();

  const pendingMembersWarning = showPendingMembersWarning ? (
    <Tooltip
      tooltipText="You have users pending removal"
      placement="top"
      version={version}
    >
      <WarningIcon name="warningSign" />
    </Tooltip>
  ) : null;

  return (
    <Wrapper className={className}>
      <Header>
        <AppName>Parsec for Teams</AppName>
        <TeamNameWrapper>
          <Company loading={!team}>{team?.name}</Company>
          {canUpdateTeamInfo && (
            <EditTeamNameModal defaultTeamName={team?.name}>
              <EditButton
                icon="pencil"
                title="edit"
                aria-label="Edit Team name"
              />
            </EditTeamNameModal>
          )}
        </TeamNameWrapper>
        <ExtraInfo>
          <IdCopyPaste
            disabled={!teamId}
            cursor={!!teamId}
            onClick={() => {
              copy();
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
            }}
          >
            <Icon css={{ marginTop: '.2rem' }} name="adminRoles" />

            <Tooltip
              placement="top"
              tooltipText={teamId || null}
              version={version}
            >
              <TeamId>Team Id</TeamId>
            </Tooltip>

            <CopiedPopUp aria-hidden={!openCopied} open={openCopied}>
              Copied!
            </CopiedPopUp>
          </IdCopyPaste>
          <SeatsComparisonWrapper>
            <SeatsComparison>
              <Icon name="team" />
              <SeatNumbers loading={!team}>
                {team && `${usedSeats} out of ${totalSeats}`}
              </SeatNumbers>
            </SeatsComparison>
          </SeatsComparisonWrapper>
        </ExtraInfo>
      </Header>
      <Nav>
        {/* Team */}
        <AnimateHeight height={shouldDisplayTeamSection ? 'auto' : 0}>
          <SectionLabel>Team</SectionLabel>
          {/* Members & Invites */}
          {shouldDisplayMembersAndInvites && (
            <Section active={isMembersActive || isInvitesActive}>
              <StyledLink
                href="/members"
                active={isMembersActive || isInvitesActive}
              >
                <NavIcon
                  active={isMembersActive || isInvitesActive}
                  name="team"
                />
                <span>Members &amp; Invites</span>
                {pendingMembersWarning}
              </StyledLink>
            </Section>
          )}
          {/* Group Management */}
          {shouldDisplayGroupManagement && (
            <Section active={isGroupsActive}>
              <StyledLink href="/groups" active={isGroupsActive}>
                <NavIcon active={isGroupsActive} name="groupManagement" />
                <span>Group Management</span>
              </StyledLink>
            </Section>
          )}
          {/* Admin Roles */}
          {shouldDisplayAdminRoles && (
            <Section active={isRolesActive}>
              <StyledLink href="/roles" active={isRolesActive}>
                <NavIcon active={isRolesActive} name="adminRoles" />
                <span>Admin Roles</span>
              </StyledLink>
            </Section>
          )}
          {/* Guest Access */}
          {shouldDisplayGuestAccess && (
            <Section active={isGuestAccessActive}>
              <StyledLink href="/guest-access" active={isGuestAccessActive}>
                <NavIcon active={isGuestAccessActive} name="team" />
                <span>Guest Access</span>
              </StyledLink>
            </Section>
          )}
          {/* Team Computers */}
          {shouldDisplayTeamComputers && (
            <Section active={isComputersActive}>
              <StyledLink href="/computers" active={isComputersActive}>
                <NavIcon active={isComputersActive} name="computer" />
                <span>Team Computers</span>
              </StyledLink>
            </Section>
          )}
          {/* Relays */}
          {shouldDisplayRelays && (
            <Section active={isRelaysActive}>
              <StyledLink
                href="/relays"
                active={isRelaysActive}
                showStatusIcon={hasRelayIssues}
              >
                <NavIcon active={isRelaysActive} name="serverRack" />
                <span>Relays</span>
                {hasRelayIssues ? (
                  <Tooltip
                    placement="right"
                    tooltipText={`${relayIssuesCount} relay${
                      relayIssuesCount === 1 ? '' : 's'
                    } in Unknown state`}
                  >
                    <NavIcon name="warningSign" />
                  </Tooltip>
                ) : null}
              </StyledLink>
            </Section>
          )}
        </AnimateHeight>
        {/*
         * Access
         */}
        <AnimateHeight height={shouldDisplayAccessSection ? 'auto' : 0}>
          <SectionLabel>Access</SectionLabel>
          {/* SAML */}
          {shouldDisplaySecurityAndSaml && (
            <Section active={isSecurityActive}>
              <StyledLink href="/security" active={isSecurityActive}>
                <NavIcon active={isSecurityActive} name="shield" />
                <span>Domain &amp; SAML</span>
                {showComplianceWarning && <WarningIcon name="warningSign" />}
                {samlDomainWarning}
              </StyledLink>
            </Section>
          )}
          {/* Team App Rules */}
          {shouldDisplayAppRules && (
            <Section active={isAppRulesActive}>
              <StyledLink href="/app-rules" active={isAppRulesActive}>
                <NavIcon active={isAppRulesActive} name="gear" />
                <span>App Rules</span>
              </StyledLink>
            </Section>
          )}
          {/* API Keys */}
          {shouldDisplayAPIKeys && (
            <Section active={isKeysActive}>
              <StyledLink href="/keys" active={isKeysActive}>
                <NavIcon active={isKeysActive} name="key" />
                <span>Keys</span>
              </StyledLink>
            </Section>
          )}
        </AnimateHeight>
        {/*
         * Account
         */}
        <AnimateHeight height={shouldDisplayAccountSection ? 'auto' : 0}>
          <SectionLabel>Account</SectionLabel>
          {/* Products & Subscriptions */}
          {shouldDisplaySubscription && (
            <Section active={isSubscriptionActive}>
              <StyledLink href="/subscription" active={isSubscriptionActive}>
                <NavIcon active={isSubscriptionActive} name="flag" />
                <span>Products &amp; Subscriptions</span>
              </StyledLink>
            </Section>
          )}
          {/* Billing */}
          {shouldDisplayBilling && (
            <Section active={isBillingActive}>
              <StyledLink
                href="/billing"
                active={isBillingActive}
                issues={hasBillingIssues}
              >
                <NavIcon
                  active={isBillingActive}
                  name={hasBillingIssues ? 'warningSign' : 'bill'}
                />
                <span>Billing</span>
              </StyledLink>
            </Section>
          )}
          {shouldDisplayAuditLogs && (
            <Section active={isAuditLogsActive}>
              <StyledLink href="/audit-logs" active={isAuditLogsActive}>
                <NavIcon active={isAuditLogsActive} name="list" />
                <span>Audit Logs</span>
              </StyledLink>
            </Section>
          )}
          {/* Help */}
          {shouldDisplayHelp && (
            <Section active={isHelpActive}>
              <StyledLink href="/help" active={isHelpActive}>
                <NavIcon active={isHelpActive} name="help" />
                <span>Help</span>
              </StyledLink>
            </Section>
          )}
          <ChangelogSection />
        </AnimateHeight>
      </Nav>
      {/*
       * Profile
       */}
      {me && (
        <Footer>
          {inTrialWithAdmin && trialData && (
            <TrialsInfo>
              <div>
                Parsec for Teams Trial
                <Expiration>
                  {trialNonRenewing ? (
                    <>
                      Access to your cancelled trial <br /> expires in{' '}
                    </>
                  ) : (
                    'Trial expires in '
                  )}
                  <strong>
                    {trialData.daysLeft} days (
                    <Time date={trialData.endDate} />)
                  </strong>
                </Expiration>
              </div>
              <StyledLink
                href="/subscription"
                onClick={handleSubscriptionClick}
              >
                <Button as="span" kind="ghost" version={version}>
                  Manage Subscription
                </Button>
              </StyledLink>
            </TrialsInfo>
          )}

          <Profile trial={inTrialWithAdmin}>
            <Flyout
              x={-15}
              y={-80}
              placement="right"
              items={[
                [
                  {
                    text: <AALink href={DASH_URL}>View Account</AALink>
                  }
                ],
                [
                  {
                    text: <LogOut onClick={handleLogOutClick}>Log Out</LogOut>
                  }
                ]
              ]}
            >
              {({ isOpen, props }) => (
                <ProfileBtn {...props}>
                  <Avatar userId={me.id} size={30} />
                  <Name>{me.name}</Name>
                  <CaretContainer>
                    <Caret name="caret" open={isOpen} />
                  </CaretContainer>
                </ProfileBtn>
              )}
            </Flyout>
          </Profile>
        </Footer>
      )}
    </Wrapper>
  );
}

function ChangelogSection() {
  const viewChangelog = useViewChangelog();
  if (
    viewChangelog &&
    viewChangelog.isSuccess &&
    viewChangelog.allLogs.length >= 1
  ) {
    const { lastUpdatedDate, onView, newLogAvailable } = viewChangelog;

    return (
      <Section borderTop>
        {lastUpdatedDate ? (
          <LogUpdated>
            {`last updated `}
            <Time format="relative" date={lastUpdatedDate} />
          </LogUpdated>
        ) : null}
        <NewChangelogModal>
          <ViewLogBtn new={newLogAvailable} onClick={onView}>
            <span>View Changelog</span>
          </ViewLogBtn>
        </NewChangelogModal>
      </Section>
    );
  }
  return (
    <Section borderTop>
      <Link href={CHANGELOG_URL}>View Changelog</Link>
    </Section>
  );
}

function useHeaderState() {
  const team = useGetTeam();

  const usedSeats = team.data
      ? team.data.num_members + team.data.num_invites
      : 0,
    totalSeats = team.data ? team.data.capabilities.total_seats : 0;

  const adminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = adminPermissionsQuery.data;
  const canUpdateTeamInfo = perms?.team.update_team_info ?? false;

  const { data: saml } = useGetTeamSaml();

  return {
    team: team.data,
    usedSeats,
    totalSeats,
    canUpdateTeamInfo,
    hasSaml: Boolean(saml?.entity)
  };
}
