// @parsec
import { host } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('host');

export function useGetHost(
  vars: host.GetHostReq,
  options?: { enabled?: boolean }
) {
  const kessel = useKessel();

  const enabled = Boolean(
    vars.peer_id && vars.secret && (options?.enabled ?? true)
  );

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.host.getHost(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, { error: "Couldn't get host." });
  return { ...result, error };
}
