import { useEffect, useState, ComponentType } from 'react';

// HOC component to render wrapping component as client-side only.
export const withClientOnly = <P extends object>(
  WrappedComponent: ComponentType<P>
): React.FC<P> => {
  const ClientOnlyComponent: React.FC<P> = props => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
      setIsClient(true);
    }, []);

    if (!isClient) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return ClientOnlyComponent;
};
