export function getPlatform() {
  if (typeof window === 'undefined') return undefined;

  const { appVersion, platform, userAgent } = window.navigator;
  if (
    platform.indexOf('Android') > -1 ||
    (userAgent && userAgent.indexOf('Android') > -1)
  ) {
    return 'android';
  } else if (appVersion.includes('Mac')) {
    return 'macos';
  } else if (appVersion.includes('Linux')) {
    return 'linux';
  } else if (appVersion.includes('Win')) {
    return 'windows';
  }

  return undefined;
}
