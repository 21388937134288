import { forwardRef, useEffect, useRef, MutableRefObject, Ref } from 'react';

import { CSS, styled } from '@parsec/styles';

type InputProps = JSX.IntrinsicElements['input'];

export interface CheckboxProps extends Omit<InputProps, 'type'> {
  indeterminate?: boolean;
  ref?: Ref<HTMLInputElement>;
  kind?: 'solid' | 'outline';
  css?: CSS;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(props, fwdRef) {
    const { checked, indeterminate, css, ...rest } = props;

    const ref: MutableRefObject<HTMLInputElement | null> = useRef(null);
    useEffect(() => {
      if (!ref?.current) return;

      ref.current.indeterminate = !!indeterminate;
    }, [checked, indeterminate]);

    return (
      <Input
        {...rest}
        type="checkbox"
        checked={checked}
        css={css}
        tabIndex={0}
        ref={(input: HTMLInputElement) => {
          ref.current = input;
          if (typeof fwdRef === 'function') fwdRef(input);
          else if (fwdRef) fwdRef.current = input;
        }}
      />
    );
  }
);

export default Checkbox;

const Input = styled('input', {
  background: '$chandelure center center no-repeat',
  borderRadius: '$small',
  color: '$consoleWhite',
  transition: '125ms box-shadow ease, 125ms background-color ease',
  appearance: 'none',
  width: '1.6rem',
  height: '1.6rem',
  cursor: 'pointer',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.18)',

  //indeterminate
  '&:indeterminate': {
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgOCAyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNyAwSDFDMC40NDc3MTUgMCAwIDAuNDQ3NzE1IDAgMUMwIDEuNTUyMjggMC40NDc3MTUgMiAxIDJIN0M3LjU1MjI4IDIgOCAxLjU1MjI4IDggMUM4IDAuNDQ3NzE1IDcuNTUyMjggMCA3IDBaIiBmaWxsPSIjRjlGOUY5Ii8+Cjwvc3ZnPgo=")`
  },

  //hover
  '&:not(:disabled):not([checked]):hover': {
    boxShadow:
      'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.07), inset 0 0 0 1px $colors$pangoro, 0px 1px 2px 0px rgba(0, 0, 0, 0.18)',
    '&::-moz-focus-inner': {
      border: 'none'
    }
  },

  //disabled
  '&:disabled': {
    backgroundColor: '$duskull',
    cursor: 'default'
  },

  //checked
  '&:not(:indeterminate):checked': {
    backgroundColor: '$primary500',
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC4yOTMgMC4yOTI5MTlMNC4wMDAwNCA2LjU4NTkyTDEuNzA3MDQgNC4yOTI5MkMxLjUxODQ0IDQuMTEwNzYgMS4yNjU4NCA0LjAwOTk3IDEuMDAzNjQgNC4wMTIyNEMwLjc0MTQ0MyA0LjAxNDUyIDAuNDkwNjMgNC4xMTk2OSAwLjMwNTIyMiA0LjMwNTFDMC4xMTk4MTQgNC40OTA1MSAwLjAxNDY0NTMgNC43NDEzMiAwLjAxMjM2NjkgNS4wMDM1MkMwLjAxMDA4ODUgNS4yNjU3MSAwLjExMDg4MyA1LjUxODMyIDAuMjkzMDQxIDUuNzA2OTJMMy4yOTMwNCA4LjcwNjkyQzMuNDgwNTcgOC44OTQzOSAzLjczNDg4IDguOTk5NzEgNC4wMDAwNCA4Ljk5OTcxQzQuMjY1MjEgOC45OTk3MSA0LjUxOTUxIDguODk0MzkgNC43MDcwNCA4LjcwNjkyTDExLjcwNyAxLjcwNjkyQzExLjg4OTIgMS41MTgzMiAxMS45OSAxLjI2NTcxIDExLjk4NzcgMS4wMDM1MkMxMS45ODU0IDAuNzQxMzIxIDExLjg4MDMgMC40OTA1MDggMTEuNjk0OSAwLjMwNTFDMTEuNTA5NSAwLjExOTY5MiAxMS4yNTg2IDAuMDE0NTIzMyAxMC45OTY0IDAuMDEyMjQ0OEMxMC43MzQyIDAuMDA5OTY2NDEgMTAuNDgxNiAwLjExMDc2MSAxMC4yOTMgMC4yOTI5MTlaIiBmaWxsPSIjRjlGOUY5Ii8+Cjwvc3ZnPgo=")`
  },

  //checked and disabled
  '&:not(:indeterminate):checked:disabled': {
    backgroundColor: '$duskull',
    backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC4yOTMgMC4yOTI5MTlMNC4wMDAwNCA2LjU4NTkyTDEuNzA3MDQgNC4yOTI5MkMxLjUxODQ0IDQuMTEwNzYgMS4yNjU4NCA0LjAwOTk3IDEuMDAzNjQgNC4wMTIyNEMwLjc0MTQ0MyA0LjAxNDUyIDAuNDkwNjMgNC4xMTk2OSAwLjMwNTIyMiA0LjMwNTFDMC4xMTk4MTQgNC40OTA1MSAwLjAxNDY0NTMgNC43NDEzMiAwLjAxMjM2NjkgNS4wMDM1MkMwLjAxMDA4ODUgNS4yNjU3MSAwLjExMDg4MyA1LjUxODMyIDAuMjkzMDQxIDUuNzA2OTJMMy4yOTMwNCA4LjcwNjkyQzMuNDgwNTcgOC44OTQzOSAzLjczNDg4IDguOTk5NzEgNC4wMDAwNCA4Ljk5OTcxQzQuMjY1MjEgOC45OTk3MSA0LjUxOTUxIDguODk0MzkgNC43MDcwNCA4LjcwNjkyTDExLjcwNyAxLjcwNjkyQzExLjg4OTIgMS41MTgzMiAxMS45OSAxLjI2NTcxIDExLjk4NzcgMS4wMDM1MkMxMS45ODU0IDAuNzQxMzIxIDExLjg4MDMgMC40OTA1MDggMTEuNjk0OSAwLjMwNTFDMTEuNTA5NSAwLjExOTY5MiAxMS4yNTg2IDAuMDE0NTIzMyAxMC45OTY0IDAuMDEyMjQ0OEMxMC43MzQyIDAuMDA5OTY2NDEgMTAuNDgxNiAwLjExMDc2MSAxMC4yOTMgMC4yOTI5MTlaIiBmaWxsPSIjNjk2OTY5Ii8+Cjwvc3ZnPgo=")`
  }
});
