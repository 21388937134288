import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, estimate } from './schema';

/******************************************************************************
 * Estimate For Team Subscription
 ******************************************************************************/

export interface EstimateTeamSubscriptionReq {
  plan_id: string; // TODO: this should be more specific
  seats: number;
  country?: string;
  zip?: string;
  coupon?: string;
}

export const estimateTeamSubscription =
  (options: ApiOptions) => (req: EstimateTeamSubscriptionReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/estimate/team-subscription`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(estimate),
      body: req
    });

/******************************************************************************
 * Estimate For Warp Subscription
 ******************************************************************************/

export interface EstimateWarpSubscriptionReq {
  plan_id: string;
  country?: string;
  zip?: string;
  coupon?: string;
}

export const estimateWarpSubscription =
  (options: ApiOptions) => (req: EstimateWarpSubscriptionReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/estimate/warp-subscription`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(estimate),
      body: req
    });

/******************************************************************************
 * Estimate For Team Purchase of Guest Access Credits
 ******************************************************************************/

export interface EstimatePurchaseReq {
  addon_id: string;
  quantity: number;
}

export const estimatePurchase =
  (options: ApiOptions) => (req: EstimatePurchaseReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/estimate/purchase`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(estimate),
      body: req
    });
