import {
  genJourney,
  JourneyGroup,
  type StepNum,
  type UserEvent,
  type JourneyTriggerType
} from './events';

/**
 * Helper to push to the dataLayer
 * @param event
 */
export function pushToDl<T>(event: UserEvent<T>) {
  if (typeof window !== 'undefined') {
    window.dataLayer?.push(event);
  } else {
    console.error(
      'Tried to push to the dataLayer without the global window object.'
    );
  }
}

/*==== Sign Up Journey ====== */
type TeamInfo = { isTeam: boolean; isTeamAdmin: boolean };

type FailureSteps<T extends JourneyGroup> = {
  step: Extract<StepNum<T>, '1.4.2'>; // Password submit failure step
  isConversion?: boolean;
  stepMsg: string; // error|failure message
  trigger?: JourneyTriggerType;
};

type SuccessSteps<T extends JourneyGroup> = {
  step: Exclude<StepNum<T>, '1.4.2'>; // Password submit failure step
  isConversion?: boolean;
  stepMsg?: never; // error|failure message
  trigger?: JourneyTriggerType;
};

// Conditional require for stepMsg
type TeamMemberSignUp =
  | (TeamInfo & FailureSteps<JourneyGroup.AccountSignUpTeamMember>)
  | (TeamInfo & SuccessSteps<JourneyGroup.AccountSignUpTeamMember>);

type TeamAdminSignUp =
  | (TeamInfo & FailureSteps<JourneyGroup.AccountSignUpTeamAdmin>)
  | (TeamInfo & SuccessSteps<JourneyGroup.AccountSignUpTeamAdmin>);

type UserSignUp =
  | (TeamInfo & FailureSteps<JourneyGroup.AccountSignUpUser>)
  | (TeamInfo & SuccessSteps<JourneyGroup.AccountSignUpUser>);

type PushDlJourneyProps = TeamAdminSignUp | TeamMemberSignUp | UserSignUp;

/**
 * Specific to SignUp v2.0 User Sign up Journey
 *
 */
export function pushDlSignUp({
  step,
  isTeam,
  isTeamAdmin,
  isConversion = false,
  stepMsg,
  trigger
}: PushDlJourneyProps) {
  if (!isTeam) {
    // regular user
    pushToDl(
      genJourney({
        group: JourneyGroup.AccountSignUpUser,
        step: step as StepNum<JourneyGroup.AccountSignUpUser>,
        isConversion,
        stepMsg,
        trigger
      })
    );
  } else if (isTeam && isTeamAdmin) {
    // team admin
    pushToDl(
      genJourney({
        group: JourneyGroup.AccountSignUpTeamAdmin,
        step: step as StepNum<JourneyGroup.AccountSignUpTeamAdmin>,
        isConversion,
        stepMsg,
        trigger
      })
    );
  } else {
    // team member
    pushToDl(
      genJourney({
        group: JourneyGroup.AccountSignUpTeamMember,
        step,
        isConversion,
        stepMsg,
        trigger
      })
    );
  }
}

/** ===== Warp and Teams Trial Sign Up ======= */

/**
 * Specific to the SignUp2.0 Warp Trial Sign Up Journey
 *
 */
export function pushDlWarpTrial({
  step,
  isConversion = false
}: {
  step: StepNum<JourneyGroup.WarpTrialSignUp>;
  isConversion?: boolean;
}) {
  pushToDl(
    genJourney({ group: JourneyGroup.WarpTrialSignUp, step, isConversion })
  );
}

/**
 * Specific to the SignUp2.0 Teams Trial Sign Up Journey
 *
 */
export function pushDlTeamTrial({
  step,
  isConversion = false
}: {
  step: StepNum<JourneyGroup.TeamTrialSignUp>;
  isConversion?: boolean;
}) {
  pushToDl(
    genJourney({ group: JourneyGroup.TeamTrialSignUp, step, isConversion })
  );
}

/** ===== Sign up 3.0 Journey ======= */

// Conditional require for stepMsg
type PushDlJourneyV3Props =
  | FailureSteps<JourneyGroup.AccountCreation>
  | SuccessSteps<JourneyGroup.AccountCreation>;

export function pushDlSignUpV3({
  step,
  isConversion,
  stepMsg,
  trigger
}: PushDlJourneyV3Props) {
  pushToDl(
    genJourney({
      journeyName: 'signup 3.0',
      step,
      group: JourneyGroup.AccountCreation,
      isConversion,
      stepMsg,
      trigger
    })
  );
}

/** ==== Select Product SignUp 3.0 ==== */
interface PushDlJourneySelectProps {
  step: StepNum<JourneyGroup.AccountSetUpSelect>;
  trigger?: JourneyTriggerType;
}

export function pushDlSelectProduct({
  step,
  trigger
}: PushDlJourneySelectProps) {
  pushToDl(
    genJourney({
      journeyName: 'signup 3.0',
      step,
      group: JourneyGroup.AccountSetUpSelect,
      trigger
    })
  );
}

/** ==== User Account Set Up Signup 3.0 ==== */
interface PushDlMeAcctSetUpProps {
  step: StepNum<JourneyGroup.AccountSetUpForMe>;
  isConversion?: boolean;
  trigger?: JourneyTriggerType;
}

export function pushDlMeSetUp({
  step,
  isConversion = false,
  trigger
}: PushDlMeAcctSetUpProps) {
  pushToDl(
    genJourney({
      journeyName: 'signup 3.0',
      step,
      group: JourneyGroup.AccountSetUpForMe,
      isConversion,
      trigger
    })
  );
}

/** ==== Team Account Set Up Signup 3.0 ===== */

interface PushDlTeamAcctSetUpProps {
  step: StepNum<JourneyGroup.AccountSetUpForTeam>;
  isConversion?: boolean;
  trigger?: JourneyTriggerType;
}

export function pushDlTeamSetUp({
  step,
  isConversion = false,
  trigger
}: PushDlTeamAcctSetUpProps) {
  pushToDl(
    genJourney({
      journeyName: 'signup 3.0',
      step,
      group: JourneyGroup.AccountSetUpForTeam,
      isConversion,
      trigger
    })
  );
}

/* ==== Warp Trial CTA Signup 3.0 ==== */
interface pushDlWarpTrialCtaProps {
  step: StepNum<JourneyGroup.AccountSetUpForMeWarp>;
  isConversion?: boolean;
  trigger?: JourneyTriggerType;
}

export function pushDlWarpTrialCta({
  step,
  isConversion = false,
  trigger
}: pushDlWarpTrialCtaProps) {
  pushToDl(
    genJourney({
      journeyName: 'signup 3.0',
      step,
      group: JourneyGroup.AccountSetUpForMeWarp,
      isConversion,
      trigger
    })
  );
}
