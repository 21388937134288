export const STATE_CODES = [
  { value: 'Alabama', name: 'AL - Alabama' },
  { value: 'Alaska', name: 'AK - Alaska' },
  { value: 'Arizona', name: 'AZ - Arizona' },
  { value: 'Arkansas', name: 'AR - Arkansas' },
  { value: 'California', name: 'CA - California' },
  { value: 'Colorado', name: 'CO - Colorado' },
  { value: 'Connecticut', name: 'CT - Connecticut' },
  { value: 'Delaware', name: 'DE - Delaware' },
  { value: 'District of Columbia', name: 'DC - District of Columbia' },
  { value: 'Florida', name: 'FL - Florida' },
  { value: 'Georgia', name: 'GA - Georgia' },
  { value: 'Hawaii', name: 'HI - Hawaii' },
  { value: 'Idaho', name: 'ID - Idaho' },
  { value: 'Illinois', name: 'IL - Illinois' },
  { value: 'Indiana', name: 'IN - Indiana' },
  { value: 'Iowa', name: 'IA - Iowa' },
  { value: 'Kansas', name: 'KS - Kansas' },
  { value: 'Kentucky', name: 'KY - Kentucky' },
  { value: 'Louisiana', name: 'LA - Louisiana' },
  { value: 'Maine', name: 'ME - Maine' },
  { value: 'Maryland', name: 'MD - Maryland' },
  { value: 'Massachusetts', name: 'MA - Massachusetts' },
  { value: 'Michigan', name: 'MI - Michigan' },
  { value: 'Minnesota', name: 'MN - Minnesota' },
  { value: 'Mississippi', name: 'MS - Mississippi' },
  { value: 'Missouri', name: 'MO - Missouri' },
  { value: 'Montana', name: 'MT - Montana' },
  { value: 'Nebraska', name: 'NE - Nebraska' },
  { value: 'Nevada', name: 'NV - Nevada' },
  { value: 'New Hampshire', name: 'NH - New Hampshire' },
  { value: 'New Jersey', name: 'NJ - New Jersey' },
  { value: 'New Mexico', name: 'NM - New Mexico' },
  { value: 'New York', name: 'NY - New York' },
  { value: 'North Carolina', name: 'NC - North Carolina' },
  { value: 'North Dakota', name: 'ND - North Dakota' },
  { value: 'Ohio', name: 'OH - Ohio' },
  { value: 'Oklahoma', name: 'OK - Oklahoma' },
  { value: 'Oregon', name: 'OR - Oregon' },
  { value: 'Pennsylvania', name: 'PA - Pennsylvania' },
  { value: 'Rhode Island', name: 'RI - Rhode Island' },
  { value: 'South Carolina', name: 'SC - South Carolina' },
  { value: 'South Dakota', name: 'SD - South Dakota' },
  { value: 'Tennessee', name: 'TN - Tennessee' },
  { value: 'Texas', name: 'TX - Texas' },
  { value: 'Utah', name: 'UT - Utah' },
  { value: 'Vermont', name: 'VT - Vermont' },
  { value: 'Virginia', name: 'VA - Virginia' },
  { value: 'Washington', name: 'WA - Washington' },
  { value: 'West Virginia', name: 'WV - West Virginia' },
  { value: 'Wisconsin', name: 'WI - Wisconsin' },
  { value: 'Wyoming', name: 'WY - Wyoming' }
];
