import { useEffect, useRef, RefObject } from 'react';

export function useClickOutside<T extends HTMLElement>(
  fn: () => void,
  ...refs: RefObject<T | null>[]
) {
  const onClickOutside = useRef(fn);
  onClickOutside.current = fn;

  useEffect(() => {
    let shouldFire = false;

    const check = (event: MouseEvent) => {
      shouldFire = !refs.every(ref => {
        return ref.current && ref.current.contains(event.target as Node);
      });
    };

    const handle = () => {
      if (shouldFire) {
        shouldFire = false;
        onClickOutside.current();
      }
    };

    document.addEventListener('mousedown', check, true);
    document.addEventListener('mousedown', handle);

    return () => {
      document.removeEventListener('mousedown', check, true);
      document.removeEventListener('mousedown', handle);
    };
  }, [refs]);
}
