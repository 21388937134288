import {
  useQuery as useReactQuery,
  QueryFunction,
  QueryKey,
  UseQueryOptions
} from 'react-query';

import { useCookie } from '@parsec/cookie';
import { parseError } from '@parsec/request';

import { useLifecycle } from './Provider';

export function useQuery<Key extends QueryKey, Data>(
  key: Key,
  fn: QueryFunction<Data, Key>,
  options: UseQueryOptions<Data, unknown, Data, Key> = {}
) {
  const { onInvalidSession } = useLifecycle();
  const { token } = useCookie();

  return useReactQuery(key, fn, {
    ...options,
    onError(err) {
      const { codes, status } = parseError(err);
      if (!token) {
        onInvalidSession('NO_COOKIE');
      } else if (status === 401 || status === 412) {
        onInvalidSession(`${status}`);
      } else if (codes.some(code => code.type === 'session_incorrect')) {
        onInvalidSession();
      }

      options.onError?.(err);
    }
  });
}
