import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { customer, card, data } from './schema';

export interface WarpBillingDetailsReq {
  first_name?: string;
  last_name?: string;
  email: string;
  phone?: string;
  company?: string;
  tax_id?: string;
  tax_exempt_number?: string;
  billing_address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    zip?: string;
    country: string;
  };
}

/******************************************************************************
 * Get Warp Billing Details
 ******************************************************************************/

export const getWarpBillingDetails = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/warp/billing`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(customer)
  });

/******************************************************************************
 * Update Warp Billing Details
 ******************************************************************************/

export const updateWarpBillingDetails =
  (options: ApiOptions) => (body: WarpBillingDetailsReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/warp/billing`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(customer),
      body
    });

export interface WarpBillingCardRes {
  first_name: string;
  last_name: string;
  last4: string;
  expiry_month: number;
  expiry_year: number;
  zip: string;
  country: string;
}

/******************************************************************************
 * Get Warp Billing Card
 ******************************************************************************/

export const getWarpBillingCard = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/warp/billing/card`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(card)
  });

/******************************************************************************
 * Create Warp Billing Card
 ******************************************************************************/

export interface CreateWarpBillingCardReq {
  token: string;
}

export const createWarpBillingCard =
  (options: ApiOptions) => (body: CreateWarpBillingCardReq) => {
    return request<{ data: WarpBillingCardRes }>({
      type: Method.POST,
      url: `${options.kessel}/warp/billing/card`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(card),
      body
    });
  };
