// @parsec
import { schema, teamGroup } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('group_connection');

export function useTeamGroupConnectionsData() {
  return useQueryData(key.all(), schema.paginatedTeamGroupData);
}

// ******************************************************************************

/** Fetches all of a group's connections */
export function useGetAllGroupConnections(
  opts: Omit<
    teamGroup.GetTeamGroupConnectionsReq,
    'team_id' | 'offset' | 'limit'
  > & { enabled?: boolean }
) {
  const kessel = useKessel();
  const me = useGetMe();

  const { enabled: enabledFromProps = true, ...optsRest } = opts;

  const vars = {
    ...optsRest,
    team_id: me.data?.team_id ?? '',
    offset: 0,
    limit: 200
  };
  const enabled = vars.team_id !== '' && enabledFromProps;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroupConnections(req);
      // Sort: alphanumeric
      res.body.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team group connections."
  });
  return { ...result, error };
}

// ******************************************************************************

/** Fetches all of a group's connections (incoming and outgoing) */
export function useGetAllGroupConnectionsV2(
  opts: Omit<teamGroup.GetTeamGroupConnectionsV2Req, 'team_id'> & {
    enabled?: boolean;
  }
) {
  const kessel = useKessel();
  const me = useGetMe();

  const { enabled: enabledFromProps = true, ...optsRest } = opts;

  const vars = {
    ...optsRest,
    team_id: me.data?.team_id ?? ''
  };
  const enabled = vars.team_id !== '' && enabledFromProps;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroupConnectionsV2(req);
      // Sort: alphanumeric
      res.body.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team group connections."
  });
  return { ...result, error };
}

// ******************************************************************************

type CreateGroupConnectionsVars = Omit<
  teamGroup.CreateTeamGroupConnectionsReq,
  'team_id'
>;

/** Creates a batch of group connections */
export function useCreateGroupConnections() {
  const kessel = useKessel();
  const cache = useTeamGroupConnectionsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: CreateGroupConnectionsVars) {
    return await kessel.teamGroup.createTeamGroupConnections({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    cache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't create team group connection."
  });
  return { ...result, error };
}

// ******************************************************************************

type CreateGroupConnectionsV2Vars = Omit<
  teamGroup.CreateTeamGroupConnectionsV2Req,
  'team_id'
>;

/** Creates a batch of group connections */
export function useCreateGroupConnectionsV2() {
  const kessel = useKessel();
  const cache = useTeamGroupConnectionsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: CreateGroupConnectionsV2Vars) {
    return await kessel.teamGroup.createTeamGroupConnectionsV2({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    cache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't create team group connection."
  });
  return { ...result, error };
}

// ******************************************************************************

type UpdateGroupConnectionsVars = Omit<
  teamGroup.UpdateTeamGroupConnectionsReq,
  'team_id'
>;

export function useUpdateGroupConnections() {
  const kessel = useKessel();
  const cache = useTeamGroupConnectionsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: UpdateGroupConnectionsVars) {
    return await kessel.teamGroup.updateTeamGroupConnections({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    cache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't update team group connection."
  });
  return { ...result, error };
}

// ******************************************************************************

type UpdateGroupConnectionsV2Vars = Omit<
  teamGroup.UpdateTeamGroupConnectionsV2Req,
  'team_id'
>;

export function useUpdateGroupConnectionsV2() {
  const kessel = useKessel();
  const cache = useTeamGroupConnectionsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: UpdateGroupConnectionsV2Vars) {
    return await kessel.teamGroup.updateTeamGroupConnectionsV2({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    cache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't update team group connection."
  });
  return { ...result, error };
}

// ******************************************************************************

type DeleteGroupConnectionsVars = Omit<
  teamGroup.DeleteTeamGroupConnectionsReq,
  'team_id'
>;

/** Deletes a batch of group connections */
export function useDeleteGroupConnections() {
  const kessel = useKessel();
  const cache = useTeamGroupConnectionsData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(reqBody: DeleteGroupConnectionsVars) {
    return await kessel.teamGroup.deleteTeamGroupConnections({
      ...reqBody,
      team_id: teamId
    });
  }

  function onSuccess() {
    cache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "couldn't delete team group connection."
  });
  return { ...result, error };
}
