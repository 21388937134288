import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';

import { Token } from '@stripe/stripe-js';

import {
  type TurnstileInstance,
  CaptchaWidget as BaseCaptchaWidget,
  CaptchaActions,
  Checkbox,
  Divider as HorizontalRule,
  Input,
  LegacyModal,
  PaymentMethodForm,
  styled
} from '@parsec/components';
import {
  useCreateTeamBillingCard,
  useEstimateAccessLinkCreditPurchase,
  useGetTeam,
  useGetTeamBillingCard,
  useGetTeamBillingDetails,
  usePurchaseGuestAccess
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { ACCESS_LINK_COST } from 'constants/payment';

import { MARKETING_URL, TURNSTILE_SITE_KEY } from 'lib/config';
import LogoSvg from 'lib/images/logo.svg';

import { Bread, Enum, PoNumberInput, usePoNumberInputProps } from 'components';

import { AccessLinkPriceType } from './AccessLinkBilling';

const version = 'newFont';

enum Step {
  BillingInfo,
  Confirm
}

interface AddAccessLinkCreditModalProps {
  isOpen: boolean;
  quantityProps?: number;
  priceTypeProps?: AccessLinkPriceType;
  onClose(): void;
}

export function AddAccessLinkCreditModal(props: AddAccessLinkCreditModalProps) {
  const { isOpen, quantityProps, priceTypeProps } = props;
  const { onClose } = props;

  const addonID =
    priceTypeProps === AccessLinkPriceType.Package
      ? 'guest-access-package'
      : 'guest-access-standard';

  const onlyConfirm = quantityProps || priceTypeProps;

  const [step, setStep] = useState<Step>(
    onlyConfirm ? Step.Confirm : Step.BillingInfo
  );

  const [quantity, setQuantity] = useState(quantityProps ?? 1);
  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const [editingCard, setEditingCard] = useState(false);

  // CAPTCHA
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);

  const {
    data: card,
    refetch: fetchCard,
    isLoading: loadingCard,
    error: billingCardError
  } = useGetTeamBillingCard();
  const { mutateAsync: createTeamBillingCard } = useCreateTeamBillingCard();

  const { data: billingDetails, error: billingDetailsError } =
    useGetTeamBillingDetails();
  const isInvoiced = !billingDetails?.invoice_config.auto_collection;

  const isBillingEnabled =
    useGetTeam().data?.capabilities.features.is_billing_enabled;

  const purchaseEstimateQuery = useEstimateAccessLinkCreditPurchase({
    addon_id: addonID,
    quantity
  });
  const purchaseEstimate = purchaseEstimateQuery.data;

  const { mutateAsync: purchaseGuestAccess, isLoading: purchaseDisabled } =
    usePurchaseGuestAccess();

  const poNumberProps = usePoNumberInputProps();
  const { poNumber, usePoNumber } = poNumberProps;

  const canSubmit = quantity < 100 && quantity > 0 && !editingCard;
  const fetchError = billingCardError || billingDetailsError;

  useEffect(() => {
    quantityProps && setQuantity(quantityProps);
  }, [quantityProps]);

  return (
    <LegacyModal
      version={version}
      type="principal"
      title="Purchase Guest Credits"
      description={
        <>
          <Logo />
          <Description>Remote access for creatives.</Description>
        </>
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      actions={
        step === Step.BillingInfo
          ? [
              {
                text: 'Next',
                disabled: !canSubmit,
                onClick: () => {
                  setStep(Step.Confirm);
                  setError('');
                }
              },
              {
                text: 'Cancel',
                level: 'secondary',
                onClick: onClose
              }
            ]
          : [
              {
                text: 'Confirm Purchase',
                disabled:
                  !captchaToken.length ||
                  (!error && (!checked || purchaseDisabled)),
                onClick: async () => {
                  setError('');
                  try {
                    // Po numbers are submitted on a one-time basis for purchases (i.e. not subscription)
                    const res = await purchaseGuestAccess({
                      addon_id: addonID,
                      quantity: quantity,
                      po_number: usePoNumber ? poNumber : '',
                      captcha_token: captchaToken
                    });

                    // Match the status logic found in Kessel around guest access purchases.
                    // Ref: https://github.com/parsec-cloud/kessel/blob/main/pkg/restapi/team_purchase_handlers.go#L71
                    if (
                      res.body.data.status === 'not_paid' ||
                      res.body.data.status === 'voided'
                    ) {
                      setError('There was a problem processing the payment.');
                    }
                    setChecked(false);
                    onClose();
                  } catch (err) {
                    const error = parseError(err);
                    setError(
                      error.error ??
                        'There was an error resuming your Warp Trial. Please try again.'
                    );
                  } finally {
                    // Reset the captcha widget so we are granted a new token
                    setCaptchaToken('');
                    captchaRef.current?.reset();
                  }
                }
              },
              {
                text: onlyConfirm ? 'Cancel' : 'Back',
                level: 'secondary',
                onClick: onlyConfirm
                  ? () => {
                      setChecked(false);
                      onClose();
                    }
                  : () => {
                      setStep(Step.BillingInfo);
                    }
              }
            ]
      }
    >
      <Enum value={step}>
        {value => {
          switch (value) {
            case Step.BillingInfo: {
              return (
                <>
                  <CreditPurchaseForm
                    quantity={quantity}
                    setQuantity={setQuantity}
                    onSubmit={() => setStep(Step.Confirm)}
                  />
                  <HorizontalRule />
                  <CheckoutLine>
                    Subtotal
                    <Span>
                      <Strong>
                        <Bread>
                          {purchaseEstimate?.subtotal_amount_cents ?? NaN}
                        </Bread>
                      </Strong>{' '}
                      / billed once
                    </Span>
                  </CheckoutLine>

                  <DarkWrapper>
                    <PoNumberInput {...poNumberProps} />
                    {!isInvoiced && isBillingEnabled && (
                      <PaymentMethodForm
                        id="update_card"
                        disabled={purchaseDisabled}
                        loading={loadingCard}
                        card={card}
                        name={
                          billingDetails?.first_name
                            ? `${billingDetails.first_name} ${billingDetails.last_name}`
                            : undefined
                        }
                        onToggleEdit={() => setEditingCard(!editingCard)}
                        onSubmit={async (token: Token) => {
                          await createTeamBillingCard({
                            token: token.id
                          });
                        }}
                        onSubmitError={err => setError((err as Error).message)}
                        onSubmitSuccess={() => {
                          fetchCard();
                          setEditingCard(false);
                        }}
                      />
                    )}

                    {fetchError ? (
                      <ErrorMessage>{fetchError.error}</ErrorMessage>
                    ) : null}
                  </DarkWrapper>
                </>
              );
            }

            case Step.Confirm: {
              return (
                <>
                  <CheckoutLine>
                    Subtotal
                    <Span>
                      <Bread>
                        {purchaseEstimate?.subtotal_amount_cents ?? NaN}
                      </Bread>
                    </Span>
                  </CheckoutLine>
                  <CheckoutLine>
                    Sales Tax
                    <Span>
                      {purchaseEstimateQuery.isLoading ? (
                        '$...'
                      ) : (
                        <Bread>
                          {purchaseEstimate?.tax_amount_cents ?? NaN}
                        </Bread>
                      )}
                    </Span>
                  </CheckoutLine>
                  <CheckoutLine css={{ margin: '1.4rem 0rem' }}>
                    Estimated Total
                    <Span>
                      <Strong>
                        {purchaseEstimateQuery.isLoading ? (
                          '$...'
                        ) : (
                          <Bread>
                            {purchaseEstimate?.total_amount_cents ?? NaN}
                          </Bread>
                        )}
                      </Strong>
                    </Span>
                  </CheckoutLine>

                  {onlyConfirm && <PoNumberInput {...poNumberProps} />}
                  <br />

                  <Blurb>
                    This is a one time purchase. Credits do not expire.
                  </Blurb>
                  <Blurb>
                    Have questions?{' '}
                    <a
                      href="https://support.parsec.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact our support team
                    </a>
                    .
                  </Blurb>
                  <DarkWrapper>
                    <TosWrapper>
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                      <span>
                        I agree to Parsec&apos;s{' '}
                        <a
                          href={`${MARKETING_URL}/terms`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Terms of Service
                        </a>
                      </span>
                    </TosWrapper>
                    <Blurb faded>
                      Parsec for Teams automatically renews at the end of your
                      subscription period. Parsec will immediately charge the
                      payment method provided, and automatically do so until you
                      cancel. You can cancel at any time.
                    </Blurb>

                    {error ? <ErrorMessage>{error}</ErrorMessage> : null}

                    <CaptchaWidget
                      ref={captchaRef}
                      action={CaptchaActions.PurchaseGuestAccess}
                      siteKey={TURNSTILE_SITE_KEY}
                      onSuccess={(token: string) => {
                        setCaptchaToken(token);
                      }}
                    />
                  </DarkWrapper>
                </>
              );
            }
          }
        }}
      </Enum>
    </LegacyModal>
  );
}

function CreditPurchaseForm(props: {
  quantity: number;
  setQuantity(quantity: number): void;
  onSubmit(): void;
}) {
  const { quantity, setQuantity, onSubmit } = props;

  return (
    <form id="purchase_credits" onSubmit={onSubmit}>
      <Table>
        <thead>
          <tr>
            <Th css={{ width: '25%' }}>Name</Th>
            <Th css={{ width: '20%' }}>Amount</Th>
            <Th css={{ width: '40%' }}>Price per Unit</Th>
            <Th css={{ textAlign: 'right' }}>Total</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <strong>Access Credits</strong>
            </Td>
            <Td>
              <StyledInput
                version={version}
                name="quantity"
                type="numeric"
                value={quantity}
                min={1}
                max={99}
                onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (isNaN(Number(e.target.value))) return;
                  const num = Math.max(1, Math.min(Number(e.target.value), 99));
                  setQuantity(num);
                }}
                css={{ textAlign: 'center' }}
              />
            </Td>
            <Td>
              <Bread>{ACCESS_LINK_COST}</Bread>
            </Td>
            <Td css={{ textAlign: 'right' }}>
              <Bread>{quantity * ACCESS_LINK_COST}</Bread>
            </Td>
          </tr>
        </tbody>
      </Table>
    </form>
  );
}

const Logo = styled(LogoSvg, {
  width: '29rem',
  height: '3rem',
  fill: '#f9fafc'
});

const Description = styled('p', {
  fontSize: '$newBody',
  lineHeight: '$body',
  marginTop: '$medium'
});

const Table = styled('table', {
  width: '100%',
  marginBottom: '2rem'
});

const Th = styled('th', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  textAlign: 'left',
  marginBotton: '2rem',
  padding: '0.6rem 2rem 0.6rem 0'
});

const Td = styled('td', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  padding: '0.6rem 2rem 0.6rem 0'
});

const Strong = styled('strong', {
  fontSize: '2.2rem',
  color: '$consoleWhite'
});

const Span = styled('span', {
  color: '$rhyhorn'
});

const TosWrapper = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  stack: {
    gap: '$large',
    hdistribute: 'start'
  },
  marginBottom: '1rem'
});

const DarkWrapper = styled('div', {
  backgroundColor: '$computerBlack',
  margin: '$xxlarge -$xxlarge -$xxlarge',
  padding: '$xxxlarge',
  display: 'grid',
  gap: '$xxlarge'
});

const Blurb = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  variants: {
    faded: {
      true: {
        color: '$rhyhorn'
      },
      false: {}
    }
  }
});

const CheckoutLine = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '$small'
});

const StyledInput = styled(Input, {
  width: '60%'
});

const ErrorMessage = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  marginTop: '$medium',
  color: '$error500'
});

const CaptchaWidget = styled(BaseCaptchaWidget, {
  justifyContent: 'flex-start'
});
