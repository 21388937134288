import { useRef } from 'react';

import {
  FieldLabel,
  Icon,
  Input,
  InputProps,
  styled
} from '@parsec/components';

import { useAlertContext } from 'context';

const CopyToClipboardWrapper = styled('div', {
  position: 'relative',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  padding: '$large'
});

const StyledButton = styled('button', {
  position: 'absolute',
  top: '50%',
  cursor: 'pointer',
  transform: 'translateY(-50%)',
  height: `$xxlarge`,
  /*
  we set some additional padding on the icon to make the click area slightly larger.  
  that same amount is added to the icon to maintain its size.
  */
  width: 'calc(2rem + 0.5rem)',
  padding: '0.5rem',
  right: '$medium'
});

const StyledIcon = styled(Icon, {
  color: '$consoleWhite'
});

export const CopyToClipboard = ({
  label,
  ...rest
}: { label: string } & InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { show: showAlert } = useAlertContext();

  const copyClipboard = () => {
    if (inputRef.current) {
      try {
        navigator.clipboard.writeText(inputRef.current.value);
        showAlert({
          type: 'success',
          title: 'Success',
          message: 'Copied to clipboard!'
        });
      } catch (error: Error | unknown) {
        showAlert({
          type: 'error',
          title: 'Problem copying to clipboard',
          message: error instanceof Error ? error.message : 'Unknown Error'
        });
      }
    }
  };

  return (
    <CopyToClipboardWrapper>
      <FieldLabel.Label label={label}>
        <Input
          type="numeric"
          ref={inputRef}
          accessory={
            <StyledButton onClick={copyClipboard}>
              <StyledIcon name="copy" title="Copy" />
            </StyledButton>
          }
          {...rest}
        />
      </FieldLabel.Label>
    </CopyToClipboardWrapper>
  );
};
