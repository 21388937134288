/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, useState, useCallback } from 'react';

type ComponentProps<T> = T extends ComponentType<infer U> ? U : never;

interface ModalMap {
  [key: string]: ComponentType<any>;
}

interface State<K, P> {
  key: K;
  open: boolean;
  props: P;
}

export function useModals<T extends ModalMap>(modals: T) {
  const [state, setState] = useState<State<
    keyof T,
    ComponentProps<T[keyof T]>
  > | null>(null);

  const open = useCallback(
    <K extends keyof T>(
      key: K,
      props: Omit<
        ComponentProps<T[K]>,
        'isOpen' | 'onClose' | 'onAfterClose'
      > & { onClose?(): void }
    ) => setState({ key, props: props as any, open: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const close = useCallback(
    () => setState(prev => prev && { ...prev, open: false }),
    [setState]
  );

  const stateKey = state?.key;

  const afterClose = useCallback(
    () => setState(prev => (prev?.key === stateKey ? null : prev)),
    [setState, stateKey]
  );

  let dom: JSX.Element | null = null;
  if (state) {
    const ModalComponent = modals[state.key];
    dom = (
      <ModalComponent
        {...(state.props as any)}
        isOpen={state.open}
        onClose={close}
        onAfterClose={afterClose}
      />
    );
  }

  return {
    dom,
    open,
    close
  };
}
