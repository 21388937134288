export enum Side {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

export enum Align {
  Start = 'start',
  Center = 'center',
  End = 'end'
}

export enum Size {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum IncidentStatus {
  Positive = 'positive', // Issues fixed, platform back to normal
  Neutral = 'neutral', // Informs user we're working to resolve the issues
  Negative = 'negative', // Hinders platform usability, denotes instability...
  Maintenance = 'maintenance', // Upcoming Maintenance
  UnplannedMaintenance = 'unplanned-maintenance', // Unplanned Maintenance
  MaintenanceDown = 'maintenance-down', // Maintenance DOWN, platform is down
  MaintenanceDone = 'maintenance-done' // Maintenance Completed, platform back to normal
}
