// libraries
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export * from '@sentry/react';

interface InitOptions {
  release?: string;
  dsn: string;
  env: string;
}

const IS_SERVER = typeof window === 'undefined';
export function initSentry(options: InitOptions) {
  if (IS_SERVER || options.env === 'development' || options.env === 'testing') {
    return;
  }

  Sentry.init({
    dsn: options.dsn,
    environment: options.env,
    release: options.release || undefined, // omit this option if options.release is an empty string
    integrations: [new BrowserTracing()],
    allowUrls: [window.location.hostname],
    ignoreErrors: [
      /secret=[a-zA-Z0-9]+/ // Ignore errors that contain a secret value
    ]
  });
}

interface IdentifyOptions {
  userId: number;
  teamId?: string;
}

export function identify(options: IdentifyOptions) {
  Sentry.setUser({ id: `${options.userId}` });
  if (options.teamId) Sentry.setContext('team', { id: options.teamId });
}
