export interface BreadProps {
  kind?: 'ciabatta' | 'baguette' | 'sourdough';
  decimal?: number;
  children: number;
}

/**
 * Bread is used to convert a price in cents to a price in dollars
 * rounded to the given number of decimal points (default 2).
 * Example: Turns 400 into $4.00.
 *
 * Why's this called bread? History time: according to May Kim,
 * when we were working in office, “let’s get this bread” was a
 * commonly-said phrase that could mean either: "let’s go get
 * lunch", "profit", or "I’m going to hunker down on this work
 * task". Bread was a component that was named in honor of the
 * office culture, as the component deals with the USD currency
 * and bread was a euphemism for money.
 */
function Bread({ children, decimal = 2 }: BreadProps) {
  return Number.isFinite(children) ? (
    `$${(children / 100).toFixed(decimal)}`
  ) : (
    <>$...</>
  );
}

export default Bread;
