import { styled } from '@parsec/styles';

import { IncidentStatus } from '../SharedType';

// This allows us to resurface the incident banner for a new incidient if someone has already dismissed it
// INCREMENT THIS ONLY IF YOU WANT IT TO SHOW UP AGAIN
const incidentCount = 7;

const HyperLink = styled('a', {
  color: '$primary700'
});

export const INCIDENT_CONFIG = {
  isDisplayed: false,
  isDismissible: false,
  incidentCount,
  text: (
    <p>
      We will be conducting brief maintenance beginning in 10 minutes. You will
      not be able to initiate new connections during this window. Existing
      connections are unaffected. Please see our{' '}
      <HyperLink
        href="https://status.unity.com/"
        target="_blank"
        rel="noreferrer"
      >
        status page
      </HyperLink>{' '}
      for more information.
    </p>
  ),
  status: IncidentStatus.UnplannedMaintenance
};
