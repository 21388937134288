import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { teamMachineKeyData } from './schema';

/******************************************************************************
 * Get Team Machine Key
 ******************************************************************************/

export interface GetTeamMachineKeyReq {
  team_id: string;
}

export const getTeamMachineKey =
  (options: ApiOptions) =>
  /**
   * Get the machine provisioning key for this team.
   */
  (req: GetTeamMachineKeyReq) => {
    const { team_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/machine-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: teamMachineKeyData,
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Generate Team Machine Key
 ******************************************************************************/

export interface GenerateTeamMachineKey {
  team_id: string;
}
export const generateTeamMachineKey =
  (options: ApiOptions) =>
  ({ team_id: id }: GenerateTeamMachineKey) => {
    return request<{ data: { machine_key: string } }>({
      type: Method.POST,
      url: `${options.kessel}/teams/${id}/machine-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Delete Team Machine Key
 ******************************************************************************/

export interface DeleteTeamMachineKeyReq {
  team_id: string;
}

export const deleteTeamMachineKey =
  (options: ApiOptions) =>
  /**
   * Delete an Team Machine key.
   */
  (req: DeleteTeamMachineKeyReq) => {
    const { team_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/machine-key`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
