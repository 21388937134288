import { useMemo, useState } from 'react';

import {
  styled,
  Avatar,
  Loading,
  Modal,
  Combobox,
  Button,
  Icon
} from '@parsec/components';
import { useDebounce } from '@parsec/hooks';
import { useAddRoleToTeamMember, useGetAllTeamMembers } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { Description, Section } from 'components';

const version = 'newFont';

export interface AssignRoleModalProps {
  isOpen: boolean;
  onClose(): void;
  roleId: string;
  defaultUserId?: number;
}
export function AssignRoleModal(props: AssignRoleModalProps) {
  const { defaultUserId } = props;
  const alert = useAlertContext();

  const [assignee, setAssignee] = useState<number | undefined | null>(
    defaultUserId
  );
  const addRoleToTeamMember = useAddRoleToTeamMember();
  return (
    <Modal
      version={version}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Assign Role to Member"
      description={
        <Description>
          Multiple roles can be assigned to the same user.
        </Description>
      }
      size="medium"
      actions={[
        {
          text: 'Confirm',
          disabled: !assignee,
          async onClick() {
            try {
              alert.clear();
              await addRoleToTeamMember.mutateAsync({
                user_id: assignee!,
                role_ids: [props.roleId]
              });
              alert.show({
                type: 'success',
                title: 'Success',
                message: 'Role added'
              });
              return props.onClose();
            } catch (err) {
              const res = parseError(err, {
                error: "Couldn't add role."
              });
              alert.show({
                type: 'error',
                title: 'Error',
                message: res.error
              });
            }
          }
        },
        {
          text: 'Cancel',
          level: 'secondary',
          onClick: props.onClose
        }
      ]}
    >
      <Description>Select the user it will be assigned to:</Description>

      <Section style={{ paddingTop: '1rem' }}>
        {/* User */}
        <UserSelector selected={assignee} onChange={setAssignee} />
      </Section>
    </Modal>
  );
}

/// Assign to User
///------------------------------------

interface UserSelectorProps {
  selected?: number | null;
  onChange(assignee: number | undefined | null): void;
}

function UserSelector(props: UserSelectorProps) {
  const { selected, onChange } = props;
  const [search, setSearch] = useState('');
  const onInput = useDebounce(setSearch, { ms: 500 });
  const teamMembersQuery = useGetAllTeamMembers({});
  const teamMembers = teamMembersQuery.data?.data;

  const results = useMemo(
    () =>
      (teamMembers ?? []).filter(member => {
        return (
          member.user.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          member.user.email.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          member.tag.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      }),
    [teamMembers, search]
  );

  const selectedUser =
    selected &&
    teamMembers &&
    teamMembers.find(member => member.user_id === selected);

  return (
    <div style={{ height: '10rem' }}>
      <Loading loading={teamMembersQuery.isLoading && !results.length}>
        {() => {
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!selectedUser ? (
                // Filter users
                <Search>
                  <Combobox
                    icon="search"
                    type="search"
                    placeholder="Find a User"
                    withDropdown={false}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onInput(e.target.value);
                    }}
                    items={results.map(member => {
                      const { id, name, email } = member.user;
                      return {
                        value: `${name} (${email})`,
                        text: (
                          <Description>
                            <strong>{name}</strong>&nbsp;
                            <span>({email})</span>
                          </Description>
                        ),
                        onSelect() {
                          onChange(id);
                        }
                      };
                    })}
                  />
                </Search>
              ) : (
                // A team member is selected
                <Result>
                  <ResultImage>
                    <Avatar
                      size={36}
                      style={{ width: '3.6rem' }}
                      userId={selectedUser.user.id}
                    />
                  </ResultImage>
                  <div>
                    <Description info>
                      <strong>{selectedUser.user.name}</strong>#
                      <SubduedText>{selectedUser.user.id}</SubduedText>
                    </Description>
                    <Description info>
                      <SubduedText>{selectedUser.user.email}</SubduedText>
                    </Description>
                  </div>
                  <ResultRemoveButton
                    level="secondary"
                    kind="error"
                    onClick={() => onChange(null)}
                  >
                    <Icon name="ex" />
                  </ResultRemoveButton>
                </Result>
              )}
            </>
          );
        }}
      </Loading>
    </div>
  );
}

const SubduedText = styled('span', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  color: '$rhyhorn'
});

const Search = styled('div', {
  borderRadius: '$small',
  marginTop: '1rem',
  marginBottom: '4rem',
  cursor: 'pointer'
});

const Result = styled('div', {
  display: 'grid',
  gridTemplateColumns: '3.6rem auto 3.6rem',
  columnGap: '$large',
  alignItems: 'center',
  backgroundColor: '$perfectGray',
  borderRadius: '5.8rem',
  padding: '$large',
  marginTop: '1rem',
  marginBottom: '1rem'
});

const ResultImage = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  backgroundColor: '$pancham',
  width: '3.6rem',
  height: '3.6rem'
});

const ResultRemoveButton = styled(Button, {
  padding: '1rem'
});
