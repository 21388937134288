type Namespace = 'list' | 'detail';

export default class KeyFactory<T extends string> {
  namespace: T;

  constructor(namespace: T) {
    this.namespace = namespace;
  }

  all() {
    return [this.namespace] as [T];
  }

  list(): [T, 'list'];
  list<V extends object>(vars: V): [T, 'list', V];
  list<V extends object>(vars?: V) {
    if (vars) return [this.namespace, 'list', vars];
    return [this.namespace, 'list'];
  }

  detail(): [T, 'detail'];
  detail<V extends object>(vars: V): [T, 'detail', V];
  detail<V extends object>(vars?: V) {
    if (vars) return [this.namespace, 'detail', vars];
    return [this.namespace, 'detail'];
  }

  vars<V>(key: [T, Namespace, V]) {
    return key[2];
  }
}
