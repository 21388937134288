// libraries
import { useCallback, FormEvent } from 'react';

// @parsec
import { styled } from '@parsec/styles';

interface Props {
  codes: string[];
  onDone(): void;
  onDownload?(): void;
  id?: string;
}

export default function TfaBackupCodes(props: Props) {
  const { codes, onDone, id, onDownload } = props;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      downloadCodes(codes);
      onDownload?.();
      onDone();
    },
    [codes, onDownload, onDone]
  );

  return (
    <form onSubmit={handleSubmit} id={id}>
      <p>
        If you lose access to your two-factor authentication method, you can use
        a backup code instead. Make sure to keep them somewhere safe!
      </p>
      <CodeList>
        {codes.map(code => (
          <li key={code}>{code}</li>
        ))}
      </CodeList>
    </form>
  );
}

const CodeList = styled('ul', {
  backgroundColor: '$carkol',
  padding: '$large $xlarge',
  fontFamily: 'monospace',
  lineHeight: '$attribution',
  borderRadius: '$medium',
  columnCount: 2
});

function downloadCodes(codes: string[]) {
  const text = codes.join('\n');
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', 'parsec_backup_codes.txt');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
