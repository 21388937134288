import {
  type ReactNode,
  createContext,
  useContext,
  forwardRef,
  ComponentPropsWithRef
} from 'react';

import { captureException } from '@parsec/sentry';
import { styled } from '@parsec/styles';

import { priceFormatCents } from '../../utils';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import BaseTime from '../Time';

import { STATUS, STATUS_COPY, type StatusValue } from './constants';
import { Table } from './Table';

const sentryContext = {
  tags: { component: 'SubscriptionSummary' }
};

const Content = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateColumns: '35.6rem 1fr',
  gridTemplateRows: 'min-content 1fr',
  gridRowGap: '$xlarge',
  gridColumnGap: '2.9rem',
  gridTemplateAreas: `"header header"
    "statusSummary detailTable"`,
  backgroundColor: '$samehada',
  borderRadius: '0 0 $large $large',
  width: '100%',
  minWidth: '81.7rem',
  maxWidth: '94rem',
  padding: '$xlarge $xxlarge'
});

const DetailWrapper = styled('div', {
  fontFamily: '$newDefault',
  gridArea: 'detailTable'
});

const TitleHeading = styled('h2', {
  fontFamily: '$newDefault',
  paddingBottom: '$medium',
  fontSize: '$subtitle',
  lineHeight: '2.9rem',
  letterSpacing: '0'
});

const HeaderWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '$xlarge $xxlarge',
  backgroundColor: '$ultraDark',
  borderRadius: '$large $large 0 0',
  width: '100%',
  minWidth: '81.7rem',
  maxWidth: '94rem'
});

const RenewSpan = styled('span', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  color: '$rhyhorn'
});

const RenewalWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'end',
  gap: '$medium'
});

const Time = styled(BaseTime, {
  fontFamily: '$newDefault',
  fontWeight: '$bold'
});

interface RenewalDateProps {
  text?: string;
  date: Date;
}

const DEFAULT_TEXT = 'You will be charged at your next renewal on:';

/**
 * Display for when the renewal date is
 * @param {Object} renewal - contains renewal relevant information.
 * @param {string} renewal.text - The text to override the DEFAULT_TEXT with.
 * @param {Date} renewal.date - The renewal date.
 */
function RenewalDate({ text = DEFAULT_TEXT, date }: RenewalDateProps) {
  const isDateInvalid = isNaN(date.getTime());
  if (isDateInvalid) {
    // notify sentry
    captureException(new Error('Invalid renewal date'), {
      ...sentryContext,
      extra: { date }
    });
  }
  return (
    <RenewalWrapper>
      <RenewSpan>{text}</RenewSpan>
      {!isDateInvalid ? <Time date={date} /> : null}
    </RenewalWrapper>
  );
}

const SeatsPriceWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  lineHeight: '2.9rem',
  letterSpacing: '0',
  gridArea: 'header'
});

interface SeatsPriceProps {
  seats: number;
  priceCents: number;
}

/**
 *
 * @param {number} props.seats  - number of seats
 * @param {number} props.priceCents  - total price of seats IN CENTS
 * @returns
 */
function SeatsPrice({ seats, priceCents }: SeatsPriceProps) {
  return (
    <SeatsPriceWrapper>
      <span>{`${seats} Seats`}</span>
      <span>{priceFormatCents(priceCents)}</span>
    </SeatsPriceWrapper>
  );
}

const StatusHeading = styled('h3', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateColumns: '1.4rem 1fr 1fr',
  alignItems: 'center',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  letterSpacing: 0
});

const StatusP = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '1rem',
  lineHeight: '$info',
  letterSpacing: 0
});

const StatusWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateRows: 'min-content',
  gridRowGap: '$medium',
  color: '$ultraDark',
  borderRadius: '0.9rem',
  width: '35.6rem',
  minHeight: '10.9rem',
  padding: '$medium $large $large',
  gridArea: 'statusSummary',
  variants: {
    status: {
      [`${STATUS.ChangesPending}`]: {
        backgroundColor: '$warning500'
      },
      [`${STATUS.UpToDate}`]: {
        backgroundColor: '$success500'
      },
      [`${STATUS.AccountOverdue}`]: {
        backgroundColor: '$error500',
        color: '$consoleWhite'
      },
      [`${STATUS.AccountCancelling}`]: {
        backgroundColor: '$error500',
        color: '$consoleWhite'
      },
      [`${STATUS.AccountCancelled}`]: {
        backgroundColor: '$dewgon',
        color: '$ultraDark'
      },
      [`${STATUS.AccountSuspended}`]: {
        backgroundColor: '$dewgon',
        color: '$ultraDark'
      },
      [`${STATUS.PaymentProcessingError}`]: {
        backgroundColor: '$error500',
        color: '$consoleWhite'
      },
      [`${STATUS.ActiveTrial}`]: {
        backgroundColor: '$ultraDark',
        color: '$consoleWhite'
      },
      [`${STATUS.ActiveTrialCancelled}`]: {
        backgroundColor: '$ultraDark',
        color: '$consoleWhite'
      },
      [`${STATUS.AccountPaused}`]: {
        backgroundColor: '$dewgon',
        color: '$ultraDark'
      }
    }
  }
});

// TODO find a better name
const Note = styled('div', {
  fontFamily: '$newDefault',
  justifySelf: 'end',
  fontSize: '1rem',
  fontWeight: '$bold',
  lineHeight: '1rem',
  letterSpacing: 0,
  textAlign: 'right'
});

const StatusIcon = styled(Icon, { width: '1rem', height: '1rem' });

const AccountStatusContext = createContext<{ status: StatusValue } | undefined>(
  undefined
);

export const useAccountStatusContext = () => {
  const context = useContext(AccountStatusContext);
  if (!context) {
    throw new Error(
      'useAccountStatusContext must be used within a <AccountStatusProvider>'
    );
  }
  return context;
};

interface AccountStatusProps {
  children?: ReactNode;
  status: StatusValue;
  note?: string;
}

/**
 * Renders the account status and corresponding actions
 * @param {Object} status - const object of current status to render.
 */
function AccountStatus({ children, status, note }: AccountStatusProps) {
  return (
    <AccountStatusContext.Provider value={{ status }}>
      <StatusWrapper status={status}>
        <StatusHeading>
          <StatusIcon name={STATUS_COPY[status].iconName} />
          {STATUS_COPY[status].title}
          {note ? <Note>{note}</Note> : null}
        </StatusHeading>
        <StatusP>{STATUS_COPY[status].body}</StatusP>
        {children}
      </StatusWrapper>
    </AccountStatusContext.Provider>
  );
}

const StatusBtn = styled(Button, {
  fontFamily: '$newDefault',
  alignSelf: 'end',
  justifySelf: 'flex-start',
  fontSize: '$newBody',
  marginTop: '1rem'
});

interface StatusBtnProps extends ButtonProps {
  status?: StatusValue;
}

const StatusButton = forwardRef<HTMLButtonElement, StatusBtnProps>(
  ({ status: statusFromProps, ...rest }, forwardedRef) => {
    const { status: statusFrmCtx } = useAccountStatusContext();

    // if we need to override context
    const status = statusFromProps ?? statusFrmCtx;

    // don't render if status doesn't have cta text
    if (!STATUS_COPY[status].ctaText) {
      return null;
    }

    // So typescript isn't mad
    const getIcon = () => {
      const iconName = STATUS_COPY[status].ctaIconName;
      if (!iconName) return undefined;

      return <Icon name={iconName} />;
    };

    return (
      <StatusBtn
        iconPosition="left"
        icon={getIcon()}
        ref={forwardedRef}
        {...STATUS_COPY[status].buttonVariant}
        {...rest}
      >
        {STATUS_COPY[status].ctaText}
      </StatusBtn>
    );
  }
);

interface SubscriptionSummaryProps
  extends ComponentPropsWithRef<typeof SummaryWrapper> {
  children?: ReactNode;
}

const SummaryWrapper = styled('div', {});

/**
 * Main export wrapper. Can be turned into a context down the line if needed.
 * @param children
 *
 */
export function Summary({ children, ...rest }: SubscriptionSummaryProps) {
  return <SummaryWrapper {...rest}>{children}</SummaryWrapper>;
}

Summary.Header = HeaderWrapper;
Summary.TitleHeading = TitleHeading;
Summary.Content = Content;
Summary.RenewalDate = RenewalDate;
Summary.AccountStatus = AccountStatus;
Summary.SeatsPrice = SeatsPrice;
Summary.Details = DetailWrapper;
Summary.StatusButton = StatusButton;

/**
 * Exporting custom Table components as sub summary component
 */
Summary.Table = Table;
Summary.Th = Table.Th;
Summary.Td = Table.Td;
Summary.Tr = Table.Tr;
Summary.Tbody = Table.Tbody;
Summary.Tfoot = Table.Tfoot;
Summary.Thead = Table.Thead;
