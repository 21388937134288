import { useEffect, useState } from 'react';

import { styled } from '@parsec/components';
import {
  useCancelTeamInvites,
  useGetTeamInvites,
  useGetTeamRolePermissionSummary,
  useResendTeamInvites,
  useUpdateTeamInvites
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { Pagination } from 'components';

import { DataList } from './DataList';
import { TeamInviteListItem } from './TeamInviteListItem';

export interface TeamInviteListProps {
  search?: string;
}

export function TeamInviteList(props: TeamInviteListProps) {
  const [params, setParams] = useState<Parameters<typeof useGetTeamInvites>[0]>(
    {
      offset: 0,
      limit: 10,
      email: undefined
    }
  );
  const { search } = props;
  useEffect(() => {
    setParams(params => ({ ...params, email: search }));
  }, [search]);
  const getTeamInvitesQuery = useGetTeamInvites(params);
  const invites = getTeamInvitesQuery.data?.data ?? [];
  const count = getTeamInvitesQuery.data?.count ?? 0;
  const isLoading = getTeamInvitesQuery.isLoading;

  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data;
  const hasGlobalScopePerm = perms?.team.invite_team_members;

  const hasGroupScopePerm = Object.values(perms?.groups ?? {}).some(
    p => p.invite_team_members
  );

  const canInviteTeamMembers =
    (hasGlobalScopePerm || hasGroupScopePerm) ?? false;

  const alert = useAlertContext();

  const handleMutation = async (
    defaultSuccessMessage: string,
    defaultErrorMessage: string,
    fn: () => Promise<unknown>
  ) => {
    try {
      alert.clear();
      await fn();
      alert.show({
        type: 'success',
        title: 'Success',
        message: defaultSuccessMessage
      });
    } catch (err) {
      const res = parseError(err, {
        error: defaultErrorMessage
      });
      alert.show({
        type: 'error',
        title: 'Error',
        message: res.error
      });
    }
  };
  const resendTeamInvites = useResendTeamInvites();
  const updateTeamInvites = useUpdateTeamInvites();
  const cancelTeamInvites = useCancelTeamInvites();
  return (
    <DataList loading={isLoading}>
      {() => {
        return (
          <>
            <PaginationWrapper>
              <Pagination
                offset={params.offset}
                limit={params.limit}
                count={count}
                onPrev={() => {
                  setParams({
                    ...params,
                    offset: params.offset - params.limit
                  });
                }}
                onNext={() => {
                  setParams({
                    ...params,
                    offset: params.offset + params.limit
                  });
                }}
              />
            </PaginationWrapper>
            <Grid>
              {!isLoading &&
                !invites.length &&
                (search ? (
                  <Empty>No pending invites match your search criteria.</Empty>
                ) : (
                  <Empty>There are currently no pending invites.</Empty>
                ))}

              {invites.map(invite => {
                return (
                  <TeamInviteListItem
                    key={invite.email}
                    invite={invite}
                    onUpdate={
                      canInviteTeamMembers
                        ? opts =>
                            handleMutation(
                              'Successfully updated!',
                              'Could not update invites',
                              () => updateTeamInvites.mutateAsync(opts)
                            )
                        : undefined
                    }
                    onResend={
                      canInviteTeamMembers
                        ? opts =>
                            handleMutation(
                              'Successfully resent!',
                              'Could not resend invites',
                              () => resendTeamInvites.mutateAsync(opts)
                            )
                        : undefined
                    }
                    onCancel={
                      canInviteTeamMembers
                        ? opts =>
                            handleMutation(
                              'Successfully cancelled!',
                              'Could not cancel invites',
                              () => cancelTeamInvites.mutateAsync(opts)
                            )
                        : undefined
                    }
                  />
                );
              })}
            </Grid>
          </>
        );
      }}
    </DataList>
  );
}

const Grid = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$xlarge',
  listStyle: 'none',
  padding: '$large'
});

const Empty = styled('p', {
  textAlign: 'center',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution'
});

const PaginationWrapper = styled('div', {
  display: 'grid',
  justifyContent: 'end',
  height: '6rem',
  borderBottom: '.1rem solid $pangoro'
});
