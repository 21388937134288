import { RefObject, useCallback, useEffect, useState } from 'react';

interface ScrollPosition {
  top: number;
  bottom: number;
  canScroll: boolean;
}

export function useScrollPosition(ref: RefObject<HTMLElement>): ScrollPosition {
  const [top, setTop] = useState(0);
  const [bottom, setBottom] = useState(-1);
  const [canScroll, setCanScroll] = useState(false);

  const handleScroll = useCallback(() => {
    const el = ref.current;
    if (!el) return;
    setTop(el.scrollTop);
    setBottom(getBottomPos(el));
  }, [setTop, ref]);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    setBottom(getBottomPos(el));
    setCanScroll(el.scrollHeight - el.clientHeight > 1);

    el.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, setCanScroll, ref]);

  return {
    top,
    bottom,
    canScroll
  };
}

function getBottomPos(el: HTMLElement) {
  if (!el) return -1;
  const position = Math.round(el.scrollHeight - el.clientHeight - el.scrollTop);
  return position < 0 ? 0 : position;
}
