import { schema, accessLinkCredit } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('access_link_credit');

export function useAccessLinkCreditData() {
  return useQueryData(key.all(), schema.creditBalance);
}

/******************************************************************************
 * Get access link balance
 ******************************************************************************/

export function useGetAccessLinkCreditBalance() {
  const kessel = useKessel();
  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const vars = { team_id: teamId };
  const enabled = vars.team_id !== '';

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.accessLinkCredit.getAccessLinkCreditBalance(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get access link credit balance.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Add access link credits
 ******************************************************************************/

type AddAccessLinkCreditVars = Omit<
  accessLinkCredit.AddAccessLinkCreditReq,
  'team_id'
>;

export function useAddAccessLinkCredit() {
  const kessel = useKessel();
  const accessLinkCreditCache = useAccessLinkCreditData();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: AddAccessLinkCreditVars) {
      return kessel.accessLinkCredit.addAccessLinkCredit({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess: () => accessLinkCreditCache.invalidate() }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to add access link credits.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Get access link purchase cost estimate
 ******************************************************************************/

type GetAccessLinkCostEstimateVars = Omit<
  accessLinkCredit.GetAccessLinkCostEstimateReq,
  'team_id'
>;

export function useGetAccessLinkCostEstimate() {
  const kessel = useKessel();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(async function mutationFn(
    req: GetAccessLinkCostEstimateVars
  ) {
    return kessel.accessLinkCredit.getAccessLinkCostEstimate({
      ...req,
      team_id: teamId
    });
  });

  const error = useWrapError(result.error, {
    error: 'Failed to get access link cost estimate.'
  });
  return { ...result, error };
}
