import {
  useGetTeamNonCompliantUsers,
  useGetTeam,
  useGetTeamRolePermissionSummary,
  useGetTeamDomains
} from '@parsec/queries';

export const useSeatCompliance = () => {
  const { data: team, isLoading: teamLoading } = useGetTeam();

  const { data: nonCompliantUsers, isLoading: getCountLoading } =
    useGetTeamNonCompliantUsers();
  const { data: permissions } = useGetTeamRolePermissionSummary();
  const { data: teamDomains } = useGetTeamDomains();

  const hasVerifiedDomains =
    (teamDomains ?? []).filter(domain => domain.is_verified).length > 0;

  const canManageNonCompliantUsers = Boolean(
    permissions?.team.manage_non_compliant_users
  );

  const freePlanBlockingEnabled = Boolean(
    team?.capabilities?.settings.is_consumer_connection_blocked
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nonCompliantUserCount = (nonCompliantUsers as any)?.count || 0;

  const hasNonCompliantUsers = nonCompliantUserCount > 0;

  const showComplianceWarning =
    !freePlanBlockingEnabled &&
    hasNonCompliantUsers &&
    canManageNonCompliantUsers &&
    hasVerifiedDomains;

  return {
    canManageNonCompliantUsers,
    hasVerifiedDomains,
    isLoading: teamLoading || getCountLoading,
    nonCompliantUserCount,
    freePlanBlockingEnabled,
    hasNonCompliantUsers,
    showComplianceWarning
  };
};
