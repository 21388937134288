import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { accessLinkData, paginatedAccessLinkData } from './schema';

export interface GetAccessLinkReq {
  team_id: string;
  id: string;
}
export const getAccessLink =
  (options: ApiOptions) =>
  /**
   * Gets an AccessLink by ID
   */
  ({ team_id, id }: GetAccessLinkReq) => {
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/access-links/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: accessLinkData,
      middleware: options.middleware
    });
  };

export interface GetMyAccessLinksReq {
  team_id: string;
  offset: number;
  limit: number;
}
export const getMyAccessLinks =
  (options: ApiOptions) =>
  /**
   * Gets a paginated list of your AccessLinks
   */
  ({ team_id, offset, limit }: GetMyAccessLinksReq) => {
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/access-links?offset=${offset}&limit=${limit}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: paginatedAccessLinkData,
      middleware: options.middleware
    });
  };

export interface CreateAccessLinkReq {
  team_id: string;
  host_peer_id: string;
  email: string[];
  starts_at: string;
  expires_at: string;
  gamepad: boolean;
  keyboard: boolean;
  mouse: boolean;
  event_name: string;
  time_zone: string;
  message?: string;
  captcha_token: string;
}

export const createAccessLink =
  (options: ApiOptions) =>
  /**
   * Creates an AccessLink by ID with capability of multiple ids
   */
  ({ team_id, ...body }: CreateAccessLinkReq) => {
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/access-links`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

export type UpdateAccessLinkReq = {
  team_id: string;
  id: string;
} & Partial<{
  host_peer_id: string;
  starts_at: string;
  expires_at: string;
  gamepad: boolean;
  keyboard: boolean;
  mouse: boolean;
  time_zone: string;
}>;
export const updateAccessLink =
  (options: ApiOptions) =>
  /**
   * Updates an AccessLink by ID
   */
  ({ team_id, id, ...body }: UpdateAccessLinkReq) => {
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/access-links/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

export interface DeleteAccessLinkReq {
  team_id: string;
  id: string;
}
export const deleteAccessLink =
  (options: ApiOptions) =>
  /**
   * Deletes an AccessLink by ID
   */
  ({ team_id, id }: DeleteAccessLinkReq) => {
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/access-links/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
