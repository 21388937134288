import { useEffect } from 'react';

import { Alert, styled } from '@parsec/components';

import { useAlertContext, useAlertDataContext } from 'context';

const Wrapper = styled('ul', {
  variants: {
    visible: {
      true: {
        display: 'inherit'
      },
      false: {
        display: 'none'
      }
    },
    showHere: {
      true: {
        width: '100%'
      },
      false: {
        position: 'fixed',
        top: '$xxxlarge',
        right: '$xxxlarge',
        left: '32.4rem'
      }
    }
  },

  display: 'grid',
  gridAutoFlow: 'row',
  rowGap: '$large',
  zIndex: 9999
});

interface AlertsProps {
  // show Alerts in place.  Will replace Alerts showing in global position.
  showHere?: boolean;
}

export const Alerts = ({ showHere = false }: AlertsProps) => {
  const { setInPlace } = useAlertContext();
  const { alerts, inPlace } = useAlertDataContext();

  useEffect(() => {
    if (showHere) {
      setInPlace(true);
      return () => setInPlace(false);
    }
    return;
  }, [showHere, setInPlace]);

  return (
    <Wrapper visible={showHere ? true : !inPlace} showHere={showHere}>
      {alerts.map((props, i) => (
        <li key={i}>
          <Alert {...props} version="newFont" />
        </li>
      ))}
    </Wrapper>
  );
};
