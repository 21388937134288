import { forwardRef, useEffect, useRef, MutableRefObject, Ref } from 'react';

import { CSS, styled } from '@parsec/styles';

type InputProps = JSX.IntrinsicElements['input'];

export interface RadioProps extends Omit<InputProps, 'type'> {
  indeterminate?: boolean;
  ref?: Ref<HTMLInputElement>;
  css?: CSS;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  function Radio(props, fwdRef) {
    const { checked, indeterminate, css, ...rest } = props;

    const ref: MutableRefObject<HTMLInputElement | null> = useRef(null);
    useEffect(() => {
      if (!ref?.current) return;

      ref.current.indeterminate = !!indeterminate;
    }, [checked, indeterminate]);

    return (
      <Input
        {...rest}
        type="radio"
        checked={checked}
        css={css}
        ref={(input: HTMLInputElement) => {
          ref.current = input;
          if (typeof fwdRef === 'function') fwdRef(input);
          else if (fwdRef) fwdRef.current = input;
        }}
      />
    );
  }
);
export default Radio;

const Input = styled('input', {
  width: '1.6rem',
  height: '1.6rem',
  appearance: 'none',
  '-webkit-appearance': 'none',
  margin: 0,
  display: 'grid',
  placeContent: 'center',
  border: 'none',
  borderRadius: '50%',
  tabIndex: 0,
  cursor: 'pointer',
  transition: '125ms box-shadow ease',
  backgroundColor: '$chandelure',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.18)',

  '&::before': {
    content: '',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: ' 120ms transform ease-in-out',
    boxShadow: 'inset .3rem .3rem $colors$consoleWhite',
    backgroundColor: 'CanvasText'
  },
  '&:checked': {
    boxShadow:
      'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0 0 0 0.5rem #26acff, 0px 1px 2px 0px rgba(0, 0, 0, 0.18)',
    cursor: 'default',
    backgroundColor: '$consoleWhite',
    '&::before': {
      transform: 'scale(1)'
    }
  },

  '&:disabled': {
    backgroundColor: '$duskull',
    pointerEvents: 'none',
    '&:checked': {
      boxShadow:
        'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0 0 0 0.5rem rgba(71, 71, 71, 1), 0px 1px 2px 0px rgba(0, 0, 0, 0.18)',
      backgroundColor: '$nice',
      '&::before': {
        transform: 'scale(1)'
      }
    }
  },

  '&:hover': {
    boxShadow:
      'inset 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0 0 0 1px $colors$pangoro, 0px 1px 2px 0px rgba(0, 0, 0, 0.18)'
  }
});
