// @parsec
import { GetTeamAccessLinkLedgerReq } from '@parsec/kessel/src/accessLinkLedger';

import { accessLinkLedgerEntryData } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('access_link_ledger');

export function useAccessLinkLedgerData() {
  return useQueryData(key.all(), accessLinkLedgerEntryData);
}

const DEFAULT_OPTIONS = {
  enabled: true
};

export function useGetAccessLinkLedgerData(
  vars: GetTeamAccessLinkLedgerReq,
  options = DEFAULT_OPTIONS
) {
  const kessel = useKessel();

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.accessLinkLedger.getTeamAccessLinkLedger(req);
      return { body: res.body.data, count: res.body.count };
    },
    { ...options }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get access link ledger data."
  });
  return { ...result, error };
}
