import { ReactNode, useEffect, useRef, useState } from 'react';

import { styled, keyframes } from '@parsec/styles';

import Icon from '../Icon';

export interface LoadingProps {
  children?: ReactNode | (() => ReactNode);
  loading: boolean;
  className?: string;
}

export const DefaultLoader = (props: { className?: string }) => {
  return (
    <LoaderWrapper {...props}>
      <Loader name="loader" />
    </LoaderWrapper>
  );
};

export default function Loading(props: LoadingProps) {
  const { children, loading, className } = props;
  const [show, setShow] = useState(!loading);
  const timeout = useRef(0);

  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      setShow(true);
    }, 500);
    return () => clearTimeout(timeout.current);
  }, []);

  return show ? (
    loading ? (
      <DefaultLoader className={className} />
    ) : typeof children === 'function' ? (
      children()
    ) : (
      children
    )
  ) : null;
}

const spin = keyframes({
  '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
  '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' }
});

const LoaderWrapper = styled('div', {
  position: 'relative'
});

const Loader = styled(Icon, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  animation: `${spin} linear 2s infinite`,
  fontSize: '$title'
});
