import type { Validator } from '@parsec/kessel';

import { useMemo } from 'react';

import { QueryClient, useQueryClient } from 'react-query';

type QueryFilters = Parameters<QueryClient['getQueryData']>[1];
type QueryUpdater<T> = T | ((data: T | undefined) => T);

export class QueryData<T> {
  private validator: Validator<T>;
  private key: readonly unknown[];
  private client: QueryClient;

  constructor(
    client: QueryClient,
    key: readonly unknown[],
    validator: Validator<T>
  ) {
    this.client = client;
    this.key = key;
    this.validator = validator;
  }

  /** Gets the current cache value. */
  get(filters?: QueryFilters) {
    const data = this.client.getQueryData(this.key, filters);

    if (data === undefined) return data;
    if (!this.validator(data)) {
      console.error(this.key, data);
      throw new Error(`Query ${this.key} has invalid data!`);
    }

    return data;
  }

  /** Sets the cache value. */
  set(data: QueryUpdater<T>) {
    this.client.setQueryData(this.key, data);
  }

  /** Removes the cached query. */
  remove() {
    this.client.removeQueries(this.key);
  }

  /** Invalidates the cached query, causing it to be refetched if there are any current listeners. */
  invalidate(vars?: { [key: string]: string | number }) {
    if (vars) {
      this.client.invalidateQueries([...this.key, vars]);
    } else {
      this.client.invalidateQueries(this.key);
    }
  }
}

/** Synchronously interact with the query cache.
 * @param key The key with which to interact
 * @param validator A function that ensures query data is the correct type
 */
export function useQueryData<T>(
  key: readonly unknown[],
  validator: Validator<T>
) {
  const client = useQueryClient();

  const jsonKey = JSON.stringify(key);
  return useMemo(
    () => new QueryData(client, key, validator),
    [client, jsonKey, validator]
  );
}
