import { useCallback } from 'react';

import { styled } from '@parsec/components';
import { TeamMember, TeamRole } from '@parsec/kessel';
import {
  useGetTeamGroups,
  useGetAllAppRules,
  useGetAllRoles,
  useAssignAppRule,
  useRemoveTeamMember,
  useResetTeamMemberTFA,
  useUpdateTeamMember,
  useUpdateTeamMemberGroups,
  useResendTeamMemberConfirmationEmail
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { useSelectionState } from 'lib/hooks';

import { ListMessage } from './ListMessage';
import { TableBuilder } from './TableBuilder';
import { TeamMemberRow, TeamMemberTableColumnType } from './TeamMemberRow';

const MEMBERS_TABLE_PRESET: { [key in TeamMemberTableColumnType]: string } = {
  select: 'minmax(0, 2rem)',
  nameAndEmail: 'minmax(0, 3fr)',
  roles: 'minmax(0, 1.5fr)',
  group: 'minmax(0, 2fr)',
  ruleset: 'minmax(0, 2fr)',
  more: 'minmax(0, 3.8rem)'
};

function membersPresetFromColumns(cols: Array<TeamMemberTableColumnType>) {
  return Object.fromEntries(cols.map(col => [col, MEMBERS_TABLE_PRESET[col]]));
}

interface Props {
  selectionState?: ReturnType<typeof useSelectionState>;
  columns: TeamMemberTableColumnType[];
  members: TeamMember[];
  currentUserId: number;
  viewOnly?: boolean;
  error?: string;
  isLoading: boolean;
  filterName?: string;
  isScimEnabled: boolean;

  // If true - allows the caller to select multiple Scim Members
  // Temporary work around to enable multi-select certain pages (app rule)
  // while leaving other pages (members) alone.
  canSelectScimMember?: boolean;

  onResetSearch?(): void;
}

export function TeamMemberTable(props: Props) {
  const {
    columns,
    selectionState,
    members,
    currentUserId,
    viewOnly = false,
    isScimEnabled,
    canSelectScimMember = false
  } = props;

  // Team Roles
  const roles = useGetAllRoles().data?.data ?? [];
  const rolesMap = new Map(roles.map(role => [role.id, role]));

  // Team Groups
  const allGroupsQuery = useGetTeamGroups();
  const allGroups = allGroupsQuery.data?.data ?? [];

  // App Rules
  const allAppRulesQuery = useGetAllAppRules();
  const allAppRules = allAppRulesQuery.data?.data ?? [];

  // Request methods
  const { mutateAsync: addTeamMemberToGroups } = useUpdateTeamMemberGroups();
  const { mutateAsync: assignAppRule } = useAssignAppRule();
  const { mutateAsync: removeTeamMember } = useRemoveTeamMember();
  const { mutateAsync: resetTeamMemberTFA } = useResetTeamMemberTFA();
  const { mutateAsync: updateTeamMember } = useUpdateTeamMember();

  const { mutateAsync: resendTeamMemberConfirmationEmail } =
    useResendTeamMemberConfirmationEmail();

  const alert = useAlertContext();

  const onSuccess = useCallback(
    (msg: string) => {
      alert.show({
        type: 'success',
        title: 'Success',
        message: msg
      });
    },
    [alert]
  );

  const onFailure = useCallback(
    (err: unknown, title: string) => {
      const res = parseError(err);
      alert.show({
        type: 'error',
        title: title,
        message: res.body?.error ?? res.error
      });
    },
    [alert]
  );

  const onAddTeamMemberToGroups = useCallback(
    async (req: { user_id: number; group_ids: number[] | null }) => {
      try {
        alert.clear();
        await addTeamMemberToGroups(req);
        onSuccess('Successfully updated group membership.');
      } catch (err) {
        onFailure(err, 'Could not update group membership.');
      }
    },
    [alert, addTeamMemberToGroups, onSuccess, onFailure]
  );

  const onAssignAppRule = useCallback(
    async (req: { ruleId: string; userId: number }) => {
      try {
        alert.clear();
        await assignAppRule({
          app_rule_id: req.ruleId,
          user_ids: [req.userId]
        });
        onSuccess('Team Member assignment updated.');
      } catch (err) {
        onFailure(err, 'Could not update Team Member assignment.');
      }
    },
    [onSuccess, onFailure, alert, assignAppRule]
  );

  const onUpdateTeamMember = useCallback(
    async ({ userId, tag }: { userId: number; tag: string }) => {
      try {
        alert.clear();
        await updateTeamMember({ user_id: userId, tag });
        onSuccess('Team member(s) updated.');
      } catch (err) {
        onFailure(err, 'Could not update Team Member.');
      }
    },
    [onSuccess, onFailure, alert, updateTeamMember]
  );

  const onRemoveTeamMember = useCallback(
    async ({ userId }: { userId: number }) => {
      try {
        alert.clear();
        await removeTeamMember({ user_id: userId });
        onSuccess('Team members were removed successfully.');
      } catch (err) {
        onFailure(err, 'Could not remove team members.');
      }
    },
    [onSuccess, onFailure, alert, removeTeamMember]
  );

  const onSendTfaResetEmail = useCallback(
    async ({ userId }: { userId: number }) => {
      try {
        alert.clear();
        await resetTeamMemberTFA({ user_id: userId });
        onSuccess('Successfully sent TFA reset email.');
      } catch (err) {
        onFailure(err, 'Could not send TFA reset email.');
      }
    },
    [onSuccess, onFailure, alert, resetTeamMemberTFA]
  );

  const onResendConfirmationEmail = useCallback(
    async ({ userId }: { userId: number }) => {
      try {
        alert.clear();
        await resendTeamMemberConfirmationEmail({ user_id: userId });
        onSuccess('Successfully sent confirmation email.');
      } catch (err) {
        onFailure(err, 'Could not send confirmation email.');
      }
    },
    [onSuccess, onFailure, alert, resendTeamMemberConfirmationEmail]
  );

  if (props.isLoading) return <ListMessage>Loading...</ListMessage>;
  if (props.error)
    return (
      <ListMessage>
        {props.error}
        <br />
        <ResetSearchButton onClick={props.onResetSearch}>
          Reset your search
        </ResetSearchButton>
      </ListMessage>
    );
  return (
    <TableBuilder
      preset={membersPresetFromColumns(columns)}
      emptyState={
        <ListMessage>
          No Results
          <br />
          {!!props.onResetSearch && (
            <ResetSearchButton onClick={props.onResetSearch}>
              Reset your search
            </ResetSearchButton>
          )}
        </ListMessage>
      }
    >
      {members.map(member => {
        const selected = selectionState?.isSelected(member.user_id);

        return (
          <TeamMemberRow
            key={member.user_id}
            columns={columns}
            viewOnly={viewOnly}
            isSelf={member.user_id === currentUserId}
            isScimTeam={isScimEnabled}
            canSelectScimMember={canSelectScimMember}
            member={member}
            groups={allGroups}
            appRules={allAppRules}
            selected={selected}
            onSelect={() => selectionState?.selectOne(member.user_id)}
            onDeselect={() => selectionState?.deselectOne(member.user_id)}
            memberRoles={
              member.role_ids
                .map(id => rolesMap.get(id))
                .filter(Boolean) as TeamRole[]
            }
            onSetTag={tag => {
              onUpdateTeamMember({
                userId: member.user_id,
                tag
              });
            }}
            onResetTfa={() => {
              onSendTfaResetEmail({
                userId: member.user_id
              });
            }}
            onRemove={() => {
              onRemoveTeamMember({
                userId: member.user_id
              });
            }}
            onAddToGroups={async (groupIds: number[] | null) => {
              await onAddTeamMemberToGroups({
                user_id: member.user_id,
                group_ids: groupIds
              });
            }}
            onAssignAppRule={(ruleId: string) => {
              onAssignAppRule({ ruleId, userId: member.user_id });
            }}
            onResendConfirmationEmail={() => {
              onResendConfirmationEmail({
                userId: member.user_id
              });
            }}
          />
        );
      })}
    </TableBuilder>
  );
}

const ResetSearchButton = styled('button', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  cursor: 'pointer',
  color: '$primary500',
  transition: 'all 125ms ease',
  '&:focus, &:hover': {
    color: '$primary400'
  }
});
