// @parsec
import { schema, warpBilling } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('warp_billing');

export function useWarpBillingCardData() {
  return useQueryData(key.all(), schema.card);
}
/** ****************************************************************************/

export function useGetWarpBillingCard() {
  const kessel = useKessel();
  const me = useGetMe();

  const enabled = Boolean(me.data);

  const result = useQuery(
    key.all(),
    async function queryFn() {
      const res = await kessel.warpBilling.getWarpBillingCard();
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Warp billing card."
  });

  return { ...result, error };
}
/** ****************************************************************************/

export function useCreateWarpBillingCard() {
  const kessel = useKessel();
  const warpBillingCardCache = useWarpBillingCardData();

  const result = useMutation(
    async function (vars: warpBilling.CreateWarpBillingCardReq) {
      const res = await kessel.warpBilling.createWarpBillingCard(vars);
      return res.body.data;
    },
    {
      onSuccess() {
        warpBillingCardCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create Warp billing card."
  });

  return { ...result, error };
}
