import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, purchase } from './schema';

export interface CreateTeamPurchaseGuestAccessReq {
  team_id: string;
  addon_id: string;
  quantity: number;
  po_number?: string;
  captcha_token: string;
}

export const createTeamPurchaseGuestAccess =
  (options: ApiOptions) =>
  ({ team_id, ...body }: CreateTeamPurchaseGuestAccessReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/purchase/guest-access`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(purchase),
      body
    });
