import { NextRouter } from 'next/router';

import { Avatar, Button, Flyout, styled } from '@parsec/components';
import { TeamMember } from '@parsec/kessel';
import {
  useGetTeamRolePermissionSummary,
  useGetAllRoles,
  useGetMe,
  useRemoveRoleFromTeamMember
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { ListMessage } from 'components';

import { DataList } from './DataList';

const version = 'newFont';

export interface RoleAdminsListProps {
  loading?: boolean;
  data: Array<TeamMember>;
  onPrevPage(): void;
  onNextPage(): void;
  roleId: string;
  router: NextRouter;
}

export function RoleAdminsList(props: RoleAdminsListProps) {
  const { loading, data, roleId, router } = props;

  // Permissions
  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data?.team;
  const canManageAdminRoles = perms?.manage_admin_roles ?? false;
  const canRemoveAdminRoles = canManageAdminRoles;

  const getMeQuery = useGetMe();
  const me = getMeQuery.data;

  const getAllRolesQuery = useGetAllRoles();
  const roles = getAllRolesQuery.data?.data ?? [];
  const role = roles.find(role => role.id === roleId);

  const removeRoleFromTeamMember = useRemoveRoleFromTeamMember();

  const alert = useAlertContext();
  return (
    <DataList loading={loading ?? false}>
      {() => (
        <>
          {/* <Pagination
            offset={0}
            limit={10}
            count={12}
            onPrev={props.onNextPage}
            onNext={props.onPrevPage}
          /> */}
          <Grid>
            <Th>Name &amp; Email</Th>
            <Th>Other Roles</Th>
            {/* <Th>Assigned Role</Th> */}
            <Th></Th>
          </Grid>
          {data.length === 0 && !loading && (
            <ListMessage>No Results</ListMessage>
          )}
          {data.map(({ user, role_ids }) => {
            const memberRoles = new Set(role_ids);
            const isMe = me?.id === user.id;
            const isSuperAdmin = role?.name === 'Super Admin';

            const canRemoveRole =
              canRemoveAdminRoles && (isMe ? !isSuperAdmin : true); // don't allow removing self from super admin role
            return (
              <Grid key={user.id}>
                <Info>
                  <StyledAvatar userId={user.id} size={36} />
                  <InfoRow>
                    <Name>
                      <strong>{user.name}</strong>
                      <Id>#{user.id}</Id>
                    </Name>
                  </InfoRow>
                  <InfoRow>
                    <Subtext>{user.email}</Subtext>
                  </InfoRow>
                </Info>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {role_ids.length > 1 && <p>+{role_ids.length - 1}</p>}
                  &nbsp;&nbsp;
                  <Flyout
                    items={roles
                      .filter(
                        role => memberRoles.has(role.id) && role.id !== roleId
                      )
                      .map(role => ({
                        text: role.name,
                        onSelect: () => {
                          router.push(`/roles/${role.id}/admins`);
                        }
                      }))}
                  >
                    {({ props, isOpen: _ }) => {
                      return (
                        <Button
                          version={version}
                          style={{
                            display: role_ids.length <= 1 ? 'none' : ''
                          }}
                          {...props}
                          level="secondary"
                        >
                          View
                        </Button>
                      );
                    }}
                  </Flyout>
                </div>
                <div>
                  <Button
                    version={version}
                    disabled={!canRemoveRole}
                    level="secondary"
                    kind="error"
                    onClick={async () => {
                      try {
                        await removeRoleFromTeamMember.mutateAsync({
                          user_id: user.id,
                          role_ids: [roleId]
                        });
                        alert.show({
                          type: 'success',
                          title: 'Success',
                          message: 'You have successfully removed the role'
                        });
                      } catch (err) {
                        const res = parseError(err, {
                          error: "Couldn't remove role"
                        });
                        alert.show({
                          type: 'error',
                          title: 'Error',
                          message: res.error
                        });
                      }
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </Grid>
            );
          })}
        </>
      )}
    </DataList>
  );
}

const Grid = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  display: 'grid',
  gridTemplateColumns: '30rem auto 10rem',
  columnGap: '2rem',
  alignItems: 'flex-start',
  height: '6rem',
  padding: '2rem 2rem 0 2rem'
});

const Th = styled('h3', {
  color: '$nice',
  textTransform: 'uppercase',
  fontSize: '$newInfo'
});

const Info = styled('div', {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '$large'
});

const StyledAvatar = styled(Avatar, {
  gridRow: '1 / 3',
  width: '3.6rem',
  height: '3.6rem'
});

const InfoRow = styled('div', {
  stack: {
    hdistribute: 'start',
    valign: 'center',
    gap: '$medium'
  }
});

const Name = styled('div', {
  stack: {
    hdistribute: 'start'
  },
  fontSize: '$newInfo',
  lineHeight: '$info',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const Id = styled('span', {
  color: '$rhyhorn'
});

const Subtext = styled('div', {
  position: 'relative',
  fontSize: '$newInfo',
  lineHeight: '$info',
  color: '$rhyhorn',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&:not(:first-child)': {
    paddingLeft: '$medium',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      width: '0.1rem',
      height: '100%',
      backgroundColor: '$pancham'
    }
  }
});
