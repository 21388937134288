// libraries
import { createContext, useContext, useMemo, ReactNode, useState } from 'react';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  Query
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// @parsec
import { Kessel } from '@parsec/kessel';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const KesselContext = createContext<Kessel>({} as any); // TODO: don't export
export const LifecycleContext = createContext({
  onInvalidSession(_arg0?: string) {}
});

interface QueryProviderProps {
  kessel: Kessel;
  children?: ReactNode;
  onInvalidSession?(arg0?: string): void;
  onQueryCacheError?: (error: unknown, query: Query) => void;
}

export function QueryProvider(props: QueryProviderProps) {
  const {
    children,
    kessel,
    onInvalidSession = () => {},
    onQueryCacheError = () => {}
  } = props;

  const createQueryClient = () =>
    new QueryClient({
      defaultOptions: {
        mutations: {
          retry: false
        },
        queries: {
          retry: false,
          staleTime: 300000, // == 5 min
          cacheTime: 300000, // == 5 min
          refetchOnWindowFocus: false,
          refetchOnReconnect: false
        }
      },
      queryCache: new QueryCache({
        onError: onQueryCacheError
      })
    });

  const [client] = useState(createQueryClient());

  const lifecycle = useMemo(
    () => ({
      onInvalidSession: (statusCode: string) => {
        const handleDeleteSession = async () => {
          try {
            if (statusCode === '412') {
              // In the case of a 412 response, the session is still valid but cannot
              // perform the necessary action.
              // The user must re-authenticate to grant the ability to perform the desired action.
              // Since there's no current support for re-authentication, we'll delete the session
              // and prompt the user to log back in.
              await kessel.auth.deleteSession();
            }
          } catch (_) {
            // Best effort to remove the session from backend.
            // Not critical enough to alert in any external service.
          } finally {
            onInvalidSession(statusCode);
          }
        };
        handleDeleteSession();
      }
    }),
    [onInvalidSession]
  );

  return (
    <LifecycleContext.Provider value={lifecycle}>
      <KesselContext.Provider value={kessel}>
        <QueryClientProvider client={client}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </KesselContext.Provider>
    </LifecycleContext.Provider>
  );
}

export function useKessel() {
  return useContext(KesselContext);
}

export function useLifecycle() {
  return useContext(LifecycleContext);
}
