import * as icons from '@parsec/icons';
import { CSS, styled } from '@parsec/styles';

export type IconNames = keyof typeof icons;

const SvgElement = styled('svg', {});

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  css?: CSS;
  className?: string;
  title?: string;
  role?: string;
  name: IconNames;
}

export default function Icon(props: IconProps) {
  const { className, css, title, role, name } = props;
  const Svg = icons[name];
  return (
    <SvgElement
      as={Svg}
      css={css}
      className={className}
      title={title}
      role={role}
    />
  );
}
