const QUOTA_EXCEEDED_ERR_CODE = 22;
const DOM_QUOTA_REACHED_ERR_CODE = 1014;

/**
 * Use this to test if we can use local or session storage
 * @param type localStorage or sessionStorage
 * @returns
 */
export function storageAvailable(type: 'localStorage' | 'sessionStorage') {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === QUOTA_EXCEEDED_ERR_CODE ||
        // Firefox
        e.code === DOM_QUOTA_REACHED_ERR_CODE ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}
