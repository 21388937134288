import { useRef } from 'react';

import { estimate, schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const warpKey = new KeyFactory('warp_estimate');
const teamKey = new KeyFactory('team_estimate');
const accessLinkKey = new KeyFactory('access_link_estimate');

export function useWarpEstimateData() {
  return useQueryData(warpKey.all(), schema.estimate);
}

export function useTeamEstimateData() {
  return useQueryData(teamKey.all(), schema.estimate);
}

/******************************************************************************
 * Get an estimate of team subscription cost
 ******************************************************************************/

export function useEstimateTeamSubscription(
  vars: estimate.EstimateTeamSubscriptionReq,
  options?: { enabled?: boolean }
) {
  const kessel = useKessel();
  const enabled = options?.enabled ?? true;

  const result = useQuery(
    teamKey.detail(vars),
    async function queryFn(ctx) {
      const req = teamKey.vars(ctx.queryKey);
      const res = await kessel.estimate.estimateTeamSubscription(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Team subscription estimate."
  });
  return { ...result, error };
}

/******************************************************************************
 * Get an estimate of warp subscription cost
 ******************************************************************************/

export function useEstimateWarpSubscription(
  vars: estimate.EstimateWarpSubscriptionReq,
  options?: { enabled?: boolean }
) {
  const enabled = options?.enabled ?? true;
  const kessel = useKessel();

  const result = useQuery(
    warpKey.detail(vars),
    async function queryFn(ctx) {
      const req = warpKey.vars(ctx.queryKey);
      const res = await kessel.estimate.estimateWarpSubscription(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Warp subscription estimate."
  });
  return { ...result, error };
}

/******************************************************************************
 * Get an estimate of guest access credit purchase
 ******************************************************************************/

export function useEstimateAccessLinkCreditPurchase(
  vars: estimate.EstimatePurchaseReq & { skip?: boolean }
) {
  const kessel = useKessel();
  const varsRef = useRef(vars);

  const keepPreviousData =
    varsRef.current.addon_id === vars.addon_id &&
    varsRef.current.quantity === vars.quantity;
  varsRef.current = vars;

  const enabled = !vars.skip;

  const result = useQuery(
    accessLinkKey.detail(vars),
    async function queryFn(ctx) {
      const req = accessLinkKey.vars(ctx.queryKey);
      const res = await kessel.estimate.estimatePurchase(req);
      return res.body.data;
    },
    { enabled, keepPreviousData }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get estimate of guest access credit purchase.'
  });

  return { ...result, error };
}
