import { cookie, save, clear } from '@parsec/cookie';

export function getAuthRedirect() {
  const { redirect } = cookie();

  return redirect;
}

export function setAuthRedirect(url: string) {
  const { token } = cookie();

  save({ token, redirect: url });
}

export function resetAuthRedirect() {
  const { token } = cookie();

  if (!token) clear();
  else save({ token, redirect: '' });
}

export function runAuthRedirect(fallback?: string) {
  const url = getAuthRedirect() || fallback;

  if (!url) return;

  resetAuthRedirect();
  redirect(url);
}

// okay to have this as a module-level singleton, because if it's true we're already redirecting away from the page
let redirecting = false;

export function redirect(url: string) {
  if (typeof window === 'undefined') {
    console.warn('Attempted to redirect in non-browser environment!');
    return;
  }

  if (redirecting) {
    console.warn('Attempted to redirect again during in-progress redirect!');
    return;
  }

  redirecting = true;
  window.location.assign(url);
}
