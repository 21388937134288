import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, saml, SAML } from './schema';

export interface GetTeamSAMLReq {
  team_id: string;
}

export const getTeamSAML =
  (options: ApiOptions) =>
  ({ team_id }: GetTeamSAMLReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/saml`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(saml)
    });

export interface UpdateTeamSAMLIDPReq extends SAML {
  team_id: string;
}

export const updateTeamSAMLIDP =
  (options: ApiOptions) =>
  ({ team_id, ...body }: UpdateTeamSAMLIDPReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/saml-idp`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });

export interface UpdateTeamSAMLSettingsReq {
  team_id: string;
  alias?: string;
  duration?: number;
}

export const updateTeamSAMLSettings =
  (options: ApiOptions) =>
  ({ team_id, ...body }: UpdateTeamSAMLSettingsReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/saml-settings`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
