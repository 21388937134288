import { apiKey, schema, team } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useMeData } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('api_key');

export function useApiKeyData() {
  return useQueryData(key.all(), schema.paginatedAPIKeyData);
}

export function useGetAllTeamApiKeys() {
  const kessel = useKessel();
  const meCache = useMeData();
  const me = meCache.get();

  const vars = {
    team_id: me?.team_id ?? '',
    offset: 0,
    // The kessel API only ever returns up to 200 results at a time. So, passing in
    // limit as 200 here is equivalent to requesting all the available results.
    limit: 200
  };

  const enabled = Boolean(me?.team_id);

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.apiKey.listAPIKeys(req);
      return res.body;
    },
    {
      enabled
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get api keys."
  });

  return { ...result, error };
}

export function useUpdateTeamApiKey() {
  const kessel = useKessel();
  const apiKeyCache = useApiKeyData();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: Omit<apiKey.UpdateAPIKeyReq, 'team_id'>) {
      const res = await kessel.apiKey.updateAPIKey({
        ...req,
        team_id: teamId
      });

      return res.body.data;
    },
    {
      onSuccess: () => {
        apiKeyCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update api key."
  });

  return { ...result, error };
}

export function useCreateTeamApiKey() {
  const kessel = useKessel();
  const apiKeyCache = useApiKeyData();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: Omit<team.GenerateAPIKeyReq, 'team_id'>) {
      const res = await kessel.team.generateAPIKey({
        ...req,
        team_id: teamId
      });

      return res.body?.data;
    },
    {
      onSuccess: () => {
        apiKeyCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create api key."
  });

  return { ...result, error };
}

export function useDeleteTeamApiKey() {
  const kessel = useKessel();
  const apiKeyCache = useApiKeyData();
  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: Omit<apiKey.DeleteAPIKeyReq, 'team_id'>) {
      return await kessel.apiKey.deleteAPIKey({
        ...req,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        apiKeyCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't delete api key."
  });

  return { ...result, error };
}
