import { forwardRef, Ref } from 'react';

import { styled } from '@parsec/styles';

type InputProps = JSX.IntrinsicElements['input'];

export interface ToggleProps extends Omit<InputProps, 'checkbox' | 'value'> {
  ref?: Ref<HTMLInputElement>;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  (props, ref) => {
    return <Input ref={ref} {...props} type="checkbox" />;
  }
);

Toggle.displayName = 'Toggle';
export default Toggle;

const Input = styled('input', {
  appearance: 'none',
  position: 'relative',
  display: 'block',
  width: '3.6rem',
  height: '1.8rem',
  transition:
    '125ms box-shadow ease, 125ms background-color ease, 100ms transform ease',
  borderRadius: '1rem',
  backgroundColor: '$cereza',
  boxShadow: '0px 1px 0px rgba(255,255,255,0.1)',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '1.2rem',
    height: '1.2rem',
    left: '0.3rem',
    top: '50%',
    transform: 'translate(0, -50%)',
    backgroundColor: '$espurr',
    borderRadius: '50%',
    transition: '0.25s',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.05) inset,  0px 2px 2px 0px rgba(0, 0, 0, 0.18)'
  },

  //hover
  '&:not(:disabled):not(:checked):hover': {
    cursor: 'pointer',
    boxShadow:
      'inset 0 0 0 1px $colors$pancham, 0px 1px 0px rgba(255,255,255,0.1)',
    '&::-moz-focus-inner': {
      border: 'none'
    }
  },

  //focus
  '&:focus': {
    outline: 'none',
    border: '0.1rem solid $consoleWhite'
  },

  //disabled
  '&:disabled': {
    opacity: 0.5,
    backgroundColor: '$duskull',
    cursor: 'not-allowed',

    '&::before': {
      backgroundColor: '$nice',
      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.05)'
    }
  },

  //checked
  '&:checked': {
    backgroundColor: '$primary500',
    boxShadow: '0px 1px 0px rgba(255,255,255,0.1)',
    '&:focus': {
      boxShadow: 'none'
    },
    '&::before': {
      top: '50%',
      transform: 'translate(1.8rem, -50%)',
      backgroundColor: '$consoleWhite'
    }
  }
});
