// @parsec
import { schema } from '@parsec/kessel';

// hooks
import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_role_action_summary');

export function useTeamRolePermissionSummaryData() {
  return useQueryData(key.all(), schema.teamRolePermissionSummary);
}

/******************************************************************************
 * Get Team Role Action Summary
 ******************************************************************************/

export function useGetTeamRolePermissionSummary() {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = { team_id: me.data?.team_id ?? '', user_id: me.data?.id ?? 0 };
  const enabled = vars.team_id !== '' && vars.user_id !== 0;

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);

      const res =
        await kessel.teamRolePermissionSummary.getTeamRolePermissionSummary(
          req
        );
      // Uncomment the following lines for local permission debugging help
      // const params = new URLSearchParams(window.location.search);
      // console.log(params.get('perms'));
      // // eslint-ignore-next-line
      // try {
      //   res.body.data = {
      //     ...res.body.data,
      //     ...JSON.parse(params.get('perms') as any)
      //   };
      // } catch (err) {}
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'failed to get team role permissions summary.'
  });
  return { ...result, error };
}
