import { createContext, useContext, ReactNode, useMemo } from 'react';

interface ContextProps {
  hasError?: boolean;
  isValid?: boolean;
  version?: 'newFont';
}

export const Context = createContext<ContextProps>({
  hasError: false,
  isValid: false,
  version: undefined
});

export const useFieldLabel = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useFieldLabel must be used within an FieldLabelContext.Provider. Wrap a parent component in <FieldLabelProvider> to fix this error.'
    );
  }
  return context;
};

export interface FieldLabelContextProps {
  hasError?: boolean;
  isValid?: boolean;
  children: ReactNode;
  version?: 'newFont';
}

const FieldLabelProvider = ({
  hasError,
  isValid,
  version,
  children
}: FieldLabelContextProps) => {
  const value = useMemo(
    () => ({ hasError, isValid, version }),
    [hasError, isValid, version]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default FieldLabelProvider;
