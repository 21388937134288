/**
 * NEW STYLE UPDATE FOR NEW PAGE DESIGNS. We don't want to have to update the old Tip component. We want to be able to just switch them all out once we have updated the styles on all the pages.
 */

import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

const Wrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridAutoFlow: 'column',
  columnGap: '$medium',
  alignItems: 'center',
  justifyContent: 'left',
  padding: '$medium 1rem',
  border: '0.1rem solid $colors$pancham',
  borderRadius: '$small',

  variants: {
    type: {
      info: {
        color: '$rhyhorn'
      },
      warning: {
        color: '$consoleWhite'
      },
      important: {
        color: '$consoleWhite',
        columnGap: '$xxlarge',
        padding: '$xxlarge',
        backgroundColor: '$chandelure',
        lineHeight: '$attribution'
      }
    },
    disabled: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  color: '$notice500',
  height: '1.2rem',
  width: '1.2rem',
  variants: {
    type: {
      warning: {
        color: '$warning500'
      },
      important: {
        color: '$consoleWhite',
        height: 'auto',
        width: 'auto'
      },
      info: {
        color: '$notice500'
      }
    },
    disabled: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

const CopyContainer = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$info',
  lineHeight: '$info'
});

export interface TipV2Props {
  className?: string;
  children: ReactNode;
  type?: 'important' | 'warning' | 'info';
  disabled?: boolean;
}

export function TipV2({
  type = 'info',
  disabled = false,
  className,
  ...props
}: TipV2Props) {
  const iconName = (() => {
    switch (type) {
      case 'important':
        return 'infoFilled';
      case 'info':
        return 'bulb';
      case 'warning':
        return 'warningSign';
      default:
        return 'bulb';
    }
  })();

  return (
    <Wrapper className={className} type={type} disabled={disabled}>
      <StyledIcon name={iconName} type={type} disabled={disabled} />
      <CopyContainer>{props.children}</CopyContainer>
    </Wrapper>
  );
}
