import { useEffect, useRef, useState } from 'react';

export function useMediaQuery(query: string) {
  const mq = useRef(
    typeof window !== 'undefined' ? window.matchMedia(query) : null
  );

  const [matches, setMatches] = useState(
    mq.current ? mq.current.matches : false
  );

  useEffect(() => {
    const { current } = mq;
    if (!current) return () => {};

    function check() {
      setMatches(current ? current.matches : false);
    }

    if (current.addEventListener) current.addEventListener('change', check);
    else current.addListener(check);

    return () => {
      if (current.removeEventListener)
        current.removeEventListener('change', check);
      else current.removeListener(check);
    };
  }, [query, mq]);
  return matches;
}
