import { useRouter } from 'next/router';

import { Modal, styled } from '@parsec/components';
import { useDeleteRole } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

const version = 'newFont';

const Text = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody'
});
export interface DeleteRoleModalProps {
  roleId: string;
  isOpen: boolean;
  onClose(): void;
}

export function DeleteRoleModal(props: DeleteRoleModalProps) {
  const alert = useAlertContext();
  const router = useRouter();
  const deleteRole = useDeleteRole();
  return (
    <Modal
      version={version}
      title="Delete Role"
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      actions={[
        {
          text: deleteRole.isLoading ? 'Deleting...' : 'Delete Role',
          kind: 'error',
          disabled: deleteRole.isLoading,
          async onClick() {
            try {
              alert.clear();
              await deleteRole.mutateAsync({
                role_id: props.roleId
              });
              alert.show({
                type: 'success',
                title: 'Success',
                message: 'Role deleted'
              });
              props.onClose();
              router.replace('/roles');
            } catch (err) {
              const res = parseError(err, {
                error: "Couldn't delete role."
              });
              alert.show({
                type: 'error',
                title: 'Error',
                message: res.error
              });
            }
          }
        },
        { text: 'Cancel', level: 'secondary', onClick: props.onClose }
      ]}
    >
      <Text>Delete this role with all its permissions and admins?</Text>
    </Modal>
  );
}
