import { Identify } from '@amplitude/analytics-browser';

const noopClient = {
  identify: (_: Identify) => {},
  reset: () => {},
  track: (_name: string, _properties: Record<string, never>) => {},
  setUserId: (_: string | undefined) => {}
};

// The AmplitudeClient is loaded from the window. We've confirmed
// that this window client matches the client that is used in GTM.
// If available, the loaded client is the Amplitude Browser SDK.
// For more information, see: https://www.docs.developers.amplitude.com/data/sdks/browser-2/
// If the client is not available, we will default to a no-op client.
// The client may not be available when a user disables performance cookies.
export default function AmplitudeClient() {
  if (
    typeof window !== 'undefined' &&
    window.amplitudeGTM !== undefined &&
    window.amplitudeGTM._iq !== undefined &&
    window.amplitudeGTM._iq.allAmp !== undefined
  ) {
    return {
      isLoaded: true,
      ...window.amplitudeGTM._iq.allAmp
    };
  } else {
    return {
      isLoaded: false,
      ...noopClient
    };
  }
}
