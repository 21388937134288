import { Method, request, Status } from '@parsec/request';

import { ApiOptions } from './api';
import {
  TeamRole,
  PaginatedTeamRoleData,
  AssignableTeamPermissions,
  AssignableGroupPermissions
} from './schema';

/******************************************************************************
 * Get TeamRoles
 ******************************************************************************/

export interface GetTeamRolesReq {
  team_id: string;
  offset: number;
  limit: number;
}
export const getTeamRoles =
  (options: ApiOptions) =>
  /**
   * Gets a paginated list of TeamRoles for a Team.
   */
  async (req: GetTeamRolesReq) => {
    const { team_id, offset, limit } = req;
    const params = `offset=${offset}&limit=${limit}`;

    const res = await request<PaginatedTeamRoleData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/roles?${params}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
    // Faux pagination
    res.body.count = res.body.data.length;
    if (res.type === Status.Success) {
      res.body.data.forEach(role => {
        if (role.name === 'team_owner') {
          role.name = 'Super Admin';
        }
        if (role.name === 'team_admin') {
          role.name = 'Admin';
        }
        if (role.name === 'team_access_link_admin') {
          role.name = 'Guest Access Manager';
        }
      });
      res.body.data.sort((a, b) => {
        if (b.name === 'Super Admin') {
          return 1;
        }
        if (b.name === 'Admin' && a.name !== 'Super Admin') {
          return 1;
        }
        if (
          b.name === 'Guest Access Manager' &&
          a.name !== 'Super Admin' &&
          a.name !== 'Admin'
        ) {
          return 1;
        }
        if (a.system_role && !b.system_role) return -1;
        if (!a.system_role && b.system_role) return 1;
        return 0;
      });
    }
    return res;
  };

/******************************************************************************
 * Create TeamRole
 ******************************************************************************/

export interface CreateTeamRoleReq
  extends Omit<TeamRole, 'id' | 'system_role' | 'actions' | 'group_id'> {
  team_id: string;
  group_id?: number;
  actions?: AssignableTeamPermissions | AssignableGroupPermissions;
}
export const createTeamRole =
  (options: ApiOptions) =>
  /**
   * Creates a TeamRole by Team ID
   */
  (req: CreateTeamRoleReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/roles`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update TeamRole
 ******************************************************************************/
export interface UpdateTeamRoleReq
  extends Omit<TeamRole, 'system_role' | 'actions'> {
  team_id: string;
  actions: AssignableTeamPermissions | AssignableGroupPermissions;
}
export const updateTeamRole =
  (options: ApiOptions) =>
  /**
   * Updates a TeamRole by Team ID and TeamRole ID.
   */
  (req: UpdateTeamRoleReq) => {
    const { team_id, id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/roles/${id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Delete TeamRole
 ******************************************************************************/

export interface DeleteTeamRoleReq {
  role_id: string;
  team_id: string;
}
export const deleteTeamRole =
  (options: ApiOptions) =>
  /**
   * Deletes a TeamRole by Team ID and TeamRole ID
   */
  (req: DeleteTeamRoleReq) => {
    const { team_id, role_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/roles/${role_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
