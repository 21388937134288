import { FormEvent, useState } from 'react';

type Values = {
  [key: string]: string | string[] | number;
};

/**
 * Aggregate the values from a form element's inputs.
 * Throws an error if an input is invalid.
 */
export function formValues<T = Values>(event: Event): T {
  const form = event.target as HTMLFormElement;
  if (!form.noValidate && !form.checkValidity())
    throw new Error(`Form "${form.name || '[unnamed]'}" is invalid.`);
  const elements = Array.from(form.elements) as HTMLInputElement[];
  const inputs = elements.filter(element => element.name);
  return inputs.reduce<Values>(value, {} as Values) as unknown as T;
}

function value<T extends Values>(
  memo: T,
  el: HTMLInputElement | HTMLTextAreaElement
) {
  if (el.type === 'textarea') {
    return { ...memo, [el.name]: el.value };
  }
  el = el as HTMLInputElement;
  switch (el.type) {
    case 'checkbox':
      return { ...memo, [el.name]: Boolean(el.checked) };

    case 'radio':
      return el.checked ? { ...memo, [el.name]: el.value } : memo;

    case 'number':
      return { ...memo, [el.name]: Number(el.value) };

    case 'file':
      return { ...memo, [el.name]: el.files };

    default:
      return { ...memo, [el.name]: el.value };
  }
}

export function useForm<T>(cb: (values: T) => void | Promise<void>) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    const event = e.nativeEvent;

    try {
      await cb(formValues(event));
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred.');
    } finally {
      setSubmitting(false);
    }
  }

  return { submitting, error, onSubmit };
}

export function handleFormSubmit<T = Values>(
  cb: (values: T) => void | Promise<void>
) {
  return async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const els: (HTMLInputElement | HTMLButtonElement)[] = Array.from(
      form.querySelectorAll('input, textarea, button')
    );
    const originalDisabled = new Map(els.map(el => [el, el.disabled]));
    for (let el of els) {
      el.disabled = true;
    }
    try {
      const values = formValues<T>(e.nativeEvent);
      await cb(values);
    } catch (err) {}
    for (let el of els) {
      el.disabled = originalDisabled.get(el)!;
    }
  };
}
