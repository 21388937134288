import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { outOfDateHosts, PaginatedMachineData } from './schema';

/******************************************************************************
 * Get Team Machines
 ******************************************************************************/

export interface GetTeamMachinesReq {
  team_id: string;
  offset: number;
  limit: number;
  team_group_id?: number;
  team_app_rule_id?: string;
  search_type?: 'computer' | 'user';
  search_query?: string;
  is_mine?: boolean;
  exclude_online?: boolean;
  exclude_offline?: boolean;
  exclude_unassigned?: boolean;
  exclude_user_assigned?: boolean;
  exclude_group_assigned?: boolean;
  exclude_guest_access?: boolean;
  exclude_non_guest_access?: boolean;
  exclude_reserved?: boolean;
  exclude_last_updated_at_less_than?: number;
  exclude_last_updated_at_greater_than?: number;
  exclude_team_app_rule?: boolean;
}
export const getTeamMachines =
  (options: ApiOptions) => (req: GetTeamMachinesReq) => {
    const { team_id, ...params } = req;
    let q = '';
    for (const [k, v] of Object.entries(params)) {
      if (v != undefined) q += `${!q ? '?' : '&'}${k}=${v}`;
    }

    return request<PaginatedMachineData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/machines/${q}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Create TeamMachine
 ******************************************************************************/

export interface CreateTeamMachinesReq {
  team_id: string;
  key: string;
  name: string;
  user_id?: number;
  user_email?: string; // if both user id and email supplied, user_id is used
  team_group_ids?: number[];
  team_app_rule_id?: string;
  peer_id?: string; // for persistent peer ids
  is_guest_access?: boolean;
}
export const createTeamMachine =
  (options: ApiOptions) =>
  /**
   * Creates a TeamMachine's name.
   */
  (req: CreateTeamMachinesReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/machines`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update TeamMachine
 ******************************************************************************/

export interface UpdateTeamMachineReq {
  team_id: string;
  machine_id: string;
  is_guest_access: boolean;
}
export const updateTeamMachine =
  (options: ApiOptions) => (req: UpdateTeamMachineReq) => {
    const { team_id, machine_id, ...body } = req;

    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/machines/${machine_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Assign TeamMachine
 ******************************************************************************/

export interface AssignTeamMachineReq {
  team_id: string;
  machine_id: string;
  user_id: number | null;
  email: string | null;
  team_group_ids: number[] | null;
}
export const assignTeamMachine =
  (options: ApiOptions) =>
  (
    /**
     * Replaces the Team Machine's assigned user/groupIDs to the value.
     */
    req: AssignTeamMachineReq
  ) => {
    const { team_id, machine_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/machines/${machine_id}/assignment`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Unassign TeamMachine
 ******************************************************************************/

export interface UnassignTeamMachineReq {
  team_id: string;
  machine_id: string;
}

/******************************************************************************
 * Delete TeamMachine
 ******************************************************************************/

export interface DeleteTeamMachinesReq {
  team_id: string;
  machine_id: string;
}
export const deleteTeamMachine =
  (options: ApiOptions) => (req: DeleteTeamMachinesReq) => {
    const { team_id, machine_id } = req;

    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/machines/${machine_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Kick TeamMachine Guest
 ******************************************************************************/

export interface KickTeamMachineGuestReq {
  team_id: string;
  machine_id: string;
  guest_id: number;
}
export const kickTeamMachineGuest =
  (options: ApiOptions) =>
  /**
   * Kicks a TeamMachine guest.
   */
  (req: KickTeamMachineGuestReq) => {
    const { team_id, machine_id, guest_id } = req;

    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/machines/${machine_id}/guests/${guest_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * List Out of Date Team Machines
 ******************************************************************************/
export interface GetOutOfDateHostsReq {
  team_id: string;
}
export const getOutOfDateHosts =
  (options: ApiOptions) => (req: GetOutOfDateHostsReq) => {
    const { team_id } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/machines/outdated`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: outOfDateHosts
    });
  };
