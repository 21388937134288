import { useMemo, ReactNode } from 'react';

import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// @parsec
import { Kessel } from '@parsec/kessel';

import { KesselContext, LifecycleContext } from '../Provider';

interface TestQueryProviderProps {
  kessel: Kessel;
  cacheTime?: number | 0;
  children?: ReactNode;
  onInvalidSession?(arg0?: string): void;
}

export function TestQueryProvider(props: TestQueryProviderProps) {
  const { children, kessel, cacheTime, onInvalidSession = () => {} } = props;
  setLogger({
    // eslint-disable-next-line no-console
    log: console.log,
    warn: console.warn,
    // ✅ no more errors on the console
    error: () => {}
  });

  const client = new QueryClient({
    defaultOptions: {
      mutations: {
        retry: false
      },
      queries: {
        retry: false,
        cacheTime: cacheTime
      }
    }
  });

  const lifecycle = useMemo(() => ({ onInvalidSession }), [onInvalidSession]);

  return (
    <LifecycleContext.Provider value={lifecycle}>
      <KesselContext.Provider value={kessel}>
        <QueryClientProvider client={client}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </KesselContext.Provider>
    </LifecycleContext.Provider>
  );
}
