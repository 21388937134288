import type { ErrorCode, SuccessRes } from '@parsec/request';

import { configure, Kessel } from './kessel';

type KesselGroups = keyof Kessel;
type KesselRequests = keyof Kessel[KesselGroups];
type MockRequest<K extends keyof Kessel, R extends keyof Kessel[K]> = jest.Mock<
  // @ts-expect-error not sure why typescript doesn't like this; MockRequest ends up with the correct type
  ReturnType<Kessel[K][R]>,
  // @ts-expect-error not sure why typescript doesn't like this; MockRequest ends up with the correct type
  Parameters<Kessel[K][R]>
>;

type MockedKessel = {
  [K in keyof Kessel]: {
    [R in keyof Kessel[K]]: MockRequest<K, R>;
  };
};

export class MockKessel {
  mocks = {} as MockedKessel;

  constructor() {
    const defaults = configure({
      kessel: '',
      downtimeURL: '',
      token: () => ''
    });
    for (const group of Object.keys(defaults) as KesselGroups[]) {
      // @ts-expect-error {} isn't assignable to a group of requests; we'll build it up in the loop
      this.mocks[group] = {};
      for (const request of Object.keys(defaults[group]) as KesselRequests[]) {
        // @ts-expect-error the default mock doesn't have the correct signature because it always rejects
        this.mocks[group][request] = jest
          .fn()
          .mockRejectedValue(`No mock implementation for ${group}.${request}!`);
      }
    }
  }

  mock<T extends keyof Kessel, U extends keyof Kessel[T]>(
    group: T,
    req: U,
    fn: Kessel[T][U]
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.mocks[group][req].mockImplementationOnce(fn as any);
    return this;
  }

  success<T extends keyof Kessel, U extends keyof Kessel[T]>(
    group: T,
    req: U,
    // @ts-expect-error see previous mock data type error
    res?: SuccessData<ReturnType<Kessel[T][U]>>,
    status = 200
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fn: any = () => success(res, status);
    return this.mock(group, req, fn);
  }

  error<T extends keyof Kessel>(
    group: T,
    req: keyof Kessel[T],
    message: string,
    codes: ErrorCode[],
    status = 400
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fn: any = () => error(message, codes, status);
    return this.mock(group, req, fn);
  }
}

type SuccessData<T> = T extends PromiseLike<SuccessRes<infer U>> ? U : never;

export function success<T>(body: T, status = 200) {
  return Promise.resolve({ type: 2, status, body });
}

export function error(message: string, codes: ErrorCode[], status = 400) {
  return Promise.reject({
    type: 2,
    status,
    error: message,
    body: { error: message, codes }
  });
}
