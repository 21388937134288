import { ReactNode } from 'react';

import { styled } from '@parsec/components';

import { linkify } from 'lib/react-utils';

import { Badge } from './Badge';
import { Description } from './Description';

interface Props {
  className?: string;
  title: string;
  description: string;
  htmlFor?: string;
  disabled?: boolean;
  hasOverride?: boolean;
  children?: ReactNode;
}

export function SettingLayout(props: Props) {
  const {
    className,
    title,
    description,
    htmlFor,
    disabled,
    hasOverride,
    children
  } = props;

  return (
    <Wrapper className={className} disabled={disabled}>
      <TitleWrapper>
        <Label htmlFor={htmlFor}>{title}</Label>
        {hasOverride && <Badge>Overridden</Badge>}
      </TitleWrapper>
      <StyledDescription info="true">{linkify(description)}</StyledDescription>
      <Field>{children}</Field>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '2fr 1fr',
  gridColumnGap: '2rem',
  alignItems: 'center',
  justifyItems: 'start',
  variants: {
    disabled: {
      true: {
        color: '$rhyhorn'
      },
      false: {}
    }
  }
});

const TitleWrapper = styled('div', {
  display: 'flex',
  marginBottom: '$medium'
});

const Label = styled('label', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$body'
});

const StyledDescription = styled(Description, {
  gridColumn: '1 / 2'
});

const Field = styled('div', {
  gridRow: '1 / 3',
  gridColumn: '2 / 3',
  justifySelf: 'end',
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gap: '$medium'
});
