import { useState, forwardRef, useEffect, useCallback } from 'react';

import { useCombobox } from 'downshift';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import Avatar from '../Avatar';
import {
  DropdownLi,
  DropdownMenu,
  DropdownMenuWrapper,
  DropdownProps,
  DropdownUl
} from '../Dropdown';
import Icon from '../Icon';
import Input, { InputProps } from '../Input';

export interface ComboboxProps
  extends Omit<
    DropdownProps & InputProps & { withDropdown?: boolean },
    'children'
  > {}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  function Combobox(props, _ref) {
    const {
      x = 0,
      y = 8,
      placement = 'bottom-start',
      items,
      icon,
      withDropdown = true,
      ...inputProps
    } = props;

    const [toggle, setToggle] = useState<HTMLElement | null>(null);
    const [popover, setPopover] = useState<HTMLElement | null>(null);

    const offset: [number, number] =
      placement.startsWith('left') || placement.startsWith('right')
        ? [y, x]
        : [x, y];

    const { styles, attributes } = usePopper(toggle, popover, {
      placement,
      modifiers: [
        {
          name: 'flip'
        },
        {
          name: 'offset',
          options: { offset }
        }
      ]
    });

    const inputItems = items.flat();

    const {
      isOpen,
      openMenu,
      closeMenu,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      setHighlightedIndex,
      getItemProps,
      setInputValue,
      inputValue
    } = useCombobox({
      items: inputItems,
      itemToString(item) {
        return `${item?.value ?? item?.text ?? ''}`;
      },
      onSelectedItemChange: state => {
        state.selectedItem?.onSelect?.();
        closeMenu();
      }
    });
    const { defaultValue } = props;

    useEffect(() => {
      if (!inputValue && defaultValue) {
        setInputValue(defaultValue as string);
      }
    }, [defaultValue, setInputValue, inputValue]);

    useEffect(() => {
      if (!isOpen) setInputValue('');
    }, [isOpen, setInputValue]);

    const onHandleOpen = useCallback(() => {
      if (!isOpen) openMenu();
    }, [isOpen, openMenu]);

    return (
      <>
        <Input
          {...getInputProps({ ref: setToggle })}
          icon={icon}
          onFocus={onHandleOpen}
          onClick={onHandleOpen}
          withDropdown={withDropdown}
          version="newFont"
          {...inputProps}
        />
        {createPortal(
          <DropdownMenuWrapper
            style={styles.popper}
            {...attributes.popper}
            {...getMenuProps({ ref: setPopover })}
          >
            {isOpen && inputItems.length > 0 && (
              <DropdownMenu onMouseLeave={() => setHighlightedIndex(-1)}>
                <DropdownUl>
                  {inputItems.map((item, itemIndex) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <DropdownLi
                        {...getItemProps({
                          key: item.key || `${item?.value ?? item?.text}`,
                          disabled: item.disabled,
                          item,
                          index: itemIndex
                        })}
                        highlighted={highlightedIndex === itemIndex}
                        version="newFont"
                      >
                        {item.icon && typeof item.icon === 'string' ? (
                          <Icon name={item.icon} />
                        ) : item.icon ? (
                          <Avatar size={18} userId={item.icon} />
                        ) : null}
                        <span>{item.text}</span>
                      </DropdownLi>
                    );
                  })}
                </DropdownUl>
              </DropdownMenu>
            )}
          </DropdownMenuWrapper>,
          document.getElementById('popovers') ?? document.body
        )}
      </>
    );
  }
);

export default Combobox;
