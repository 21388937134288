import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { APIKey, apiKeyData, paginatedAPIKeyData } from './schema';

/******************************************************************************
 * List API Keys
 ******************************************************************************/

export interface ListAPIKeyReq {
  team_id: string;
  offset?: number;
  limit?: number;
}

export const listAPIKeys =
  (options: ApiOptions) =>
  /**
   * Lists API keys for this team.
   */
  (req: ListAPIKeyReq) => {
    const { team_id, offset = 0, limit = 20 } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/api-keys?offset=${offset}&limit=${limit}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      validator: paginatedAPIKeyData,
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Update API Key
 ******************************************************************************/

export interface UpdateAPIKeyReq {
  team_id: string;
  key_id: string;
  name?: string;
  is_active?: boolean;
  actions?: APIKey['actions'];
}

export const updateAPIKey =
  (options: ApiOptions) =>
  /**
   * Update an API key.
   */
  (req: UpdateAPIKeyReq) => {
    const { team_id, key_id, ...body } = req;
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/api-keys/${key_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body,
      validator: apiKeyData
    });
  };

/******************************************************************************
 * Delete API Key
 ******************************************************************************/

export interface DeleteAPIKeyReq {
  team_id: string;
  key_id: string;
}

export const deleteAPIKey =
  (options: ApiOptions) =>
  /**
   * Delete an API key.
   */
  (req: DeleteAPIKeyReq) => {
    const { team_id, key_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/api-keys/${key_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
