import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, host } from './schema';

export interface GetHostReq {
  peer_id: string;
  secret?: string;
}

export const getHost = (options: ApiOptions) => (req: GetHostReq) => {
  const query = req.secret ? `?secret=${req.secret}` : '';
  return request({
    type: Method.GET,
    url: `${options.kessel}/public/hosts/${req.peer_id}${query}`,
    middleware: options.middleware,
    validator: data(host)
  });
};
