// @parsec
import { schema, teamNonCompliantUsers } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_non_compliant_users');

export function useNonCompliantUsersData() {
  return useQueryData(key.all(), schema.teamNonCompliantUsers);
}

export function useGetTeamNonCompliantUsers() {
  const kessel = useKessel();
  const me = useGetMe();
  const teamQuery = useGetTeam();

  const vars = { team_id: me.data?.team_id ?? '' };
  const enabled = (Boolean(vars.team_id) && teamQuery.data?.is_active) ?? false;

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);

      const res =
        await kessel.teamNonCompliantUsers.getTeamNonCompliantUsers(req);
      return res.body;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Non Compliant Users."
  });

  return { ...result, error };
}

export function useUpdateFreePlanBlocking() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamCache = useTeamData();

  const teamId = teamQuery.data?.id ?? '';

  const onSuccess = () => {
    teamCache.invalidate();
  };

  const result = useMutation(
    async function mutationFn(
      req: Omit<teamNonCompliantUsers.UpdateTeamFreePlanBlockingReq, 'team_id'>
    ) {
      return await kessel.teamNonCompliantUsers.updateFreePlanBlocking({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Team Free Plan Blocking."
  });

  return {
    ...result,
    error
  };
}
