import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { SuccessRes, Status, parseError } from '@parsec/request';

export interface PaginatedListStore<
  P extends { offset: number; limit: number },
  T extends { data: unknown[]; count: number }
> {
  params?: P;
  data: T['data'];
  count: number;
  isLoading: boolean;
  error: string;
  fetch(p?: P): Promise<SuccessRes<T>>;
  fetchAll(p?: P, limit?: number): Promise<SuccessRes<T>>;
  setData: Dispatch<SetStateAction<T['data']>>;
  setCount: Dispatch<SetStateAction<number>>;
}

export function usePaginatedList<
  P extends { offset: number; limit: number },
  T extends { data: unknown[]; count: number }
>(
  f: (params: P) => Promise<SuccessRes<T>>,
  defaultParams?: P
): PaginatedListStore<P, T> {
  const [params, setParams] = useState<P | undefined>(defaultParams);
  const [data, setData] = useState<T['data']>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (params) fetch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    params,
    data,
    count,
    isLoading,
    error,
    fetch,
    fetchAll,
    setData,
    setCount
  };

  async function fetchAll(p: P | undefined = params, limit = 200) {
    if (!p) throw new Error('No params specified');
    setParams(p);
    setIsLoading(true);
    const results: T['data'] = [];
    let count = 1;
    for (let offset = 0; offset < count; offset += limit) {
      try {
        const res = await f({
          ...p,
          offset,
          limit
        });
        count = res.body.count;
        results.push(...res.body.data);
      } catch (err) {
        const res = parseError(err);
        setError(res.body?.error ?? res.error);
        setIsLoading(false);
        throw err; // let it blow up
      }
    }
    setError('');
    setData(results);
    setCount(count);
    setIsLoading(false);
    return {
      type: Status.Success,
      status: 200,
      body: {
        data: results,
        count: count
      }
    } as SuccessRes<T>;
  }

  async function fetch(p: P | undefined = params) {
    if (!p) throw new Error('No params specified');
    setParams(p);
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { offset: _offset0, limit: _limit0, ...a } = p;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { offset: _offset1, limit: _limit1, ...b } = params ?? p;
    const shouldReset = JSON.stringify(a) !== JSON.stringify(b);
    if (shouldReset) {
      setData(() => []);
    }
    try {
      const res = await f(p);
      setError('');
      setData(data => {
        const nextData = [...data];
        // replace subset of data with latest results
        nextData.splice(p.offset, p.limit, ...res.body.data);
        return nextData;
      });
      setCount(res.body.count);
      setIsLoading(false);
      return res;
    } catch (err) {
      const res = parseError(err);
      setError(res.error);
      setIsLoading(false);
      throw err;
    }
  }
}
