import { styled } from '@parsec/styles';

import Icon from '../Icon';

const Rules = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'left',
  gap: '$medium',
  marginTop: '$medium'
});

const Rule = styled('li', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '$small',
  alignItems: 'center',
  borderRadius: '0.9rem',
  height: '1.8rem',
  lineHeight: '1.8rem',
  padding: '0 0.8rem 0 0.4rem',
  fontSize: '$info',
  fontWeight: 'bold',
  backgroundColor: '$pancham',
  color: '$rhyhorn',
  whiteSpace: 'nowrap',
  variants: {
    pass: {
      true: {
        backgroundColor: '$success500',
        color: '$success800'
      }
    }
  }
});

const IconWrapper = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '1.2rem',
  height: '1.2rem',
  variants: {
    pass: {
      true: {
        backgroundColor: '$success800'
      }
    }
  }
});

const IconSvg = styled(Icon, {
  width: '0.4rem',
  height: '0.4rem',
  color: '$rhyhorn',
  variants: {
    pass: {
      true: {
        width: '0.6rem',
        height: '0.6rem',
        color: '$success500'
      }
    }
  }
});

function RuleIcon(props: { pass: boolean }) {
  const { pass } = props;

  return (
    <IconWrapper pass={pass}>
      <IconSvg pass={pass} name={pass ? 'check' : 'circle'} />
    </IconWrapper>
  );
}

type PasswordErrors = {
  minLength: boolean;
  hasLetter: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
};

export const PasswordRules = ({
  errors
}: {
  errors: PasswordErrors | undefined;
}) => {
  const hasErrors = errors !== undefined;

  const checks = {
    minLength: hasErrors && !errors.minLength,
    hasLetter: hasErrors && !errors.hasLetter,
    hasNumber: hasErrors && !errors.hasNumber,
    hasSymbol: hasErrors && !errors.hasSymbol
  };

  return (
    <div>
      <Rules>
        <Rule pass={checks.minLength}>
          <RuleIcon pass={checks.minLength} />
          <span>12 characters</span>
        </Rule>
        <Rule pass={checks.hasLetter}>
          <RuleIcon pass={checks.hasLetter} />
          <span>Letter</span>
        </Rule>
        <Rule pass={checks.hasNumber}>
          <RuleIcon pass={checks.hasNumber} />
          <span>Number</span>
        </Rule>
        <Rule pass={checks.hasSymbol}>
          <RuleIcon pass={checks.hasSymbol} />
          <span>Symbol</span>
        </Rule>
      </Rules>
    </div>
  );
};
