import type { Validator } from 'narrows';

import { useState, useEffect } from 'react';

import { storageAvailable } from './utils/storageAvailable';
// Grabbed from https://www.joshwcomeau.com/snippets/react-hooks/use-sticky-state/

// NOT SSR SAFE
export function useStickyState<T>(
  defaultValue: T,
  key: string,
  validator: Validator<T>
) {
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') return defaultValue;
    if (storageAvailable('localStorage')) {
      const stickyValue = window.localStorage.getItem(key);
      try {
        // != for undefined and null values
        const parsedValue =
          stickyValue != null ? JSON.parse(stickyValue) : defaultValue;

        return validator(parsedValue) ? parsedValue : defaultValue;
      } catch (_err) {
        return defaultValue;
      }
    } else {
      console.warn('No access to localStorage.');
      return defaultValue;
    }
  });

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      if (window.localStorage.getItem(key) == null) {
        // set the defaultValue if the initial setState has already run (i.e. we have another alert)
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } else {
      console.warn('No access to localStorage.');
    }
  }, [defaultValue, key, value]);

  return [value, setValue] as const;
}
