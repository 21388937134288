import type { ApiOptions } from './api';

import * as accessLink from './accessLink';
import * as accessLinkCredit from './accessLinkCredit';
import * as accessLinkLedger from './accessLinkLedger';
import * as accessLinkPublicData from './accessLinkPublicData';
import * as account from './account';
import * as apiKey from './apiKey';
import * as appRule from './appRule';
import * as auth from './auth';
import * as changelogRss from './changelogRss';
import * as estimate from './estimate';
import * as host from './host';
import * as me from './me';
import * as scimApiKey from './scimApiKey';
import * as team from './team';
import * as teamBilling from './teamBilling';
import * as teamCapability from './teamCapability';
import * as teamDomain from './teamDomain';
import * as teamEvents from './teamEvents';
import * as teamGroup from './teamGroup';
import * as teamInvite from './teamInvite';
import * as teamInvoice from './teamInvoice';
import * as teamMachine from './teamMachine';
import * as teamMachineKey from './teamMachineKey';
import * as teamMember from './teamMember';
import * as teamNonCompliantUsers from './teamNonCompliantUsers';
import * as teamPurchase from './teamPurchase';
import * as teamRelay from './teamRelay';
import * as teamRole from './teamRole';
import * as teamRolePermissionSummary from './teamRolePermissionSummary';
import * as teamSaml from './teamSaml';
import * as teamSubscription from './teamSubscription';
import * as tfa from './tfa';
import * as verification from './verification';
import * as warpBilling from './warpBilling';
import * as warpSubscription from './warpSubscription';

interface Requests {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (options: ApiOptions) => (...args: any[]) => Promise<any>;
}

function factory<T extends Requests>(options: ApiOptions, reqs: T) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [K in keyof T]: ReturnType<T[K]> } = {} as any;

  for (const [key, fn] of Object.entries(reqs))
    result[key as keyof T] = fn(options) as ReturnType<T[keyof T]>;

  return result;
}

/**
 * Creates an object with Kessel API request methods
 * @param options API configuration options
 */
export function configure(options: ApiOptions) {
  return {
    accessLink: factory(options, accessLink),
    accessLinkCredit: factory(options, accessLinkCredit),
    accessLinkLedger: factory(options, accessLinkLedger),
    accessLinkPublicData: factory(options, accessLinkPublicData),
    account: factory(options, account),
    apiKey: factory(options, apiKey),
    appRule: factory(options, appRule),
    auth: factory(options, auth),
    changelogRss: factory(options, changelogRss),
    estimate: factory(options, estimate),
    host: factory(options, host),
    me: factory(options, me),
    scimApiKey: factory(options, scimApiKey),
    team: factory(options, team),
    teamBilling: factory(options, teamBilling),
    teamCapability: factory(options, teamCapability),
    teamDomain: factory(options, teamDomain),
    teamEvents: factory(options, teamEvents),
    teamGroup: factory(options, teamGroup),
    teamInvite: factory(options, teamInvite),
    teamInvoice: factory(options, teamInvoice),
    teamMachine: factory(options, teamMachine),
    teamMachineKey: factory(options, teamMachineKey),
    teamMember: factory(options, teamMember),
    teamPurchase: factory(options, teamPurchase),
    teamRelay: factory(options, teamRelay),
    teamRole: factory(options, teamRole),
    teamRolePermissionSummary: factory(options, teamRolePermissionSummary),
    teamSaml: factory(options, teamSaml),
    teamSubscription: factory(options, teamSubscription),
    teamNonCompliantUsers: factory(options, teamNonCompliantUsers),
    tfa: factory(options, tfa),
    warpBilling: factory(options, warpBilling),
    warpSubscription: factory(options, warpSubscription),
    verification: factory(options, verification)
  };
}

export type Kessel = ReturnType<typeof configure>;
