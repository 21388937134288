// @parsec
import { teamGroup } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('group_hosts');

// ******************************************************************************

/** Fetches all of a group's permitted hosts */
export function useGetAllGroupPermittedHosts(
  opts: Omit<teamGroup.GetTeamGroupPermittedHostsReq, 'team_id'>
) {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    ...opts,
    team_id: me.data?.team_id ?? ''
  };
  const enabled = vars.team_id !== '' && opts.group_id !== 0;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroupPermittedHosts(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team group permitted hosts."
  });
  return { ...result, error };
}

// ******************************************************************************

/** Fetches a group's display hosts */
export function useGetGroupDisplayHosts(
  opts: Omit<teamGroup.GetTeamGroupDisplayHostsReq, 'team_id'>
) {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = {
    ...opts,
    team_id: me.data?.team_id ?? ''
  };
  const enabled = vars.team_id !== '' && opts.group_id !== 0;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamGroup.getTeamGroupDisplayHosts(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team group display hosts."
  });
  return { ...result, error };
}
