import { styled } from '@parsec/components';

export const Badge = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  backgroundColor: '#FFAD11', // no token
  color: '#745008',
  borderRadius: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '0 $small',
  marginLeft: '$medium',
  height: '2rem'
});
