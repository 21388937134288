/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';

export function useDebounce<T extends any[]>(
  fn: (...args: T) => void,
  { ms = 250, leading = false } = {}
) {
  const timeout = useRef<number | null>(null) as any;
  return (...args: T) => {
    let called = false;
    if (timeout.current) clearTimeout(timeout.current);
    else if (leading) {
      called = true;
      fn(...args);
    }
    timeout.current = setTimeout(() => {
      timeout.current = null;
      if (!called) fn(...args);
    }, ms);
  };
}
