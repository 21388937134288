import * as teamRelay from '@parsec/kessel/src/teamRelay';

import { schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

// @parsec
const key = new KeyFactory('team_relay');

type ReqVars<T> = Omit<T, 'team_id'>;

export function useTeamRelaysData() {
  return useQueryData(key.all(), schema.teamRelayList);
}

/******************************************************************************
 * useGetTeamRelays fetches a list of team relays.
 ******************************************************************************/

interface GetTeamRelaysQueryProps {
  refetchInterval: number;
}

export function useGetTeamRelays(options?: GetTeamRelaysQueryProps) {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const vars = { team_id: teamId };

  const featureEnabled = teamQuery.data?.capabilities.features.is_relay_enabled;
  const featureFlagged = teamQuery.data?.capabilities.release_toggles.rt_hpr_v2;

  const enabled = Boolean(
    vars.team_id !== '' &&
      teamQuery.data?.is_active &&
      featureEnabled &&
      featureFlagged
  );

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamRelay.getTeamRelays(req);
      return res.body.data;
    },
    { enabled, refetchInterval: options?.refetchInterval ?? false }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get team relays'
  });
  return { ...result, error };
}

/******************************************************************************
 * useCreateTeamRelay creates a team relay.
 ******************************************************************************/

export function useCreateTeamRelay() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const teamRelayCache = useTeamRelaysData();

  async function mutationFn(reqBody: ReqVars<teamRelay.CreateTeamRelayReq>) {
    const res = await kessel.teamRelay.createTeamRelay({
      name: reqBody.name,
      team_id: teamId
    });
    return res.body?.data;
  }

  function onSuccess() {
    teamRelayCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'failed to create team relay.'
  });

  return { ...result, error };
}

/******************************************************************************
 * useUpdateTeamRelay updates a team relay.
 ******************************************************************************/

export function useUpdateTeamRelay() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const teamRelayCache = useTeamRelaysData();

  async function mutationFn(reqBody: ReqVars<teamRelay.UpdateTeamRelayReq>) {
    const res = await kessel.teamRelay.updateTeamRelay({
      team_id: teamId,
      ...reqBody
    });
    return res.body?.data;
  }

  function onSuccess() {
    teamRelayCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'failed to update team relay.'
  });

  return { ...result, error };
}

/******************************************************************************
 * useDeleteTeamRelay deletes a team relay.
 ******************************************************************************/

export function useDeleteTeamRelay() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const teamRelayCache = useTeamRelaysData();

  async function mutationFn(reqBody: ReqVars<teamRelay.DeleteTeamRelayReq>) {
    return await kessel.teamRelay.deleteTeamRelay({
      team_id: teamId,
      ...reqBody
    });
  }

  function onSuccess() {
    teamRelayCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: 'failed to delete team relay.'
  });

  return { ...result, error };
}
