// @parsec
import { schema, teamDomain } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useNonCompliantUsersData } from './teamNonCompliantUsers';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_domain');

export function useTeamDomainData() {
  return useQueryData(key.all(), schema.domainList);
}

/******************************************************************************
 * Create a record of a verified domain for a team.
 ******************************************************************************/

export function useCreateTeamDomain() {
  const kessel = useKessel();
  const cache = useTeamDomainData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn({
      domain
    }: Omit<teamDomain.CreateTeamDomainReq, 'team_id'>) {
      const res = await kessel.teamDomain.createTeamDomain({
        domain,
        team_id: teamId
      });
      return res.body.data;
    },
    {
      onSuccess: () => {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create new domain."
  });

  return { ...result, error };
}

/******************************************************************************
 * Get a list of a team's registered domains.
 ******************************************************************************/

export function useGetTeamDomains() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const vars = { team_id: teamId };
  const enabled = Boolean(vars.team_id !== '' && teamQuery.data?.is_active);

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamDomain.getTeamDomains(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Failed to get team's domains."
  });
  return { ...result, error };
}

/******************************************************************************
 * Remove a registered domain from a team.
 ******************************************************************************/

export function useDeleteTeamDomain() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const cache = useTeamDomainData();
  const nonCompliantUsersCache = useNonCompliantUsersData();
  const teamCache = useTeamData();

  const result = useMutation(
    async function mutationFn({
      domain_id
    }: Omit<teamDomain.DeleteTeamDomainReq, 'team_id'>) {
      return await kessel.teamDomain.deleteTeamDomain({
        domain_id,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        cache.invalidate();
        // When we delete a verified domain, the number of non-compliant users may decrease because there are fewer
        // domains to cross-reference with active users. Therefore, we need to refresh the list of non-compliant users.
        nonCompliantUsersCache.invalidate();
        // Similarly, if the last domain in the list is deleted, then the team setting for blocking consumer log ins
        // gets turned off because the team needs at least one domain to turn this setting on. Therefore, we need to
        // refresh the capabilities list for a given team.
        teamCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't delete domain."
  });

  return { ...result, error };
}

/******************************************************************************
 * Request to verify a registered domain.
 ******************************************************************************/

export function useVerifyTeamDomain() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const cache = useTeamDomainData();
  const nonCompliantUsersCache = useNonCompliantUsersData();

  const result = useMutation(
    async function mutationFn({
      domain_id
    }: Omit<teamDomain.VerifyTeamDomainReq, 'team_id'>) {
      return await kessel.teamDomain.verifyTeamDomain({
        domain_id,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        cache.invalidate();
        // When we delete a verified domain, the number of non-compliant users may decrease because there are fewer
        // domains to cross-reference with active users. Therefore, we need to refresh the list of non-compliant users.
        nonCompliantUsersCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't verify domain."
  });

  return { ...result, error };
}
