// libraries
import { useState, forwardRef, useCallback } from 'react';

// @parsec
import { styled } from '@parsec/styles';

import Input from '../Input';

const PasswordInputWrapper = styled('div', {
  position: 'relative'
});

const ShowButton = styled('button', {
  position: 'absolute',
  right: 0,
  top: 0,
  height: '100%',
  color: '$rhyhorn',
  textTransform: 'uppercase',
  fontSize: '$info',
  padding: '0 $large',
  cursor: 'pointer',

  '&:hover': {
    color: '$consoleWhite'
  }
});

const StyledInput = styled(Input, {
  '& > input': {
    // adds space for the show/hide password button
    paddingRight: '5rem'
  }
});

export interface PasswordInputToggledProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id?: string;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  identifier?: string;
  onToggleShow?: (isShowing: boolean) => void;
}

export const PasswordInputToggled = forwardRef<
  HTMLInputElement,
  PasswordInputToggledProps
>(
  (
    {
      id,
      name,
      placeholder = '************',
      autoComplete = 'off',
      onToggleShow = () => {},
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const toggleShowPassword = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        setShowPassword(prevState => {
          const isShowing = !prevState;
          onToggleShow(isShowing);
          return isShowing;
        });
      },
      [setShowPassword, onToggleShow]
    );

    return (
      <PasswordInputWrapper>
        <StyledInput
          ref={ref}
          id={id}
          name={name}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          autoComplete={autoComplete}
          {...props}
        />
        <ShowButton type="button" onClick={toggleShowPassword}>
          {showPassword ? 'Hide' : 'Show'}
        </ShowButton>
      </PasswordInputWrapper>
    );
  }
);
