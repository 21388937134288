import { ReactNode } from 'react';

import { IconButton, IconNames, styled } from '@parsec/components';

import { Content } from './Content';

export interface HeaderProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  menuHelp?: {
    icon: IconNames;
    title: string;
    onClick?(): void;
  };
}

export function Header(props: HeaderProps) {
  const { className, children, title } = props;

  return (
    <Wrapper className={className}>
      <Content header>
        <NameWrapper>
          <Name>{title ? title : <Placeholder width="wide" />}</Name>
          {!!props.menuHelp && (
            <StyledIconButton
              icon={props.menuHelp.icon}
              title={props.menuHelp.title}
              onClick={props.menuHelp?.onClick}
              css={{ color: '$rhyhorn' }}
            />
          )}
        </NameWrapper>
        {children && <div>{children}</div>}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  padding: '2.4rem 0',
  minHeight: '6rem',
  boxShadow: '0 0.1rem 0 $colors$pangoro',
  minWidth: '45rem'
});

const NameWrapper = styled('div', {
  stack: {
    gap: '$medium',
    hdistribute: 'start',
    valign: 'center'
  }
});

const Placeholder = styled('div', {
  display: 'inline-block',
  height: '60%',
  width: '20rem',
  borderRadius: '$small',
  background: '$duskull',
  variants: {
    width: {
      narrow: {
        width: '2rem'
      },
      wide: {
        width: '20rem'
      }
    }
  }
});

const Name = styled('h2', {
  fontFamily: '$newDefault',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  textTransform: 'capitalize',
  position: 'relative',
  lineHeight: '4rem',
  height: '4rem'
});

const StyledIconButton = styled(IconButton, {
  width: 'unset',
  height: 'unset',
  color: '$rhyhorn',
  transition: '0.25s all ease',
  '&:hover, &:focus': {
    color: '$dewgon'
  }
});
