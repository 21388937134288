import { ReactNode } from 'react';

import { styled } from '@parsec/components';

export interface ListMessageProps {
  children?: ReactNode;
}

export function ListMessage(props: ListMessageProps) {
  return (
    <ListMessageWrapper>
      <ListMessageP>{props.children}</ListMessageP>
    </ListMessageWrapper>
  );
}

const ListMessageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '52rem',
  paddingBottom: '5rem'
});

const ListMessageP = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  textAlign: 'center',
  padding: '4rem'
});
