import { schema, teamSubscription } from '@parsec/kessel';

import { useTeamEstimateData as useEstimateData } from './estimate';
import KeyFactory from './KeyFactory';
import { useTeamRolePermissionSummaryData } from './permission';
import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_subscription');

export function useTeamSubscriptionData() {
  return useQueryData(key.all(), schema.teamSubscription);
}

/** ****************************************************************************/

export function useGetTeamSubscription(options?: { enabled?: boolean }) {
  const kessel = useKessel();

  const teamQuery = useGetTeam({ ...options });

  const teamId = teamQuery.data?.id ?? '';
  const vars = { team_id: teamId };

  const enabled = (options?.enabled ?? true) && Boolean(vars.team_id);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamSubscription.getTeamSubscription(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Team subscription."
  });

  return { ...result, error };
}

/******************************************************************************/

export function useUpdateTeamSubscription() {
  const kessel = useKessel();
  const cache = useTeamSubscriptionData();
  const teamCache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function (
      vars: Omit<teamSubscription.UpdateTeamSubscriptionReq, 'team_id'>
    ) {
      const res = await kessel.teamSubscription.updateTeamSubscription({
        team_id: teamId,
        ...vars
      });
      return res.body?.data;
    },
    {
      onSuccess() {
        cache.invalidate();
        teamCache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Warp subscription"
  });

  return { ...result, error };
}

/******************************************************************************/
export function useResumeTeamSubscription() {
  const kessel = useKessel();

  const cache = useTeamSubscriptionData();
  const teamCache = useTeamData();
  const permissionsCache = useTeamRolePermissionSummaryData();
  const estimateCache = useEstimateData();

  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn() {
      const res = await kessel.teamSubscription.resumeTeamSubscription({
        team_id: teamId
      });
      return res.body.data;
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
        permissionsCache.invalidate();
        teamCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't resume Team subscription."
  });
  return { ...result, error };
}
/******************************************************************************/

export function useCancelTeamSubscription() {
  const kessel = useKessel();
  const cache = useTeamSubscriptionData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function mutationFn() {
      await kessel.teamSubscription.cancelTeamSubscription({
        team_id: teamId
      });
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't cancel Team subscription."
  });

  return { ...result, error };
}

/******************************************************************************/

export function useRemoveScheduledTeamSubscriptionChanges() {
  const kessel = useKessel();
  const cache = useTeamSubscriptionData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function mutationFn() {
      await kessel.teamSubscription.removeScheduledTeamSubChanges({
        team_id: teamId
      });
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't remove scheduled team subscription changes."
  });

  return { ...result, error };
}
