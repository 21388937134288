import { useReducer, SyntheticEvent, ComponentPropsWithRef } from 'react';

import { styled } from '@parsec/styles';

const RoundedIconContainer = styled('div', {
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$aggron'
});

const LogoAvatar = styled('img', {
  width: '100%',
  height: '100%',
  transform: 'scale(0.8)'
});

const ImageAvatar = styled('img', {
  borderRadius: '100%',
  backgroundColor: '$consoleWhite'
});

export const DEFAULT_AVATAR_ALT = 'Default avatar image';
export const USER_AVATAR_ALT = 'User avatar image';

const LOGO_AVATAR =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNjQgMTAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMjQuOTM2OSAzNy4xOTYzTDE4LjUzNzggMzMuMjczN1Y2MC4xOTU3TDM3LjI3MTEgNzEuNTczNlY2NC4xOTEzTDI0LjkzNjkgNTYuNjg3M1YzNy4xOTYzWiIgZmlsbD0iIzdBN0E3QSIvPgo8cGF0aCBkPSJNNjMuOTkxIDM3LjQ4ODdDNjMuOTkxIDM3LjQ4ODcgNjQuMTM3NiAzNC40NDMyIDYyLjgxODcgMzEuNjY1N0M2MS4zMjg4IDI4LjU0NzIgNTkuMzI2IDI2Ljg5MDQgNTcuMTI3OSAyNS41NTA0QzU0LjkyOTcgMjQuMjM0OCAxOS4wNzUyIDIuMTYxMjIgMTkuMDc1MiAyLjE2MTIyQzE5LjA3NTIgMi4xNjEyMiAxNS44NzU2IC0wLjM0ODI1MSAxMi4wNjU1IDEuMjg0MTJDMTAuNiAxLjkxNzU4IDkuNjcxOTMgMi44NDM0IDkuMDg1NzUgMy44MTc5NUMxMC41NzU2IDQuMjU2NSAxMS42MDE0IDQuOTM4NjkgMTEuOTE4OSA1LjE4MjMyQzEzLjcyNjMgNi4zMDMwNiA0Ny43NzM1IDI3LjI1NTkgNDkuODk4NCAyOC41NDcyQzUyLjA3MjEgMjkuODYyOCA1NC41MTQ1IDMxLjcxNDUgNTYuMjczIDM1LjQxNzdDNTcuNjg5NiAzOC4zOTAxIDU3LjYxNjQgNDEuNTgxOCA1Ny42MTY0IDQyLjA0NDdWOTIuOTY0OUM1Ny42MTY0IDkzLjc2OSA1Ny41MTg3IDk0LjU0ODYgNTcuMzQ3NyA5NS4yNzk1QzU4LjI1MTQgOTUuMjc5NSA1OS4yMDM5IDk1LjEzMzMgNjAuMTgwOSA5NC42NzA0QzY0LjEzNzYgOTIuNzcgNjMuOTY2NiA4OC45MjA2IDYzLjk2NjYgODguNDA4OVYzNy40ODg3SDYzLjk5MVoiIGZpbGw9IiM3QTdBN0EiLz4KPHBhdGggZD0iTTAgNjcuMDE3NUMwLjAyNDQyNDEgNjcuODQ1OSAwLjEyMjEyIDY5LjAxNTQgMC40Mzk2MzMgNjkuNjI0NUMwLjk3Njk2MiA3MC42OTY1IDEuMTk2NzggNzEuNTczNiAzLjkzMjI3IDczLjI1NDdDNi42Njc3NyA3NC45MzU4IDQ0LjE4MzEgOTcuOTgzOSA0NC4xODMxIDk3Ljk4MzlDNDQuMTgzMSA5Ny45ODM5IDQ4LjExNTQgMTAxLjA1NCA1Mi4wNDc3IDk5LjE1MzNDNTYuMDA0NCA5Ny4yNTMgNTUuODMzNCA5My40MDM1IDU1LjgzMzQgOTIuODkxOVY0MS45OTZDNTUuODMzNCA0MS45OTYgNTUuOTc5OSAzOC45NTA1IDU0LjY2MSAzNi4xNzNDNTMuMTcxMiAzMy4wNTQ1IDUxLjE2ODQgMzEuMzk3NyA0OC45NzAyIDMwLjA1NzdDNDYuNzcyMSAyOC43MTc3IDEwLjkxNzYgNi42NDQxNSAxMC45MTc2IDYuNjQ0MTVDMTAuOTE3NiA2LjY0NDE1IDcuNzE4IDQuMTM0NjggMy45MDc4NSA1Ljc2NzA1QzAuMTIyMTIgNy40MjM3OSAwIDExLjA3ODQgMCAxMi4wNTI5VjY3LjA0MTlWNjcuMDE3NVpNMTYuNzMwNSAzMC4wNTc3TDM5LjA1NDEgNDMuNzI1OFY3NC43MTY1TDE2LjczMDUgNjEuMTk0NlYzMC4wNTc3WiIgZmlsbD0iIzdBN0E3QSIvPgo8L3N2Zz4K';

const getAvatarURL = (id: number, size: number, nonce?: string) => {
  const base = `https://parsecusercontent.com/cdn-cgi/image/w=${size},h=${size},fit=crop,background=white,q=90,f=jpeg/avatars/${id}/avatar`;

  return nonce ? `${base}?nonce=${nonce}` : base;
};

interface ReducerStates {
  state: 'loading' | 'success' | 'failed';
}

type ReducerAction = { type: 'success' } | { type: 'failure' };

const reducer: React.Reducer<ReducerStates, ReducerAction> = (
  prevState,
  action
) => {
  switch (action.type) {
    case 'success':
      return { ...prevState, state: 'success' };
    case 'failure':
      return { ...prevState, state: 'failed' };
  }
};

type ImgProps = ComponentPropsWithRef<typeof ImageAvatar>;

export interface AvatarProps extends Omit<ImgProps, 'src' | 'alt'> {
  className?: string;
  userId?: number;
  size?: number;
  nonce?: string;
}

export default function Avatar({
  className,
  userId,
  nonce,
  size = 36,
  onError,
  ...rest
}: AvatarProps) {
  const [avatarImage, dispatch] = useReducer(reducer, { state: 'loading' });

  const isDefault =
    avatarImage.state === 'loading' || avatarImage.state === 'failed';

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    dispatch({ type: 'failure' });

    if (onError) {
      onError(e);
    }
  };

  const handleOnLoad = () => dispatch({ type: 'success' });

  return (
    <div className={className}>
      {isDefault ? (
        <RoundedIconContainer css={{ width: size, height: size }}>
          <LogoAvatar src={LOGO_AVATAR} alt={DEFAULT_AVATAR_ALT} />
        </RoundedIconContainer>
      ) : null}
      {userId ? (
        <ImageAvatar
          width={size}
          height={size}
          css={{
            display: avatarImage.state === 'success' ? 'inherit' : 'none'
          }}
          src={getAvatarURL(userId, size, nonce)}
          onLoad={handleOnLoad}
          onError={handleError}
          alt={USER_AVATAR_ALT}
          {...rest}
        />
      ) : null}
    </div>
  );
}
