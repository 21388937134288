import * as n from 'narrows';

// @parsec
import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, user, session } from './schema';

export const getMe = (options: ApiOptions) => () =>
  request({
    type: Method.GET,
    url: `${options.kessel}/me`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware,
    validator: data(user)
  });

export interface UpdateMeReq {
  name: string;
}

export const updateMe = (options: ApiOptions) => (body: UpdateMeReq) =>
  request({
    type: Method.PUT,
    url: `${options.kessel}/me`,
    headers: { Authorization: `Bearer ${options.token()}` },
    body
  });

export interface UpdateMarketingAttributionReq {
  marketing_opt_in: boolean; // boolean only since we're changing it from null
}

export const updateMarketingAttribution =
  (options: ApiOptions) => (body: UpdateMarketingAttributionReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/me/marketing-attribution`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export interface UpdateMarketingAttributionDataReq {
  attribution_data: {
    [keys: string]: Record<string, string> | string;
  };
}

export const updateMarketingAttributionData =
  (options: ApiOptions) => (body: UpdateMarketingAttributionDataReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/me/marketing-attribution`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export interface EmailChangeReq {
  email: string;
  password: string;
  tfa?: string;
  captcha_token: string;
}

// change email from account page
export const emailChange = (options: ApiOptions) => (body: EmailChangeReq) =>
  request({
    type: Method.POST,
    url: `${options.kessel}/me/email-change`,
    headers: { Authorization: `Bearer ${options.token()}` },
    body
  });

export interface ConfirmEmailChangeReq {
  new_email: string;
  secret: string;
  captcha_token: string;
}

// after email verification of change
export const confirmEmailChange =
  (options: ApiOptions) => (body: ConfirmEmailChangeReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/me/email-change/confirmation`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export interface UpdatePasswordReq {
  password: string;
  new_password: string;
  tfa?: string;
}

export const updatePassword =
  (options: ApiOptions) => (body: UpdatePasswordReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/me/password`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export const deleteAccount = (options: ApiOptions) => () =>
  request({
    type: Method.DELETE,
    url: `${options.kessel}/me`,
    headers: { Authorization: `Bearer ${options.token()}` },
    middleware: options.middleware
  });

// Update Avatar

export interface UpdateAvatarReq {
  image: File;
}

export const updateAvatar =
  (options: ApiOptions) =>
  async ({ image }: UpdateAvatarReq) => {
    // get pre-signed URL
    const res = await request({
      type: Method.PUT,
      url: `${options.kessel}/me/avatar`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body: { content_length: image.size, content_type: image.type },
      validator: data(n.string)
    });

    // upload image to pre-signed URL
    return fetch(res.body.data, {
      method: 'PUT',
      body: image,
      headers: {
        'Content-Length': `${image.size}`,
        'Content-Type': image.type,
        'x-amz-acl': 'public-read'
      }
    });
  };

export const removeAvatar = (options: ApiOptions) => () =>
  request({
    type: Method.DELETE,
    url: `${options.kessel}/me/avatar`,
    headers: { Authorization: `Bearer ${options.token()}` }
  });

export interface ResendAccountConfirmationEmailReq {
  captcha_token: string;
}

export const resendAccountConfirmationEmail =
  (options: ApiOptions) => (body: ResendAccountConfirmationEmailReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/me/send-confirmation-email`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body
    });

export interface ConfirmEmailCodeReq {
  email: string;
  secret: string;
  captcha_token: string;
}

export const confirmEmailCode =
  (options: ApiOptions) => (body: ConfirmEmailCodeReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/public/user-signup-confirmation`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body,
      validator: data(session)
    });
