// @parsec
import { teamCapability } from '@parsec/kessel';

import { useKessel } from './Provider';
import { useGetTeam, useTeamData } from './team';
import { useMutation } from './useMutation';
import { useWrapError } from './useWrapError';

/******************************************************************************
 * Update team capability. NOTE: only used to update SCIM usage flag as of yet
 ******************************************************************************/

export function useUpdateTeamCapabilityScim() {
  const kessel = useKessel();
  const teamCache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn({
      is_scim_enabled
    }: Omit<teamCapability.UpdateTeamCapabilitySCIMReq, 'team_id'>) {
      return await kessel.teamCapability.updateTeamCapabilitySCIM({
        is_scim_enabled,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        teamCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update SCIM capability."
  });

  return { ...result, error };
}

/******************************************************************************
 * Update team capability. NOTE: only used to update SAML Domain flag
 ******************************************************************************/
export function useUpdateTeamCapabilityNewSamlDomain() {
  const kessel = useKessel();
  const teamCache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn({
      is_new_saml_domain_enabled
    }: Omit<teamCapability.UpdateTeamCapabilitySamlDomainReq, 'team_id'>) {
      return await kessel.teamCapability.updateTeamCapabilitySamlDomain({
        is_new_saml_domain_enabled,
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        teamCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update new SAML domain."
  });

  return { ...result, error };
}
