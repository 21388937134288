import { schema, accessLink } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('access_link');

export function useAccessLinkData() {
  return useQueryData(key.all(), schema.paginatedAccessLinkData);
}

/******************************************************************************
 * Get a paginated list of your access links
 ******************************************************************************/

export function useGetMyAccessLinks() {
  const kessel = useKessel();
  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const vars = {
    team_id: teamId,
    offset: 0,
    limit: 200
  };
  const enabled = vars.team_id !== '';

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.accessLink.getMyAccessLinks(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get your access links.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Get an access link by ID
 ******************************************************************************/

export function useGetAccessLink(
  opts: Omit<accessLink.GetAccessLinkReq, 'team_id'>
) {
  const kessel = useKessel();
  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const vars = { ...opts, team_id: teamId };
  const enabled = vars.team_id !== '';

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.accessLink.getAccessLink(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to get access link.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Create an access link for one or more emails
 ******************************************************************************/

type CreateAccessLinkVars = Omit<accessLink.CreateAccessLinkReq, 'team_id'>;

export function useCreateAccessLink() {
  const kessel = useKessel();
  const accessLinksCache = useAccessLinkData();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: CreateAccessLinkVars) {
      return kessel.accessLink.createAccessLink({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess: () => accessLinksCache.invalidate() }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to create access link.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Update an access link
 ******************************************************************************/

type UpdateAccessLinkVars = Omit<accessLink.UpdateAccessLinkReq, 'team_id'>;

export function useUpdateAccessLink() {
  const kessel = useKessel();
  const accessLinksCache = useAccessLinkData();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: UpdateAccessLinkVars) {
      return kessel.accessLink.updateAccessLink({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess: () => accessLinksCache.invalidate() }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to update access link.'
  });
  return { ...result, error };
}

/******************************************************************************
 * Delete an access link
 ******************************************************************************/

type DeleteAccessLinkVars = Omit<accessLink.DeleteAccessLinkReq, 'team_id'>;

export function useDeleteAccessLink() {
  const kessel = useKessel();
  const accessLinksCache = useAccessLinkData();

  const me = useGetMe();
  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(req: DeleteAccessLinkVars) {
      return kessel.accessLink.deleteAccessLink({
        ...req,
        team_id: teamId
      });
    },
    { onSuccess: () => accessLinksCache.invalidate() }
  );

  const error = useWrapError(result.error, {
    error: 'Failed to delete access link.'
  });
  return { ...result, error };
}
