// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

/**
 * Use this to test if we can use local or session storage
 * @param type localStorage or sessionStorage
 * @returns
 */
export function storageAvailable(type: 'localStorage' | 'sessionStorage') {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export const priceFormatter = (options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options
  });

/**
 * Returns the formatted USD dollar amount of cents using the priceFormatter
 * @param {Number} cents - USD cent amount
 * @returns formatted price in dollars
 */
export const priceFormatCents = (
  cents: number,
  options?: Intl.NumberFormatOptions
) => {
  const dollarAmt = cents / 100; // 100 cents per dollar
  return priceFormatter(options).format(dollarAmt);
};
