import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, publicTeamInvite, PaginatedTeamInviteData } from './schema';

/******************************************************************************
 * Get TeamInvite
 ******************************************************************************/

export interface GetTeamInvitesReq {
  team_id: string;
  offset: number;
  limit: number;
  email?: string;
}
export const getTeamInvites =
  (options: ApiOptions) =>
  /**
   * Gets a paginated list of TeamInvites for a Team.
   */
  (req: GetTeamInvitesReq) => {
    const { team_id, offset, limit, email } = req;

    let params = `offset=${offset}&limit=${limit}`;
    if (email) params += `&email=${encodeURIComponent(email)}`;

    return request<PaginatedTeamInviteData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/invites?${params}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Create TeamInvite
 ******************************************************************************/

export interface CreateTeamInvitesReq {
  team_id: string;
  emails: string[];
  group_ids?: number[];
  team_app_rule_id?: string;
  captcha_token: string;
}
export const createTeamInvite =
  (options: ApiOptions) =>
  /**
   * Create a new TeamInvite for a Team.
   */
  (req: CreateTeamInvitesReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/invites`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Resend TeamInvite
 ******************************************************************************/

export interface ResendTeamInvitesReq {
  team_id: string;
  emails: string[];
  captcha_token: string;
}
export const resendTeamInvite =
  (options: ApiOptions) =>
  /**
   * Resend a new TeamInvite for a Team.
   */
  (req: ResendTeamInvitesReq) => {
    const { team_id, emails, captcha_token } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/resend-invites`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { emails, captcha_token }
    });
  };

/******************************************************************************
 * Update TeamInvite
 ******************************************************************************/

export interface UpdateTeamInvitesReq {
  team_id: string;
  emails: string[];
  group_ids?: number[];
  team_app_rule_id?: string;
}
export const updateTeamInvite =
  (options: ApiOptions) =>
  /**
   * Update a TeamInvite for a Team.
   */
  (req: UpdateTeamInvitesReq) => {
    const { team_id, emails, group_ids, team_app_rule_id } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/invites`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { emails, group_ids, team_app_rule_id }
    });
  };

/******************************************************************************
 * Cancel TeamInvite
 ******************************************************************************/

export interface CancelTeamInvitesReq {
  team_id: string;
  emails: string[];
}
export const cancelTeamInvite =
  (options: ApiOptions) =>
  /**
   * Cancel a new TeamInvite for a Team.
   */
  (req: CancelTeamInvitesReq) => {
    const { team_id, emails } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/invites`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { emails }
    });
  };

/******************************************************************************
 * Get Public Team Invite
 ******************************************************************************/

export interface GetPublicTeamInviteReq {
  teamId: string;
  hash: string;
  email: string;
}

export const getPublicTeamInvite =
  (options: ApiOptions) =>
  async ({ teamId, hash, email }: GetPublicTeamInviteReq) => {
    return request({
      type: Method.PUT,
      url: `${options.kessel}/public/teams/${teamId}/invites`,
      headers: { Authorization: `Bearer ${options.token()}` },
      body: { hash, email },
      middleware: options.middleware,
      validator: data(publicTeamInvite)
    });
  };
