import { Identify as Event } from '@amplitude/analytics-browser';

import AmplitudeClient from './AmplitudeClient';

interface IdentifyProps {
  userId?: number;
  userEmail?: string;
  teamId?: string;
  teamSubscription?:
    | 'team-monthly'
    | 'team-yearly'
    | 'team-enterprise-monthly'
    | 'team-enterprise-yearly';
  warpSubscription?: 'warp-monthly' | 'warp-yearly';
  marketingOptIn?: boolean;
  signedUp?: boolean; // User signed up during the session
}

export default function Identify(props: IdentifyProps) {
  const {
    userId: baseUserId,
    teamId,
    teamSubscription,
    warpSubscription,
    marketingOptIn,
    signedUp,
    userEmail
  } = props;

  const event = new Event();
  const client = AmplitudeClient();

  // userId must be at least five characters long
  const userId = baseUserId?.toString().padStart(5, '0');

  if (userId) {
    client.setUserId(userId);
    event.set('user_logged_in', 'yes');
  }
  if (userEmail) {
    const emailDomain = userEmail.substring(userEmail.lastIndexOf('@') + 1);
    event.set('user_email_domain', emailDomain);
  }

  if (teamId) event.set('user_org_id', teamId);

  if (marketingOptIn !== undefined)
    event.set('user_is_newsletter_subscribed', marketingOptIn ? 'yes' : 'no');

  if (signedUp !== undefined)
    event.set('user_is_new_customer', signedUp ? 'yes' : 'no');

  switch (teamSubscription) {
    case 'team-monthly':
      event.postInsert('user_subscription_type', 'parsec teams monthly');
      break;
    case 'team-yearly':
      event.postInsert('user_subscription_type', 'parsec teams annual');
      break;
    case 'team-enterprise-monthly':
      event.postInsert('user_subscription_type', 'parsec enterprise monthly');
      break;
    case 'team-enterprise-yearly':
      event.postInsert('user_subscription_type', 'parsec enterprise annual');
      break;
  }

  switch (warpSubscription) {
    case 'warp-monthly':
      event.postInsert('user_subscription_type', 'parsec warp monthly');
      break;
    case 'warp-yearly':
      event.postInsert('user_subscription_type', 'parsec warp annual');
      break;
  }

  client.identify(event);
}
