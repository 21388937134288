import * as n from 'narrows';

import { getPlatform } from '@parsec/platform';
import { captureException } from '@parsec/sentry';

import * as errors from './errors';

export enum Status {
  Idle,
  Pending,
  Success,
  Failure
}

export interface ErrorCode {
  type: (typeof errors)[keyof typeof errors];
  values?: string[];
}

export interface IdleRes {
  type: Status.Idle;
}

export interface PendingRes {
  type: Status.Pending;
}

export interface SuccessRes<T> {
  type: Status.Success;
  status: number;
  body: T;
}

export interface FailureRes<E> {
  type: Status.Failure;
  status: number;
  error: string;
  body?: E;
  codes: ErrorCode[];
}

export type Res<T = unknown, E = { error?: string }> =
  | IdleRes
  | PendingRes
  | SuccessRes<T>
  | FailureRes<E>;

export enum Method {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export type Req<T> =
  | {
      type: Method.GET;
      url: string;
      headers?: { [key: string]: string };
      validator?: n.Validator<T>;
      middleware?(res: Response): void;
      includePlatform?: boolean;
    }
  | {
      type: Method.POST | Method.PUT | Method.PATCH | Method.DELETE;
      url: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body?: any;
      headers?: { [key: string]: string };
      validator?: n.Validator<T>;
      middleware?(res: Response): void;
      includePlatform?: boolean;
    };

export async function request<T, E = { error?: string }>(
  req: Req<T>
): Promise<SuccessRes<T>> {
  const { validator = () => true, middleware, includePlatform = true } = req;
  const platform = getPlatform();

  let res: Response | null = null;
  let body: unknown;

  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      ...req.headers
    };

    if (platform && includePlatform) headers['X-Parsec-OS'] = platform;

    // Make request
    res = await fetch(req.url, {
      method: req.type,
      headers,
      body:
        req.type !== Method.GET
          ? headers['Content-Type'] === 'application/json'
            ? JSON.stringify(req.body)
            : req.body
          : undefined
    });

    middleware?.(res);

    const pathname = new URL(req.url).pathname;

    let rawBody = '';
    if (res.status !== 204) {
      try {
        rawBody = await res.text();
        if (res.headers.get('Content-Type')?.includes('xml')) {
          body = { data: { body: rawBody } };
        } else {
          body = JSON.parse(rawBody);
        }
      } catch (err) {
        console.error(err);
        captureException(err, {
          tags: { path: pathname, requestErrorType: 'invalid_json' },
          contexts: { res: { rawBody } }
        });

        throw new Error('Request failed: Invalid JSON');
      }
    }

    // Failed
    if (!res.ok || res.status >= 400) {
      const message = hasError(body) ? body.error : '';
      throw new Error(message || 'Request failed');
    }

    if (!validator(body)) {
      const invalid = n.report(validator, body) || [];
      const invalidPropertyPath = invalid.join('.') ?? '';

      const err = new Error(
        `${req.type} ${pathname}: Invalid response body at ${invalidPropertyPath}`
      );
      captureException(err, {
        tags: { pathname, requestErrorType: 'validator', invalidPropertyPath },
        contexts: { res: { body, ok: res.ok, status: res.status } }
      });

      throw err;
    }

    return {
      type: Status.Success,
      status: res.status,
      body: body as T
    };
  } catch (err) {
    throw {
      type: Status.Failure,
      status: res?.status ?? -1,
      error: err instanceof Error ? err.message : 'Request failed.',
      body: body as E
    };
  }
}

const validateCodes = n.array(
  n.record({
    type: n.any(...Object.values(errors).map(code => n.literal(code))),
    values: n.optional(n.array(n.string))
  })
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseError<T = any>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any,
  defaults: Partial<FailureRes<T>> = {}
): FailureRes<T> {
  const { status = -1, error = 'Request failed.', body, codes = [] } = defaults;

  return {
    type: Status.Failure,
    status: typeof err?.status === 'number' ? err.status : status,
    error: typeof err?.error === 'string' ? err.error : error,
    body: err?.body ?? body,
    codes: validateCodes(err?.body?.codes)
      ? err.body.codes
      : [...codes, { type: errors.ERR_CODE_INVALID }]
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasError(body: any): body is { error: string } {
  return Boolean(body) && 'error' in body && typeof body.error === 'string';
}
