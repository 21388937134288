import {
  useMutation as useReactQueryMutation,
  MutationFunction,
  UseMutationOptions
} from 'react-query';

import { parseError } from '@parsec/request';

import { useLifecycle } from './Provider';

export function useMutation<Data = unknown, Vars = void, Context = unknown>(
  fn: MutationFunction<Data, Vars>,
  options: UseMutationOptions<Data, unknown, Vars, Context> = {}
) {
  const { onInvalidSession } = useLifecycle();

  return useReactQueryMutation(fn, {
    ...options,
    onError(err, vars, context) {
      const { codes } = parseError(err);
      if (codes.some(code => code.type === 'session_incorrect')) {
        onInvalidSession();
      }

      options.onError?.(err, vars, context);
    }
  });
}
