import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { accessLinkMetaData } from './schema';

// Get Access Link metadata
export interface GetAccessLinkReq {
  accessLinkId: string;
}

export const getAccessLinkPublicData =
  (options: ApiOptions) =>
  /**
   * Gets an AccessLink by ID
   */
  ({ accessLinkId }: GetAccessLinkReq) => {
    return request({
      type: Method.GET,
      url: `${options.kessel}/public/access-links/${accessLinkId}`,
      validator: accessLinkMetaData,
      middleware: options.middleware
    });
  };
