import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { data, customer, card } from './schema';

/******************************************************************************
 * Get Team Billing Details
 ******************************************************************************/

export interface GetTeamBillingDetailsReq {
  team_id: string;
}

export const getTeamBillingDetails =
  (options: ApiOptions) =>
  ({ team_id }: GetTeamBillingDetailsReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/billing`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(customer)
    });

/******************************************************************************
 * Update Team Billing Details
 ******************************************************************************/

export interface UpdateTeamBillingDetailsReq {
  team_id: string;
  first_name?: string;
  last_name?: string;
  email: string;
  phone?: string;
  company?: string;
  tax_id?: string;
  billing_address: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
  };
}

export const updateTeamBillingDetails =
  (options: ApiOptions) =>
  ({ team_id, ...body }: UpdateTeamBillingDetailsReq) =>
    request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/billing`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(customer),
      body
    });

/******************************************************************************
 * Get Team Billing Card
 ******************************************************************************/

export interface GetTeamBillingCardReq {
  team_id: string;
}

export const getTeamBillingCard =
  (options: ApiOptions) =>
  ({ team_id }: GetTeamBillingCardReq) =>
    request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/billing/card`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(card)
    });

/******************************************************************************
 * Create Team Billing Card
 ******************************************************************************/

export interface CreateTeamBillingCardReq {
  team_id: string;
  token: string;
}

export const createTeamBillingCard =
  (options: ApiOptions) =>
  ({ team_id, ...body }: CreateTeamBillingCardReq) =>
    request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/billing/card`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: data(card),
      body
    });
