import { useMutation, useQuery } from 'react-query';

import { card, teamBilling } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe, useMeData } from './me';
import { useKessel } from './Provider';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_billing_card');

export function useTeamBillingCardData() {
  return useQueryData(key.all(), card);
}

/** ****************************************************************************/

export function useGetTeamBillingCard() {
  const kessel = useKessel();
  const me = useGetMe();
  const vars = { team_id: me.data?.team_id ?? '' };
  const enabled = Boolean(me.data?.team_id);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamBilling.getTeamBillingCard(req);
      return res.body?.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get team billing details."
  });

  return { ...result, error };
}
/** ****************************************************************************/

export function useCreateTeamBillingCard() {
  const kessel = useKessel();
  const cache = useTeamBillingCardData();

  const meCache = useMeData();
  const me = meCache.get();
  const teamId = me?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(
      vars: Omit<teamBilling.CreateTeamBillingCardReq, 'team_id'>
    ) {
      const res = await kessel.teamBilling.createTeamBillingCard({
        ...vars,
        team_id: teamId
      });
      return res.body?.data;
    },
    {
      onSuccess() {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create team billing details"
  });
  return { ...result, error };
}
