// libraries
import {
  Component,
  type ReactNode,
  type ComponentType,
  type ErrorInfo
} from 'react';

// @parsec
import { captureException } from '@parsec/sentry';

import Fallback from '../Fallback';

export interface ErrorBoundaryProps {
  name: string;
  fallback?: ComponentType<{ id: string; error?: string }>;
  children: ReactNode | ReactNode[];
}

interface State {
  id: string;
  error: string;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  State
> {
  state = { id: '', error: '' };

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { componentStack } = info;
    console.error(componentStack);

    const id = captureException(error, {
      contexts: { react: { componentStack } }
    });

    this.setState(() => ({ id, error: error.message }));
  }

  render() {
    const { children, fallback: FallbackComponent = Fallback } = this.props;
    const { id, error } = this.state;

    if (error) return <FallbackComponent id={id} error={error} />;
    return children;
  }
}
