// @parsec
import { useRef } from 'react';

import { schema, Invoice, teamInvoice } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('invoices');

export function useInvoicesData() {
  return useQueryData(key.all(), schema.invoice);
}
/** ****************************************************************************/

export function useGetTeamInvoices() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const vars = { team_id: teamId };

  const enabled = teamId !== '';

  const varsRef = useRef(vars);
  const keepPreviousData = varsRef.current.team_id === vars.team_id;
  varsRef.current = vars;

  const result = useQuery(
    key.list(vars),
    async function queryFn() {
      /**
       * Because Infinite Query does not give us the total number of invoices,
       * it doesn't work well with our pagination. So we are looping a regular query
       * to get a comprehensive list of invoices instead.
       */
      let invoices: Invoice[] = [];
      let cursor = '';
      let maxRequests = 100;
      const limit = 50;

      while (true) {
        const res = await kessel.teamInvoice.getTeamInvoices({
          cursor,
          limit,
          team_id: teamId
        });
        invoices = invoices.concat(res.body.data?.invoices);
        cursor = res.body.data?.cursor;
        if (!res.body.data?.cursor || --maxRequests <= 0) break;
      }

      return { data: invoices, count: invoices.length };
    },
    { enabled, keepPreviousData }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get team invoices."
  });

  return { ...result, error };
}

/******************************************************************************
 * Download Invoice as PDF
 ******************************************************************************/

export function useCreateTeamInvoicePdf() {
  const kessel = useKessel();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(
    vars: Omit<teamInvoice.CreateTeamInvoicePdfReq, 'team_id'>
  ) {
    return await kessel.teamInvoice.createTeamInvoicePdf({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess(res: { body: { data: { download_url: string } } }) {
    location.assign(res.body.data.download_url);
  }

  return useMutation(mutationFn, { onSuccess });
}

/******************************************************************************
 * Update invoice PO Number
 ******************************************************************************/

export function useUpdateInvoicePoNumber() {
  const kessel = useKessel();
  const cache = useInvoicesData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  const result = useMutation(
    async function mutationFn(
      vars: Omit<teamInvoice.UpdateInvoicePoNumberReq, 'team_id'>
    ) {
      const res = await kessel.teamInvoice.updateInvoicePoNumber({
        ...vars,
        team_id: teamId
      });

      return res;
    },
    {
      onSuccess() {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update invoice PO number."
  });

  return { ...result, error };
}
