import { useEffect, useState } from 'react';

import { useGetWarpSubscription } from '@parsec/queries';

export function useWarpTrialDate() {
  const subscription = useGetWarpSubscription();

  const [data, setData] = useState<{
    daysLeft: number;
    endDate: Date;
  }>();

  useEffect(() => {
    if (
      subscription.data?.trial_end &&
      subscription.data.status === 'in_trial'
    ) {
      const endDate = new Date(subscription.data.trial_end);

      const countDownDate = new Date(endDate).getTime();
      const now = new Date().getTime();
      const timeleft = countDownDate - now;
      const daysLeft = Math.ceil(timeleft / (1000 * 60 * 60 * 24));

      setData({ daysLeft: daysLeft > 0 ? daysLeft : 0, endDate: endDate });
    } else {
      //resets if looking in the moment of conversion. edge case.
      setData(undefined);
    }
  }, [subscription.data?.trial_end, subscription.data?.status]);

  return data;
}
