import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

export interface NoticeProps {
  children: ReactNode;
}

export default function Notice(props: NoticeProps) {
  const { children, ...rest } = props;
  return (
    <Wrapper role="status" {...rest}>
      <Icon name="hourglass" title="Close" />
      <Title>Notice</Title>
      <Message>{children}</Message>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '$medium 1rem',
  gap: '.8rem',
  width: '94rem',
  border: '$space$xxsmall solid $warning500',
  color: '$warning500',
  borderRadius: '.4rem',
  overflow: 'hidden',
  backgroundColor: '$cereza',
  minHeight: '32px',
  fontSize: '$info'
});

const Title = styled('div', {
  fontWeight: '$bold'
});

const Message = styled('div', {
  color: '$consoleWhite',
  width: '100%'
});
