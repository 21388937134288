import { useEffect, useState } from 'react';

import { useCookie } from '@parsec/cookie';
import {
  useGetMe,
  useGetTeamSubscription,
  useGetWarpSubscription
} from '@parsec/queries';

import Identify from '../utils/Identify';

interface DataLayerProps {
  authEnabled?: boolean;
}

/** We shouldn't push to the dataLayer besides the first initialization from
 * the code */
const DataLayer = ({ authEnabled = true }: DataLayerProps) => {
  const { token } = useCookie();
  const userLoggedIn = Boolean(authEnabled && token);
  const me = useGetMe({ enabled: userLoggedIn });

  const teamSubscription = useGetTeamSubscription({
    enabled: Boolean(me.data?.team_id)
  });
  const warp = useGetWarpSubscription({ enabled: Boolean(me.data?.warp) });

  const [identifyFired, setIdentifiedFired] = useState(false);

  useEffect(() => {
    const meLoaded = Boolean(me.data);

    const teamSubscriptionLoaded =
      Boolean(teamSubscription.data) ||
      teamSubscription.isIdle ||
      // Error occurs if user does not have the correct permissions
      teamSubscription.isError;
    const warpLoaded = Boolean(warp.data) || warp.isIdle || warp.isError;

    if (!identifyFired && meLoaded && teamSubscriptionLoaded && warpLoaded) {
      Identify({
        userId: me.data?.id,
        teamId: me.data?.team_id,
        teamSubscription: teamSubscription.data?.plan_id,
        warpSubscription: warp.data?.plan_id,
        marketingOptIn: me.data?.marketing_opt_in ?? undefined,
        userEmail: me.data?.email
      });
      setIdentifiedFired(true);
    }
    // I believe we didn't want this to run if identifyFired changes TODO: ask Martin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, teamSubscription, warp]);

  return null;
};

export default DataLayer;
