import { useEffect, useState } from 'react';

import {
  styled,
  Avatar,
  Button,
  Flyout,
  Icon,
  IconButton
} from '@parsec/components';
import { useModals } from '@parsec/hooks';
import { TeamInvite } from '@parsec/kessel';
import * as time from '@parsec/time';

import { ConfirmModal } from './ConfirmModal';
import ResendTeamInviteModal from './ResendTeamInviteModal';

const version = 'newFont';

export interface TeamInviteListItemProps {
  className?: string;
  invite: TeamInvite;
  onResend?(values: {
    team_id: string;
    emails: string[];
    captcha_token: string;
  }): void;
  onCancel?(values: { team_id: string; emails: string[] }): void;
  onUpdate?(values: {
    team_id: string;
    emails: string[];
    group_id: number;
  }): void;
}

export function TeamInviteListItem(props: TeamInviteListItemProps) {
  const { className, invite, onResend, onCancel } = props;
  const [didResend, setDidResend] = useState(false);
  const isExpired = time.fromISO(invite.expires_at).getTime() < Date.now();

  const modals = useModals({
    confirm: ConfirmModal
  });

  useEffect(() => {
    if (!didResend) return;

    const timer = setTimeout(() => setDidResend(false), 8 * 1000);
    return () => clearTimeout(timer);
  }, [didResend]);

  const handleResendInvite = (token: string) => {
    if (!onResend) return;

    setDidResend(true);
    onResend({
      team_id: invite.team_id,
      emails: [invite.email],
      captcha_token: token
    });
  };

  return (
    <Wrapper className={className}>
      {modals.dom}
      <Avatar />

      <Info>
        <p>Invite {isExpired ? 'Expired' : 'Pending'}</p>
        <Email>{invite.email}</Email>
      </Info>

      <ResendTeamInviteModal onResend={opt => handleResendInvite(opt)}>
        <Button
          version={version}
          level="secondary"
          css={{ backgroundColor: 'transparent' }}
          disabled={didResend || !onResend}
        >
          <ButtonContent>
            <Icon name="send" css={{ width: '1.2rem', height: '1rem' }} />{' '}
            <span>{didResend ? 'Invite Resent!' : 'Resend Invite'}</span>
          </ButtonContent>
        </Button>
      </ResendTeamInviteModal>

      <Flyout
        items={[
          {
            text: 'Cancel Invite',
            disabled: !onCancel,
            onSelect() {
              modals.open('confirm', {
                title: 'Cancel Invite',
                onSubmit: () =>
                  onCancel?.({
                    team_id: invite.team_id,
                    emails: [invite.email]
                  }),
                children: `Are you sure you want to cancel your invite to ${invite.email}?`
              });
            }
          }
        ]}
      >
        {({ props }) => (
          <IconButton icon="ellipsis" title="more actions" {...props} />
        )}
      </Flyout>
    </Wrapper>
  );
}

const Wrapper = styled('li', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto auto auto',
  gridColumnGap: '$large',
  alignItems: 'center'
});

const ButtonContent = styled('span', {
  stack: {
    gap: '$medium',
    valign: 'center'
  },
  fontSize: '$newInfo',
  lineHeight: '$info'
});

const Info = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  lineHeight: '$info'
});

const Email = styled('p', {
  color: '$rhyhorn'
});
