// hooks
import { XMLParser, X2jOptions } from 'fast-xml-parser';

import KeyFactory from '../KeyFactory';
import { useKessel } from '../Provider';
import { useQuery } from '../useQuery';
import { useWrapError } from '../useWrapError';

const key = new KeyFactory('changelog');
const CHANGELOG_REFETCH_INTERVAL = 600000;

export interface FeedItem {
  category: string;
  guid: string;
  link: string;
  pubDate: string;
  title: string;
  description: string;
  build?: string;
  relatedBuild?: string;
  tags?: string[];
  releaseMessage?: string;
  changes: {
    title: string;
    content: string[] | { '#text': string; subcontent: string[] }[];
  }[];
}

const alwaysArray = new Set([
  'category',
  'tags',
  'changes',
  'content',
  'subcontent'
]);
const isArray: X2jOptions['isArray'] = tagName => alwaysArray.has(tagName);

const defaultXmlOptions = {
  ignoreAttributes: false,
  parseTagValue: false,
  trimValues: true, //default
  removeNSPrefix: true,
  isArray
};

const defaultOptions = {
  enabled: true,
  refetchInterval: CHANGELOG_REFETCH_INTERVAL
};

interface ChangelogQueryProps {
  enabled: boolean;
  refetchInterval: number;
  select?: (data: { allLogs: FeedItem[]; lastBuildDate: string }) => {
    allLogs: FeedItem[];
    lastBuildDate: string;
  };
}

export function useChangelogQuery(options?: ChangelogQueryProps) {
  if (typeof options === 'undefined') {
    options = { ...defaultOptions };
  }

  const { enabled, refetchInterval, select } = options;

  const kessel = useKessel();
  const parser = new XMLParser(defaultXmlOptions);

  const result = useQuery(
    key.all(),
    async function queryFn() {
      const res = await kessel.changelogRss.getRSS();
      const RssObj = parser.parse(res.body.data.body);
      const allLogs: FeedItem[] = RssObj.rss.channel.item;
      const lastBuildDate: string = RssObj.rss.channel.lastBuildDate;

      return {
        allLogs,
        lastBuildDate
      };
    },
    { enabled, refetchInterval, select }
  );

  const error = useWrapError(result.error, {
    error:
      "couldn't get changelog rss or couldn't transform changelog rss response."
  });

  return { ...result, error };
}
