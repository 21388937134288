import { ReactNode } from 'react';

import { IconButton, IconNames, styled } from '@parsec/components';

import { Title } from './Title';

interface SidebarLayoutProps {
  title: string;
  menuHelp?: {
    icon: IconNames;
    title: string;
    onClick?(): void;
  };
  sidebar: ReactNode;
  children: ReactNode;
}

export function SidebarLayout(props: SidebarLayoutProps) {
  return (
    <Columns>
      <InnerSidebar>
        <SidebarHeader>
          <Title>{props.title}</Title>
          {!!props.menuHelp && (
            <StyledIconButton
              icon={props.menuHelp.icon}
              title={props.menuHelp.title}
              onClick={() => props.menuHelp?.onClick?.()}
              css={{ color: '$rhyhorn' }}
            />
          )}
        </SidebarHeader>
        {props.sidebar}
      </InnerSidebar>
      <Main>{props.children}</Main>
    </Columns>
  );
}

const Columns = styled('div', {
  display: 'grid',
  gridTemplateColumns: '22rem auto',
  columnGap: '$xlarge',
  paddingTop: '$xxlarge'
});

const InnerSidebar = styled('aside', {
  alignContent: 'start',
  alignItems: 'stretch',
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '$medium',
  justifyContent: 'stretch',
  justifyItems: 'stretch'
});

const SidebarHeader = styled('header', {
  alignContent: 'stretch',
  alignItems: 'center',
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '$medium',
  justifyContent: 'start',
  justifyItems: 'stretch'
});

const StyledIconButton = styled(IconButton, {
  width: 'unset',
  height: 'unset',
  color: '$rhyhorn',
  transition: '0.25s all ease',
  '&:hover': {
    color: '$dewgon'
  }
});

const Main = styled('main', {
  alignContent: 'start',
  alignItems: 'stretch',
  display: 'grid',
  gridAutoFlow: 'row',
  justifyContent: 'stretch',
  justifyItems: 'stretch',
  rowGap: '1.4rem',
  position: 'relative',
  minWidth: '62rem',
  marginLeft: '$large'
});
