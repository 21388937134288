export enum TeamPlanId {
  Monthly = 'team-monthly',
  Yearly = 'team-yearly'
}

export enum WarpPlanId {
  Monthly = 'warp-monthly',
  Yearly = 'warp-yearly'
}

// TEAM_MIN_SEATS is the minimum number of seats a Team plan must have for the plan
// to be valid.
export const TEAM_MIN_SEATS = 5;
// TEAM_MAX_SEATS is the maximum number of seats a Team plan can have for the plan
// to be valid.
export const TEAM_MAX_SEATS = 9999;

// Minimum number seats needed for an enterprise plan
export const ENTERPRISE_MIN_SEATS = 20;

// ERRORS stores mappings between kessel and the website
export const ERRORS = {
  COUPON_ERROR: 'Requested resource is not found.',
  BILLING_ADDRESS:
    "We couldn't validate the billing address you entered. Please adjust it so we can try again."
};
