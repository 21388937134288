import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';

/******************************************************************************
 * Update team capability. NOTE: only used to update SCIM usage flag as of yet
 ******************************************************************************/

export interface UpdateTeamCapabilitySCIMReq {
  team_id: string;
  is_scim_enabled: boolean;
}

export const updateTeamCapabilitySCIM =
  (options: ApiOptions) =>
  /**
   * Update the SCIM flag for a team. NOTE: request is more specifically named, though the endpoint sounds more generic. Can rename once the endpoint actually updates more than SCIM flag
   */
  (req: UpdateTeamCapabilitySCIMReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/capabilities`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update team capability. NOTE: only used to update SAML domain flag
 ******************************************************************************/

export interface UpdateTeamCapabilitySamlDomainReq {
  team_id: string;
  is_new_saml_domain_enabled: boolean;
}

export const updateTeamCapabilitySamlDomain =
  (options: ApiOptions) => (req: UpdateTeamCapabilitySamlDomainReq) => {
    const { team_id, ...body } = req;
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/capabilities`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };
