import type { Group } from './types';

// libraries
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  ReactNode
} from 'react';

// @parsec
import { useClickOutside, useFocus, useMediaQuery } from '@parsec/hooks';
import { styled, media } from '@parsec/styles';

interface Props {
  children: ReactNode;
  groups: Group[];
  anchorRight?: boolean;
  open: boolean;
  onToggle(open: boolean): void;
}

export default function SubMenu(props: Props) {
  const { children, groups, open, anchorRight, onToggle } = props;

  const large = useMediaQuery(media.large);

  const focus = useFocus({
    onFocusChange(focused) {
      if (!focused) onToggle(false);
    }
  });

  // toggle the menu when it's clicked
  const toggle = useRef(onToggle);

  const handleSummaryClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      toggle.current(!open);
    },
    [open]
  );

  // if the menu is open, close it when the user clicks outside
  const details = useRef<HTMLDetailsElement>(null);
  useClickOutside(() => {
    if (open) toggle.current(false);
  }, details);

  // `detailsOpen` keeps the <details> element open while the exit animation finishes
  const [detailsOpen, setDetailsOpen] = useState(open);

  /** If the menu is closed, sets `detailsOpen` to false.
   * This function should be called on the `transitionend` event of the menu wrapper — that transition should trigger when `open` changes, so this function should only call `setDetailsOpen` when the exit animation finishes. */
  const closeDetails = useCallback(() => {
    if (!open) setDetailsOpen(false);
  }, [open]);

  // when the menu opens, this effect sets up the menu positioning and exit animations
  useEffect(() => {
    if (!open) return;

    // setting `detailsOpen` to `true` prepares it to keep the sub-menu open for the exit animation
    setDetailsOpen(true);
  }, [open]);

  return (
    <details ref={details} open={open || (large && detailsOpen)} {...focus}>
      <Summary open={open} onClick={handleSummaryClick}>
        {children}
      </Summary>
      <Wrapper
        open={open}
        anchorRight={anchorRight}
        onTransitionEnd={closeDetails}
      >
        {groups.map((group, i) => (
          <div key={i}>
            {group.title ? <GroupTitle>{group.title}</GroupTitle> : null}
            <ul>
              {group.items.map(action => (
                <li key={action.text}>
                  {'href' in action ? (
                    <NavLink href={action.href}>{action.text}</NavLink>
                  ) : (
                    <NavLink as="button" onClick={action.onClick}>
                      {action.text}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Wrapper>
    </details>
  );
}

const Summary = styled('summary', {
  color: 'inherit',
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  alignItems: 'center',
  columnGap: '$medium',
  listStyle: 'none',
  '&::-webkit-details-marker': { display: 'none' },
  '&::after': {
    content: '""',
    width: '1.2rem',
    height: '0.6rem',
    display: 'block',
    border: '0.6rem solid transparent',
    borderBottom: 'none',
    borderTopColor: 'currentcolor',
    cursor: 'pointer',
    transition: '0.25s transform ease'
  },
  variants: {
    open: {
      true: { '&::after': { transform: 'rotate(180deg)' } }
    }
  }
});

const Wrapper = styled('div', {
  padding: '$medium 0',
  backgroundColor: '#ffffff',
  color: '$computerBlack',
  overflow: 'hidden',
  transition: 'height 250ms ease-out',
  '@large': {
    display: 'grid',
    gridAutoFlow: 'column',
    borderRadius: '$small',
    boxShadow: '0 $space$small $space$medium rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    transform: 'translateY(-1.6rem)',
    opacity: 0,
    transition: 'transform 250ms ease-out, opacity 250ms ease'
  },
  variants: {
    open: {
      true: {
        '@large': {
          transform: 'translateY(0)',
          opacity: 1
        }
      }
    },
    anchorRight: {
      true: { '@large': { right: 0 } }
    }
  }
});

const GroupTitle = styled('h2', {
  padding: '0 $xxlarge',
  color: '$rhyhorn',
  fontSize: '$info',
  lineHeight: '$title',
  fontWeight: '$normal',
  textTransform: 'uppercase'
});

const NavLink = styled('a', {
  display: 'block',
  padding: '$medium $xxlarge',
  whiteSpace: 'nowrap',
  fontFamily: '$heading',
  fontSize: '$attribution',
  fontWeight: '$normal',
  color: '$computerBlack',
  cursor: 'pointer'
});
