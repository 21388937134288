// libraries
import { useContext, useRef } from 'react';

import { useQueryClient } from 'react-query';

// @parsec
import { clear, save } from '@parsec/cookie';
import {
  ToAssignableGroupPermissions,
  ToAssignableTeamPermissions,
  GroupPermissions,
  TeamPermissions,
  teamInvite,
  teamRole
} from '@parsec/kessel';
import { identify } from '@parsec/sentry';

import { useGetMe } from './src/me';
import { useTeamRolePermissionSummaryData } from './src/permission';
import { KesselContext } from './src/Provider';
import { useGetTeam, useTeamData } from './src/team';
import { useMutation } from './src/useMutation';
import { useQuery } from './src/useQuery';
import { useWrapError } from './src/useWrapError';

export { QueryProvider } from './src/Provider';
export * from './src/accessLinkPublicData';
export * from './src/accessLinkCredit';
export * from './src/accessLinkLedger';
export * from './src/accessLink';
export * from './src/appRule';
export * from './src/connect';
export * from './src/estimate';
export * from './src/host';
export * from './src/me';
export * from './src/permission';
export * from './src/saml';
export * from './src/scimApiKey';
export * from './src/tfa';
export * from './src/team';
export * from './src/teamSaml';
export * from './src/teamApiKey';
export * from './src/teamBillingCard';
export * from './src/teamBillingDetails';
export * from './src/teamCapability';
export * from './src/teamDomain';
export * from './src/teamGroup';
export * from './src/teamGroupConnection';
export * from './src/teamGroupHosts';
export * from './src/teamInvoice';
export * from './src/teamMachine';
export * from './src/teamMachineKey';
export * from './src/teamMember';
export * from './src/teamPurchase';
export * from './src/teamRelay';
export * from './src/teamSubscription';
export * from './src/teamNonCompliantUsers';
export * from './src/verification';
export * from './src/warpBillingCard';
export * from './src/warpBillingDetails';
export * from './src/warpSubscription';
export * from './src/changelog';
export * from './src/__mocks__';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryKey<K extends string, V extends Record<string, any>> = [K, V];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryVars<T> = T extends QueryKey<any, infer U> ? U : never;

/******************************************************************************
 * Get Public Team Invite
 ******************************************************************************/

const GET_PUBLIC_TEAM_INVITE_QUERY_TYPE = 'PUBLIC_TEAM_INVITE';

interface PublicTeamInviteVars {
  teamId: string;
  hash: string;
  email: string;
}

export function useGetPublicTeamInvite(vars: PublicTeamInviteVars) {
  const kessel = useContext(KesselContext);

  return useQuery(
    [GET_PUBLIC_TEAM_INVITE_QUERY_TYPE, vars],
    async function queryFn() {
      const res = await kessel.teamInvite.getPublicTeamInvite(vars);
      return res.body;
    },
    { enabled: vars.teamId !== '' }
  );
}

/******************************************************************************
 * Get Team Invites
 ******************************************************************************/

const GET_TEAM_INVITES_QUERY_TYPE = 'TEAM_INVITES';

type GetTeamInvitesQueryKey = QueryKey<
  typeof GET_TEAM_INVITES_QUERY_TYPE,
  {
    team_id: string;
    email?: string;
    offset: number;
    limit: number;
  }
>;

/** Fetches team invites */
export function useGetTeamInvites(
  opts: Omit<QueryVars<GetTeamInvitesQueryKey>, 'team_id'>
) {
  const kessel = useContext(KesselContext);
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? '',
    ...opts
  };

  const queryKey: GetTeamInvitesQueryKey = [GET_TEAM_INVITES_QUERY_TYPE, vars];

  async function queryFn() {
    const res = await kessel.teamInvite.getTeamInvites(vars);
    return res.body;
  }

  const enabled = vars.team_id !== '';

  const varsRef = useRef(vars);

  const keepPreviousData =
    varsRef.current.team_id === vars.team_id &&
    varsRef.current.email === vars.email;
  varsRef.current = vars;

  return useQuery(queryKey, queryFn, { enabled, keepPreviousData });
}

/******************************************************************************
 * Get Team Events
 ******************************************************************************/
const GET_TEAM_EVENTS_QUERY_TYPE = 'TEAM_EVENTS';
type GetTeamEventsQueryKey = QueryKey<
  typeof GET_TEAM_EVENTS_QUERY_TYPE,
  {
    teamId: string;
  }
>;
export function useGetTeamEvents() {
  const kessel = useContext(KesselContext);
  const me = useGetMe();

  const teamId = me.data?.team_id ?? '';

  const queryKey: GetTeamEventsQueryKey = [
    GET_TEAM_EVENTS_QUERY_TYPE,
    { teamId }
  ];

  async function queryFn() {
    const res = await kessel.teamEvents.getTeamEvents({ teamId });
    return res.body;
  }

  const enabled = teamId !== '';

  return useQuery(queryKey, queryFn, { enabled });
}

/******************************************************************************
 * Get All Roles
 ******************************************************************************/

const GET_ALL_ROLES_QUERY_TYPE = 'ALL_ROLES';

type GetAllRolesQueryKey = QueryKey<
  typeof GET_ALL_ROLES_QUERY_TYPE,
  {
    team_id: string;
  }
>;

/** Fetches all roles in the auth user's team */
export function useGetAllRoles() {
  const kessel = useContext(KesselContext);
  const me = useGetMe();

  const vars = {
    team_id: me.data?.team_id ?? ''
  };

  const queryKey: GetAllRolesQueryKey = [GET_ALL_ROLES_QUERY_TYPE, vars];

  async function queryFn() {
    const res = await kessel.teamRole.getTeamRoles({
      ...vars,
      offset: 0,
      limit: 200
    });
    return res.body;
  }

  const enabled = vars.team_id !== '';

  return useQuery(queryKey, queryFn, { enabled });
}

/******************************************************************************
 * Get Role
 ******************************************************************************/

const GET_ROLE_QUERY_TYPE = 'ROLE';

type GetRoleQueryKey = QueryKey<
  typeof GET_ROLE_QUERY_TYPE,
  {
    role_id: string;
  }
>;

/** Fetches a role by ID */
export function useGetRole(roleId: string) {
  const allRoles = useGetAllRoles();

  const vars = {
    role_id: roleId
  };

  const queryKey: GetRoleQueryKey = [GET_ROLE_QUERY_TYPE, vars];

  function queryFn() {
    const role = allRoles.data?.data.find(role => role.id === roleId);
    if (!role) throw new Error('Failed to load role');
    return role;
  }

  const enabled = vars.role_id !== '' && allRoles.isSuccess;

  return useQuery(queryKey, queryFn, { enabled });
}

/******************************************************************************
 * Sign Up
 ******************************************************************************/

export function useSignUp() {
  const kessel = useContext(KesselContext);
  return useMutation(kessel.account.signUp);
}

/******************************************************************************
 * Send Password Reset Email
 ******************************************************************************/

export function useSendPasswordResetEmail() {
  const kessel = useContext(KesselContext);
  return useMutation(kessel.account.sendPasswordResetEmail);
}

/******************************************************************************
 * Reset Password
 ******************************************************************************/

export function useResetPassword() {
  const kessel = useContext(KesselContext);
  return useMutation(kessel.account.resetPassword);
}

/******************************************************************************
 * Log In
 ******************************************************************************/

export function useLogIn() {
  const kessel = useContext(KesselContext);

  const result = useMutation(kessel.auth.createSession, {
    onSuccess(res) {
      save({ token: res.body.session_id });
      if (res.body.user_id) identify({ userId: res.body.user_id });
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't log in."
  });
  return { ...result, error };
}

export function useGetZendeskToken() {
  const kessel = useContext(KesselContext);

  return useQuery(['ZENDESK_TOKEN'], async function queryFn() {
    const res = await kessel.auth.getZendeskToken();
    return res.body.jwt;
  });
}

/******************************************************************************
 * Log Out
 ******************************************************************************/

export function useLogOut() {
  const kessel = useContext(KesselContext);

  return useMutation(kessel.auth.deleteSession, {
    onSuccess() {
      clear();
    }
  });
}

/******************************************************************************
 * Create a Role
 ******************************************************************************/

type CreateRoleVars = Omit<teamRole.CreateTeamRoleReq, 'team_id'>;

/** Creates a role */
export function useCreateRole() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: CreateRoleVars) {
    const { group_id, actions: initialActions } = vars;
    const isGroupRole = group_id != null;

    const baseActions = initialActions ?? {};

    const actions = isGroupRole
      ? ToAssignableGroupPermissions(baseActions as GroupPermissions)
      : ToAssignableTeamPermissions(baseActions as TeamPermissions);

    await kessel.teamRole.createTeamRole({
      ...vars,
      actions,
      team_id: teamId
    });

    const queryKey: GetAllRolesQueryKey = [
      GET_ALL_ROLES_QUERY_TYPE,
      { team_id: teamId }
    ];

    const getAllRolesQuery = await client.fetchQuery<
      ReturnType<typeof useGetAllRoles>['data']
    >(queryKey, { staleTime: 0 });
    const roles = getAllRolesQuery?.data ?? [];
    const newRole = roles.find(role => role.name === vars.name);
    return newRole;
  }

  return useMutation(mutationFn);
}

/******************************************************************************
 * Update a Role
 ******************************************************************************/

type UpdateRoleVars = Omit<teamRole.UpdateTeamRoleReq, 'team_id'>;

/** Updates a role */
export function useUpdateRole() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();
  const permissionsData = useTeamRolePermissionSummaryData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: UpdateRoleVars) {
    const { group_id, actions: baseActions } = vars;
    const isGroupRole = group_id != null;

    const actions = isGroupRole
      ? ToAssignableGroupPermissions(baseActions as GroupPermissions)
      : ToAssignableTeamPermissions(baseActions as TeamPermissions);

    await kessel.teamRole.updateTeamRole({
      ...vars,
      actions,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        permissionsData.invalidate();
        return query.queryKey[0] === GET_ALL_ROLES_QUERY_TYPE;
      }
    });
  }

  return useMutation(mutationFn, { onSuccess });
}

/******************************************************************************
 * Delete a Role
 ******************************************************************************/

type DeleteRoleVars = Omit<teamRole.DeleteTeamRoleReq, 'team_id'>;

/** Deletes a role */
export function useDeleteRole() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();
  const permissionsData = useTeamRolePermissionSummaryData();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: DeleteRoleVars) {
    await kessel.teamRole.deleteTeamRole({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        permissionsData.invalidate();
        return query.queryKey[0] === GET_ALL_ROLES_QUERY_TYPE;
      }
    });
  }

  return useMutation(mutationFn, { onSuccess });
}

/******************************************************************************
 * Create Team Invites
 ******************************************************************************/

type CreateTeamInvitesVars = Omit<teamInvite.CreateTeamInvitesReq, 'team_id'>;

/** Creates a batch of team invites */
export function useCreateTeamInvites() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();

  const team = useGetTeam();
  const teamCache = useTeamData();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: CreateTeamInvitesVars) {
    return await kessel.teamInvite.createTeamInvite({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        const type = query.queryKey[0];
        return type === GET_TEAM_INVITES_QUERY_TYPE;
      }
    });
    teamCache.invalidate();
  }

  const result = useMutation(mutationFn, { onSuccess });

  const error = useWrapError(result.error, {
    error: "Couldn't send team invites."
  });

  return { ...result, error };
}

/******************************************************************************
 * Cancel Team Invites
 ******************************************************************************/

type CancelTeamInvitesVars = Omit<teamInvite.CancelTeamInvitesReq, 'team_id'>;

/** Cancels a batch of team invites */
export function useCancelTeamInvites() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();
  const teamCache = useTeamData();
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: CancelTeamInvitesVars) {
    return await kessel.teamInvite.cancelTeamInvite({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        const type = query.queryKey[0];
        return type === GET_TEAM_INVITES_QUERY_TYPE;
      }
    });
    teamCache.invalidate();
  }

  return useMutation(mutationFn, { onSuccess });
}

/******************************************************************************
 * Update Team Invites
 ******************************************************************************/

type UpdateTeamInvitesVars = Omit<teamInvite.UpdateTeamInvitesReq, 'team_id'>;

/** Updates a batch of team invites */
export function useUpdateTeamInvites() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();

  const team = useGetTeam();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: UpdateTeamInvitesVars) {
    return await kessel.teamInvite.updateTeamInvite({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        const type = query.queryKey[0];
        return type === GET_TEAM_INVITES_QUERY_TYPE;
      }
    });
  }

  return useMutation(mutationFn, { onSuccess });
}

/******************************************************************************
 * Resend Team Invites
 ******************************************************************************/

type ResendTeamInvitesVars = Omit<teamInvite.ResendTeamInvitesReq, 'team_id'>;

/** Resends a batch of team invites */
export function useResendTeamInvites() {
  const kessel = useContext(KesselContext);
  const client = useQueryClient();

  const team = useGetTeam();
  const teamCache = useTeamData();
  const teamId = team.data?.id ?? '';

  async function mutationFn(vars: ResendTeamInvitesVars) {
    return await kessel.teamInvite.resendTeamInvite({
      ...vars,
      team_id: teamId
    });
  }

  function onSuccess() {
    client.invalidateQueries({
      predicate: query => {
        const type = query.queryKey[0];
        return type === GET_TEAM_INVITES_QUERY_TYPE;
      }
    });
    teamCache.invalidate();
  }

  return useMutation(mutationFn, { onSuccess });
}
