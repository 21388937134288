// @parsec
import { useCookie } from '@parsec/cookie';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('verification');

export function useGetVerification() {
  const kessel = useKessel();

  const { token } = useCookie();

  const res = useGetMe({ enabled: Boolean(token) });

  const id = res.data?.id ?? '';

  const result = useQuery(
    key.detail({ id }),
    async function queryFn() {
      const res = await kessel.verification.getVerification();
      return res.body;
    },
    {
      enabled: Boolean(id)
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't verify email"
  });

  return {
    ...result,
    isLoading: result.isLoading || res.isLoading,
    error
  };
}
