import {
  ChangeEvent,
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';

// @parsec
import { styled } from '@parsec/styles';

import Bread from '../Bread';
import ErrorMessage from '../ErrorMessage';
import Input from '../Input';

const INTERVAL_YEARLY = 'year';
const INTERVAL_MONTHLY = 'month';

export interface ManageSeatsProps {
  className?: string;
  disabled?: boolean;
  interval: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
  paidSeats: number;
  seatUnitPrice: number;
  freeSeats?: number;
  minPaidSeats: number;
  maxPaidSeats: number;
  canDowngradeSeats?: boolean;
  onSetPaidSeats(seats: number): void;
  onSeatValueValid: Dispatch<SetStateAction<boolean>>;
}

function ManageSeats(props: ManageSeatsProps) {
  const {
    className,
    disabled,
    paidSeats,
    freeSeats = 0,
    seatUnitPrice,
    interval,
    minPaidSeats,
    maxPaidSeats,
    canDowngradeSeats = true,
    onSetPaidSeats,
    onSeatValueValid
  } = props;

  const [seatInputValue, setSeatInputValue] = useState<number | ''>(paidSeats);

  const checkSeatValid = useCallback(
    (seatCount: string) => {
      const seatValue = Number(seatCount);
      // don't have to worry about weird words in this case since it's numeric
      if (typeof seatCount === 'string' && seatCount === '') {
        onSeatValueValid(false);
      }
      if (seatValue < minPaidSeats) {
        onSeatValueValid(false);
      }
      if (seatValue > maxPaidSeats) {
        onSeatValueValid(false);
      }
      onSeatValueValid(true);
    },
    [maxPaidSeats, minPaidSeats, onSeatValueValid]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      checkSeatValid(event.target.value);
      // if it's not numeric, we don't want it
      if (!/^[0-9]*$/.test(event.target.value) || event.target.value === '') {
        setSeatInputValue('');
      } else {
        setSeatInputValue(Number(event.target.value)); // setting input value so input matches what user types
      }
    },
    [checkSeatValid]
  );

  const handleInputBlur = useCallback(() => {
    if (seatInputValue === '' || isNaN(seatInputValue)) {
      setSeatInputValue(paidSeats);
    } else if (seatInputValue < minPaidSeats) {
      setSeatInputValue(minPaidSeats);
      onSetPaidSeats(minPaidSeats);
    } else if (seatInputValue > maxPaidSeats) {
      setSeatInputValue(maxPaidSeats);
      onSetPaidSeats(maxPaidSeats);
    } else {
      onSetPaidSeats(seatInputValue);
    }
  }, [maxPaidSeats, minPaidSeats, onSetPaidSeats, paidSeats, seatInputValue]);

  // Have to re-evaluate when user presses enter
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleInputBlur();
      }
    },
    [handleInputBlur]
  );

  const handleButtonClick = useCallback(
    (type: 'dec' | 'inc') => {
      const addValue = type === 'dec' ? -1 : 1;

      const newSeat = !isNaN(Number(seatInputValue))
        ? Number(seatInputValue) + addValue
        : '';
      setSeatInputValue(newSeat);
      onSetPaidSeats(Number(newSeat));
    },
    [onSetPaidSeats, seatInputValue]
  );

  const renderSeatInputError = useCallback(() => {
    const seatValue = Number(seatInputValue);
    // don't have to worry about weird words in this case since it's numeric
    if (typeof seatInputValue === 'string' && seatInputValue === '') {
      return <ErrorMessage>Please enter a valid number of seats.</ErrorMessage>;
    }
    if (seatValue < minPaidSeats) {
      return (
        <ErrorMessage>Seats must be a minimum of {minPaidSeats}.</ErrorMessage>
      );
    }
    if (seatValue > maxPaidSeats) {
      return (
        <ErrorMessage>Seats have a maximum of {maxPaidSeats}.</ErrorMessage>
      );
    }
    if (canDowngradeSeats) {
      return <Minimum>Minimum of {minPaidSeats} paid seats per team.</Minimum>;
    }
    return (
      <Minimum>Contact us to downgrade below {minPaidSeats} seats.</Minimum>
    );
  }, [seatInputValue, maxPaidSeats, minPaidSeats, canDowngradeSeats]);

  const totalPerCycle = useMemo(
    () => paidSeats * seatUnitPrice,
    [paidSeats, seatUnitPrice]
  );

  return (
    <Wrapper className={className}>
      <Controls>
        <Paddle
          id="decrement_seats"
          disabled={disabled || Number(seatInputValue) <= minPaidSeats}
          type="button"
          kind="dec"
          onClick={() => handleButtonClick('dec')}
        >
          &#8722;
        </Paddle>
        <Label htmlFor="seats">Seats</Label>
        <SeatsInput
          id="seats"
          pattern="[0-9]*"
          name="seats"
          type="number"
          step="1"
          value={seatInputValue}
          min={minPaidSeats}
          max={maxPaidSeats}
          disabled={disabled}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
        />
        <Paddle
          id="increment_seats"
          type="button"
          kind="inc"
          onClick={() => handleButtonClick('inc')}
          disabled={disabled || Number(seatInputValue) >= maxPaidSeats}
        >
          &#43;
        </Paddle>
      </Controls>
      <Explanation>
        <Calculation>
          <strong>{paidSeats}</strong> seats ×{' '}
          <strong>
            <Bread decimal={0}>{seatUnitPrice}</Bread>
          </strong>
          {freeSeats > 0 && (
            <>
              {' '}
              + <strong>{freeSeats}</strong> free seats
            </>
          )}{' '}
          p/{interval} ={' '}
          <strong>
            <Bread>{totalPerCycle}</Bread>
          </strong>{' '}
          before tax billed {interval === 'month' ? 'month' : 'annual'}ly
        </Calculation>

        {renderSeatInputError()}
      </Explanation>
    </Wrapper>
  );
}

export default ManageSeats;

const Wrapper = styled('div', {
  display: 'flex'
});

const Controls = styled('div', {
  display: 'grid',
  gridTemplateAreas: '". label ." "dec input inc"',
  alignItems: 'center',
  gridRowGap: '0.4rem',
  gridColumnGap: '0.8rem'
});

const Paddle = styled('button', {
  width: '1.6rem',
  height: '1.6rem',
  lineHeight: '1.6rem',
  textAlign: 'center',
  fontWeight: 900,
  borderRadius: '50%',
  color: '$perfectGray',
  cursor: 'pointer',
  '&:disabled': {
    opacity: 0.5,
    cursor: 'default'
  },
  variants: {
    kind: {
      inc: {
        gridArea: 'inc',
        backgroundColor: '$primary500'
      },
      dec: {
        gridArea: 'dec',
        backgroundColor: '$error500'
      }
    }
  }
});

const Label = styled('label', {
  gridArea: 'label',
  textAlign: 'center',
  fontSize: '$info',
  lineHeight: '$info'
});

const SeatsInput = styled(Input, {
  gridArea: 'input',
  textAlign: 'center',
  borderRadius: '1.8rem',
  width: '4.8rem'
});

const Explanation = styled('div', {
  marginLeft: '2.4rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '1.4rem'
});

const Calculation = styled('p', {
  margin: 0,
  lineHeight: '1.4rem'
});

const Minimum = styled('p', {
  color: '$rhyhorn'
});
