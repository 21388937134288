export const PATH_MALFORMED = 'request_path_malformed';
export const PATH_INVALID = 'request_path_invalid';
export const QUERY_MALFORMED = 'request_query_string_malformed';
export const QUERY_INVALID = 'request_query_string_invalid';
export const BODY_MALFORMED = 'request_body_malformed';
export const BODY_INVALID = 'request_body_invalid';
export const PASSWORD_INCORRECT = 'password_incorrect';
export const PASSWORD_EXPIRED = 'password_expired';
export const TFA_INCORRECT = 'tfa_incorrect';
export const IP_UNVERIFIED = 'ip_unverified';
export const SAML_REQUIRED = 'saml_required';
export const TOKEN_INCORECT = 'token_incorrect';
export const TOKEN_EXPIRED = 'token_expired';
export const SESSION_INCORRECT = 'session_incorrect';
export const USER_UNVERIFIED = 'user_unverified';
export const INSUFFICIENT_PERMISSIONS = 'insufficient_permissions';
// special code for if the error codes themselves are invalid
export const ERR_CODE_INVALID = 'error_code_invalid';
