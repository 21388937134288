/**
 * When to use this Flyout component ?
 * If you need to implement a small windows with stuff in it.
 * Check the design here https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=610-12966&t=hiOqq1SMDs0EmYYH-4
 * and here https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=623-14192&t=hiOqq1SMDs0EmYYH-4
 *
 * This component is a basic component that just provides a flyout windows with the default style.
 * You can add whatever you want inside the Flyout.content
 * you can also use the Flyout.trigger as a wrapper around a custom button or else
 */

import { type ReactNode, forwardRef } from 'react';

import * as Popover from '@radix-ui/react-popover';

import { styled } from '@parsec/styles';

import { Side, Align } from '../SharedType';

// STYLES
const BaseFlyoutContent = styled(Popover.Content, {
  backgroundColor: '$cereza',
  minHeight: '5.6rem',
  minWidth: '19.2rem',
  border: '0.1rem solid rgba(249,249,249, 0.1)',
  boxShadow: '0rem 0.2rem 2rem 2rem rgba(0, 0, 0, 0.1)',
  borderRadius: '$medium'
});

// COMPONENTS
export interface BaseFlyoutProps {
  children: ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  modal?: boolean; // Set to true to disable interaction with outside elements. Only the content of the flyout will be visible to screen readers.
}

function BaseFlyout(props: BaseFlyoutProps) {
  const {
    children,
    defaultOpen,
    open,
    onOpenChange,
    modal = false,
    ...rest
  } = props;

  return (
    <Popover.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      modal={modal}
      {...rest}
    >
      {children}
    </Popover.Root>
  );
}

export default BaseFlyout;

/**
 * Trigger
 * Button that toggles the Flyout. Can be styled or used as a wrapper.
 * By default the Flyout.content will position itself against the trigger.
 */
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Trigger = (props: TriggerProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Popover.Trigger asChild={asChild} {...rest}>
      {children}
    </Popover.Trigger>
  );
};

/**
 * Anchor
 * An optional element to position the Flyout.Content against.
 * If this part is not used, the content will position alongside the Flyout.Trigger.
 * Can be styled or used as a wrapper.
 */
interface AnchorProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Anchor = (props: AnchorProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Popover.Anchor asChild={asChild} {...rest}>
      {children}
    </Popover.Anchor>
  );
};

/**
 * Portal
 * When used, portals the content part into the body.
 */
interface PortalProps {
  children: ReactNode;
  forceMount?: true;
  container?: HTMLElement;
}

const Portal = (props: PortalProps) => {
  const { children, forceMount, container = document.body, ...rest } = props;

  return (
    <Popover.Portal forceMount={forceMount} container={container} {...rest}>
      {children}
    </Popover.Portal>
  );
};

/**
 * Content
 * The component that pops out when the flyout is open.
 * Didn't add all the props, check the doc for more info.
 * Doc: https://www.radix-ui.com/docs/primitives/components/popover#content
 */
interface ContentProps {
  children: ReactNode;
  asChild?: boolean;
  forceMount?: true;
  side?: Side;
  sideOffset?: number;
  align?: Align;
  alignOffset?: number;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(function (
  props: ContentProps,
  ref
) {
  const {
    children,
    asChild = false,
    forceMount,
    side = Side.Bottom,
    sideOffset = 0,
    align = Align.Center,
    alignOffset = 0,
    ...rest
  } = props;
  return (
    <BaseFlyoutContent
      asChild={asChild}
      forceMount={forceMount}
      side={side}
      sideOffset={sideOffset}
      align={align}
      alignOffset={alignOffset}
      ref={ref}
      {...rest}
    >
      {children}
    </BaseFlyoutContent>
  );
});

/**
 * Arrow
 * An optional arrow element to render alongside the Flyout.
 * This can be used to help visually link the anchor with the Flyout.Content.
 * Must be rendered inside Flyout.Content.
 * Not styled.
 */
interface ArrowProps {
  children: ReactNode;
  asChild?: boolean;
  width?: number;
  height?: number;
}

const Arrow = (props: ArrowProps) => {
  const { children, asChild = false, width, height, ...rest } = props;

  return (
    <Popover.Arrow asChild={asChild} width={width} height={height} {...rest}>
      {children}
    </Popover.Arrow>
  );
};

/**
 * Close
 * The button that closes an open popover.
 * You can use it as a wrapper.
 * Not styled.
 */
interface CloseProps {
  children: ReactNode;
  asChild?: boolean;
}

const Close = (props: CloseProps) => {
  const { children, asChild, ...rest } = props;

  return (
    <Popover.Close asChild={asChild} {...rest}>
      {children}
    </Popover.Close>
  );
};

// EXPORTS
BaseFlyout.Trigger = Trigger;
BaseFlyout.Portal = Portal;
BaseFlyout.Content = Content;
BaseFlyout.Arrow = Arrow;
BaseFlyout.Close = Close;
BaseFlyout.Anchor = Anchor;
