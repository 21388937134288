/**
 * When to use this Modal component ?
 * It's a window overlaid on either the primary window or another dialog window,
 * rendering the content underneath inert.
 * Use this one if you need to implement simple content or a form.
 */
import type { ReactNode } from 'react';

import { forwardRef } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { styled, keyframes, VariantProps, CSS } from '@parsec/styles';

import Icon from '../Icon';
import IconButton, { IconButtonProps } from '../IconButton';
import { ModalSize } from '../SharedType';

import modalStyles from './baseModal.module.css';

export interface BaseModalProps extends Dialog.DialogProps {}

function BaseModal(props: BaseModalProps) {
  const {
    children,
    open,
    defaultOpen,
    onOpenChange,
    modal = true,
    ...rest
  } = props;

  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      modal={modal}
      {...rest}
    >
      {children}
    </Dialog.Root>
  );
}

export default BaseModal;

/**
 * Trigger
 * A button that opens the dialog
 */
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Trigger = (props: TriggerProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <Dialog.Trigger asChild={asChild} {...rest}>
      {children}
    </Dialog.Trigger>
  );
};

/**
 * Portal
 * When used, portals your overlay and content parts into the body.
 */
interface PortalProps {
  children: ReactNode;
  forceMount?: true;
  container?: HTMLElement;
}

const Portal = (props: PortalProps) => {
  const { children, forceMount, container = document.body, ...rest } = props;

  return (
    <Dialog.Portal forceMount={forceMount} container={container} {...rest}>
      {children}
    </Dialog.Portal>
  );
};

/**
 * Overlay
 * A layer that covers the inert portion of the view when the dialog is open.
 */
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const BaseModalOverlay = styled(Dialog.Overlay, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0., 1)`,
  '@reducedMotion': { animation: 'none' },

  variants: {
    hasOverflow: {
      true: {
        overflow: 'auto'
      }
    }
  }
});

interface OverlayProps {
  children?: ReactNode;
  asChild?: boolean;
  forceMount?: true;
  hasOverflow?: boolean;
}

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(function (
  props: OverlayProps,
  ref
) {
  const { children, asChild = false, forceMount, ...rest } = props;
  return (
    <BaseModalOverlay
      asChild={asChild}
      forceMount={forceMount}
      ref={ref}
      className={modalStyles.DialogOverlay}
      {...rest}
    >
      {children}
    </BaseModalOverlay>
  );
});

interface HeaderCloseButtonProps
  extends Omit<IconButtonProps, 'icon' | 'title'> {}

/**
 * A component that renders a close button for a dialog.
 * This is a convenience component that wraps IconButton with the correct props. It's auto-included in the DialogContent component, but you can use it on it's own if you need to.
 */
const HeaderCloseButton = (props: HeaderCloseButtonProps) => (
  <CloseBtn
    {...props}
    aria-label="Close"
    icon="ex"
    kind="basic"
    title="Close"
  />
);

/**
 * Content
 * Contains content to be rendered when the dialog is open.
 */
const BaseModalContent = styled(Dialog.Content, {
  position: 'relative',
  minHeight: '25.6rem',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  backgroundColor: '$computerBlack',
  border: '0.05rem solid rgba(249, 249, 249, 0.1)',
  boxShadow:
    '0 0.2rem 4rem 2rem rgba(0, 0, 0, 0.15), inset 0 0.1rem 0 rgba(255, 255, 255, 0.2)',
  borderRadius: '$xlarge',
  overflow: 'hidden',
  variants: {
    size: {
      small: {
        width: '38rem'
      },
      medium: {
        width: '54rem'
      },
      large: {
        width: '62rem'
      }
    }
  }
});

interface ContentProps extends Dialog.DialogContentProps {
  size: ModalSize;
  showCloseButton?: boolean; // necessary for correct focus order
}

const PrimitiveContent = forwardRef<HTMLDivElement, ContentProps>(function (
  props: ContentProps,
  ref
) {
  const { children, size, showCloseButton = true, ...rest } = props;
  return (
    <BaseModalContent
      ref={ref}
      size={size}
      className={modalStyles.DialogContent}
      {...rest}
    >
      {children}
      {showCloseButton ? (
        <Dialog.Close asChild>
          <HeaderCloseButton />
        </Dialog.Close>
      ) : null}
    </BaseModalContent>
  );
});

/**
 * Title
 * An accessible name to be announced when the dialog is opened.
 * If you want to hide the title, wrap it inside a Visually Hidden component.
 */
const BaseModalTitle = styled(Dialog.Title, {
  fontFamily: '$newDefault',
  fontWeight: '$bold',
  fontSize: '$subtitle',
  lineHeight: '2.9rem',
  textTransform: 'capitalize'
});

interface TitleProps {
  css?: CSS;
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Title = ({ children, asChild = false, ...rest }: TitleProps) => {
  return (
    <BaseModalTitle asChild={asChild} {...rest}>
      {children}
    </BaseModalTitle>
  );
};

/**
 * Description
 * An optional accessible description to be announced when the dialog is opened.
 * If you want to hide the description, wrap it inside a Visually Hidden component.
 * If you want to remove the description entirely, remove this part and pass aria-describedby={undefined} to BaseModal.Content
 */
const BaseModalDescription = styled(Dialog.Description, {
  fontFamily: '$newDefault',
  fontSize: '1rem',
  lineHeight: '$info',
  variants: {
    unstyled: {
      true: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit'
      }
    }
  }
});

interface DescriptionProps extends VariantProps<typeof BaseModalDescription> {
  css?: CSS;
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Description = ({
  children,
  asChild = false,
  ...rest
}: DescriptionProps) => {
  return (
    <BaseModalDescription asChild={asChild} {...rest}>
      {children}
    </BaseModalDescription>
  );
};

/**
 * Close
 * The button that closes the dialog.
 */
interface CloseProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Close = ({ children, asChild = false, ...rest }: CloseProps) => {
  return (
    <Dialog.Close asChild={asChild} {...rest}>
      {children}
    </Dialog.Close>
  );
};

/**
 * Dialogue Header
 * Use this header if you need to implement a modal with this style
 * https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=610-12891&t=5Tuj771WkyBGMhy6-4
 */
const StyledDialogueHeader = styled('header', {
  padding: '$xxlarge $xxlarge 1rem $xxlarge',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gap: '$medium'
});

/**
 * Modal Header
 * Use this header if you need to implement a modal with this style
 * https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=610-12644&t=5Tuj771WkyBGMhy6-4
 */
const StyledModalHeader = styled(StyledDialogueHeader, {
  padding: '$xxlarge $xxlarge 0 $xxlarge',

  '&::after': {
    content: '',
    display: 'block',
    height: '0.1rem',
    backgroundColor: '$pangoro',
    gridRow: 'auto',
    marginTop: '0.6rem'
  }
});

const CloseBtn = styled(IconButton, {
  position: 'absolute',
  top: '1.5rem',
  right: '1.2rem'
});

/**
 * For modal styles like https://www.figma.com/design/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?node-id=1373-12217&node-type=frame&t=HkRzwKrCibVw8ah5-0
 *
 * make sure to use a `h2` element inside for accessibility 🎉
 */
const BlockHeader = styled('header', {
  fontFamily: '$newDefault',
  position: 'relative',
  padding: '$xxlarge $xxlarge 1.7rem $xxlarge',
  borderRadius: '$xlarge $xlarge 0 0',
  backgroundColor: '$computerBlack',
  color: '$consoleWhite',
  '& svg': { color: '$consoleWhite' },
  variants: {
    type: {
      principal: {
        backgroundColor: '$primary500',
        color: '$consoleWhite',
        '& svg': { color: '$consoleWhite' }
      },
      negative: {
        backgroundColor: '$error500',
        color: '$consoleWhite',
        '& svg': { color: '$consoleWhite' }
      },
      highlight: {
        backgroundColor: '$warning500',
        color: '$computerBlack',
        '& svg': { color: '$computerBlack' }
      },
      brand: {
        backgroundColor: '$brand500',
        color: '$consoleWhite',
        '& svg': { color: '$consoleWhite' }
      }
    }
  }
});

/**
 * Error Message
 * Error message within the alert dialog.
 * For screen readers to know when to pop this up, we need to have it exist in the DOM but visually hidden.
 * When the message changes, that's when the screen reader will read it out.
 */

const horizontalShake = keyframes({
  '0%, 100%': { transform: 'translateX(0)' },
  '10%, 30%, 50%, 70%': { transform: 'translateX(-0.8rem)' },
  '20%, 40%, 60%': { transform: 'translateX(0.8rem)' },
  '80%': { transform: 'translateX(0.4rem)' },
  '90%': { transform: 'translateX(-0.4rem)' }
});

const ErrorContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.1rem 1fr',
  alignItems: 'center',
  gridColumnGap: '1.4rem',
  padding: '1rem $large',
  borderRadius: '$medium',
  variants: {
    visuallyHidden: {
      true: { display: 'none' }
    },
    type: {
      error: {
        backgroundColor: '$error500'
      },
      warning: {
        backgroundColor: '$warning500',
        color: '$ultraDark'
      }
    },
    animate: {
      true: {
        animation: `${horizontalShake} 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`
      }
    }
  }
});

const ErrorCopy = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution'
});

const ErrorMessage = ({
  type,
  message,
  animate = false
}: {
  type: VariantProps<typeof ErrorContainer>['type'];
  message?: ReactNode;
  animate?: boolean;
}) => {
  return (
    <ErrorContainer
      type={type}
      role="alert"
      animate={animate}
      visuallyHidden={!Boolean(message)}
    >
      <Icon name="warningSign" css={{ width: '2.1rem', height: '2rem' }} />
      <ErrorCopy>{message}</ErrorCopy>
    </ErrorContainer>
  );
};

/**
 * Footer
 */
const FooterContainer = styled('footer', {
  display: 'flex',
  backgroundColor: '$samehada',
  padding: '$xxlarge',
  gap: '$xxlarge',
  variants: {
    error: {
      true: {
        backgroundColor: '$perfectGray',
        padding: '$medium',
        gap: 0,
        display: 'grid',
        gridAutoFlow: 'row'
      }
    }
  }
});

const ActionsWrapper = styled('div', {
  display: 'flex',
  gap: '$xxlarge',
  padding: 0,
  variants: {
    error: {
      true: {
        padding: '$xxlarge $large $large'
      }
    }
  }
});

type FooterProps =
  | {
      children: ReactNode;
      errorMessage?: never;
      errorType?: never;
      animateErrorMsg?: never;
    }
  | {
      children: ReactNode;
      errorMessage: ReactNode;
      errorType: VariantProps<typeof ErrorContainer>['type'];
      animateErrorMsg?: boolean;
    };

const Footer = ({
  children,
  errorMessage,
  errorType,
  animateErrorMsg = false
}: FooterProps) => (
  <FooterContainer error={Boolean(errorMessage)}>
    <ErrorMessage
      type={errorType}
      message={errorMessage}
      animate={animateErrorMsg}
    />
    <ActionsWrapper error={Boolean(errorMessage)}>{children}</ActionsWrapper>
  </FooterContainer>
);

/**
 * Content Wrapper
 */
const ContentWrapper = styled('div', {
  padding: '1rem $xxlarge',
  overflowX: 'hidden',
  overflowY: 'scroll'
});

// EXPORTS
BaseModal.Trigger = Trigger;
BaseModal.Portal = Portal;
BaseModal.Overlay = Overlay;
BaseModal.Content = PrimitiveContent;
BaseModal.Title = Title;
BaseModal.Description = Description;
BaseModal.Close = Close;
BaseModal.BlockHeader = BlockHeader;
BaseModal.Header = StyledModalHeader;
BaseModal.DialogueHeader = StyledDialogueHeader;
BaseModal.ContentWrapper = ContentWrapper;
BaseModal.ErrorMessage = ErrorMessage;
BaseModal.Footer = Footer;
BaseModal.HeaderCloseButton = HeaderCloseButton;
