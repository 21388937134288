import { forwardRef } from 'react';

import * as Switch from '@radix-ui/react-switch';

import { styled } from '@parsec/styles';

import toggleStyles from './baseToggle.module.css';

// STYLES
const ToggleRoot = styled(Switch.Root, {
  position: 'relative',
  display: 'block',
  width: '3.6rem',
  height: '1.8rem',
  borderRadius: '1.1rem',
  backgroundColor: '$cereza',
  boxShadow: '0 .1rem 0 0 rgba(255,255,255,0.1)',
  cursor: 'pointer',
  padding: '.3rem',
  transition: '125ms box-shadow ease, 125ms background-color ease',

  //hover
  '&:hover': {
    boxShadow:
      'inset 0 0 0 .1rem $colors$pancham, 0 .1rem 0 rgba(255,255,255,0.1)',
    '&::-moz-focus-inner': {
      border: 'none'
    }
  },

  '&:focus': {
    boxShadow: '0 0 0 .2rem rgba(249, 249, 249, 1)',
    outline: 'none'
  },

  //disabled
  '&:disabled': {
    opacity: 0.5,
    backgroundColor: '$duskull',
    cursor: 'not-allowed'
  }
});

const ToggleThumb = styled(Switch.Thumb, {
  display: 'block',
  width: '1.2rem',
  height: '1.2rem',
  backgroundColor: '$espurr',
  borderRadius: '50%',
  boxShadow:
    '0 .1rem 0 0 rgba(255, 255, 255, 0.05) inset,  0 .2rem .2rem 0 rgba(0, 0, 0, 0.18)',
  transition: '125ms background-color ease, 0.25s transform ease',
  variants: {
    disabled: {
      true: {
        backgroundColor: '$nice',
        boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.05)'
      }
    }
  }
});

/**
 * BaseToggle
 * More info on props https://www.radix-ui.com/primitives/docs/components/switch#root
 */
export interface BaseToggleProps extends Switch.SwitchProps {}

export const BaseToggle = forwardRef<HTMLButtonElement, BaseToggleProps>(
  function BaseToggle(props: BaseToggleProps, ref) {
    return (
      <ToggleRoot {...props} className={toggleStyles.ToggleRoot} ref={ref}>
        <ToggleThumb
          disabled={props.disabled}
          className={toggleStyles.ToggleThumb}
        />
      </ToggleRoot>
    );
  }
);
