import { schema, WarpBillingDetailsReq } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('warp_billing_details');

export function useWarpBillingDetailsData() {
  return useQueryData(key.all(), schema.customer);
}
/** ****************************************************************************/

export function useGetWarpBillingDetails() {
  const kessel = useKessel();
  const me = useGetMe();
  const enabled = Boolean(me.data);

  const result = useQuery(
    key.all(),
    async function queryFn() {
      const res = await kessel.warpBilling.getWarpBillingDetails();
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Warp billing details."
  });

  return { ...result, error };
}

/** ****************************************************************************/

export function useUpdateWarpBillingDetails() {
  const kessel = useKessel();
  const billingDetailsCache = useWarpBillingDetailsData();

  const result = useMutation(
    async function mutationFn(vars: WarpBillingDetailsReq) {
      const res = await kessel.warpBilling.updateWarpBillingDetails(vars);
      return res.body.data;
    },
    {
      onSuccess() {
        billingDetailsCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Warp billing details."
  });

  return { ...result, error };
}
