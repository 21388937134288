import AnimateHeight from 'react-animate-height';

import {
  styled,
  Button,
  Checkbox,
  Icon,
  IconButton,
  MultiSelect,
  Dropdown,
  Flyout
} from '@parsec/components';
import { useGetTeamRolePermissionSummary } from '@parsec/queries';

import { Pagination } from 'components';

const version = 'newFont';

export interface TeamMemberListToolbarProps {
  selectionMode: 'include' | 'exclude';
  selectedCount: number;
  onSelectPage(): void;
  onSelectAll(): void;
  onDeselectAll(): void;
  groups: Array<{ id: number; name: string }>;
  selectedUsersGroups: number[][];
  offset: number;
  limit: number;
  total: number;
  appRules: Array<{ id: string; name: string }>;
  onAddToGroups?(groupIds: number[] | null): void;
  onMassAssignAppRule?(appRuleId: string): Promise<void>;
  onSelectPage(): void;
  onSelectAll(): void;
  onDeselectAll(): void;
  onPrevPage(): void;
  onNextPage(): void;
}

export function TeamMemberListToolbar(props: TeamMemberListToolbarProps) {
  const {
    selectionMode,
    selectedCount,
    onSelectPage,
    onSelectAll,
    onDeselectAll,
    groups,
    selectedUsersGroups,
    appRules,
    onAddToGroups,
    onMassAssignAppRule,
    offset,
    limit,
    total,
    onPrevPage,
    onNextPage
  } = props;

  const isSelectionHelpBarVisible = selectedCount > 0;
  const isAllSelected = selectedCount === total;
  const isAllSelectedAcrossPages = isAllSelected && total > limit;

  const searchDropdownItems = [
    {
      text: 'Select All',
      onSelect: onSelectPage
    }
  ];

  if (total > limit) {
    searchDropdownItems.push({
      text: 'Select All Across All Pages',
      onSelect: onSelectAll
    });
  }

  if (selectedCount > 0) {
    searchDropdownItems.push({
      text: 'Clear Selection',
      onSelect: onDeselectAll
    });
  }

  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data?.team;

  const canAssignTeamMembersToGroups =
    perms?.assign_team_members_to_groups || groups.length > 0;
  const canAssignToAppRule = !!perms?.manage_app_rules;

  return (
    <>
      <Wrapper>
        <Group css={{ gridArea: 'action' }}>
          <Checkbox
            disabled={total < 1}
            checked={total > 0 && selectedCount === total}
            indeterminate={0 < selectedCount && selectedCount < total}
            onChange={() => {
              // none
              if (selectedCount === 0) {
                onSelectPage();
              }
              // all
              else if (selectedCount === total) {
                onDeselectAll();
              }
              // some
              else {
                onSelectAll();
              }
            }}
          />
          <Flyout items={searchDropdownItems}>
            {({ props, isOpen }) => (
              <ArrowToggle
                open={isOpen}
                title="Selection options"
                icon="arrow"
                {...props}
              />
            )}
          </Flyout>

          <Divider />

          {selectedCount > 0 && (
            <SelectorWrapper>
              <MultiSelect
                showSearchbar={groups.length >= 8}
                onSubmit={values => {
                  const groupIds = Array.from(values) as number[];
                  const update = groupIds.length > 0 ? groupIds : null;
                  onAddToGroups?.(update);
                }}
                items={groups.map(({ id, name }) => {
                  // defaultSelected if all selected users belong to the group
                  const defaultSelected = selectedUsersGroups.every(groupIds =>
                    groupIds.includes(id)
                  );

                  // indeterminate if some selected users belong to the group
                  const indeterminate =
                    !defaultSelected &&
                    selectedUsersGroups.some(groupIds => groupIds.includes(id));

                  return {
                    text: name,
                    value: id,
                    defaultSelected,
                    indeterminate
                  };
                })}
              >
                {({ props, isOpen }) => (
                  <Button
                    // Temporarily disabling group assignment across all pages
                    // https://jira.unity3d.com/browse/PARSEC-1044
                    disabled={
                      !canAssignTeamMembersToGroups || isAllSelectedAcrossPages
                    }
                    level="secondary"
                    {...props}
                    icon={<ArrowIcon name="caret" open={isOpen} />}
                    version={version}
                  >
                    Assign to Groups
                  </Button>
                )}
              </MultiSelect>
              <Dropdown
                items={appRules.map(({ id, name }) => ({
                  text: name,
                  onSelect: () => onMassAssignAppRule?.(id)
                }))}
                version={version}
              >
                {({ props, isOpen }) => (
                  <Button
                    disabled={!canAssignToAppRule}
                    level="secondary"
                    {...props}
                    icon={<ArrowIcon name="caret" open={isOpen} />}
                    version={version}
                  >
                    Change Ruleset
                  </Button>
                )}
              </Dropdown>
            </SelectorWrapper>
          )}
        </Group>

        <Group css={{ gridArea: 'navigation' }}>
          {selectedCount > 0 && <Label bold>{selectedCount} Selected</Label>}

          <Divider />

          <Pagination
            offset={offset}
            count={total}
            limit={limit}
            onPrev={onPrevPage}
            onNext={onNextPage}
          />
        </Group>
      </Wrapper>

      <AnimateHeight height={isSelectionHelpBarVisible ? 'auto' : 0}>
        <HelpWrapper>
          {isAllSelected || selectionMode === 'exclude' ? (
            <p>
              {isAllSelected ? 'All' : ''} {selectedCount} user
              {selectedCount === 1 ? '' : 's'} in this list{' '}
              {selectedCount === 1 ? 'is' : 'are'} selected.{' '}
              <LinkButton onClick={onDeselectAll}>Clear Selection</LinkButton>
            </p>
          ) : (
            <p>
              {selectedCount} user{selectedCount === 1 ? '' : 's'} on this page{' '}
              {selectedCount === 1 ? 'is' : 'are'} selected.{' '}
              <LinkButton onClick={onSelectAll}>
                Select all {total} Users
              </LinkButton>
            </p>
          )}
        </HelpWrapper>
      </AnimateHeight>
    </>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  justifyContent: 'space-between',
  padding: '$medium 2.7rem',
  boxShadow: '0 0.1rem 0 $colors$pangoro',
  position: 'relative',
  gridAutoFlow: 'column',
  gridTemplateAreas: '"action navigation"',
  gridTemplateColumns: '1fr auto'
});

const Group = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '1.2rem',
  '@large': {
    gap: '1.2rem 2.45rem'
  }
});

const Divider = styled('hr', {
  height: '2.4rem',
  width: '0.1rem',
  backgroundColor: '$pancham',
  border: 'none'
});

const ArrowToggle = styled(IconButton, {
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const Label = styled('span', {
  fontFamily: '$newDefault',
  fontSize: '1rem',
  lineHeight: '$info',
  variants: {
    bold: {
      true: {
        fontWeight: 'bold'
      },
      false: {
        fontWeight: 'normal'
      }
    }
  }
});

const HelpWrapper = styled('div', {
  fontFamily: '$newDefault',
  padding: '$large',
  backgroundColor: '$pangoro',
  textAlign: 'center',
  fontSize: '$info',
  lineHeight: '$info',
  fontWeight: 'bold'
});

const LinkButton = styled('button', {
  fontSize: '$info',
  lineHeight: '$info',
  fontWeight: 'bold',
  color: '$primary500',
  cursor: 'pointer',
  fontFamily: '$newDefault'
});

const SelectorWrapper = styled('div', {
  display: 'flex',
  gap: '0.8rem'
});
