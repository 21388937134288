// @parsec
import { customer, teamBilling } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team_billing');

export function useTeamBillingDetailsData() {
  return useQueryData(key.all(), customer);
}
/** ****************************************************************************/

export function useGetTeamBillingDetails() {
  const kessel = useKessel();
  const me = useGetMe();

  const vars = { team_id: me.data?.team_id ?? '' };

  const enabled = Boolean(me.data?.team_id);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamBilling.getTeamBillingDetails(req);
      return res.body?.data;
    },
    {
      enabled
    }
  );

  const error = useWrapError(result.error, {
    error: "couldn't get team billing details."
  });

  return { ...result, error };
}
/** ****************************************************************************/

export function useUpdateTeamBillingDetails() {
  const kessel = useKessel();
  const cache = useTeamBillingDetailsData();
  const me = useGetMe();

  const teamId = me.data?.team_id ?? '';

  const result = useMutation(
    async function mutationFn(vars: teamBilling.UpdateTeamBillingDetailsReq) {
      const res = await kessel.teamBilling.updateTeamBillingDetails({
        ...vars,
        team_id: teamId
      });
      return res.body?.data;
    },
    {
      onSuccess() {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "couldn't update team billing details."
  });
  return { ...result, error };
}
