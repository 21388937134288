import { styled } from '@parsec/components';

export const ErrorMessage = styled('p', {
  color: '$error500',
  marginBottom: '$large',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  '&:first-letter': {
    textTransform: 'capitalize'
  }
});
