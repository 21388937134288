/**
 * Default style for the Trigger button
 * See https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=975-12821&t=ZLvcx2T1S1C1OJrd-4
 */
export const triggerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  height: '3.6rem',
  fontSize: '1.4rem',
  lineHeight: '$attribution',
  boxShadow:
    '0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.18), inset 0rem 0.1rem 0rem rgba(255, 255, 255, 0.07)',
  borderRadius: '$medium',
  color: '$consoleWhite',
  backgroundColor: '$chandelure',
  padding: '0.6rem 1rem',
  cursor: 'pointer'
};

export const StyledContent = {
  backgroundColor: '$carkol',
  border: '0.05rem solid rgba(249, 249, 249, 0.1)',
  boxShadow:
    '0rem 0.2rem 4rem 2rem rgba(0, 0, 0, 0.15), inset 0rem 0.1rem 0rem rgba(255, 255, 255, 0.2)',
  borderRadius: '$large',
  paddingRight: '$small',

  variants: {
    size: {
      xsmall: {
        width: '19.2rem'
      },
      small: {
        width: '22rem'
      },
      medium: {
        width: '24rem'
      },
      large: {
        width: '28rem'
      }
    }
  }
};

export const StyledViewport = {
  maxHeight: '24.4rem',
  padding: '1rem $xlarge 1rem $medium'
};

export const StyledItem = {
  borderRadius: '$small',
  lineHeight: '$info',
  fontSize: '$newBody',
  padding: '1rem',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'rgba(249, 249, 249, 0.05)',
    outline: 'none'
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 $space$xxsmall $colors$consoleWhite',
    backgroundColor: 'rgba(249, 249, 249, 0.05)',
    outline: 'none'
  }
};
