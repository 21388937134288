// Open source lib with how to detect card https://github.com/coderdiaz/react-card-brand/blob/main/src/utils/validation.ts

import { Card } from '@parsec/kessel';
import { styled } from '@parsec/styles';

import Icon from '../Icon';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '4rem 10.9rem 1fr',
  gridAutoFlow: 'column'
});

const Span = styled('span', {
  fontSize: '1.4rem',
  fontWeight: '$bold',
  lineHeight: '2.4rem'
});

interface BillingCardPreviewProps
  extends Pick<Card, 'last4' | 'expiry_month' | 'expiry_year'> {}

export default function BillingCardPreview({
  last4,
  expiry_month,
  expiry_year
}: BillingCardPreviewProps) {
  const yearLen = Math.ceil(Math.log10(expiry_year + 1));
  // if given two digits, expand to full year
  const fullExpiryYear = yearLen >= 4 ? expiry_year : `20${expiry_year}`; // update this when we're past 2099.

  const formattedMonth =
    expiry_month < 10
      ? expiry_month.toString().padStart(2, '0')
      : expiry_month.toString();

  return (
    <Wrapper>
      <Icon name="creditCard" />
      <Span>******{last4}</Span>
      <Span css={{ justifySelf: 'end' }}>
        EXP {formattedMonth} / {fullExpiryYear}
      </Span>
    </Wrapper>
  );
}
