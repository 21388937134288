import { CSSProperties, ComponentPropsWithRef, forwardRef } from 'react';

import { styled } from '@parsec/styles';

import Icon, { IconNames } from '../Icon';

const Button = styled('button', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  textAlign: 'center',
  fontSize: '$body',
  fontWeight: 'bold',
  width: '3.6rem',
  height: '3.6rem',
  lineHeight: '3.6rem',
  border: 'none',
  borderRadius: '5.4rem',
  backgroundColor: '$primary500',
  color: '$consoleWhite',
  transition: '125ms background-color ease, 125ms color ease',
  cursor: 'pointer',
  outline: 0,
  '&:hover': {
    backgroundColor: '$primary400'
  },
  '&:focus': {
    backgroundColor: '$primary400'
  },
  '&:active': {
    backgroundColor: '$primary400'
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color: '$nice',
    cursor: 'default'
  },
  variants: {
    kind: {
      basic: {
        backgroundColor: 'transparent',
        color: '$consoleWhite',
        '&:focus, &:hover': {
          backgroundColor: '$pancham'
        },
        '&:focus': {
          backgroundColor: '$pancham'
        },
        '&:active': {
          backgroundColor: '$pancham'
        }
      },
      principal: {
        backgroundColor: '$zekrom',
        color: '$primary500',
        '&:hover': {
          backgroundColor: '$primary400',
          color: '$consoleWhite'
        },
        '&:focus': {
          backgroundColor: '$primary400',
          color: '$consoleWhite'
        },
        '&:active': {
          backgroundColor: '$primary400',
          color: '$consoleWhite'
        }
      },
      positive: {
        backgroundColor: '$zekrom',
        color: '$success500',
        '&:hover': {
          backgroundColor: '$success400',
          color: '$consoleWhite'
        },
        '&:focus': {
          backgroundColor: '$success400',
          color: '$consoleWhite'
        },
        '&:active': {
          backgroundColor: '$success400',
          color: '$consoleWhite'
        }
      },
      negative: {
        backgroundColor: '$zekrom',
        color: '$error500',
        '&:hover': {
          backgroundColor: '$error400',
          color: '$consoleWhite'
        },
        '&:focus': {
          backgroundColor: '$error400',
          color: '$consoleWhite'
        },
        '&:active': {
          backgroundColor: '$error400',
          color: '$consoleWhite'
        }
      },
      highlight: {
        backgroundColor: 'transparent',
        color: '$consoleWhite',
        '&:hover': {
          backgroundColor: '#D08E0D'
        },
        '&:focus': {
          backgroundColor: '#D08E0D'
        },
        '&:active': {
          backgroundColor: '#D08E0D'
        }
      }
    }
  }
});

export interface IconButtonProps extends ComponentPropsWithRef<typeof Button> {
  className?: string;
  icon: IconNames;
  title: string;
  id?: string;
  kind?: 'basic' | 'principal' | 'positive' | 'negative' | 'highlight';
  disabled?: boolean;
  style?: CSSProperties;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const {
      className,
      icon,
      title,
      id,
      kind = 'basic',
      disabled,
      onClick,
      style,
      ...rest
    } = props;

    return (
      <Button
        ref={ref}
        className={className}
        id={id}
        kind={kind}
        disabled={disabled}
        style={style}
        onClick={onClick}
        {...rest}
      >
        <Icon name={icon} title={title} />
      </Button>
    );
  }
);

export default IconButton;
