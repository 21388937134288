import { formatDate, now, RelativeDateOption } from '@parsec/time';

const LONG_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: '2-digit'
};

const SHORT_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

const FULL_FORMAT: Intl.DateTimeFormatOptions = {
  dateStyle: 'full',
  timeStyle: 'full'
};

export interface TimeProps {
  className?: string;
  date: Date;
  format?: 'short' | 'long' | 'relative';
  id?: string;
  relativeEndDate?: Date;
}

export default function Time(props: TimeProps) {
  const {
    className,
    date,
    format = 'long',
    id,
    relativeEndDate = now()
  } = props;

  const formatOptions: Intl.DateTimeFormatOptions | RelativeDateOption =
    format === 'relative'
      ? { isRelative: true, relativeEndDate }
      : format === 'long'
        ? LONG_FORMAT
        : SHORT_FORMAT;
  return (
    <time
      className={className}
      dateTime={date.toISOString()}
      title={formatDate(date, FULL_FORMAT)}
      id={id}
    >
      {formatDate(date, formatOptions)}
    </time>
  );
}
