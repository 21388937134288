// @parsec
import { schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

/******************************************************************************
 * Get the Team's Machine key.
 ******************************************************************************/

const key = new KeyFactory('team_machine_key');

export function useTeamMachineKeyData() {
  return useQueryData(key.all(), schema.teamMachineKey);
}

export function useGetTeamMachineKey() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const vars = {
    team_id: teamId
  };

  const enabled = vars.team_id !== '';

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.teamMachineKey.getTeamMachineKey(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Team Machine key."
  });

  return { ...result, error };
}

export function useCreateTeamMachineKey() {
  const kessel = useKessel();
  const cache = useTeamMachineKeyData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn() {
      const res = await kessel.teamMachineKey.generateTeamMachineKey({
        team_id: teamId
      });
      return res.body?.data;
    },
    {
      onSuccess: () => cache.invalidate()
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create team machine key"
  });

  return { ...result, error };
}

export function useDeleteTeamMachineKey() {
  const kessel = useKessel();
  const cache = useTeamMachineKeyData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn() {
      return await kessel.teamMachineKey.deleteTeamMachineKey({
        team_id: teamId
      });
    },
    {
      onSuccess: () => cache.remove()
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't delete team machine key."
  });

  return { ...result, error };
}
