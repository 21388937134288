/**
 * When to use this Alert Modal component ?
 * If you need to implement a modal that interrupts the user with important content and expects a response.
 * Usually it asks questions to confirm user taken actions.
 * Used generally for interactions that cannot be undone or things that will have a significant impact.
 */

import type { AlertDialogProps } from '@radix-ui/react-alert-dialog';
import type { ReactNode } from 'react';

import { forwardRef } from 'react';

import * as AlertDialog from '@radix-ui/react-alert-dialog';

import { styled, keyframes, VariantProps, CSS } from '@parsec/styles';

import Icon from '../Icon';
import IconButton from '../IconButton';

import alertModalStyles from './alertModal.module.css';

export interface AlertModalProps extends AlertDialogProps {}

function AlertModal(props: AlertModalProps) {
  const { children, defaultOpen, open, onOpenChange, ...rest } = props;

  return (
    <AlertDialog.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      {children}
    </AlertDialog.Root>
  );
}

export default AlertModal;

// Trigger
// Button that opens the dialog
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Trigger = (props: TriggerProps) => {
  const { children, asChild = false, ...rest } = props;

  return (
    <AlertDialog.Trigger asChild={asChild} {...rest}>
      {children}
    </AlertDialog.Trigger>
  );
};

// Portal
// When used, portals your overlay and content parts into the body.
interface PortalProps {
  children: ReactNode;
  forceMount?: true;
  container?: HTMLElement;
}

const Portal = (props: PortalProps) => {
  const { children, forceMount, container = document.body, ...rest } = props;

  return (
    <AlertDialog.Portal forceMount={forceMount} container={container} {...rest}>
      {children}
    </AlertDialog.Portal>
  );
};

// Overlay
// A layer that covers the inert portion of the view when the dialog is open.
interface OverlayProps {
  children?: ReactNode;
  asChild?: boolean;
  forceMount?: true;
}

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(function (
  props: OverlayProps,
  ref
) {
  const { children, asChild = false, forceMount, ...rest } = props;
  return (
    <AlertDialogOverlay
      asChild={asChild}
      forceMount={forceMount}
      ref={ref}
      className={alertModalStyles.AlertDialogOverlay}
      {...rest}
    >
      {children}
    </AlertDialogOverlay>
  );
});

// Content
// Contains content to be rendered when the dialog is open.
interface ContentProps extends AlertDialog.AlertDialogContentProps {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
  asChild?: boolean;
  forceMount?: true;
}

const Content = ({
  children,
  size,
  asChild = false,
  forceMount,
  ...rest
}: ContentProps) => {
  return (
    <AlertDialogContent
      asChild={asChild}
      forceMount={forceMount}
      size={size}
      className={alertModalStyles.AlertDialogContent}
      {...rest}
    >
      {children}
    </AlertDialogContent>
  );
};

// Cancel
// A button that closes the dialog.
// This button should be distinguished visually from AlertDialog.Action buttons.
interface CancelProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Cancel = ({ children, asChild = false, ...rest }: CancelProps) => {
  return (
    <AlertDialog.Cancel asChild={asChild} {...rest}>
      {children}
    </AlertDialog.Cancel>
  );
};

// Action
// A button that closes the dialog.
// These buttons should be distinguished visually from the AlertDialog.Cancel button.
interface ActionProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Action = ({ children, asChild = false, ...rest }: ActionProps) => {
  return (
    <AlertDialog.Action asChild={asChild} {...rest}>
      {children}
    </AlertDialog.Action>
  );
};

// Title
// An accessible name to be announced when the dialog is opened.
//Alternatively, you can provide aria-label or aria-labelledby to AlertDialog.Content and exclude this component.
interface TitleProps {
  css?: CSS;
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
}

const Title = ({ children, asChild = false, ...rest }: TitleProps) => {
  return (
    <AlertDialogTitle asChild={asChild} {...rest}>
      {children}
    </AlertDialogTitle>
  );
};

const AlertDialogDescription = styled(AlertDialog.Description, {
  fontSize: '1rem',
  lineHeight: '$info',
  variants: {
    unstyled: {
      true: {
        fontSize: 'inherit',
        lineHeight: 'inherit'
      }
    }
  }
});

// Description
// An accessible name to be announced when the dialog is opened.
// Alternatively, you can provide aria-describedby to AlertDialog.Content and exclude this component.

interface DescriptionProps {
  children?: ReactNode;
  asChild?: boolean; // Set this to true if you're using a react component as a child
  unstyled?: boolean;
}

const Description = ({
  children,
  asChild = false,
  ...rest
}: DescriptionProps) => {
  return (
    <AlertDialogDescription asChild={asChild} {...rest}>
      {children}
    </AlertDialogDescription>
  );
};

// Modal Header
// Use this header if you need to implement an alert modal with this style
// https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=610-12644&t=5Tuj771WkyBGMhy6-4
interface HeaderProps {
  children: ReactNode;
  onClose?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const Header = ({ children, onClose, ...rest }: HeaderProps) => {
  return (
    <StyledModalHeader {...rest}>
      {children}
      <AlertDialog.Cancel asChild>
        <CloseBtn onClick={onClose} icon="ex" title="Close" kind="basic" />
      </AlertDialog.Cancel>
    </StyledModalHeader>
  );
};

// Dialogue Header
// Use this header if you need to implement an alert modal with this style
// https://www.figma.com/file/HU1AfYnEQfTg4uwQwV9Bxh/Parsec-Business-Design-System-(Tether-UI)?type=design&node-id=610-12891&t=5Tuj771WkyBGMhy6-4
interface DialogueHeaderProps {
  children: ReactNode;
  onClose?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const DialogueHeader = ({
  children,
  onClose,
  ...rest
}: DialogueHeaderProps) => {
  return (
    <StyledDialogueHeader {...rest}>
      {children}
      <AlertDialog.Cancel asChild>
        <CloseBtn onClick={onClose} icon="ex" title="Close" kind="basic" />
      </AlertDialog.Cancel>
    </StyledDialogueHeader>
  );
};

const ErrorContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.1rem 1fr',
  alignItems: 'center',
  gridColumnGap: '1.4rem',
  padding: '1rem $medium 1rem $large',
  borderRadius: '$medium',
  color: '$ultraDark',
  variants: {
    visuallyHidden: {
      true: { display: 'none' }
    },
    type: {
      error: {
        backgroundColor: '$error500',
        color: '$consoleWhite'
      },
      warning: {
        backgroundColor: '$warning500'
      }
    }
  }
});

const ErrorCopy = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution'
});

/**
 * Error message within the alert dialog. For screen readers to know when to pop this up, we need to have it exist in the DOM but visually hidden. When the message changes, that's when the screen reader will read it out.
 * @param param0
 * @returns
 */
const ErrorMessage = ({
  type,
  message
}: {
  type: VariantProps<typeof ErrorContainer>['type'];
  message?: ReactNode;
}) => {
  return (
    <ErrorContainer type={type} role="alert" visuallyHidden={!Boolean(message)}>
      <Icon name="warningSign" css={{ width: '2.1rem', height: '2rem' }} />
      <ErrorCopy>{message}</ErrorCopy>
    </ErrorContainer>
  );
};

const ContentWrapper = styled('div', {
  padding: '1rem $xxlarge',
  overflowX: 'hidden',
  overflowY: 'scroll'
});

const FooterContainer = styled('footer', {
  display: 'flex',
  backgroundColor: '$carkol',
  padding: '$xxlarge',
  gap: '$xxlarge',
  variants: {
    error: {
      true: {
        backgroundColor: '$perfectGray',
        padding: '$medium',
        gap: 0,
        display: 'grid',
        gridAutoFlow: 'row'
      }
    }
  }
});

const ActionsWrapper = styled('div', {
  display: 'flex',
  gap: '$xxlarge',
  padding: 0,
  variants: {
    error: {
      true: {
        padding: '$xxlarge $large $large'
      }
    }
  }
});

type FooterProps =
  | {
      children: ReactNode;
      errorMessage?: never;
      errorType?: never;
    }
  | {
      children: ReactNode;
      errorMessage: ReactNode;
      errorType: VariantProps<typeof ErrorContainer>['type'];
    };

const Footer = ({ children, errorMessage, errorType }: FooterProps) => (
  <FooterContainer error={Boolean(errorMessage)}>
    <ErrorMessage type={errorType} message={errorMessage} />
    <ActionsWrapper error={Boolean(errorMessage)}>{children}</ActionsWrapper>
  </FooterContainer>
);

AlertModal.Trigger = Trigger;
AlertModal.Portal = Portal;
AlertModal.Overlay = Overlay;
AlertModal.Content = Content;
AlertModal.Cancel = Cancel;
AlertModal.Action = Action;
AlertModal.Title = Title;
AlertModal.Description = Description;
AlertModal.Header = Header;
AlertModal.DialogueHeader = DialogueHeader;
AlertModal.ContentWrapper = ContentWrapper;
AlertModal.Footer = Footer;
AlertModal.ErrorMessage = ErrorMessage;

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const AlertDialogOverlay = styled(AlertDialog.Overlay, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
});

const AlertDialogContent = styled(AlertDialog.Content, {
  minHeight: '25.6rem',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  backgroundColor: '$computerBlack',
  border: '0.05rem solid rgba(249, 249, 249, 0.1)',
  boxShadow:
    '0 0.2rem 4rem 2rem rgba(0, 0, 0, 0.15), inset 0 0.1rem 0 rgba(255, 255, 255, 0.2)',
  borderRadius: '$xlarge',
  overflow: 'hidden',
  variants: {
    size: {
      small: {
        width: '38rem'
      },
      medium: {
        width: '54rem'
      },
      large: {
        width: '62rem'
      }
    }
  }
});

const AlertDialogTitle = styled(AlertDialog.Title, {
  fontWeight: '$bold',
  fontSize: '$subtitle',
  lineHeight: '2.9rem',
  textTransform: 'capitalize'
});

const StyledDialogueHeader = styled('header', {
  padding: '$xxlarge $xxlarge 1rem $xxlarge',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'auto',
  gap: '1rem'
});

const StyledModalHeader = styled(StyledDialogueHeader, {
  padding: '$xxlarge $xxlarge 0 $xxlarge',

  '&::after': {
    content: '',
    display: 'block',
    height: '0.1rem',
    backgroundColor: '$pangoro',
    gridRow: 'auto',
    gridColumn: '1 / span 2'
  }
});

const CloseBtn = styled(IconButton, {
  gridRow: '1',
  gridColumn: '2'
});
