// @parsec
import { schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useGetTeam } from './team';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

export function useScimKeyData() {
  return useQueryData(key.all(), schema.scimApiKey);
}

/******************************************************************************
 * Create a SCIM auth API key for the team. This data is not visible to the user after creation.
 ******************************************************************************/

export function useCreateScimApiKey() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const scimKeyCache = useScimKeyData();

  const result = useMutation(
    async function mutationFn() {
      const res = await kessel.scimApiKey.createScimApiKey({
        team_id: teamId
      });
      return res.body.data;
    },
    {
      onSuccess: () => {
        scimKeyCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't create SCIM API key."
  });

  return { ...result, error };
}

/******************************************************************************
 * Get the Team's SCIM API key.
 ******************************************************************************/

const key = new KeyFactory('scim_key');

export function useGetScimApiKey() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const isScimEnabled =
    teamQuery.data?.capabilities.settings.is_scim_enabled ?? false;

  const vars = {
    team_id: teamId
  };

  const enabled = vars.team_id !== '' && isScimEnabled;

  const result = useQuery(
    key.list(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.scimApiKey.getScimApiKey(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get SCIM API key."
  });

  return { ...result, error };
}

/******************************************************************************
 * Delete the SCIM auth API key for the team.
 ******************************************************************************/

export function useDeleteScimApiKey() {
  const kessel = useKessel();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';
  const scimKeyCache = useScimKeyData();

  const result = useMutation(
    async function mutationFn() {
      return await kessel.scimApiKey.deleteScimApiKey({
        team_id: teamId
      });
    },
    {
      onSuccess: () => {
        scimKeyCache.remove();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't delete SCIM API key."
  });

  return { ...result, error };
}
