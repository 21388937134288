import { useState } from 'react';

import { TeamSubscription, WarpSubscription } from '@parsec/kessel';

import Button from './Button';
import Modal from './Modal';
import Time from './Time';

interface EditSubscriptionModalProps {
  isOpen: boolean;
  onClose(): void;
  onAfterClose(): void;
  onSubmit(): void;
  subscription?: TeamSubscription | WarpSubscription;
  endDate?: Date;
  type: 'teams' | 'warp';
}

function EditSubscriptionModal(props: EditSubscriptionModalProps) {
  const {
    isOpen,
    subscription,
    type,
    endDate,
    onClose,
    onAfterClose,
    onSubmit
  } = props;
  const [loading, setLoading] = useState(false);
  const cancelled = Boolean(subscription?.cancelled_at);
  const trial = subscription?.status === 'in_trial';

  return (
    <Modal
      title={`Confirm Your ${trial ? 'Trial' : 'Plan'} ${
        cancelled ? 'Reactivation' : 'Cancellation'
      }`}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
      size="small"
    >
      Would you like to {cancelled ? 'reactivate' : 'cancel'} your{' '}
      {trial ? 'trial' : 'subscription'}?
      {!cancelled && (
        <>
          <br />
          <br />
          You will continue to have access to{' '}
          {type === 'teams' ? 'Teams' : 'Warp'} features until the end of your{' '}
          {trial ? 'trial period' : 'subscription period'}
          {endDate && <> on {<Time date={endDate} />}</>}.
        </>
      )}
      <Modal.Actions>
        <Button
          loading={loading}
          kind="error"
          onClick={async () => {
            setLoading(true);
            await onSubmit();
            setLoading(false);
            onClose();
          }}
        >
          {cancelled ? 'Reactivate' : 'Confirm Cancellation'}
        </Button>
        <Button onClick={onClose} level="secondary">
          Back
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default EditSubscriptionModal;
