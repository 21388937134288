import {
  type ReactNode,
  type ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';

import { useSelect } from 'downshift';
import { createPortal } from 'react-dom';
import { PopperProps, usePopper } from 'react-popper';

import { useDebounce } from '@parsec/hooks';
import { CSS, styled } from '@parsec/styles';

import Avatar from '../Avatar';
import Icon, { IconNames } from '../Icon';
import Input from '../Input';
import Radio from '../Radio';

export interface DropdownItemType {
  icon?: IconNames | number;
  text: ReactNode;
  value?: string;
  key?: string;
  onSelect?(): void;
  disabled?: boolean;
  clickable?: boolean;
  action?: { text: string; onClick(): void } | null;
}

type DropdownChildrenFunction = (options: {
  isOpen: boolean;
  props: object;
  selectedItem: DropdownItemType | null;
}) => ReactNode;

export interface DropdownProps {
  className?: string;
  y?: number;
  x?: number;
  placement?: PopperProps<unknown>['placement'];
  items: DropdownItemType[] | DropdownItemType[][];
  defaultValue?: string;
  showSearchBar?: boolean;
  placeholder?: string;
  children: DropdownChildrenFunction;
  css?: CSS;
  boundaryElement?: Element;
  toggleOnScroll?: boolean;
  version?: 'newFont';
  portalContainer?: HTMLElement;
}

export default function Dropdown(props: DropdownProps) {
  const {
    x = 0,
    y = 8,
    placement = 'bottom-start',
    items,
    defaultValue,
    showSearchBar = false,
    placeholder = 'Search',
    children,
    css,
    boundaryElement = 'clippingParents',
    toggleOnScroll = true,
    version,
    portalContainer = document.getElementById('popovers') ?? document.body
  } = props;

  // Popover configuration
  const toggleRef = useRef<HTMLElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const offset: [number, number] =
    placement.startsWith('left') || placement.startsWith('right')
      ? [y, x]
      : [x, y];

  const { styles, attributes, forceUpdate } = usePopper(
    toggleRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'right', 'left', 'bottom'],
            boundary: boundaryElement
          }
        },
        {
          name: 'offset',
          options: { offset }
        }
      ]
    }
  );

  // Search function
  const [queryString, setQueryString] = useState('');
  const onDebouncedInput = useDebounce(setQueryString, { ms: 500 });

  // Selection configuration
  const {
    isOpen,
    toggleMenu,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
    selectedItem
  } = useSelect<DropdownItemType>({
    defaultSelectedItem: items
      .flat()
      .find(item => (!defaultValue ? false : item.text === defaultValue)),
    items: items.flat(),
    itemToString: item => `${item?.text}`,
    onIsOpenChange: changes => {
      if (changes.isOpen && forceUpdate) {
        Promise.resolve().then(forceUpdate);
      }
    },
    onSelectedItemChange: state => {
      state.selectedItem?.onSelect?.();
    }
  });

  const handleToggle = useCallback(() => {
    if (toggleOnScroll) {
      toggleMenu();
    }
  }, [toggleMenu, toggleOnScroll]);

  useEffect(() => {
    if (forceUpdate) {
      Promise.resolve().then(forceUpdate);
    }
    setQueryString('');
  }, [isOpen, forceUpdate, setQueryString]);

  useEffect(() => {
    if (isOpen) window.addEventListener('scroll', handleToggle);
    return () => window.removeEventListener('scroll', handleToggle);
  }, [isOpen, handleToggle]);

  // Items
  let index = 0;

  const menus = useMemo(
    () =>
      Array.isArray(items[0])
        ? (items as DropdownItemType[][])
        : [items as DropdownItemType[]],
    [items]
  );

  const isItemMatched = useCallback(
    (itemText: ReactNode) => {
      return !!itemText
        ?.toString()
        .toLowerCase()
        .includes(queryString.toLowerCase());
    },
    [queryString]
  );

  return (
    <>
      {children({
        isOpen,
        props: getToggleButtonProps({ ref: toggleRef }),
        selectedItem
      })}

      {createPortal(
        <DropdownMenuWrapper
          {...getMenuProps({
            ref: popperRef,
            css,
            style: styles.popper,
            ...attributes.popper
          })}
        >
          {isOpen && (
            <DropdownMenu onMouseLeave={() => setHighlightedIndex(-1)}>
              {showSearchBar && (
                <StyledInput
                  icon="search"
                  role="search"
                  onInput={(e: ChangeEvent<HTMLInputElement>) =>
                    onDebouncedInput(e.target.value)
                  }
                  placeholder={placeholder}
                  version={version}
                />
              )}
              {menus.map((items, i) => (
                <DropdownUl key={i}>
                  {items.map(item => {
                    const itemIndex = index;
                    index += 1;
                    /** Read:
                     * Filtering must happen at this step, because doing so earlier will cause item index to be mismatched on every render and a selection/deselection event will do the unexpected
                     */
                    const isMatch = isItemMatched(item.text);
                    return isMatch ? (
                      <DropdownLi
                        {...getItemProps({
                          key: item.key || `${item.text}`,
                          disabled: item.disabled,
                          item,
                          index: itemIndex,
                          clickable: item.clickable,
                          highlighted:
                            highlightedIndex >= 0
                              ? highlightedIndex === itemIndex
                              : selectedItem?.text === item.text
                        })}
                        version={version}
                      >
                        <Radio
                          readOnly
                          checked={selectedItem?.text === item.text}
                        />

                        {item.icon && typeof item.icon === 'string' ? (
                          <Icon name={item.icon as IconNames} />
                        ) : item.icon ? (
                          <Avatar size={18} userId={item.icon} />
                        ) : null}

                        <span>{item.text}</span>
                        {item.action && (
                          <Action onClick={item.action.onClick}>
                            {item.action.text}
                          </Action>
                        )}
                      </DropdownLi>
                    ) : null;
                  })}
                </DropdownUl>
              ))}
            </DropdownMenu>
          )}
        </DropdownMenuWrapper>,
        portalContainer
      )}
    </>
  );
}

export const DropdownMenuWrapper = styled('div', {
  minWidth: '22rem',
  '&:focus': {
    outlineStyle: 'none'
  }
});

export const DropdownMenu = styled('div', {
  backgroundColor: '$perfectGray',
  zIndex: 1000,
  padding: '$medium 0rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '24rem',
  border: '.05rem solid rgba(249, 249, 249, 0.1)',
  boxShadow:
    '0rem $xsmall 4rem $xxlarge rgba(0, 0, 0, 0.15), inset 0rem .1rem 0rem rgba(255, 255, 255, 0.2)',
  borderRadius: '$large'
});

export const DropdownUl = styled('ul', {
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
  maxHeight: '24.4rem'
});

export const DropdownLi = styled('li', {
  padding: '1rem $large', //not a token
  borderRadius: '$small',
  cursor: 'pointer',
  fontSize: '1.4rem', //not a token
  lineHeight: '$info',
  whiteSpace: 'nowrap',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  margin: '0 $medium',
  gap: '1.7rem', //not a token
  overflow: 'hidden',
  color: '$consoleWhite',
  '&:last-child': {
    marginBottom: '$medium'
  },
  '&:hover': {
    backgroundColor: 'rgba(249, 249, 249, 0.05)'
  },
  alignItems: 'center',

  variants: {
    highlighted: {
      true: {
        backgroundColor: 'rgba(249, 249, 249, 0.05)'
      },
      false: {}
    },

    clickable: {
      false: {
        cursor: 'default'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$info',
        lineHeight: '$body'
      }
    }
  }
});

const StyledInput = styled(Input, {
  marginBottom: '$small',
  borderRadius: '$medium',
  margin: '0 $medium $medium',
  boxShadow: '0rem .1rem 0 rgba(255, 255, 255, 0.1)',
  backgroundColor: '$samehada'
});

const Action = styled('button', {
  color: '$error500',
  cursor: 'pointer'
});
