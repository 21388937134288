// @parsec
import { tfa, schema } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useMeData } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('tfa');
type Context = { previous?: schema.TFA };

export function useTFAData() {
  return useQueryData(key.all(), schema.tfa);
}

export function useCreateTFASetupURI() {
  const kessel = useKessel();

  const result = useMutation(kessel.tfa.createTFASetupURI);

  const error = useWrapError(result.error, {
    error: "Couldn't set up two-factor authentication."
  });
  return { ...result, error };
}

export function useGetTFA() {
  const kessel = useKessel();

  const result = useQuery(key.all(), async function queryFn() {
    const res = await kessel.tfa.getTFA();
    return res.data;
  });

  const error = useWrapError(result.error, {
    error: "Couldn't get two-factor authentication settings."
  });
  return { ...result, error };
}

export function useEnableTFA() {
  const kessel = useKessel();

  const tfaCache = useTFAData();
  const meCache = useMeData();

  const result = useMutation(
    async function (vars: tfa.EnableTFAReq) {
      const res = await kessel.tfa.enableTFA(vars);
      return res.data;
    },
    {
      onMutate(vars) {
        tfaCache.set({ recovery_email: vars.email ?? '' });

        const me = meCache.get();
        if (me) meCache.set({ ...me, has_tfa: true });
      },
      onSuccess() {
        tfaCache.invalidate();
        meCache.invalidate();
      },
      onError() {
        tfaCache.remove();

        const me = meCache.get();
        if (me) meCache.set({ ...me, has_tfa: false });
        meCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't enable two-factor authentication."
  });
  return { ...result, error };
}

export function useResetTFABackupCodes() {
  const kessel = useKessel();

  const result = useMutation(async function (vars: {
    password: string;
    tfa?: string;
  }) {
    const res = await kessel.tfa.resetTFABackupCodes(vars);
    return res.data;
  });

  const error = useWrapError(result.error, {
    error: "Couldn't regenerate backup codes."
  });
  return { ...result, error };
}

export function useSetTFARecoveryEmail() {
  const cache = useTFAData();
  const kessel = useKessel();

  const result = useMutation(kessel.tfa.setTFARecoveryEmail, {
    onMutate(vars) {
      const previous = cache.get();

      if (previous) cache.set({ ...previous, recovery_email: vars.email });
      return { previous };
    },
    onSuccess() {
      cache.invalidate();
    },
    onError(_err, _vars, context?: Context) {
      if (context?.previous) cache.set(context?.previous);
      cache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't set recovery email."
  });
  return { ...result, error };
}

export function useSendTfaResetEmailV2() {
  const kessel = useKessel();
  const result = useMutation(kessel.tfa.sendTfaResetEmailV2);

  const error = useWrapError(result.error, {
    error: "Couldn't send reset email."
  });
  return { ...result, error };
}

export function useSendTFAResetEmail() {
  const kessel = useKessel();
  const result = useMutation(kessel.tfa.sendTFAResetEmail);

  const error = useWrapError(result.error, {
    error: "Couldn't send reset email."
  });
  return { ...result, error };
}

/**
 * Removed TFA via the v2 endpoint. Takes in the OTP code to finalize disabling TFA.
 */
export function useResetTfaV2() {
  const kessel = useKessel();
  const tfaCache = useTFAData();
  const meCache = useMeData();

  const result = useMutation(kessel.tfa.resetTfaV2, {
    onMutate() {
      const previous = tfaCache.get();
      tfaCache.remove();

      const me = meCache.get();
      if (me) {
        meCache.set({ ...me, has_tfa: false });
      }

      return { previous } as Context;
    },
    onSuccess() {
      tfaCache.remove();
      meCache.invalidate();
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) {
        tfaCache.set(context?.previous);
      }
      tfaCache.invalidate();

      const me = meCache.get();
      if (me) {
        meCache.set({ ...me, has_tfa: true });
      }
      meCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't disable two-factor authentication."
  });
  return { ...result, error };
}

export function useDeleteTFAWithResetToken() {
  const kessel = useKessel();

  const tfaCache = useTFAData();
  const meCache = useMeData();

  const result = useMutation(kessel.tfa.deleteTFAWithResetToken, {
    onMutate() {
      const previous = tfaCache.get();
      tfaCache.remove();

      const me = meCache.get();
      if (me) meCache.set({ ...me, has_tfa: false });

      return { previous } as Context;
    },
    onSuccess() {
      tfaCache.remove();
      meCache.invalidate();
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) tfaCache.set(context?.previous);
      tfaCache.invalidate();

      const me = meCache.get();
      if (me) meCache.set({ ...me, has_tfa: true });
      meCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't reset two-factor authentication."
  });
  return { ...result, error };
}

export function useDisableTFA() {
  const kessel = useKessel();

  const tfaCache = useTFAData();
  const meCache = useMeData();

  const result = useMutation(kessel.tfa.disableTFA, {
    onMutate() {
      const previous = tfaCache.get();
      tfaCache.remove();

      const me = meCache.get();
      if (me) meCache.set({ ...me, has_tfa: false });

      return { previous } as Context;
    },
    onSuccess() {
      tfaCache.remove();
      meCache.invalidate();
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) tfaCache.set(context?.previous);
      tfaCache.invalidate();

      const me = meCache.get();
      if (me) meCache.set({ ...me, has_tfa: true });
      meCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't disable two-factor authentication."
  });
  return { ...result, error };
}
