import type { ComponentPropsWithRef, ReactNode } from 'react';

import { styled } from '@parsec/styles';

const Stack = styled('div', {
  display: 'flex',
  gap: '0.8rem',
  variants: {
    direction: {
      horizontal: {},
      vertical: { flexDirection: 'column' }
    }
  }
});

export function BadgeStack({ children, ...rest }: { children: ReactNode }) {
  return <Stack {...rest}>{children}</Stack>;
}

const Span = styled('span', {
  fontFamily: '$newDefault',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '$bold',
  padding: '$xsmall $large',
  color: '$ultraDark',
  textTransform: 'uppercase',
  minWidth: '6.8rem',
  variants: {
    pill: {
      true: {
        borderRadius: '9.9rem'
      }
    },
    orange: {
      true: {
        backgroundColor: '$warning500'
      }
    },
    white: {
      true: {
        backgroundColor: '$consoleWhite'
      }
    },
    green: {
      true: {
        backgroundColor: '$success300'
      }
    },
    blue: {
      true: {
        backgroundColor: '$primary500'
      }
    },
    disabled: {
      true: { backgroundColor: '$nice', color: '$ultraDark' }
    }
  }
});

type BadgeProps = ComponentPropsWithRef<typeof Span>;
export function Badge({ children, ...rest }: BadgeProps) {
  return <Span {...rest}>{children}</Span>;
}
