import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import { paginatedAccessLinkLedgerEntryData } from './schema';

export interface GetTeamAccessLinkLedgerReq {
  team_id: string;
  offset: number;
  limit: number;
}
export const getTeamAccessLinkLedger =
  (options: ApiOptions) =>
  /**
   * Get a Team's Access Link Ledger
   */
  (req: GetTeamAccessLinkLedgerReq) => {
    const { team_id, offset, limit } = req;
    return request({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/access-link-credits/ledger?offset=${offset}&limit=${limit}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      validator: paginatedAccessLinkLedgerEntryData
    });
  };
