import { cookie } from '@parsec/cookie';

import { useMutation } from './useMutation';
import { useWrapError } from './useWrapError';

interface UseConnectVars {
  peerId: string;
  hostSecret: string;
  webAppHost?: string;
}

export function useConnect() {
  const result = useMutation(async function queryFn(vars: UseConnectVars) {
    const { peerId, hostSecret, webAppHost } = vars;

    try {
      return await connectNative(peerId, hostSecret);
    } catch (err) {
      if (!webAppHost) throw err;
    }

    const { token } = cookie();

    if (!token) {
      throw new Error('You must be logged in to connect');
    }

    const url = `${webAppHost}?peer_id=${peerId}&host_secret=${hostSecret}`;
    return window.open(url);
  });

  const error = useWrapError(result.error, {
    error: "Couldn't connect to host."
  });
  return { ...result, error };
}

async function connectNative(peerId: string, hostSecret: string) {
  await new Promise<void>(resolve => {
    // spurious &a= param added to deal with trailing slash issues on Windows browsers
    const url =
      'parsec://peer_id=' + peerId + '&host_secret=' + hostSecret + '&a=';
    window.location.assign(url);
    resolve();
  });
}
