import { SECOND } from '@parsec/time';

export const RELAY_REFRESH_INTERVAL = 30 * SECOND;
export const RELAY_DOCUMENTATION_URL =
  'https://support.parsec.app/hc/en-us/articles/27324581609997-Configure-Parsec-Relay-Server';
export const RELAY_BINARY_VERIFICATION_DOCUMENTATION_URL =
  'https://support.parsec.app/hc/en-us/articles/27324581609997-Configure-Parsec-Relay-Server#verifying_binary';
export const RELAY_BINARY_DOWNLOAD_URL_V1 = '/gateway_server.tar.gz';
export const RELAY_BINARY_DOWNLOAD_URL_V2 =
  'https://builds.parsec.app/hpr/parsechpr2.0.0.linux-amd64.tar.gz';
