// @parsec
import { save, clear } from '@parsec/cookie';
import { User, user } from '@parsec/kessel';
import { identify } from '@parsec/sentry';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('me');
type Context = { previous?: User };

export function useMeData() {
  return useQueryData(key.all(), user);
}

export function useGetMe(options?: { enabled?: boolean }) {
  const kessel = useKessel();

  const result = useQuery(
    key.all(),
    async function queryFn() {
      const res = await kessel.me.getMe();

      return res.body.data;
    },
    {
      onSuccess(data) {
        identify({ userId: data.id, teamId: data.team_id });
      },
      ...options
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get current user."
  });
  return { ...result, error };
}

export function useUpdateMe() {
  const kessel = useKessel();
  const cache = useMeData();

  const result = useMutation(kessel.me.updateMe, {
    onMutate: vars => {
      const previous = cache.get();

      if (previous) cache.set({ ...previous, name: vars.name });
      return { previous } as Context;
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) cache.set(context?.previous);
      cache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update current user."
  });
  return { ...result, error };
}

export function useUpdateMarketingAttr() {
  const kessel = useKessel();
  const cache = useMeData();

  const result = useMutation(kessel.me.updateMarketingAttribution, {
    onMutate: vars => {
      const previous = cache.get();

      if (previous)
        cache.set({ ...previous, marketing_opt_in: vars.marketing_opt_in });
      return { previous } as Context;
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) cache.set(context?.previous);
      cache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update marketing attribution."
  });
  return { ...result, error };
}

export function useUpdateMarketingAttrData() {
  const kessel = useKessel();
  const cache = useMeData();

  const result = useMutation(kessel.me.updateMarketingAttributionData, {
    onMutate: vars => {
      const previous = cache.get();

      if (previous)
        cache.set({ ...previous, attribution_data: vars.attribution_data });
      return { previous } as Context;
    },
    onError: (_err, _vars, context?: Context) => {
      if (context?.previous) cache.set(context?.previous);
      cache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update marketing attribution data."
  });
  return { ...result, error };
}

export function useUpdateAvatar() {
  const kessel = useKessel();
  const cache = useMeData();

  const result = useMutation(kessel.me.updateAvatar, {
    onSuccess() {
      const previous = cache.get();
      if (previous) {
        const nonce = `${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`;
        cache.set({ ...previous, avatar_nonce: nonce });
      }
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't update avatar."
  });
  return { ...result, error };
}

export function useRemoveAvatar() {
  const kessel = useKessel();
  const cache = useMeData();

  const result = useMutation(kessel.me.removeAvatar, {
    onSuccess() {
      const previous = cache.get();
      if (previous) {
        const nonce = `${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`;
        cache.set({ ...previous, avatar_nonce: nonce });
      }
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't remove avatar."
  });
  return { ...result, error };
}

export function useUpdatePassword() {
  const kessel = useKessel();

  const result = useMutation(kessel.me.updatePassword);

  const error = useWrapError(result.error, {
    error: "Couldn't update password."
  });
  return { ...result, error };
}

export function useDeleteAccount() {
  const kessel = useKessel();

  const result = useMutation(kessel.me.deleteAccount, {
    onSuccess() {
      clear();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't delete account."
  });
  return { ...result, error };
}

export function useResendConfirmationEmail() {
  const kessel = useKessel();

  const result = useMutation(kessel.me.resendAccountConfirmationEmail);

  const error = useWrapError(result.error, {
    error: "Couldn't resend confirmation email."
  });
  return { ...result, error };
}

export function useConfirmEmailCode() {
  const kessel = useKessel();

  const result = useMutation(kessel.me.confirmEmailCode, {
    onSuccess: res => {
      save({ token: res.body.data.id });
      const userId = res.body.data.user_id;
      if (userId) {
        identify({ userId });
      }
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't validate secret code."
  });

  return { ...result, error };
}

export function useChangeEmail() {
  const kessel = useKessel();

  const result = useMutation(kessel.me.emailChange);

  const error = useWrapError(result.error, {
    error: "Couldn't change user email."
  });
  return { ...result, error };
}

export function useConfirmChangeEmail() {
  const kessel = useKessel();

  const cache = useMeData();

  const result = useMutation(kessel.me.confirmEmailChange, {
    onSuccess: () => {
      cache.invalidate(); // invalidate me query
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't confirm the change of user email."
  });
  return { ...result, error };
}
