// All types based on this spreadsheet https://docs.google.com/spreadsheets/d/1RuZcXLwY5mAfzqsp244U51wALKOODk65nlu3CnTWhSc/edit#gid=0

// A flag to identify which element triggered this particular journey. Helps identify modified journeys based on start condition.
export const JourneyTriggers = [
  'confirmation_warp',
  'faq_teams',
  'header_signup',
  'hero_teams',
  'home_consumer',
  'home_signup',
  'home_teams',
  'home_warp',
  'plan_teams',
  'plan_warp',
  'pricing_consumer',
  'pricing1_consumer',
  'pricing2_consumer',
  'pricing_teams',
  'pricing1_teams',
  'pricing2_teams',
  'pricing_warp',
  'pricing1_warp',
  'pricing2_warp',
  'subfooter_teams'
] as const;

export type JourneyTriggerType = (typeof JourneyTriggers)[number];

/**
 * Helper that tells you if a string is of type JourneyTrigger
 * @param value string
 * @returns boolean if string is of type journey trigger
 */
export function isOfTypeJourneyTrigger(
  value: string
): value is JourneyTriggerType {
  return [
    'confirmation_warp',
    'faq_teams',
    'header_signup',
    'hero_teams',
    'home_consumer',
    'home_signup',
    'home_teams',
    'home_warp',
    'plan_teams',
    'plan_warp',
    'pricing_consumer',
    'pricing1_consumer',
    'pricing2_consumer',
    'pricing_teams',
    'pricing1_teams',
    'pricing2_teams',
    'pricing_warp',
    'pricing1_warp',
    'pricing2_warp',
    'subfooter_teams'
  ].includes(value);
}

type SignUpType = 'signup 2.0' | 'signup 3.0';

export type JourneyType = 'signup';

export type JourneyName = SignUpType;

export enum JourneyGroup {
  AccountSignUpUser = 'account signup user',
  AccountSignUpTeamAdmin = 'account signup team admin',
  AccountSignUpTeamMember = 'account signup team member',
  WarpTrialSignUp = 'warp trial signup',
  TeamTrialSignUp = 'team trial signup',
  AccountCreation = 'account creation',
  AccountSetUpSelect = 'account setup - select',
  AccountSetUpForMe = 'account setup - for me',
  AccountSetUpForMeWarp = 'account setup - for me warp',
  AccountSetUpForTeam = 'account setup - for my team'
}

export type JourneyStepName =
  | EmailAndUsernameSteps
  | SetPasswordSteps
  | VerifyEmailSteps
  | EmailVerifiedSteps
  | SetTfaSteps
  | ViewBkupCodesSteps
  | SetPlanSteps
  | BillingInfoSteps
  | SummarySteps
  | ConfirmationSteps
  | NameAndSetPlanSteps;

export enum EmailAndUsernameSteps {
  View = 'email and username - view',
  Submit = 'email and username - submit'
}

// before email is verified
export enum VerifyEmailSteps {
  View = 'verify email - view',
  Submit = 'verify email - submit'
}

// after email is verified
export enum EmailVerifiedSteps {
  View = 'email verified - view',
  Submit = 'email verified - submit',
  SubmitAuto = 'email verified - submit auto'
}

export enum SetPasswordSteps {
  View = 'set password - view',
  Submit = 'set password - submit',
  SubmitSuccess = 'set password - submit success',
  SubmitFailure = 'set password - submit failure'
}

export enum SetTfaSteps {
  View = 'set tfa - view',
  Submit = 'set tfa - submit',
  Skip = 'set tfa - skip'
}

export enum ViewBkupCodesSteps {
  View = 'view backup codes - view',
  Download = 'view backup codes - download',
  Skip = 'view backup codes - skip'
}

export enum SetPlanSteps {
  View = 'set plan - view',
  Submit = 'set plan - submit'
}

export enum BillingInfoSteps {
  View = 'billing info - view',
  Submit = 'billing info - submit'
}

export enum SummarySteps {
  View = 'summary - view',
  Submit = 'summary - submit'
}

export enum ConfirmationSteps {
  View = 'confirmation - view',
  CtaDashboard = 'confirmation - cta dashboard',
  CtaDownload = 'confirmation - cta download',
  CtaTryWarp = 'confirmation - cta try warp',
  TrialCreate = 'confirmation - trial create',
  LinkHowDoI = 'confirmation - link how do i use parsec',
  LinkParsecTeams = 'confirmation - link parsec for teams',
  LinkEnterprise = 'confirmation - link enterprise features',
  AccountPage = 'confirmation - cta account page'
}

export enum NameAndSetPlanSteps {
  View = 'name and set plan - view',
  Submit = 'name and set plan - submit'
}

export enum JoinTeamSteps {
  View = 'join team - view',
  Submit = 'join team - submit'
}

export enum SelectProductSteps {
  View = 'select product - view',
  SubmitMe = 'select product - submit me',
  SubmitTeam = 'select product - submit team'
}

export enum PersonalizationSteps1 {
  View = 'personalization - question 1 - view',
  Submit = 'personalization - question 1 - submit',
  Skip = 'personalization - question 1 - skip'
}
export enum PersonalizationSteps2 {
  View = 'personalization - question 2 - view',
  Submit = 'personalization - question 2 - submit',
  Skip = 'personalization - question 2 - skip'
}
export enum PersonalizationSteps3 {
  View = 'personalization - question 3 - view',
  Submit = 'personalization - question 3 - submit',
  Skip = 'personalization - question 3 - skip'
}
export enum PersonalizationSteps4 {
  View = 'personalization - question 4 - view',
  Submit = 'personalization - question 4 - submit',
  Skip = 'personalization - question 4 - skip'
}
export enum PersonalizationSteps5 {
  View = 'personalization - question 5 - view',
  Submit = 'personalization - question 5 - submit',
  Skip = 'personalization - question 5 - skip'
}

export enum PasswordToggledJourneyGroup {
  LoginPassword = 'login_password',
  ForgotPassword = 'forgot_password',
  ForgotPasswordRepeat = 'forgot_password_repeat',
  SignupPassword = 'signup_password',
  SignupPasswordTeam = 'signup_team_password',
  SignupPasswordRepeat = 'signup_repeat_password',
  SignupPasswordRepeatTeam = 'signup_team_repeat_password',
  SignupTfaPassword = 'singup_tfa_password',
  SignupTfaPasswordTeam = 'singup_team_tfa_password',
  ChangePassword = 'change_password',
  NewPassword = 'new_password',
  NewPasswordRepeat = 'new_password_repeat',
  enableTfaPassword = 'enable_tfa_password',
  updateEmailPassword = 'update_email_password'
}

export enum PasswordToggledSteps {
  Show = 'show password',
  Hide = 'hide password'
}

// To narrow down types
export type StepNum<T extends JourneyGroup> = keyof (typeof JourneyByStep)[T];

export type StepName<
  T extends JourneyGroup,
  K extends StepNum<T>
> = (typeof JourneyByStep)[T][K];

// There is repetition in the step keys because they can change at any time
export const JourneyByStep = {
  [JourneyGroup.AccountSignUpUser]: {
    '1.1': EmailAndUsernameSteps.View,
    '1.2': EmailAndUsernameSteps.Submit,
    '1.3': SetPasswordSteps.View,
    '1.4': SetPasswordSteps.Submit,
    '1.4.1': SetPasswordSteps.SubmitSuccess,
    '1.4.2': SetPasswordSteps.SubmitFailure,
    '1.5': VerifyEmailSteps.View,
    '1.6': VerifyEmailSteps.Submit,
    '1.7.1': EmailVerifiedSteps.View,
    '1.7.2.1': EmailVerifiedSteps.Submit,
    '1.7.2.2': EmailVerifiedSteps.SubmitAuto,
    '1.8.1': SetTfaSteps.View,
    '1.8.2.1': SetTfaSteps.Submit,
    '1.8.2.2': SetTfaSteps.Skip,
    '1.8.3': ViewBkupCodesSteps.View,
    '1.8.4.1': ViewBkupCodesSteps.Download,
    '1.8.4.2': ViewBkupCodesSteps.Skip
  },

  [JourneyGroup.AccountSignUpTeamAdmin]: {
    '1.1': EmailAndUsernameSteps.View,
    '1.2': EmailAndUsernameSteps.Submit,
    '1.3': SetPasswordSteps.View,
    '1.4': SetPasswordSteps.Submit,
    '1.4.1': SetPasswordSteps.SubmitSuccess,
    '1.4.2': SetPasswordSteps.SubmitFailure,
    '1.5': VerifyEmailSteps.View,
    '1.6': VerifyEmailSteps.Submit,
    '1.7.1': EmailVerifiedSteps.View,
    '1.7.2.1': EmailVerifiedSteps.Submit,
    '1.7.2.2': EmailVerifiedSteps.SubmitAuto,
    '1.8.1': SetTfaSteps.View,
    '1.8.2.1': SetTfaSteps.Submit,
    '1.8.2.2': SetTfaSteps.Skip,
    '1.8.3': ViewBkupCodesSteps.View,
    '1.8.4.1': ViewBkupCodesSteps.Download,
    '1.8.4.2': ViewBkupCodesSteps.Skip
  },
  [JourneyGroup.AccountSignUpTeamMember]: {
    '1.1': EmailAndUsernameSteps.View,
    '1.2': EmailAndUsernameSteps.Submit,
    '1.3': SetPasswordSteps.View,
    '1.4': SetPasswordSteps.Submit,
    '1.4.1': SetPasswordSteps.SubmitSuccess,
    '1.4.2': SetPasswordSteps.SubmitFailure,
    '1.5': VerifyEmailSteps.View,
    '1.6': VerifyEmailSteps.Submit,
    '1.7.1': EmailVerifiedSteps.View,
    '1.7.2.1': EmailVerifiedSteps.Submit,
    '1.7.2.2': EmailVerifiedSteps.SubmitAuto,
    '1.8.1': SetTfaSteps.View,
    '1.8.2.1': SetTfaSteps.Submit,
    '1.8.2.2': SetTfaSteps.Skip,
    '1.8.3': ViewBkupCodesSteps.View,
    '1.8.4.1': ViewBkupCodesSteps.Download,
    '1.8.4.2': ViewBkupCodesSteps.Skip,
    '1.9.1': JoinTeamSteps.View,
    '1.9.2': JoinTeamSteps.Submit
  },
  [JourneyGroup.TeamTrialSignUp]: {
    '2.1': NameAndSetPlanSteps.View,
    '2.2': NameAndSetPlanSteps.Submit,
    '2.3': BillingInfoSteps.View,
    '2.4': BillingInfoSteps.Submit,
    '2.5': SummarySteps.View,
    '2.6': SummarySteps.Submit,
    '2.7': ConfirmationSteps.View
  },
  [JourneyGroup.WarpTrialSignUp]: {
    '2.1': NameAndSetPlanSteps.View,
    '2.2': NameAndSetPlanSteps.Submit,
    '2.3': BillingInfoSteps.View,
    '2.4': BillingInfoSteps.Submit,
    '2.5': SummarySteps.View,
    '2.6': SummarySteps.Submit,
    '2.7': ConfirmationSteps.View
  },
  [JourneyGroup.AccountCreation]: {
    '1.1': EmailAndUsernameSteps.View,
    '1.2': EmailAndUsernameSteps.Submit,
    '1.3': SetPasswordSteps.View,
    '1.4': SetPasswordSteps.Submit,
    '1.4.1': SetPasswordSteps.SubmitSuccess,
    '1.4.2': SetPasswordSteps.SubmitFailure,
    '1.5': VerifyEmailSteps.View,
    '1.6': VerifyEmailSteps.Submit,
    '1.7': EmailVerifiedSteps.View,
    '1.8.1': EmailVerifiedSteps.Submit,
    '1.8.2': EmailVerifiedSteps.SubmitAuto
  },
  [JourneyGroup.AccountSetUpSelect]: {
    '2.1': SelectProductSteps.View,
    '2.2.1': SelectProductSteps.SubmitMe,
    '2.2.2': SelectProductSteps.SubmitTeam
  },
  [JourneyGroup.AccountSetUpForMe]: {
    '3.1.1': SetTfaSteps.View,
    '3.1.2': SetTfaSteps.Submit,
    '3.1.3': SetTfaSteps.Skip,
    '3.1.4': ViewBkupCodesSteps.View,
    '3.1.5.1': ViewBkupCodesSteps.Download,
    '3.1.5.2': ViewBkupCodesSteps.Skip,
    '3.1.6': ConfirmationSteps.View,
    '3.1.7.1': ConfirmationSteps.CtaDownload,
    '3.1.7.2': ConfirmationSteps.LinkHowDoI,
    '3.1.7.3': ConfirmationSteps.CtaTryWarp,
    '3.1.7.4': ConfirmationSteps.LinkParsecTeams
  },
  [JourneyGroup.AccountSetUpForMeWarp]: {
    '3.2.1.1.1': PersonalizationSteps1.View,
    '3.2.1.1.2.1': PersonalizationSteps1.Submit,
    '3.2.1.1.2.2': PersonalizationSteps1.Skip,
    '3.2.1.2.1': PersonalizationSteps2.View,
    '3.2.1.2.2.1': PersonalizationSteps2.Submit,
    '3.2.1.2.2.2': PersonalizationSteps2.Skip,
    '3.2.1.3.1': PersonalizationSteps3.View,
    '3.2.1.3.2.1': PersonalizationSteps3.Submit,
    '3.2.1.3.2.2': PersonalizationSteps3.Skip,
    '3.2.1.4.1': PersonalizationSteps4.View,
    '3.2.1.4.2.1': PersonalizationSteps4.Submit,
    '3.2.1.4.2.2': PersonalizationSteps4.Skip,
    '3.2.2.1': SetTfaSteps.View,
    '3.2.2.2.1': SetTfaSteps.Submit,
    '3.2.2.2.2': SetTfaSteps.Skip,
    '3.2.3.1': ViewBkupCodesSteps.View,
    '3.2.3.2.1': ViewBkupCodesSteps.Download,
    '3.2.3.2.2': ViewBkupCodesSteps.Skip,
    '3.2.4.1': NameAndSetPlanSteps.View,
    '3.2.4.2': NameAndSetPlanSteps.Submit,
    '3.2.5.1': BillingInfoSteps.View,
    '3.2.5.2': BillingInfoSteps.Submit,
    '3.2.6.1': SummarySteps.View,
    '3.2.6.2': SummarySteps.Submit,
    '3.2.7.1': ConfirmationSteps.View,
    '3.2.7.2.1': ConfirmationSteps.CtaDownload,
    '3.2.7.2.2': ConfirmationSteps.LinkParsecTeams,
    '3.2.7.2.3': ConfirmationSteps.AccountPage,
    '3.2.7.2.4': ConfirmationSteps.LinkHowDoI
  },
  [JourneyGroup.AccountSetUpForTeam]: {
    '3.3.1.1.1': PersonalizationSteps1.View,
    '3.3.1.1.2.1': PersonalizationSteps1.Submit,
    '3.3.1.1.2.2': PersonalizationSteps1.Skip,
    '3.3.1.2.1': PersonalizationSteps2.View,
    '3.3.1.2.2.1': PersonalizationSteps2.Submit,
    '3.3.1.2.2.2': PersonalizationSteps2.Skip,
    '3.3.1.3.1': PersonalizationSteps3.View,
    '3.3.1.3.2.1': PersonalizationSteps3.Submit,
    '3.3.1.3.2.2': PersonalizationSteps3.Skip,
    '3.3.1.4.1': PersonalizationSteps4.View,
    '3.3.1.4.2.1': PersonalizationSteps4.Submit,
    '3.3.1.4.2.2': PersonalizationSteps4.Skip,
    '3.3.1.5.1': PersonalizationSteps5.View,
    '3.3.1.5.2.1': PersonalizationSteps5.Submit,
    '3.3.1.5.2.2': PersonalizationSteps5.Skip,
    '3.3.2.1': SetTfaSteps.View,
    '3.3.2.2.1': SetTfaSteps.Submit,
    '3.3.2.2.2': SetTfaSteps.Skip,
    '3.3.3.1': ViewBkupCodesSteps.View,
    '3.3.3.2.1': ViewBkupCodesSteps.Download,
    '3.3.3.2.2': ViewBkupCodesSteps.Skip,
    '3.3.4.1': NameAndSetPlanSteps.View,
    '3.3.4.2': NameAndSetPlanSteps.Submit,
    '3.3.5.1': BillingInfoSteps.View,
    '3.3.5.2': BillingInfoSteps.Submit,
    '3.3.6.1': SummarySteps.View,
    '3.3.6.2': SummarySteps.Submit,
    '3.3.7.1': ConfirmationSteps.View,
    '3.3.7.2.1': ConfirmationSteps.CtaDashboard,
    '3.3.7.2.2': ConfirmationSteps.LinkEnterprise,
    '3.3.7.2.3': ConfirmationSteps.LinkHowDoI,
    '3.3.7.2.4': ConfirmationSteps.CtaDownload
  }
} as const;
