import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Checkbox,
  Dropdown,
  Flyout,
  Icon,
  IconButton,
  Tooltip,
  keyframes,
  styled
} from '@parsec/components';
import { useModals } from '@parsec/hooks';
import { Guest, AppRule } from '@parsec/kessel';
import {
  useGetTeam,
  useGetTeamRolePermissionSummary,
  useKickTeamMachineGuest
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { kessel } from 'lib/api';

import { Description } from 'components';

import { ConfirmModal } from './ConfirmModal';
import KickUserModal from './KickUserModal';

const version = 'newFont';

// STYLES
/// Computer & Status
///------------------------------------

const ComputerAndStatus = styled('div', {
  display: 'grid',
  gridTemplateColumns: '3.6rem minmax(0, 1fr)',
  columnGap: '1rem'
});

const ComputerIcon = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  width: '3.6rem',
  height: '3.6rem',
  backgroundColor: '$computerBlack',
  borderRadius: '100%'
});

const P = styled('p', {
  fontFamily: '$newDefault'
});

const Name = styled(P, {
  fontSize: '$newBody',
  lineHeight: '$info',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const Online = styled(P, {
  display: 'inline list-item',
  color: '$success500',
  fontSize: '$newInfo',
  lineHeigt: '$info',
  margin: 0
});

const Offline = styled(P, {
  display: 'inline list-item',
  color: '$rhyhorn',
  fontSize: '$newInfo',
  lineHeight: '$info',
  margin: 0
});

const GuestAccessButton = styled('button', {
  display: 'inline-block',
  padding: '0 $small',
  borderRadius: '$xsmall',
  width: 'max-content',
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  lineHeight: '$info',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  '&:not(:disabled)': {
    cursor: 'pointer'
  },
  variants: {
    kind: {
      on: {
        backgroundColor: '$success500',
        color: '$success800'
      },
      off: {
        backgroundColor: '$nice',
        color: '$retroGray'
      }
    }
  }
});

/// Assigned To
///------------------------------------

const AssignedTo = styled('div', {
  display: 'grid',
  alignItems: 'center',
  fontSize: '$info',
  overflow: 'hidden',
  variants: {
    type: {
      user: {
        fontWeight: 'normal',
        gridTemplateColumns: '1.2rem 1fr',
        columnGap: '1rem'
      },
      group: {
        fontWeight: 'bold',
        gridTemplateColumns: '1.2rem 1fr',
        columnGap: '1rem'
      },
      reserved: {
        fontWeight: 'normal'
      },
      none: {
        color: '$rhyhorn'
      }
    }
  }
});

const AssigneeName = styled(P, {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const ViewButton = styled('button', {
  marginLeft: '1.2rem',
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  color: '$primary500',
  cursor: 'pointer',
  '&:disabled': {
    color: '$duskull',
    cursor: 'auto'
  }
});

const Assign = styled('div', {
  display: 'grid'
});

const AssignButton = styled(Button, {
  color: '$primary500',
  '&:not(:disabled):hover': {
    color: '$primary500'
  }
});

const AppRuleButton = styled(Button, {
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
  backgroundColor: '$carkol'
});

const RuleName = styled('span', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block'
});

const RemoveAction = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '3.5rem auto'
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const Span = styled('span', {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'block'
});

const User = styled(P, {
  minWidth: '10rem',
  maxWidth: '30rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fill: '$consoleWhite'
});

const spin = keyframes({
  '0%': { transform: 'translate(-50%, -50%) rotate(0deg)' },
  '100%': { transform: 'translate(-50%, -50%) rotate(360deg)' }
});

const Loader = styled(Icon, {
  position: 'absolute',
  left: '30%',
  animation: `${spin} linear 2s infinite`
});

const GuestUserFlyout = styled(Flyout, {
  display: 'grid',
  gridTemplateColumns: '1.6rem auto 3rem'
});

// COMPONENTS
export type TeamComputerTableColumnType =
  | 'select'
  | 'computerAndStatus'
  | 'guestAccess'
  | 'assignee'
  | 'connections'
  | 'ruleset'
  | 'assign'
  | 'more';

interface TeamComputerRowProps {
  selected?: boolean;
  columns: TeamComputerTableColumnType[];
  viewOnly: boolean;
  name: string;
  isOnline: boolean;
  isGuestAccessEnabled: boolean;
  assigneeType: 'user' | 'group' | 'reserved' | 'none';
  assigneeName: string;
  reservationEmail: string;
  guests: Guest[];
  groups?: {
    id: number;
    name: string;
  }[];
  appRuleName: string;
  allAppRules: AppRule[];
  id: string;
  onSelect?(): void;
  onDeselect?(): void;
  onAssignAppRule(ruleID: string): Promise<void>;
  onToggleGuestAccess(): void;
  onEdit(): void;
  onRemove(): void;
  onUnassign(): void;
}

export function TeamComputerRow(props: TeamComputerRowProps) {
  const alerts = useAlertContext();

  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data;
  const hasGlobalScopePerm = perms?.team.manage_team_computers;

  const hasGroupScopePerm = Object.values(perms?.groups ?? {}).some(
    p => p.manage_team_computers
  );

  const canManageTeamComputers =
    (hasGlobalScopePerm || hasGroupScopePerm) ?? false;

  // Unassign team permissions requires a global scope permissions
  // or the caller is an admin of all the machine's groups
  const canUnassignTeamComputer =
    hasGlobalScopePerm ||
    Array.from(props.groups ?? []).every(
      group => perms?.groups[group.id.toString()]?.manage_team_computers
    );

  const allAppRules = props.allAppRules;

  const [isGuestInfoOpen, setIsGuestInfoOpen] = useState(false);

  const guestNumber = useMemo(() => {
    return props.guests.length;
  }, [props.guests]);

  const guests = useMemo(() => {
    return props.guests;
  }, [props.guests]);

  const guestUserInfo = useGetGuestInfo(
    isGuestInfoOpen,
    guests.map(g => ({ userId: g.user_id, guestId: g.guest_id }))
  );

  // Refs
  const appRuleNameRef = useRef<HTMLSpanElement>(null);
  const ruleNameBtnRef = useRef<HTMLSpanElement>(null);
  const guestUserRef = useRef<HTMLParagraphElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const assigneeNameRef = useRef<HTMLParagraphElement>(null);

  const { mutateAsync: kickGuest } = useKickTeamMachineGuest();

  const modals = useModals({
    kickUser: KickUserModal,
    confirm: ConfirmModal
  });

  const dropDownItems = [
    {
      key: 'remove-computer',
      disabled: !canManageTeamComputers,
      text: (
        <RemoveAction>
          <Icon name="ex" />
          <Description info>Remove Computer</Description>
        </RemoveAction>
      ),
      onSelect() {
        modals.open('confirm', {
          title: 'Delete Computer',
          onSubmit: props.onRemove,
          children: (
            <>
              This computer will be deleted permanently. Are you sure you want
              to remove it?
            </>
          )
        });
      }
    }
  ];

  if (props.assigneeType !== 'none' && canUnassignTeamComputer) {
    const reserved = props.assigneeType === 'reserved';
    dropDownItems.unshift({
      key: 'unassign',
      disabled: !canUnassignTeamComputer,
      text: (
        <RemoveAction>
          <Icon name="ex" />
          <Description info>
            {reserved ? 'Remove Reservation' : 'Unassign access'}
          </Description>
        </RemoveAction>
      ),
      onSelect() {
        modals.open('confirm', {
          title: reserved ? 'Remove Reservation' : 'Unassign Access',
          onSubmit: props.onUnassign,
          children: reserved
            ? `This reservation for ${props.reservationEmail} will be deleted and the computer will be left unassigned.`
            : `This computer will become unassigned and the ${
                props.assigneeType === 'group' ? 'group' : 'user'
              } will no longer be able to connect to it.`
        });
      }
    });
  }

  const showSelect = props.columns.includes('select');
  const showGuestAccess = props.columns.includes('guestAccess');
  const showAssignee = props.columns.includes('assignee');
  const showConnections = props.columns.includes('connections');
  const showAppRule = props.columns.includes('ruleset');
  const showActions = props.columns.includes('more');
  const showMore = props.columns.includes('more');

  const isDisabled = !allAppRules.length || !canManageTeamComputers;

  async function onKickGuest(req: { machine_id: string; guest_id: number }) {
    try {
      alerts.clear();
      await kickGuest(req);
      alerts.show({
        type: 'success',
        title: 'Success!',
        message: 'Successfully Kicked User'
      });
    } catch (err) {
      const res = parseError(err);
      alerts.show({
        type: 'error',
        title: 'Error',
        message:
          res.body?.error ??
          res.error ??
          'There was a problem kicking this user. Please try again.'
      });
      throw err;
    }
  }

  const isInMultipleGroups = props.groups ? props.groups.length > 1 : false;

  return (
    <>
      {modals.dom}
      {showSelect && (
        <Column gridArea="select">
          <Checkbox
            readOnly
            checked={props.selected}
            kind="solid"
            onChange={() => {
              if (props.selected) {
                props.onDeselect?.();
              } else {
                props.onSelect?.();
              }
            }}
          />
        </Column>
      )}

      {/* Computer & Status */}
      <Column gridArea="computerAndStatus">
        <ComputerAndStatus>
          <ComputerIcon>
            <Icon name="computer" />
          </ComputerIcon>
          <div>
            <Tooltip
              version={version}
              placement="top"
              tooltipText={props.name}
              showOnOverflow
              triggerRef={nameRef}
            >
              <Name ref={nameRef}>{props.name}</Name>
            </Tooltip>
            {props.isOnline ? (
              <Online>online</Online>
            ) : (
              <Offline>offline</Offline>
            )}
          </div>
        </ComputerAndStatus>
      </Column>

      {/* Guest Access */}
      {showGuestAccess && (
        <Column gridArea="guestAccess">
          <GuestAccessButton
            disabled={!canManageTeamComputers}
            kind={props.isGuestAccessEnabled ? 'on' : 'off'}
            onClick={async e => {
              e.persist();
              try {
                (e.target as HTMLButtonElement).disabled = true;
                await props.onToggleGuestAccess();
              } finally {
                (e.target as HTMLButtonElement).disabled = false;
              }
            }}
          >
            {props.isGuestAccessEnabled ? 'on' : 'off'}
          </GuestAccessButton>
        </Column>
      )}

      {/* Assigned To */}
      {showAssignee && (
        <Column gridArea="assignee">
          <Tooltip
            version={version}
            placement="bottom"
            tooltipText={renderAssignee(props)}
            showOnOverflow={!isInMultipleGroups}
            triggerRef={assigneeNameRef}
          >
            <AssignedTo type={props.assigneeType}>
              {props.reservationEmail !== '' ? (
                <AssigneeName ref={assigneeNameRef}>
                  {props.reservationEmail} (reserved)
                </AssigneeName>
              ) : props.assigneeType !== 'none' ? (
                <>
                  <Icon name={props.assigneeType as 'user' | 'group'} />{' '}
                  <AssigneeName ref={assigneeNameRef}>
                    {props.assigneeName}
                  </AssigneeName>
                </>
              ) : (
                'None'
              )}
            </AssignedTo>
          </Tooltip>
        </Column>
      )}

      {/* Connected Now */}
      {showConnections && (
        <Column gridArea="connections">
          {props.isOnline ? (
            <Description>
              {guestNumber}
              {props.guests.length > 0 && (
                <Flyout
                  items={guestUserInfo.map(info => ({
                    text: (
                      <Tooltip
                        version={version}
                        placement="bottom"
                        triggerRef={guestUserRef}
                        tooltipText={info.email || info.name}
                        showOnOverflow={true}
                      >
                        <User ref={guestUserRef}>
                          {info.email || info.name}
                        </User>
                      </Tooltip>
                    ),
                    icon: info.id,
                    action:
                      canManageTeamComputers && typeof info.name === 'string'
                        ? {
                            text: 'Kick',
                            onClick: () =>
                              modals.open('kickUser', {
                                onSubmit: () =>
                                  onKickGuest({
                                    machine_id: props.id,
                                    guest_id: info.guestId
                                  }),
                                user: info.email || info.name,
                                computer: props.name
                              })
                          }
                        : null,
                    clickable: false
                  }))}
                >
                  {({ isOpen: _, props }) => (
                    <ViewButton disabled={!canManageTeamComputers} {...props}>
                      <span
                        onClick={() => setIsGuestInfoOpen(!isGuestInfoOpen)}
                      >
                        View
                      </span>
                    </ViewButton>
                  )}
                </Flyout>
              )}
            </Description>
          ) : (
            <Description info>--</Description>
          )}
        </Column>
      )}

      {showAppRule && (
        <Column gridArea="ruleset">
          {props.viewOnly ? (
            <Tooltip
              version={version}
              placement="top"
              triggerRef={appRuleNameRef}
              showOnOverflow={true}
              tooltipText={props.appRuleName}
            >
              <Span ref={appRuleNameRef}>{props.appRuleName}</Span>
            </Tooltip>
          ) : (
            <Dropdown
              version={version}
              items={allAppRules.map(appRule => ({
                text: appRule.name,
                onSelect: () => {
                  if (!isDisabled) props.onAssignAppRule(appRule.id);
                }
              }))}
              placeholder="Find a Ruleset"
              showSearchBar={true}
              defaultValue={props.appRuleName}
              x={34}
              y={6}
            >
              {({ isOpen, props: dropdownProps }) => (
                <Tooltip
                  version={version}
                  placement="top"
                  triggerRef={ruleNameBtnRef}
                  showOnOverflow
                  tooltipText={props.appRuleName}
                >
                  <AppRuleButton
                    version={version}
                    {...dropdownProps}
                    level="secondary"
                    disabled={!allAppRules.length}
                    size="small"
                    icon={<ArrowIcon name="caret" open={isOpen} />}
                  >
                    <RuleName ref={ruleNameBtnRef}>
                      {props.appRuleName}
                    </RuleName>
                  </AppRuleButton>
                </Tooltip>
              )}
            </Dropdown>
          )}
        </Column>
      )}

      {/* Assign */}
      {showActions && (
        <Column gridArea="assign">
          <Assign>
            <AssignButton
              version={version}
              disabled={!canManageTeamComputers}
              level="secondary"
              onClick={props.onEdit}
            >
              {props.assigneeType === 'none' ? 'Assign' : 'Edit'}
            </AssignButton>
          </Assign>
        </Column>
      )}

      {showMore && (
        <Column gridArea="more">
          <GuestUserFlyout items={dropDownItems}>
            {({ props }) => (
              <IconButton
                disabled={!canManageTeamComputers}
                {...props}
                title="More actions"
                icon="ellipsis"
              />
            )}
          </GuestUserFlyout>
        </Column>
      )}
    </>
  );
}

function renderAssignee(props: TeamComputerRowProps) {
  switch (props.assigneeType) {
    case 'group':
      return (
        <ul>
          {props.groups?.map(group => <li key={group.name}>{group.name}</li>)}
        </ul>
      );
    case 'reserved':
      return <p>{props.reservationEmail} (reserved)</p>;
    case 'user':
      return <p>{props.assigneeName}</p>;
    case 'none':
      return '';
    default:
      return '';
  }
}

function Column(props: {
  gridArea: TeamComputerTableColumnType;
  children: ReactNode;
}) {
  return <div style={{ gridArea: props.gridArea }}>{props.children}</div>;
}

/**
 * TODO: Refactor with kessel backend query to get all guests instead of
 * iterating through the front-end
 */
interface DropdownGuest {
  email: string;
  name: string | ReactNode;
  id: number;
  guestId: number;
}

function useGetGuestInfo(
  isGuestInfoOpen: boolean,
  ids: { userId: number; guestId: number }[]
) {
  const idsRef = useRef(ids);
  idsRef.current = ids;

  const [names, setInfo] = useState<DropdownGuest[]>(
    ids.map(user => ({
      name: <Loader name="loader" />,
      email: '',
      id: user.userId,
      guestId: user.guestId
    }))
  );
  const team = useGetTeam();
  const teamId = team.data?.id ?? '';
  useEffect(() => {
    if (isGuestInfoOpen) {
      Promise.all(
        idsRef.current.map(ids =>
          kessel.teamMember
            .getTeamMember({ team_id: teamId, user_id: ids.userId })
            .then(res => ({
              name: res.body.data.user.name,
              email: res.body.data.user.email,
              id: ids.userId,
              guestId: ids.guestId
            }))
            .catch(_ => ({
              name: `Guest User`,
              email: '',
              id: ids.userId,
              guestId: ids.guestId
            }))
        )
      ).then(setInfo);
    }
  }, [teamId, isGuestInfoOpen, idsRef]);
  return names;
}
