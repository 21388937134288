// @parsec
import { schema, team } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useGetMe } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('team');
type Context = { previous?: schema.Team };

export function useTeamData() {
  return useQueryData(key.all(), schema.team);
}

// ****************************************************************************

export function useCreateTeam() {
  const kessel = useKessel();

  const result = useMutation(async function (vars: team.CreateTeamReq) {
    const res = await kessel.team.createTeam(vars);
    return res.body?.data;
  });

  const error = useWrapError(result.error, {
    error: "Couldn't create Team."
  });

  return { ...result, error };
}

// ****************************************************************************

export function useGetTeam(options?: { enabled?: boolean }) {
  const kessel = useKessel();

  const me = useGetMe({ ...options });

  const vars = { id: me.data?.team_id ?? '' };
  const enabled = options?.enabled ?? Boolean(vars.id);

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);
      const res = await kessel.team.getTeam(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Team."
  });

  return { ...result, error };
}

// ****************************************************************************

export function useUpdateTeamName() {
  const kessel = useKessel();
  const cache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn(req: Omit<team.UpdateTeamNameReq, 'id'>) {
      const res = await kessel.team.updateTeamName({
        ...req,
        id: teamId
      });
      return res.body.data;
    },
    {
      onMutate: vars => {
        const previous = cache.get();

        if (previous) cache.set({ ...previous, ...vars });
        return { previous } as Context;
      },
      onError: (_err, _vars, context?: Context) => {
        if (context?.previous) cache.set(context?.previous);
        cache.invalidate();
      },
      onSuccess: () => {
        cache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Team."
  });

  return { ...result, error };
}

// ****************************************************************************

export function useDeleteTeam() {
  const kessel = useKessel();
  const cache = useTeamData();
  const teamQuery = useGetTeam();
  const teamId = teamQuery.data?.id ?? '';

  const result = useMutation(
    async function mutationFn() {
      await kessel.team.deleteTeam({ id: teamId });
    },
    {
      onSuccess: () => cache.invalidate()
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't delete Team subscription."
  });

  return { ...result, error };
}
