import {
  useGetWarpSubscription,
  useGetWarpBillingDetails,
  useEstimateWarpSubscription
} from '@parsec/queries';

export function useWarpSubscriptionCost(planId?: string) {
  const { data: subscription } = useGetWarpSubscription();
  const { data: billingDetails } = useGetWarpBillingDetails();

  const plan = planId ?? subscription?.plan_id ?? '';

  const enabled = Boolean(plan && billingDetails);

  const estimate = useEstimateWarpSubscription(
    {
      plan_id: plan,
      country: billingDetails?.billing_address.country ?? '',
      zip: billingDetails?.billing_address.zip,
      coupon: subscription?.coupon
    },
    { enabled }
  );
  return {
    subtotal: estimate.data?.subtotal_amount_cents ?? NaN,
    total: estimate.data?.total_amount_cents ?? NaN,
    discount: estimate.data?.discount_amount_cents ?? NaN,
    tax: estimate.data?.tax_amount_cents ?? NaN,
    error: estimate.error?.error ?? "Couldn't get estimate for purchase"
  };
}
