import { styled } from '@parsec/styles';

import IconButton from '../IconButton';

export interface ToastProps {
  message: string;
  onClose(): void;
}

export default function Toast(props: ToastProps) {
  const { message, onClose, ...rest } = props;
  return (
    <Wrapper {...rest} role="status">
      <Message>{message}</Message>
      <Close icon="ex" title="Close" kind="basic" onClick={onClose} />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '$medium',
  paddingBottom: '$medium',
  paddingLeft: '$large',
  paddingRight: '$none',
  gap: '$medium',
  borderRadius: '$small',
  backgroundColor: '$success500',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '1.2rem',
    height: '1.2rem',
    left: '-.5rem',
    transform: 'rotate(45deg)',
    backgroundColor: '$success500'
  }
});

const Message = styled('div', {
  color: '$consoleWhite'
});

const Close = styled(IconButton, {
  paddingRight: '$xsmall',
  '&:hover, &:active, &:focus': {
    backgroundColor: 'transparent'
  }
});
