import { type ReactNode } from 'react';

import {
  ComboboxProvider,
  ComboboxLabel,
  Combobox,
  ComboboxCancel,
  ComboboxPopover,
  ComboboxItem,
  type ComboboxItemProps,
  type ComboboxPopoverProps,
  type ComboboxProps,
  type ComboboxProviderProps,
  type ComboboxLabelProps
} from '@ariakit/react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';
import { StyledContent, StyledItem } from '../SharedDropdownStyles';

import comboboxStyles from './baseCombobox.module.css';

/**
 * BaseCombobox
 * see https://ariakit.org/reference/combobox-provider for more info about the props
 */
export interface BaseComboboxProps extends ComboboxProviderProps {
  children: ReactNode;
}

export function BaseCombobox(props: BaseComboboxProps) {
  const { children, ...rest } = props;
  return <ComboboxProvider {...rest}>{children}</ComboboxProvider>;
}

/**
 * Label
 * Renders a label for the Combobox component - optional.
 * see https://ariakit.org/reference/combobox-label for more info about the props
 */

interface LabelProps extends ComboboxLabelProps {
  children: ReactNode;
}

const Label = (props: LabelProps) => {
  const { children, ...rest } = props;
  return <ComboboxLabel {...rest}>{children}</ComboboxLabel>;
};

/**
 * Input
 * Renders a combobox input element that can be used to filter a list of items.
 * see https://ariakit.org/reference/combobox for more info about the props
 */

const InputWrapper = styled('div', {
  position: 'relative'
});

const ComboboxInput = styled(Combobox, {
  width: '100%',
  height: '3.6rem',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution',
  borderRadius: '5rem',
  padding: '.6rem 3.2rem .6rem 3.6rem',
  backgroundColor: '$cereza',
  color: '$consoleWhite',
  boxShadow: '0rem $space$xxsmall 0rem 0rem rgba(255, 255, 255, 0.1)',
  '& ~ svg': {
    color: '$nice'
  },
  '&::placeholder': {
    color: '$rhyhorn'
  },
  '&:hover': {
    boxShadow:
      'inset 0 0 0 $space$xxsmall $colors$pangoro, 0rem $space$xxsmall 0rem rgba(255,255,255,0.1)'
  },
  '&:focus': {
    boxShadow: 'inset 0 0 0 $space$xsmall $colors$primary500',
    color: '$consoleWhite',
    border: 'none',
    outlineStyle: 'none',
    '& ~ svg': {
      color: '$consoleWhite '
    }
  },
  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& ~ svg': {
      color: '$nice'
    }
  },
  '&::-moz-focus-inner': {
    border: 'none'
  }
});

const StyledIcon = styled(Icon, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '$xxlarge',
  left: '$medium'
});

const StyledCancel = styled(ComboboxCancel, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '$xxlarge',
  right: '$medium',
  color: '$consoleWhite',
  borderRadius: '5rem',
  padding: '.5rem',
  cursor: 'pointer'
});

interface InputProps extends ComboboxProps {}

const Input = (props: InputProps) => {
  return (
    <InputWrapper>
      <ComboboxInput {...props} />
      <StyledIcon name="search" />
      <StyledCancel
        hideWhenEmpty={true}
        className={comboboxStyles.CancelButton}
      />
    </InputWrapper>
  );
};

/**
 * Popover
 * Renders a combobox popover.
 * The role prop is set to listbox by default, but can be overriden by any other valid combobox popup role (listbox, menu, tree, grid or dialog).
 * see https://ariakit.org/reference/combobox-popover for more info about the props
 */
const StyledPopover = styled(ComboboxPopover, {
  ...StyledContent,
  maxHeight: '24rem',
  overflowY: 'scroll',
  overflowX: 'hidden',
  zIndex: 10,
  padding: '1rem .8rem',
  '&::-webkit-scrollbar': {
    width: '2rem'
  },
  '&::-webkit-scrollbar-thumb': {
    border: '.6rem solid transparent'
  }
});

interface PopoverProps extends ComboboxPopoverProps {
  children: ReactNode;
}

const Popover = (props: PopoverProps) => {
  const { children, ...rest } = props;
  return (
    <StyledPopover {...rest} sameWidth gutter={8}>
      {children}
    </StyledPopover>
  );
};

/**
 * Item
 * Renders a combobox item inside the Popover component.
 * see https://ariakit.org/reference/combobox-item for more info about the props
 */

const StyledComboboxItem = styled(ComboboxItem, {
  ...StyledItem,
  fontFamily: '$newDefault',
  '&:focus-visible': {
    backgroundColor: 'rgba(249, 249, 249, 0.05)',
    outline: 'none'
  }
});

interface ItemProps extends ComboboxItemProps {}

const Item = (props: ItemProps) => {
  return (
    <StyledComboboxItem {...props} className={comboboxStyles.ComboboxItem}>
      {props.children}
    </StyledComboboxItem>
  );
};

// EXPORTS
BaseCombobox.Label = Label;
BaseCombobox.Input = Input;
BaseCombobox.Popover = Popover;
BaseCombobox.Item = Item;
