// @parsec
import { schema, warpSubscription } from '@parsec/kessel';

import { useWarpEstimateData as useEstimateData } from './estimate';
import KeyFactory from './KeyFactory';
import { useGetMe, useMeData } from './me';
import { useKessel } from './Provider';
import { useMutation } from './useMutation';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('warp_subscription');

export function useWarpSubscriptionData() {
  return useQueryData(key.all(), schema.warpSubscription);
}
/** ****************************************************************************/

export function useGetWarpSubscription(options?: { enabled?: boolean }) {
  const kessel = useKessel();

  const me = useGetMe({ ...options });

  const enabled = options?.enabled ?? Boolean(me.data);

  const result = useQuery(
    key.all(),
    async function queryFn() {
      const res = await kessel.warpSubscription.getWarpSubscription();
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get Warp subscription."
  });

  return { ...result, error };
}
/** ****************************************************************************/

export function useCreateWarpSubscription() {
  const kessel = useKessel();
  const meCache = useMeData();
  const cache = useWarpSubscriptionData();

  async function mutationFn(vars: warpSubscription.CreateWarpSubscriptionReq) {
    const res = await kessel.warpSubscription.createWarpSubscription(vars);
    return res.body?.data;
  }

  const result = useMutation(mutationFn, {
    onMutate() {
      const previousMe = meCache.get();
      if (previousMe) meCache.set({ ...previousMe, warp: true });
    },
    onError() {
      const previousMe = meCache.get();
      if (previousMe) meCache.set({ ...previousMe, warp: false });
    },
    onSuccess() {
      cache.invalidate();
      meCache.invalidate();
    }
  });

  const error = useWrapError(result.error, {
    error: "Couldn't create Warp subscription."
  });
  return { ...result, error };
}

/** ****************************************************************************/

export function useUpdateWarpSubscription() {
  const kessel = useKessel();
  const cache = useWarpSubscriptionData();
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function (vars: warpSubscription.UpdateWarpSubscriptionReq) {
      const res = await kessel.warpSubscription.updateWarpSubscription(vars);
      return res.body?.data;
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't update Warp subscription."
  });

  return { ...result, error };
}
/** ****************************************************************************/

export function useResumeWarpSubscription() {
  const kessel = useKessel();
  const cache = useWarpSubscriptionData();
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function mutationFn() {
      const res = await kessel.warpSubscription.resumeWarpSubscription();
      return res.body.data;
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't resume Warp subscription."
  });
  return { ...result, error };
}

/** ****************************************************************************/

export function useCancelWarpSubscription() {
  const kessel = useKessel();
  const cache = useWarpSubscriptionData();
  const estimateCache = useEstimateData();

  const result = useMutation(
    async function mutationFn() {
      await kessel.warpSubscription.cancelWarpSubscription();
    },
    {
      onSuccess() {
        cache.invalidate();
        estimateCache.invalidate();
      }
    }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't resume Warp subscription."
  });

  return { ...result, error };
}
